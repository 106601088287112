interface IConnectionManager {
  connect_websocket(url: URL): WebSocketAbortable;
}

interface IAbortable {
  _aborted: boolean;

  abort(): void;
}

type IWebSocketAbortable = IAbortable & WebSocket;

class WebSocketAbortable extends globalThis.WebSocket implements IWebSocketAbortable {
  public _aborted: boolean;

  constructor(...super_args: ConstructorParameters<typeof WebSocket>) {
    super(...super_args);
    this._aborted = false;
  }

  public abort(): void {
    super.close();
    this._aborted = true;
  }
}

/**
 * Manages outbound HTTP requests including WebSocket handshakes.
 */
class ConnectionManager implements IConnectionManager {
  static _singleton: null | ConnectionManager = null;

  readonly #_debug_log: boolean;
  readonly #_websockets_abortable: Set<IWebSocketAbortable>;

  #_abort_controller: null | { ctl: AbortController; requests: Set<string> };

  constructor({ debug_log }: { debug_log: boolean }) {
    if (ConnectionManager._singleton !== null) {
      throw new Error('ConnectionManager should only be instantiated once');
    } else {
      ConnectionManager._singleton = this;
    }

    this.#_abort_controller = null;
    this.#_debug_log = debug_log;
    this.#_websockets_abortable = new Set<IWebSocketAbortable>();
  }

  /**
   * Fetch API wrapper with some capability restricted and some added
   * functionality: Tries to prevent passing in AbortController signal and
   * adds a centralized abortion mechanism intended to be bound to auth cookie
   * state.
   */
  public async fetch(url_path: string, arg_init?: Omit<RequestInit, 'signal'>): ReturnType<typeof fetch> {
    if (typeof url_path !== 'string') {
      throw new Error('Unexpected type of url arg (1st argument): Expected string, got ' + typeof url_path);
    }
    if (arg_init) {
      if (typeof arg_init === 'object') {
        // to centralize aborting outbound requests
        if ('signal' in arg_init) {
          throw new Error("Unexpected 'signal' passed in fetch init arg (2nd argument): Should be omitted");
        }
      }
    } else {
      arg_init = {};
    }

    if (this.#_is_request_auth(url_path)) {
      /* Expecting changes in the session, therefore anything in-flight at
         this point shall be invalidated. */
      this.abort();
    }

    if (!this.#_abort_controller) {
      this.#_abort_controller = { ctl: new AbortController(), requests: new Set<string>() };
      this.#_abort_controller.requests.add(url_path);
    }
    arg_init = Object.assign({}, arg_init, { signal: this.#_abort_controller.ctl.signal });

    this.#_log_debug('%s %s', arg_init.method ?? 'GET', url_path);
    const promise: Promise<Response> = fetch(url_path, arg_init);
    promise.then((response) => {
      this.#_log_debug('Response status %d: %s', response.status, response.url);
    });
    promise.finally(() => this.#_abort_controller?.requests.delete(url_path));
    return promise;
  }

  /**
   * WebSocket connection API (constructor) wrapper with added abort capability
   * because the `AbortController` present in Fetch API does not exist in
   * WebSocket API.
   */
  public connect_websocket(url: URL): WebSocketAbortable {
    this.#_log_debug('Connecting WebSocket...');
    const ws: WebSocketAbortable = new WebSocketAbortable(url);
    this.#_websockets_abortable.add(ws);
    ws.addEventListener('open', () => this.#_websockets_abortable.delete(ws));
    return ws;
  }

  /**
   * Abort any queued or in-flight HTTP requests and WebSocket handshakes that
   * can be aborted.
   */
  public abort(): void {
    // abort WebSocket handshakes
    this.#_abort_websocket_handshakes();

    // abort HTTP requests over Fetch API
    if (this.#_abort_controller?.requests.size) {
      const abortable: string[] = this.#_abort_controller.requests.values().toArray();
      this.#_abort_controller.ctl.abort();
      this.#_abort_controller.requests.clear();
      this.#_abort_controller = null;
      this.#_log_debug('Aborted %d outbound HTTP requests:', abortable.length, abortable.join(', '));
    }
  }

  /**
   * Determine whether a request to given URL is expected to cause a change
   * in auth cookies store.
   */
  #_is_request_auth(url_path: string): boolean {
    return url_path.startsWith('/auth');
  }

  #_abort_websocket_handshakes(): number {
    let aborted_count = 0;
    for (const socket of this.#_websockets_abortable) {
      socket.abort();
      this.#_websockets_abortable.delete(socket);
      aborted_count++;
    }
    if (aborted_count > 0) {
      this.#_log_debug('Aborted %d outbound WebSocket handshakes', aborted_count);
    }
    return aborted_count;
  }

  #_log_debug(msg_fmt: string, ...args: unknown[]) {
    if (!this.#_debug_log) return;
    /* eslint-disable no-console */
    console.debug('[%s] - ' + msg_fmt, new Date().toISOString(), ...args);
  }
}

/**
 * Get reference to `ConnectionManager` singleton.
 */
function get(): ConnectionManager {
  const instance = ConnectionManager._singleton ?? new ConnectionManager({ debug_log: false });
  return instance;
}

export type { IConnectionManager, IWebSocketAbortable };
export default get;
