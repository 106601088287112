import { fetchWithOptions } from '../utility/fetch';
import { parseJWTFromCookie } from '../utility/jwtAuthTools';
import { makeLog } from '../utility/logger';
import { IGroupACLMapping } from './definitions/accessControl';

type TGroups = { orgId: string; groupName: string; capabilities: Array<string> };

const fetchGroups = async (): Promise<Array<TGroups>> => {
  try {
    const response = await fetchWithOptions(
      'api/vault/v2/accessgroup',
      { neurojwt: parseJWTFromCookie(), requestorigin: 'neuro-ui' },
      { method: 'GET' },
    );
    if (response.status === 200) return await response.json();
    else throw response;
  } catch (error) {
    makeLog('Error', { name: 'Accessgroups fetching', message: 'Fetching accessgroups failed' }, error);
    return [];
  }
};

export const mapGroupsToCapabilities = async (): Promise<IGroupACLMapping> => {
  const groups = await fetchGroups();
  return groups.reduce((acc, g) => ({ ...acc, [g.groupName]: g.capabilities }), {});
};
