import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { isPartialDate, formatPartialDate, sortPartialDate } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { isEnded } from '../../../utility/isEnded';

const TherapyDash = ({ documents }: IOwnProps): JSX.Element => {
  const sortedArr = documents?.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();
  const activeTherapies = sortedArr ? sortedArr.filter((at: ITherapy) => !isEnded(at?.endDate)) : [];
  const endedTherapies = sortedArr ? sortedArr.filter((et: ITherapy) => isEnded(et?.endDate)) : [];

  const { formatMessage } = useIntl();

  return (
    <TileContentMaker
      type="listWithSubheaders"
      data={{
        nokey: {
          data: activeTherapies.map((a) => ({
            date: isPartialDate(a.date) ? formatPartialDate(a.date) : '',
            title:
              a?.otherTherapyType ||
              (a?.therapyTypes && <FormattedMessage id={`therapy.opts.${a?.therapyTypes}`} />) ||
              '',
            key: a._id,
          })),
          type: 'recentList',
        },
        [formatMessage({ id: 'therapy.inActiveTherapies' })]: {
          data: endedTherapies.map((a) => ({
            date: isPartialDate(a.date) ? formatPartialDate(a.date) : '',
            title:
              a?.otherTherapyType ||
              (a?.therapyTypes && <FormattedMessage id={`therapy.opts.${a?.therapyTypes}`} />) ||
              '',
            endDate: isPartialDate(a.endDate) ? formatPartialDate(a.endDate) : null,
            key: a._id,
          })),
          type: 'recentList',
        },
      }}
    />
  );
};

interface IOwnProps {
  documents: ITherapy[];
}

export default TherapyDash;
