// Utility functions to check if the browser is IE or not
// legacy = true for version < 11
// legacy = false for IE 11

// Check the IE at first module call and store in value since this does not change
const IE = ((): { isIE: boolean; legacy: boolean } => {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('MSIE ') > -1) return { isIE: true, legacy: true };
  if (ua.indexOf('Trident/') > -1) return { isIE: true, legacy: false };
  return { isIE: false, legacy: false };
})();

export const isIE = (): boolean => IE.isIE;
