import { pick, sum } from 'ramda';
import { sortPartialDate } from 'neuro-utils';
import fig1 from '../Assets/sdmtfigures/symbol-01.svg';
import fig2 from '../Assets/sdmtfigures/symbol-02.svg';
import fig3 from '../Assets/sdmtfigures/symbol-03.svg';
import fig4 from '../Assets/sdmtfigures/symbol-04.svg';
import fig5 from '../Assets/sdmtfigures/symbol-05.svg';
import fig6 from '../Assets/sdmtfigures/symbol-06.svg';
import fig7 from '../Assets/sdmtfigures/symbol-07.svg';
import fig8 from '../Assets/sdmtfigures/symbol-08.svg';
import fig9 from '../Assets/sdmtfigures/symbol-09.svg';

export const figures: { [key: number]: any } = {
  1: fig1,
  2: fig2,
  3: fig3,
  4: fig4,
  5: fig5,
  6: fig6,
  7: fig7,
  8: fig8,
  9: fig9,
};

// config for symbols of sdmt test
export const sdmtSymbolConfig = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// config for practice section of sdmt test
export const sdmtPracticeConfig = [1, 5, 2, 1, 3, 6, 2, 4, 1, 6];

// config for the sdmt test itself
export const sdmtTestConfig = [
  2, 1, 6, 1, 2, 4, 6, 1, 2, 5, 6, 3, 4, 1, 2, 6, 9, 4, 3, 8, 4, 5, 7, 8, 1, 3, 7, 4, 8, 5, 2, 9, 3, 4, 7, 2, 4, 5, 1,
  6, 4, 1, 5, 6, 7, 9, 8, 3, 6, 4, 9, 5, 8, 3, 6, 7, 4, 5, 2, 3, 7, 9, 2, 8, 1, 6, 9, 7, 2, 3, 6, 4, 9, 1, 7, 2, 5, 6,
  8, 4, 2, 8, 7, 9, 3, 7, 8, 5, 1, 9, 2, 1, 4, 3, 6, 5, 2, 1, 6, 4, 2, 1, 6, 9, 7, 3, 5, 4, 8, 9,
];

export const sortAndTakeLatest = (type: string[], documents?: TDocument[]): TDocument[] => {
  if (documents) {
    return documents
      .sort((n1, n2) => n1._cdate - n2._cdate)
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()
      .filter((d) => type.some((t) => d._type === t), documents)
      .slice(0, 7);
  } else return [];
};

const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing ? documents.find((d: TDocument) => d._id === editing)?._type || undefined : undefined;

export const filterDocs = (type: string, documents: TDocument[]): TDocument[] =>
  documents ? documents.filter((d) => d._type === type) : [];

export const mocaConfig = [
  {
    name: 'visuospatialExecutive',
    options: ['connectDots', 'copyCube', 'contour', 'numbers', 'hands'],
    description: false,
  },
  {
    name: 'naming',
    options: ['lion', 'rhino', 'camel'],
    description: false,
  },
  {
    name: 'memory',
    options: [],
    description: true,
  },
  {
    name: 'attentionNumbers',
    options: ['forwards', 'backwards'],
    description: true,
  },
  {
    name: 'attentionLetters',
    options: ['pass'],
    description: true,
  },
  {
    name: 'attentionSubtraction',
    options: ['93', '86', '79', '72', '65'],
    description: true,
  },
  {
    name: 'language',
    options: ['first', 'second', 'fluency'],
    description: false,
  },
  {
    name: 'abstraction',
    options: ['first', 'second'],
    description: false,
  },
  {
    name: 'delayedRecall',
    options: ['first', 'second', 'third', 'fourth', 'fifth'],
    description: false,
  },
  {
    name: 'orientation',
    options: ['date', 'month', 'year', 'day', 'place', 'city'],
    description: false,
  },
];

/**
 * Function for returning total score of a specific task in moca
 * @param name name of the field
 * @param maxScore max score of the task -> number of options in checkbox
 * @param document formdata.document
 */
export const mocaTaskSpecificScore = (name: string, maxScore: number, document: IMOCA): string => {
  return name === 'attentionSubtraction'
    ? `${
        document[name as keyof IMOCA]
          ? document[name as keyof IMOCA].length >= 4
            ? 3
            : document[name as keyof IMOCA].length >= 2
              ? 2
              : document[name as keyof IMOCA].length === 1
                ? 1
                : 0
          : 0
      }/3`
    : `${document[name as keyof IMOCA] ? document[name as keyof IMOCA].length : 0}/${maxScore}`;
};

/**
 * function for calculating total score of moca
 * @param document formdata.document
 *
 */
export const mocaTotalScore = (document: IMOCA): number => {
  const fields = [
    'visuospatialExecutive',
    'naming',
    'attentionNumbers',
    'attentionLetters',
    'attentionSubtraction',
    'language',
    'abstraction',
    'delayedRecall',
    'orientation',
    'twelveYearsOrLessEducation',
  ];
  let score = 0;
  if (document.totalScoreOnly?.[0] === 'totalScoreOnly' && document.totalScore) {
    return document.totalScore;
  }
  fields.forEach((field: string) => {
    const key = field as keyof IMOCA;
    if (field === 'attentionSubtraction') {
      score +=
        document[key] && document[key].length > 3
          ? 3
          : document[key] && document[key].length > 1
            ? 2
            : document[key] && document[key].length === 1
              ? 1
              : 0;
    } else {
      score += document[key] ? document[key].length : 0;
    }
  });
  return score > 30 ? 30 : score;
};

export const mmseConfig = [
  {
    name: 'year',
    options: ['correct'],
    description: false,
  },
  {
    name: 'season',
    options: ['correct'],
    description: true,
  },
  {
    name: 'dayOfMonth',
    options: ['correct'],
    description: false,
  },
  {
    name: 'dayOfWeek',
    options: ['correct'],
    description: false,
  },
  {
    name: 'month',
    options: ['correct'],
    description: false,
  },
  {
    name: 'country',
    options: ['correct'],
    description: false,
  },
  {
    name: 'region',
    options: ['correct'],
    description: false,
  },
  {
    name: 'city',
    options: ['correct'],
    description: false,
  },
  {
    name: 'place',
    options: ['correct'],
    description: true,
  },
  {
    name: 'floor',
    options: ['correct'],
    description: false,
  },
  {
    name: 'words1',
    options: ['first', 'second', 'third'],
    description: true,
  },
  {
    name: 'subtraction',
    options: ['93', '86', '79', '72', '65'],
    description: true,
  },
  {
    name: 'words2',
    options: ['first', 'second', 'third'],
    description: true,
  },
  {
    name: 'items',
    options: ['a', 'b'],
    description: false,
  },
  {
    name: 'repeatSentence',
    options: ['correct'],
    description: true,
  },
  {
    name: 'paper',
    options: ['paperInLeftHand', 'foldsIt', 'setsItOnKnees'],
    description: true,
  },
  {
    name: 'text',
    options: ['correct'],
    description: true,
  },
  {
    name: 'writeSentence',
    options: ['correct'],
    description: true,
  },
  {
    name: 'draw',
    options: ['correct'],
    description: true,
  },
];

/**
 * Function for calculating total score of a mmse test
 * @param document formData.document
 */
export const mmseTotalScore = (document: IMMSE): number => {
  const mmseFields = [
    'year',
    'season',
    'dayOfMonth',
    'dayOfWeek',
    'month',
    'country',
    'region',
    'city',
    'place',
    'floor',
    'words1',
    'subtraction',
    'words2',
    'items',
    'repeatSentence',
    'paper',
    'text',
    'writeSentence',
    'draw',
  ];
  let score = 0;
  if (document.totalScoreOnly?.[0] === 'totalScoreOnly' && document.totalScore) {
    return document.totalScore;
  }
  mmseFields.forEach((field: string) => {
    const key = field as keyof IMMSE;
    // Assignataan document[key] any-typeen,
    // koska jostain syystä valittaa että property length
    // does not exist on type number (vrt mocaTotalScore)
    // Tässä tapauksessa siis tiedetään ettei document[key]:n tyyppi voi olla muuta kuin lista
    score += document[key] ? (document[key] as Array<any>).length : 0;
  });
  return score;
};

export const getHeaderId = (documents: Array<TDocument>, editing: any, viewing: any): string => {
  // handle both editing and viewing modes
  const docId = editing ? editing : viewing;
  // if the user is in editing or viewing mode and docType returns the doctype, return correct title for the header
  if (docType(documents, docId)) {
    return `cognition.${docType(documents, docId)}.${docType(documents, docId)}`;
  }
  return 'cognition.title';
};

export const getColorOfDigitPair = (
  practiceAnswers: Array<{ index: number; correctOrFalse: 'correct' | 'false' }>,
  practiceQuestionsAnswered: number,
  index: number,
): 'white' | 'green' | 'red' | 'blue' => {
  if (practiceQuestionsAnswered === index) {
    return 'blue';
  }
  if (practiceAnswers?.[index]?.correctOrFalse === 'false') {
    return 'red';
  }
  if (practiceAnswers?.[index]?.correctOrFalse === 'correct') {
    return 'green';
  }
  return 'white';
};

/**
 * Function for deciding whether to render symboldigitpair or not in the test
 * @param testQuestionsAnswered number of questions answered
 * @param index index of current symboldigitpair in the array
 */
export const renderSdmtRow = (testQuestionsAnswered: number, index: number): boolean => {
  const config = [
    { min: 0, max: 4 },
    { min: 5, max: 19 },
    { min: 20, max: 34 },
    { min: 35, max: 49 },
    { min: 50, max: 64 },
    { min: 65, max: 79 },
    { min: 80, max: 94 },
    { min: 95, max: 109 },
  ];
  const currentRange =
    testQuestionsAnswered === 0
      ? { min: 0, max: 4 }
      : config.find(
          (obj: { min: number; max: number }) => testQuestionsAnswered >= obj.min && testQuestionsAnswered <= obj.max,
        );
  if (currentRange && index >= currentRange.min && index <= currentRange.max) {
    return true;
  }
  /*
  const temp = Math.floor(testQuestionsAnswered / 10) * 10;
  if (index >= temp && index < temp + 10) {
    return true;
  }
  */
  return false;
};

/**
 * function for deciding whether to render a row or not. If user is not in viewing mode (is in editing mode), render every row
 * @param doc formData.document
 * @param viewing boolean that tells if user is in viewing mode
 * @param name name of the row/question
 */
export const renderTmtRow = (doc: ITMT, viewing: boolean | undefined, name: string): boolean => {
  if (!viewing) {
    return true;
  } else {
    if (name === 'tmtaTime') {
      if (doc.tmtaUnableToPerform?.[0] === 'unableToPerform') {
        return false;
      }
    }
    if (name === 'tmtbTime') {
      if (doc.tmtbUnableToPerform?.[0] === 'unableToPerform') {
        return false;
      }
    }
    if (name === 'tmtaUnableToPerform') {
      if (doc.tmtaUnableToPerform?.[0] != 'unableToPerform') {
        return false;
      }
    }
    if (name === 'tmtbUnableToPerform') {
      if (doc.tmtbUnableToPerform?.[0] != 'unableToPerform') {
        return false;
      }
    }
  }
  return true;
};

export const isCdrFilled = (document: ICDR): boolean => {
  const isFilled = !!(
    (document.memory || document.memory === 0) &&
    (document.orientation || document.orientation === 0) &&
    (document.judgementAndProblemSolving || document.judgementAndProblemSolving === 0) &&
    (document.communityAffairs || document.communityAffairs === 0) &&
    (document.homeAndHobbies || document.homeAndHobbies === 0) &&
    (document.personalCare || document.personalCare === 0)
  );
  return isFilled;
};

export const getCdrTotalScore = (document: ICDR): number | undefined => {
  if (!isCdrFilled(document)) return undefined;
  const totalScoreKeys = pick(
    ['memory', 'orientation', 'judgementAndProblemSolving', 'communityAffairs', 'homeAndHobbies', 'personalCare'],
    document,
  );
  const totalScoreValues = Object.values(totalScoreKeys);
  return sum(totalScoreValues);
};

export const getCdrClass = (document: ICDR): number | undefined => {
  if (!isCdrFilled(document)) return undefined;
  const memVal = document.memory;
  if (memVal === undefined) return undefined; // doesn't happen as there is isCdrFilled checker above, but typescript needs this
  let shiftUpCount = 0;
  let shiftDownCount = 0;
  let sameAsMem = 0;
  const shifters = pick(
    ['orientation', 'judgementAndProblemSolving', 'communityAffairs', 'homeAndHobbies', 'personalCare'],
    document,
  );
  const shifterValues = Object.values(shifters);
  shifterValues.forEach((v) => {
    if (v === memVal) sameAsMem += 1;
    if (v < memVal) shiftDownCount += 1;
    if (v > memVal) shiftUpCount += 1;
  });
  if (sameAsMem >= 3) return memVal;
  const shift = Math.abs(shiftUpCount - shiftDownCount) >= 2;
  if (shift) {
    if (shiftUpCount - shiftDownCount > 0) {
      if (memVal === 3) return memVal;
      if (memVal === 0) return 0.5;
      if (memVal === 0.5) return 1;
      if (memVal === 1 || memVal === 2) return memVal + 1;
    } else {
      if (memVal === 0 || memVal === 0.5) return memVal;
      if (memVal === 1) return 0.5;
      if (memVal === 2 || memVal === 3) return memVal - 1;
    }
  }
  return memVal;
};

export const getFtldCdrClass = (doc: ICDR): TCdrQuestionScale | undefined => {
  const isDocFilled =
    isCdrFilled(doc) && !!((doc.behaviour || doc.behaviour === 0) && (doc.language || doc.language === 0));
  if (!isDocFilled) return undefined;
  const fields = pick(
    [
      'memory',
      'orientation',
      'judgementAndProblemSolving',
      'communityAffairs',
      'homeAndHobbies',
      'personalCare',
      'behaviour',
      'language',
    ],
    doc,
  );
  const valuesArr = Object.values(fields);
  const maxVal = valuesArr.sort()[valuesArr.length - 1];
  if (maxVal === 0) return 0;
  if (maxVal === 0.5) return 0.5;
  if (maxVal === 1 && valuesArr.filter((v) => v === 1).length === 1 && valuesArr.filter((v) => v === 0).length === 7)
    return 0.5;
  if (
    (maxVal === 2 && valuesArr.filter((v) => v === 2).length === 1 && valuesArr.filter((v) => v === 0).length === 7) ||
    (maxVal === 3 && valuesArr.filter((v) => v === 3).length === 1 && valuesArr.filter((v) => v === 0).length === 7)
  )
    return 1;
  if (valuesArr.filter((v) => v === maxVal).length === 1 && valuesArr.filter((v) => v !== 0).length > 0) {
    if (maxVal === 1) {
      return 0.5;
    } else {
      return maxVal === 2 ? 1 : 2;
    }
  }
  if (valuesArr.filter((v) => v === maxVal).length > 1) return maxVal;
  return undefined;
};

export const getFtldCdrTotalScore = (doc: ICDR): number | undefined => {
  const isDocFilled =
    isCdrFilled(doc) && !!((doc.behaviour || doc.behaviour === 0) && (doc.language || doc.language === 0));
  if (!isDocFilled) return undefined;
  const totalScoreFields = pick(
    [
      'memory',
      'orientation',
      'judgementAndProblemSolving',
      'communityAffairs',
      'homeAndHobbies',
      'personalCare',
      'behaviour',
      'language',
    ],
    doc,
  );
  const totalScoreValues = Object.values(totalScoreFields);
  return sum(totalScoreValues);
};

export type TDocument = IMOCA | IMMSE | ITMT | ICDR | ISDMT;

// Type definition for different states of the dialog: first page is the instructions, then practicing and so on
export type IDialogState = 'instructions' | 'practice' | 'test' | 'results';

// Type definition for practice state: In practice state you can either answer correct or false for the questions
// or you can move to the test itself after having answered to those practice questions, so we have here state for deciding whether buttons to show
export type IPracticeState = 'practice' | 'startTest';
