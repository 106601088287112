import { Store } from 'redux';
import { pick, pipe, uniq, unnest, values, defaultTo } from 'ramda';
import { IGroupACLMapping } from '../config/definitions/accessControl';
import { TPlatformCapability } from '../config/capabilities';

interface IConnectedCapabilityAsserter {
  assertCapabilities(capabilities: Array<TPlatformCapability>, groupMapping?: IGroupACLMapping): boolean;
}

const defaultToArray = defaultTo([]);

/**
 * Create capability asserted and connect it to a redux store instance
 * @param store The redux store reference to use (must implement session store)
 * @returns {IConnectedCapabilityAsserter} Connected capability asserter
 */
const createConnectedCapabilityAsserter = (store: Store): IConnectedCapabilityAsserter => {
  /**
   * Asserts that a user has required capabilities based on session data in redux store
   * @param capabilities List of required capabilities
   * @returns {boolean} True/False
   */
  const assertCapabilities = (capabilities: Array<TPlatformCapability>, groupMapping?: IGroupACLMapping): boolean => {
    const state: ISessionStore = store?.getState()?.session;
    if (!state || !state.data || !groupMapping) return false; // If the session or accessgroups is not loaded yet, defaults to false
    const groups = [state.data.orgid, ...defaultToArray(state.data.usergroups)];
    const userCapabilities = pipe(values, unnest, uniq)(pick(groups, groupMapping));
    return capabilities.every((c) => userCapabilities.includes(c));
  };

  return {
    assertCapabilities,
  };
};

export default createConnectedCapabilityAsserter;
