import {
  IAddon,
  IAddonData,
  IEvent,
  IGraphDimensions,
  TAddonOption,
  TSingleAddonTheme,
} from 'Components/sq-graphics/interfaces';
import * as React from 'react';
import {
  AddonWrapper,
  StyledDivider,
  StyledColumn,
  StyledTitle,
  Addon,
  AddonOptions,
  AddonBarArea,
} from './Components';

const SingleAddon = ({ data, timeframeWidth, xPoint, theme, dimensions, totalTimeframe }: IOwnProps): JSX.Element => {
  if (data.addons?.length === 0) return <></>;
  const allEvents: Array<IEvent> = [];
  // TODO: siirrä tää single addoneitte mergeemine validateAddonData-funktioon -> ajetaan graph.tsx:n contructorissa
  data.addons?.forEach((addon) => addon.events?.forEach((event) => allEvents.push(event)));
  const addonsMerged = { ...data.addons?.[0], events: allEvents } as IAddon;
  const addonOptions: TAddonOption[] = data.addonOptions || [];
  return (
    <AddonWrapper dimensions={dimensions}>
      <StyledDivider color={theme.divider} dimensions={dimensions} />
      {/** TODO: nää mitat dimensions-conffiin */}
      <AddonBarArea>
        <StyledColumn padding="0 0.5rem 0 1rem" width={dimensions.leftColumn.width - dimensions.addons.divider.width}>
          {/** @ts-expect-error For some reason fontWeight is not recognized */}
          <StyledTitle color={theme.addon.title} fontWeight="bold">
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.name}</div>
          </StyledTitle>
        </StyledColumn>
        <StyledColumn width={timeframeWidth}>
          <Addon
            addon={addonsMerged}
            width={timeframeWidth}
            xPoint={xPoint}
            theme={theme.addon}
            totalTimeframe={totalTimeframe}
          />
        </StyledColumn>
      </AddonBarArea>
      {addonOptions.length > 0 ? (
        <AddonOptions
          options={addonOptions}
          color={theme.divider}
          width={dimensions.rightColumn.width}
          dimensions={dimensions}
        />
      ) : null}
    </AddonWrapper>
  );
};

interface IOwnProps {
  data: IAddonData;
  timeframeWidth: number;
  xPoint: (d: Date | undefined) => number | undefined;
  theme: TSingleAddonTheme;
  dimensions: IGraphDimensions;
  totalTimeframe: [Date, Date];
}

export default SingleAddon;
