import * as React from 'react';
import { useAppSelector, useAppSelector as useSelector } from 'Store/index';
import { getDiseaseModifyingMedications } from '../utils';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { intersperse } from 'ramda';
import { sortDocuments } from '../../../utility/randomUtil';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import MedicationTile from 'Components/DashboardTile/TileContentMaker/components/MedicationTile';
import { getAtcCodesByPlatform } from '../Document/config';

// Return latest SMA/DMD medication sorted its administration dates
const findLatestSmaDmdMedication = (
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
  platform: string,
  documents: IMedication[],
): IMedication[] => {
  /** Medications visualized in tile are different from graph, so define them here */
  const atcCodesToVisualize = getAtcCodesByPlatform(medicationSettings, platform, { categories: ['tile'] });

  const medications = (documents ?? []).filter((document) => {
    return atcCodesToVisualize.includes(document.atc ?? '');
  });

  return medications;
};

const GeneralTile = ({ documents }: { documents: Array<IMedication>; platform: Platform }): JSX.Element => {
  const platformMedications: IMedication[] =
    documents && documents.length > 0 ? documents.filter((d) => d.hasEnded?.[0] !== true) : [];
  const platformMedicationsEnded: IMedication[] =
    documents && documents.length > 0 ? documents.filter((d) => d.hasEnded?.[0] === true) : [];

  const latestMedication: IMedication | undefined = platformMedications.sort((a, b) =>
    sortDocuments([{ type: 'date', sortField: 'startDate' }])(a, b),
  )[0];
  const latestMedicationEnded: IMedication | undefined = platformMedicationsEnded.sort((a, b) =>
    sortDocuments([{ type: 'date', sortField: 'endDate' }])(a, b),
  )[0];

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const dataPicker = (document: IMedication) => {
    const dateFieldName = isPartialDate(document?.usageStartDate) ? 'usageStartDate' : 'startDate';
    const dateValue = document?.[dateFieldName];
    return {
      title: fm(document.hasEnded?.[0] === true ? 'medication.tileEnded' : 'medication.tileStarted'),
      medicationName: document.medicationName,
      date: isPartialDate(dateValue) ? formatPartialDate(dateValue) : null,
      endDate:
        isPartialDate(document.endDate) && document.hasEnded?.[0] === true ? formatPartialDate(document.endDate) : null,
      endReasons: intersperse(
        ', ',
        (document.endReason || []).map((reason) => fm('medication.opts.' + reason)),
      ),
      key: document._id,
    };
  };

  return (
    <React.Fragment>
      {latestMedication || latestMedicationEnded ? (
        <MedicationTile
          data={[
            ...(latestMedication ? [dataPicker(latestMedication)] : []),
            ...(latestMedicationEnded ? [dataPicker(latestMedicationEnded)] : []),
          ]}
        />
      ) : (
        <DescriptionText>
          <FormattedMessage id="medication.noDiseaseModifyingMedications" />
        </DescriptionText>
      )}
    </React.Fragment>
  );
};

const MedicationDash = ({ documents: documentsRedux }: IOwnProps): JSX.Element | null => {
  const documents: IMedication[] | undefined = JSON.parse(JSON.stringify(documentsRedux));

  // Only use documents owned by current organization
  const orgId = useSelector((s: IState) => s.session.data?.orgid);
  const selectedMedicationOrg = useSelector((s: IState) => s.session.selectedMedicationOrg) || orgId;
  const thisOrgDocuments = (documents || []).filter(
    (d) =>
      d._ownerOrg === selectedMedicationOrg ||
      (d._pastOwners && Object.keys(d._pastOwners).some((org) => org === selectedMedicationOrg)),
  );
  const platform = useAppSelector((s) => s.session.platforms?.selected);

  const studyMedications = thisOrgDocuments.filter((d) => d.isClinicalStudy);

  const medicationSettings = useAppSelector((s: IState) => s.settings?.orgSettings?.settings?.medicationSettings);

  return thisOrgDocuments && platform ? (
    <React.Fragment>
      <GeneralTile
        documents={
          platform === 'sma' || platform === 'dmd'
            ? [...findLatestSmaDmdMedication(medicationSettings, platform, thisOrgDocuments || []), ...studyMedications]
            : [
                ...getDiseaseModifyingMedications(medicationSettings, thisOrgDocuments || [], platform),
                ...studyMedications,
              ]
        }
        platform={platform}
      />
    </React.Fragment>
  ) : null;
};

interface IOwnProps {
  documents?: IMedication[];
}

export default MedicationDash;
