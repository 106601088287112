import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate } from 'neuro-utils';

import { TDocument } from '../utils';
import { sortDocuments } from '../../../utility/randomUtil';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const DiagnosticSurveyDash = ({ documents }: IOwnProps): JSX.Element => {
  const sortedDocs = documents
    ? documents
        .filter((d) => !['diagnosis', 'mmse'].includes(d._type))
        .sort((a, b) => sortDocuments([{ type: 'date', sortField: 'date' }])(a, b))
    : [];

  return (
    <React.Fragment>
      <TileContentMaker
        type="recentList"
        data={sortedDocs.map((d) => ({
          date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
          title: fm(`diagnosticSurvey.${d._type}.title`),
          key: d._id,
        }))}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument | IDiagnosis | IMMSE>;
}

export default DiagnosticSurveyDash;
