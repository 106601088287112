/**
 * Helper function for react-intl to flatten JSON tree.
 * @param  {JSON} nestedMessages - JSON object to be flattened
 * @param  {string} prefix - Optional prefix to control which keys are flattened (default '' does everything)
 * @return {{[key: string]: string}} Flattened JSON object
 */
const flattenMessages = (nestedMessages: IMessages, prefix = ''): { [key: string]: string } => {
  return Object.keys(nestedMessages).reduce((messages: IMessages, key) => {
    const value = nestedMessages[key];
    const prefixedKey: string = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

interface IMessages {
  [key: string]: string;
}

export default flattenMessages;
