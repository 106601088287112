import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { getAtcCodesByPlatform } from 'Routes/Medication/Document/config';

/*
const topdataTitle = (ageMonths: number | undefined): any => {
  if ((ageMonths || 60) < 60) {
    return 'graph.ageMonths';
  } else {
    return 'graph.ageYears';
  }
};*/

const dmdData = (
  fm: (id: string) => string,
  uiLang: Locale,
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
): IDiseaseHistoryData => {
  const clinicalMeters = fm('graph.clinicalMeters');
  const patientSelfReport = fm('graph.patientSelfReport');

  const atcCodes = getAtcCodesByPlatform(medicationSettings, 'dmd');
  const atcOpts = medicationSettings?.dmd.localizations[uiLang as 'fi' | 'en'];

  return {
    graphData: {
      common: {
        nsaa: {
          data: [],
          title: 'NSAA',
          scale: {
            type: 'linear',
            linearScale: [0, 34],
          },
        },
        mfm20: {
          data: [],
          title: 'MFM 20',
          scale: {
            type: 'linear',
            linearScale: [0, 100],
          },
        },
        mfm32: {
          data: [],
          title: 'MFM 32',
          scale: {
            type: 'linear',
            linearScale: [0, 100],
          },
        },
        _6mwt: {
          data: [],
          title: '6MWT',
          scale: {
            type: 'linear',
          },
        },
        weights: {
          data: [],
          title: fm('background.weightKG'),
          scale: {
            type: 'linear',
          },
        },
        heights: {
          data: [],
          title: fm('background.heightCM'),
          scale: {
            type: 'linear',
          },
        },
      },
      selfReporting: {
        pedsqlNeuromuscular: {
          data: [],
          title: fm('myService.sma.opts.pedsqlNeuromuscular'),
          scale: {
            id: 'pedsqlNeuromuscular',
            type: 'linear',
            linearScale: [0, 100],
          },
        },
        pedsqlFatigue: {
          data: [],
          title: fm('myService.dmd.opts.pedsqlFatigue'),
          scale: {
            id: 'pedsqlFatigue',
            type: 'linear',
            linearScale: [0, 72],
          },
        },
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: false,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    addonData: {
      motorFunctions: {
        id: 'motorFunctions',
        type: 'expansionPanel',
        name: fm('graph.motorFunctions'),
        addons: [],
      },
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('graph.medications'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          Object.keys(atcOpts ?? {})
            .filter((code) => atcCodes.includes(code))
            .map((code) => {
              return { title: `${code} - ${atcOpts?.[code]}` };
            }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('graph.endedMedications'),
        addons: [],
      },
      therapies: {
        id: 'therapies',
        type: 'expansionPanel',
        name: fm('graph.therapies'),
        addons: [],
      },
      aids: {
        id: 'aids',
        type: 'expansionPanel',
        name: fm('graph.aids'),
        addons: [],
      },
      studies: {
        id: 'studies',
        type: 'expansionPanel',
        name: fm('graph.studies'),
        addons: [],
      },
      comorbiditiesAndHospitalizations: {
        id: 'comorbiditiesAndHospitalizations',
        type: 'expansionPanel',
        name: fm('graph.comorbiditiesAndHospitalizations'),
        addons: [],
        expansionPanelTitleDescription: [
          { title: fm('comorbidity.keyDiagnoses.title'), style: { fontWeight: 600 } },
        ].concat(
          ['m24.5', 'm40', 'm40.0', 'm40.1', 'm40.2', 'm41', 'm41.4', 'q65'].map((code) => {
            return { title: `${fm(`comorbidity.keyDiagnoses.${code}`)}` };
          }) as [],
        ),
      },
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('graph.diagnosis'),
        addons: [],
      },
      appointments: {
        id: 'appointments',
        type: 'single',
        name: fm('appointments.appointments'),
        addons: [],
      },
    },
  };
};

export default dmdData;
