import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import * as React from 'react';
import { MyServiceContext } from '..';
import TaskListForm, { TaskListFormProps } from './TaskList';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { useAppSelector as useSelector } from 'Store/index';

const InviteForm = ({ taskListSettings }: { taskListSettings?: TaskListFormProps }): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, editing, setEditingData, platform } = myServContext;

  const onChangeInvForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];

    if (setEditingData) {
      setEditingData({ ...(editing?.data || ({} as IInvite)), [field]: value });
    }
  };

  const inviteData = editing?.data as IInvite;

  const sentInvites = useSelector((s: IState) => s.myapp.sentInvites);

  return (
    <React.Fragment>
      <FormRow title="myService.sendingMethod">
        <InputHandler
          name="sendingMethod"
          type="Checkbox"
          options={['sms', 'email']}
          editing={true}
          formData={{ document: { sendingMethod: inviteData?.sendingMethod }, onChange: onChangeInvForm }}
          optionFormatter={(o) => (typeof o === 'string' ? fm(`myService.opts.${o}`) : `${o}`)}
          optionSpecificElements={[
            {
              sms: (
                <InputHandler
                  type="NumberField"
                  name="sms"
                  editing={true}
                  placeholder={'myService.sms'}
                  formData={{ document: { sms: inviteData?.sms }, onChange: onChangeInvForm }}
                  saveAsString={true}
                  allowZeroStart={true}
                  allowPlusSignStart={true}
                />
              ),
            },
            {
              email: (
                <InputHandler
                  type="TextField"
                  name="email"
                  editing={true}
                  placeholder={'myService.opts.email'}
                  formData={{ document: { email: inviteData?.email }, onChange: onChangeInvForm }}
                />
              ),
            },
          ]}
        />
      </FormRow>
      <React.Fragment>
        {platform !== 'epilepsy' && (!sentInvites || sentInvites.length === 0) && (
          <React.Fragment>
            <HistoryRowSubHeader header={fm('myService.firstStimuli')} />
            <TaskListForm taskListSettings={taskListSettings} />
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default InviteForm;
