import { createReducer } from '@reduxjs/toolkit';
import { mergeDocument } from 'Utility/documentHandling';
import { reduxActions } from './actions';

const initialState: IMyAppStore = {
  mysqUserId: null,
  sentInvites: [],
  sortedAndMergedDocuments: [],
  taskLists: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(reduxActions.loadDataAction, (state, action) => {
      return {
        ...state,
        mysqUserId: action.payload.mysqUserId,
        sentInvites: action.payload.sentInvites,
        sortedAndMergedDocuments: action.payload.sortedAndMergedDocuments,
      };
    })
    .addCase(reduxActions.clearDataAction, () => {
      return initialState;
    })
    .addCase(reduxActions.newDocumentAction, (state, action) => {
      return {
        ...state,
        sortedAndMergedDocuments: [...(state.sortedAndMergedDocuments || []), mergeDocument(action.payload.document)],
      };
    })
    .addCase(reduxActions.newCommitAction, (state, action) => {
      const { id: committedDocId, data: newCommit } = action.payload;
      for (const doc of state.sortedAndMergedDocuments) {
        if (doc._id !== committedDocId) continue;
        else {
          doc._cid = newCommit.commitId;
          doc._cdate = newCommit.commitDate || doc._cdate;
          doc._commitCreator = newCommit.creatorId;
          doc._commitsLength = (doc._commitsLength || 0) + 1;
          Object.assign(doc, newCommit.data);
        }
      }
    })
    .addCase(reduxActions.deleteDocumentAction, (state, action) => {
      const sortedAndMergedDocumentsFiltered = state.sortedAndMergedDocuments?.filter(
        (d) => d._id !== action.payload.id,
      );
      return {
        ...state,
        sortedAndMergedDocuments: sortedAndMergedDocumentsFiltered || [],
      };
    })
    .addCase(reduxActions.loadTasklistsAction, (state, action) => {
      state.taskLists = action.payload;
    })
    .addCase(reduxActions.createTasklistAction, (state, action) => {
      state.taskLists = [...(state.taskLists || []), action.payload];
    })
    .addCase(reduxActions.updateTasklistAction, (state, action) => {
      const tasklistsUpdated = state.taskLists?.map((tl) => (tl.id === action.payload.id ? action.payload : tl));
      state.taskLists = tasklistsUpdated || [];
    })
    .addCase(reduxActions.deleteTasklistAction, (state, action) => {
      const tasklistsFiltered = state.taskLists?.filter((tl) => tl.id !== action.payload.id);
      state.taskLists = tasklistsFiltered || [];
    })
    .addCase(reduxActions.createInviteAction, (state, action) => {
      state.sentInvites = [...(state.sentInvites || []), action.payload];
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
