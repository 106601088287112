import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import { TDocument, isMGCComplete, mgcScore } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const getLatest = (
  type: 'mgc' | 'mgfa',
  docs: Array<TDocument>,
): { date: PartialDate | undefined; value: JSX.Element | string | undefined } | undefined => {
  const latestDoc = docs.filter((d) => d._type === type).sort((n1, n2) => sortPartialDate(n2.date, n1.date))[0];
  if (!latestDoc) return undefined;
  switch (type) {
    case 'mgc': {
      const latest = latestDoc as IMGC;
      const dateOfLatest = latest.date;
      const valueOfLatest = isMGCComplete(latest)
        ? mgcScore(latest).toString()
        : latest.manualScore || latest.manualScore === 0
          ? latest.manualScore.toString()
          : '-';
      return {
        date: dateOfLatest,
        value: valueOfLatest,
      };
    }
    case 'mgfa': {
      const latest = latestDoc as IMGFA;
      return {
        date: latest.date,
        value: latest.classification ?? '-',
      };
    }
  }
};

const FunctionalPerformanceMgravisDash = ({ documents }: IOwnProps): JSX.Element => {
  const mgc = getLatest('mgc', documents);
  const mgfa = getLatest('mgfa', documents);

  return (
    <React.Fragment>
      <TileContentMaker
        type="specificList"
        data={[
          {
            date: isPartialDate(mgc?.date) ? formatPartialDate(mgc?.date) : '',
            title: fm('functionalPerformanceMgravis.mgcShort'),
            value: !mgc ? fm('functionalPerformanceMgravis.notEvaluated') : mgc?.value,
            key: 'mgc',
          },
          {
            date: isPartialDate(mgfa?.date) ? formatPartialDate(mgfa?.date) : '',
            title: fm('functionalPerformanceMgravis.mgfaShort'),
            value: !mgfa ? fm('functionalPerformanceMgravis.notEvaluated') : mgfa?.value,
            key: 'mgfa',
          },
        ]}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default FunctionalPerformanceMgravisDash;
