import * as React from 'react';
import { FormattedMessage } from 'react-intl';

const idToGeneral = (id: string): JSX.Element => <FormattedMessage id={`general.${id.length > 0 ? id : 'empty'}`} />;

const presets = {
  yesno: ['true', 'false'],
  yes: ['true'],
  yesForceEn: ['true'],
  yesnoForceEn: ['yes', 'no'],
  yesNoUnknown: ['yes', 'no', 'unknown'],
  yesNoUnknownForceEn: ['yes', 'no', 'unknown'],
  yesNoUnknownEmpty: ['yes', 'no', 'unknown', 'empty'],
  positiveNegativeUnknown: ['positive', 'negative', 'unknown'],
} as { [key: string]: any[] };

const presetFormatter = {
  yesno: idToGeneral,
  yes: idToGeneral,
  yesForceEn: (id: boolean[]) => (id[0] ? 'Yes' : 'No'),
  yesnoForceEn: (id: string) => (id === 'yes' ? 'Yes' : 'No'),
  yesNoUnknown: idToGeneral,
  yesNoUnknownForceEn: (id: string) => (id === 'yes' ? 'Yes' : id === 'no' ? 'No' : 'Unknown'),
  yesNoUnknownEmpty: idToGeneral,
  positiveNegativeUnknown: idToGeneral,
} as { [key: string]: (id: any) => JSX.Element | string };

export const presetOpts = (preset: string): any[] => presets[preset];

export const presetOptFormatter = (preset: string): ((id: string) => string | JSX.Element) => presetFormatter[preset];
