import * as React from 'react';
import { parseLinearScale } from './calculators';
import {
  TGraphScale,
  IData,
  TGraphData,
  TGraphSettings,
  IAddon,
  IDashboardGraphProps,
  IEvent,
  IItem,
  IGraphData,
  ILegend,
  TAddonData,
} from '../interfaces';
import { shouldGraphMenuBeDrawn } from './checkers';
import { sortDate, sortTime } from 'neuro-utils';
import { equals } from 'ramda';
import store from 'Store/index';
import { getDefaultSettings } from '../config';
import * as Icon from '../Graph/AddonArea/Icons';
import { getThemeIdsAsAddonSettings } from 'Routes/Medication/Document/config';

const validateScale = (scale: TGraphScale, data: IGraphData['data'], useAdditionalScale: boolean): TGraphScale => {
  if (data.every((d) => 'data' in d)) {
    return scale;
  } else if (scale.type === 'linear' && !scale.linearScale) {
    scale.linearScale = parseLinearScale(data as IData[], scale, useAdditionalScale);
  }
  return scale;
};

// TODO: tää pitäis siirtää config.ts:ssään?
const singleGraphTypes: Array<IData['type']> = ['heatGraph'];

/**
 * Show BDI/BAI as long as either of them has datapoints.
 * @todo TODO: Determine if this should be a general rule, not just an exception specific to BDI/BAI
 */
const showBdiAndBaiLegend = (id: 'bdi' | 'bai', data: IData[]): boolean => {
  switch (id) {
    case 'bdi':
      return data.some((d) => d.id === 'bai' && d.dataPoints && d.dataPoints.length > 0);
    case 'bai':
      return data.some((d) => d.id === 'bdi' && d.dataPoints && d.dataPoints.length > 0);
    default:
      return false;
  }
};

const validateData = (data: IGraphData['data']): IGraphData['data'] => {
  if (data.every((d) => 'data' in d)) {
    return data;
  } else {
    const singleGraphType = (data as IData[]).find((d) => singleGraphTypes.includes(d.type));
    if (singleGraphType) return [singleGraphType];
    data = (data as IData[]).filter(
      (d, _, arr) =>
        d.dataPoints &&
        (d.dataPoints.length > 0 || (['bdi', 'bai'].includes(d.id) && showBdiAndBaiLegend(d.id as 'bdi' | 'bai', arr))),
    );
    (data as IData[]).forEach((d) =>
      d.dataPoints.sort((dp1, dp2) => sortDate(dp1.date, dp2.date) || sortTime(dp1.time, dp2.time)),
    );
    return data;
  }
};

/**
 * Function for validating graphData received as props
 * @param graphData The graphData object
 * @param settings Graph settings object
 * @returns Validated graphData
 */
const validateGraphData = (graphData: TGraphData, settings: Required<TGraphSettings>): TGraphData => {
  const obj: TGraphData = { common: {} };
  Object.entries(graphData).forEach(([key, data]) => {
    if (!(key in obj)) obj[key] = {};
    Object.entries(data).forEach(([k, d]) => {
      if (!shouldGraphMenuBeDrawn(d, k, settings.graphMenusToBeHiddenIfEmpty)) {
        return;
      }
      obj[key][k] = { ...d, data: validateData(d.data), scale: validateScale(d.scale, d.data, false) };
      if (d.additionalScale) obj[key][k]['additionalScale'] = validateScale(d.additionalScale, d.data, true);
    });
  });
  return obj;
};

/**
 * Function for validating addonData received as prop. Also splits the addonData into topData and bottomData objects
 * @param addonData THe addonData object
 * @param topDataAddons keys of addons to be shown above the graph
 * @returns topData and bottomData objects
 */
const parseAndValidateAddonData = (
  addonData: IDashboardGraphProps['addonData'],
  topDataAddons: string[],
): { topData: IDashboardGraphProps['addonData']; bottomData: IDashboardGraphProps['addonData'] } => {
  const topData: IDashboardGraphProps['addonData'] = {};
  const bottomData: IDashboardGraphProps['addonData'] = {};
  Object.entries(addonData).forEach(([key, addon]) => {
    switch (addon.type) {
      case 'single': {
        if (!addon || (addon.addons || []).length < 1) break;
        if ((addon.addons || []).length <= 1) {
          bottomData[key] = addon;
          break;
        }
        const events: IEvent[] = [];
        const items: IItem[] = [];
        (addon.addons || []).forEach((a) => {
          events.push(...(a.events || []));
          items.push(...(a.items || []));
        });
        const mergedAddon: IAddon = {
          id: addon?.addons?.[0].id ?? '',
          themeId: addon.addons?.find((a) => a.themeId)?.themeId,
          title: addon.addons?.[0].title ?? '',
          titleDescription: addon.addons?.find((a) => a.titleDescription)?.titleDescription,
          items: items,
          events: events,
        };
        bottomData[key] = {
          id: addon.id,
          type: 'single',
          name: addon.name,
          expansionPanelTitleDescription: addon.expansionPanelTitleDescription,
          addons: [mergedAddon],
          addonOptions: addon.addonOptions,
          legend: addon.legend,
        };
        break;
      }
      case 'expansionPanel': {
        // If expansion panel should always be shown, then add a placeholder item
        if (addon.expansionPanelShowAlways && (!addon || (addon.addons || []).length < 1)) {
          const placeHolderAddon: IAddon = { id: 'placeholder', title: 'placeholder' };
          bottomData[key] = { ...addon, addons: [placeHolderAddon] };
          break;
        }

        // Jos ei löydy addoneita ni jätetään se huomioimatta
        if (!addon || (addon.addons || []).length < 1) break;
        // Jos ei löydy mergettäviä addoneita ni ei yritetä mergee niitä
        if ((addon?.addons || []).length <= 1) {
          bottomData[key] = addon;
          break;
        }

        const ids: string[] = [];
        addon.addons?.forEach((a) => {
          if (ids.includes(a.id)) return;
          ids.push(a.id);
        });

        // Jos id:eitte määrä sama ku addoneitte määrä, voidaan suoraan lisätä addon dataan
        if (ids.length === (addon.addons || []).length) {
          bottomData[key] = addon;
          break;
        }

        const groupedAddons = ids.map((id) => addon.addons?.filter((a) => a.id === id)).filter((ga) => !!ga);
        const addons: Array<IAddon> = groupedAddons
          .filter((ga) => !!ga)
          .map((ga) => {
            if (ga && ga.length < 2) return ga[0];
            else {
              const items: IItem[] = [];
              const events: IEvent[] = [];
              ga?.forEach((a) => {
                items.push(...(a.items || []));
                events.push(...(a.events || []));
              });
              items.sort((n1, n2) => sortDate(n1.start, n2.start));
              events.sort((n1, n2) => sortDate(n1.date, n2.date));
              return {
                id: ga?.[0].id,
                themeId: ga?.[0].themeId,
                title: ga?.find((a) => !!a.title)?.title ?? '',
                titleDescription: ga?.[0].titleDescription,
                items,
                events,
              } as IAddon;
            }
          });
        const validatedAddon = { ...addon, addons: addons };
        bottomData[key] = validatedAddon;
        break;
      }
    }
  });

  // Jaetaan tässä addonit topDatan ja bottomDatan välille
  topDataAddons.forEach((key) => {
    if (key in bottomData) {
      topData[key] = Object.assign({}, bottomData[key]);
      delete bottomData[key];
    }
  });

  return { topData, bottomData };
};

/**
 * Function for slicing the dataPoints array of graphData object so that no redundant dataPoints are passed to visualizing components
 * @param graphData The graphData object
 * @param selLeftMenu Selected graphmenu
 * @param timeframe Timeframe
 * @returns Graphdata object from where redundant dataPoints have been reduced
 */
const filterGraphData = (
  graphData: IDashboardGraphProps['graphData'],
  selLeftMenu: string,
  timeframe: [Date, Date] | undefined,
): IGraphData | undefined => {
  // If no class with given selLeftMenu is found, return undefined
  if (!Object.keys(graphData).find((k) => !!graphData?.[k]?.[selLeftMenu])) return undefined;
  // Assign the class (common, patient self reporting or so) into a variable named _class
  const _class = Object.keys(graphData).find((k) => !!graphData?.[k]?.[selLeftMenu]) ?? 'common';
  /** If no timeframe is given (timeframe is totaltimeframe => filtering not required, just return the wanted object) */
  if (!timeframe) return graphData[_class][selLeftMenu];
  // Does finding the first index here cause any bugs?
  const data = graphData[_class][selLeftMenu].data.map((d) => {
    if (d && 'data' in d) {
      return {
        data: d.data.map((dd) => {
          if ((dd.dataPoints || []).length < 4) return dd;
          let startIndex =
            dd.dataPoints.findIndex(
              (dp) =>
                dp.date.getTime() > timeframe[0].getTime() ||
                (dp.endDate && dp.endDate.getTime() > timeframe[0].getTime()),
            ) - 1;
          startIndex = startIndex < 0 ? 0 : startIndex;

          let endIndex = dd.dataPoints
            .reverse()
            .findIndex(
              (dp) =>
                dp.date.getTime() < timeframe[1].getTime() ||
                (dp.endDate && dp.endDate.getTime() < timeframe[1].getTime()),
            );
          dd.dataPoints.reverse();
          endIndex = dd.dataPoints.length - endIndex + 1;
          endIndex = endIndex > dd.dataPoints.length ? dd.dataPoints.length : endIndex;
          return {
            ...dd,
            dataPoints: dd.dataPoints.slice(startIndex, endIndex),
          };
        }),
        id: d.id,
      };
    } else {
      // If 3 dataPoints or less, return them all, or if graph type === heatGraph which can have several datapoints on the same day
      if ((d.dataPoints || []).length < 4 || d.type === 'heatGraph') return d;
      // Find index of first dataPoint that has date bigger than timeframe[0] and subtract 1 from it to have the index start from the last dataPoint before timeframe
      let startIndex =
        d.dataPoints.findIndex(
          (dp) =>
            dp.date.getTime() > timeframe[0].getTime() || (dp.endDate && dp.endDate.getTime() > timeframe[0].getTime()),
        ) - 1;
      startIndex = startIndex < 0 ? 0 : startIndex;
      // From the end, find index of first item that has date smaller than timeframe[1]

      let endIndex = d.dataPoints
        .reverse()
        .findIndex(
          (dp) =>
            dp.date.getTime() < timeframe[1].getTime() || (dp.endDate && dp.endDate.getTime() < timeframe[1].getTime()),
        );
      d.dataPoints.reverse();
      endIndex = d.dataPoints.length - endIndex + 1;
      endIndex = endIndex > d.dataPoints.length ? d.dataPoints.length : endIndex;
      return {
        ...d,
        dataPoints: d.dataPoints.slice(startIndex, endIndex),
      };
    }
  });
  return { ...graphData[_class][selLeftMenu], data };
};

/**
 * Function for filtering addonData so that no redundant dataPoints (events or items) are passed to the visualizing component
 * @param addonData The addonData object
 * @param timeframe Timeframe
 * @returns AddonData object from where redundant dataPoints have been reduced
 */
const filterAddonData = (
  addonData: IDashboardGraphProps['addonData'],
  timeframe: [Date, Date],
): IDashboardGraphProps['addonData'] => {
  const obj: IDashboardGraphProps['addonData'] = {};
  Object.entries(addonData).forEach(([key, data]) => {
    obj[key] = {
      ...data,
      addons: (data.addons || []).map((a) => {
        const referenceDynamic = (a.events || [])
          .filter(
            (e) =>
              e.eventType === 'dynamic' &&
              e.date.valueOf() <= timeframe[0].valueOf() &&
              (!e.endDate || e.endDate.valueOf() > timeframe[0].valueOf()) &&
              e.date.valueOf() <= timeframe[1].valueOf(),
          )
          .sort((e1, e2) => sortDate(e2.date, e1.date))[0];
        return {
          ...a,
          events: (a.events || [])
            .filter((e: IEvent) => {
              if (equals(referenceDynamic, e)) return true;
              return e.date.valueOf() >= timeframe[0].valueOf() && e.date.valueOf() <= timeframe[1].valueOf();
            })
            .map((e) => {
              if (equals(referenceDynamic, e)) return { ...e, date: timeframe[0] };
              return e;
            }),
          items: (a.items || []).filter((i: IItem) =>
            i.end
              ? i.start.valueOf() <= timeframe[1].valueOf() && i.end.valueOf() >= timeframe[0].valueOf()
              : i.start.valueOf() <= timeframe[1].valueOf(),
          ),
        };
      }),
    };
  });
  return obj;
};

// This used for sorting timeframeLength options
const timeframeLengthValues = {
  all: 100,
  '5y': 90,
  '3y': 80,
  '2y': 70,
  '1y': 60,
  '9m': 50,
  '6m': 45,
  '3m': 40,
  '2m': 35,
  '1m': 30,
  '14d': 20,
  '7d': 15,
  '3d': 10,
  custom: 0,
};

/**
 * Function for merging the settings object received as prop with defaultSettings of the graph
 * @param settings settings object received as prop
 * @returns Settings object where all fields are provided with value
 */
const parseGraphSettings = (settings: IDashboardGraphProps['settings'] | undefined): Required<TGraphSettings> => {
  const medicationSettings = store.getState().settings?.orgSettings?.settings?.medicationSettings;
  const themeIdsAsAddonSettings = getThemeIdsAsAddonSettings(medicationSettings);

  const defaultSettings = getDefaultSettings(themeIdsAsAddonSettings);

  const timeframeLengthOptions = settings?.timeframeLengthOptions || defaultSettings.timeframeLengthOptions;
  if (!timeframeLengthOptions.includes('all')) timeframeLengthOptions.push('all');
  timeframeLengthOptions.sort((o1, o2) => timeframeLengthValues[o2] - timeframeLengthValues[o1]);
  const obj: Required<TGraphSettings> = { ...defaultSettings, ...settings, timeframeLengthOptions };
  return obj;
};

/**
 * Function for modifying the default timeframe of the graph
 * @param totalTimeframe Total timeframe
 * @returns Default timeframe for the graph
 */
const parseDefaultTimeframe = (
  totalTimeframe: [Date, Date],
  defaultTimeframeLength: TTimeframeLengthOption,
): [Date, Date] => {
  if (defaultTimeframeLength === 'all') return totalTimeframe;
  // Täs pitäis händlätä sit muut vaihtoehdot
  switch (defaultTimeframeLength) {
    default:
      return totalTimeframe;
  }
};

/**
 * Function for parsing default left menu (the graph menu that is shown when graph renders for the first time)
 * @param graphData graphData object
 * @returns default left menu
 */
const parseDefaultLeftMenu = (graphData: IDashboardGraphProps['graphData']): string => {
  // Palauttaa ekan tabin jossa dataa
  let keyToReturn: string | undefined = undefined;
  const temp: Array<[string, string]> = [];
  Object.keys(graphData).forEach((key) => {
    Object.keys(graphData[key]).forEach((k) => {
      temp.push([key, k]);
    });
  });
  // SPaghetti
  keyToReturn = temp.find((arr) => {
    if (
      graphData?.[arr[0]]?.[arr[1]]?.data?.find((d) =>
        'data' in d
          ? (d.data.find((dd) => dd.dataPoints)?.dataPoints?.length ?? 0) > 0
          : ('dataPoints' in d ? d.dataPoints : []).length > 0,
      )
    ) {
      return true;
    }
    return false;
  })?.[1];
  if (keyToReturn) return keyToReturn;
  // Jos ei löydy ni palautetaan eka täbi mikä vaan löytyy
  Object.keys(graphData)
    .reverse()
    .forEach((key) => {
      if (Object.keys(graphData[key]).length) {
        keyToReturn = Object.keys(graphData[key])[0];
        return;
      }
    });
  return keyToReturn ?? '';
};

// Spaghetti
// TODO
// SHould be refactored so that the legends are parsed completely automatically
const parseSpecificAddonLegends = (addonData: TAddonData, settings: Required<TGraphSettings>): ILegend[] => {
  if (!settings) return [];
  const legends: ILegend[] = [];
  Object.values(addonData).forEach((data) => {
    if (data.id === 'relapse' && data.addons?.find((addon) => !!addon.events?.find((e) => e.priority === 'high'))) {
      const legendTitles: string[] = [];
      data.addons.forEach((addon) => {
        if ((addon.events || []).find((e) => e.priority === 'high')) {
          (addon.events || [])
            .filter((e) => e.priority === 'high')
            .forEach((e) => {
              if (!e.title || (e.title && legendTitles.includes(e.title))) return;
              legendTitles.push(e.title);
            });
        }
      });
      legends.push({
        id: 'relapseHigh',
        legend: legendTitles.join(', '),
        icon: (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Icon.IconChange
              priority="high"
              strokeColor="#C20012"
              strokeWidth={2}
              fillColor="white"
              large={true}
              active={true}
              addon={true}
            />
          </svg>
        ),
      });
    }
    if (data.id === 'relapse' && data.addons?.find((addon) => !!addon.events?.find((e) => e.priority === 'low'))) {
      const legendTitles: string[] = [];
      data.addons.forEach((addon) => {
        if ((addon.events || []).find((e) => e.priority === 'low')) {
          (addon.events || [])
            .filter((e) => e.priority === 'low')
            .forEach((e) => {
              if (!e.title || (e.title && legendTitles.includes(e.title))) return;
              legendTitles.push(e.title);
            });
        }
      });
      legends.push({
        id: 'relapseLow',
        legend: legendTitles.join(', '),
        icon: (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Icon.IconChange
              priority="low"
              strokeColor="#C20012"
              strokeWidth={2}
              fillColor="white"
              large={true}
              active={true}
              addon={true}
            />
          </svg>
        ),
      });
    }
    if (
      data.id === 'medications' &&
      data.addons?.find(
        (addon) => addon.events?.find((event) => ['administration', 'infusion'].includes(event.eventType ?? '')),
      )
    ) {
      const legendTitles: string[] = [];
      data.addons.forEach((addon) => {
        (addon.events || []).forEach((e) => {
          if (!e.title || (e.title && legendTitles.includes(e.title))) return;
          if (e.eventType === 'administration' || e.eventType === 'infusion') legendTitles.push(e.title);
        });
      });
      legends.push({
        id: 'administration',
        legend: legendTitles.join(', '),
        icon: (
          <svg width="17" height="21" viewBox="-8 -8 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0,-1) scale(0.9)">
              <polygon points={'-8,-1 8,-1 1,7 8,14.5 -8,14.5 -1,7'} stroke="#045A8B" strokeWidth={2} fill="white" />
            </g>
          </svg>
        ),
      });
    }
    if (
      data.id === 'ninmtTreatments' &&
      data.addons?.find((addon) => addon.events?.find((e) => e.priority === 'high'))
    ) {
      const legendTitles: string[] = [];
      data.addons.forEach((addon) => {
        if ((addon.events || []).find((e) => e.priority === 'high')) {
          (addon.events || [])
            .filter((e) => e.priority === 'high')
            .forEach((e) => {
              if (!e.secondaryTitle || (e.secondaryTitle && legendTitles.includes(e.secondaryTitle))) return;
              legendTitles.push(e.secondaryTitle);
            });
        }
      });
      legends.push({
        id: 'ninmtTreatmentsWarning',
        legend: legendTitles.join(', '),
        icon: (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Icon.IconChange
              priority="high"
              strokeColor="#C20012"
              strokeWidth={2}
              fillColor="white"
              large={true}
              active={true}
              addon={true}
            />
          </svg>
        ),
      });
    }
    if (
      data.id === 'ninmtTreatments' &&
      data.addons?.find((addon) => addon.events?.find((e) => e.eventType === 'technicalIssue'))
    ) {
      const legendTitles: string[] = [];
      data.addons.forEach((addon) => {
        if ((addon.events || []).find((e) => e.eventType === 'technicalIssue')) {
          (addon.events || [])
            .filter((e) => e.eventType === 'technicalIssue')
            .forEach((e) => {
              if (!e.title || (e.title && legendTitles.includes(e.title))) return;
              legendTitles.push(e.title);
            });
        }
      });
      legends.push({
        id: 'ninmtTreatmentsComplication',
        legend: legendTitles.join(', '),
        icon: (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Icon.IconTechnicalIssue
              priority="normal"
              strokeColor="#045A8B"
              strokeWidth={2}
              fillColor="white"
              active={true}
              addon={true}
            />
          </svg>
        ),
      });
    }
    if (
      data.id === 'patientSelfReport' &&
      data.addons?.find((addon) => addon.events?.find((e) => e.priority === 'normal'))
    ) {
      const legendTitles: string[] = [];
      data.addons.forEach((addon) => {
        if ((addon.events || []).find((e) => e.priority === 'normal')) {
          (addon.events || [])
            .filter((e) => e.priority === 'normal')
            .forEach((e) => {
              if (!e.title || (e.title && legendTitles.includes(e.title))) return;
              legendTitles.push(e.title);
            });
        }
      });
      legends.push({
        id: 'patientSelfReportNormal',
        legend: legendTitles.join(', '),
        icon: (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Icon.IconChange
              priority="normal"
              strokeColor="#C20012"
              strokeWidth={2}
              fillColor="white"
              large={true}
              active={true}
              addon={true}
            />
          </svg>
        ),
      });
    }

    if (data.id === 'diseaseActivity') {
      legends.push({
        id: 'diseaseActivity',
        legend: typeof data.name === 'string' ? data.name : '',
        icon: (
          <svg width="14" height="14" viewBox="0 0 14 14" fill="white" xmlns="http://www.w3.org/2000/svg">
            <Icon.IconActivity strokeColor="#045A8B" fillColor={`#045A8B44`} strokeWidth={4} addon={true} />,
          </svg>
        ),
      });
    }
  });
  return legends;
};

export {
  validateScale,
  validateGraphData,
  parseAndValidateAddonData,
  filterGraphData,
  filterAddonData,
  parseGraphSettings,
  parseDefaultTimeframe,
  parseDefaultLeftMenu,
  parseSpecificAddonLegends,
};
