import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { IMdsUpdrsIV, mdsUpdrsIV } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { IMyServiceContext, MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { Theme, useMediaQuery } from '@mui/material';
import FormSection from 'Components/FormSection';

const StyledInfo = ({ mdOrSmaller, children }: { mdOrSmaller: boolean; children: JSX.Element }): JSX.Element => {
  return (
    <div
      style={{
        width: mdOrSmaller ? '100%' : '80%',
        color: colors.tertiaryText,
        marginBottom: '3rem',
        fontStyle: 'italic',
      }}
    >
      {children}
    </div>
  );
};

const StyledQuestionAndInfo = ({ field, fm }: { field: string; fm: IMyServiceContext['fm'] }) => {
  return (
    <div>
      <p style={{ fontWeight: 600 }}>{fm(`myService.parkinson.mdsUpdrsIVForm.${field}`)}</p>
      <p>
        {fm(`myService.parkinson.mdsUpdrsIVForm.${field}InfoExaminer`, {
          u: (chunks) => <span style={{ textDecoration: 'underline' }}>{chunks}</span>,
        })}
      </p>
      <p style={{ fontStyle: 'italic' }}>
        {fm(`myService.parkinson.mdsUpdrsIVForm.${field}InfoPatient`, {
          u: (chunks) => <span style={{ textDecoration: 'underline' }}>{chunks}</span>,
        })}
      </p>
    </div>
  );
};

const optionFormatter = (o: string | number, field: string, fm: IMyServiceContext['fm']): string | JSX.Element => {
  if (o === 'UR') return `${fm('myService.parkinson.mdsUpdrsIVForm.opts.UR')}`;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ whiteSpace: 'nowrap', minWidth: '10rem' }}>
        {`${o}: ${fm(`myService.parkinson.mdsUpdrsIVForm.opts.${o}`)}`}
        {': '}
      </div>
      <div>{fm(`myService.parkinson.mdsUpdrsIVForm.opts.${field}.${o}`)}</div>
    </div>
  );
};

const getMdsUpdrsIVScore = (percentage: number): 0 | 1 | 2 | 3 | 4 => {
  if (percentage === 0) return 0;
  if (percentage <= 25) return 1;
  if (percentage <= 50) return 2;
  if (percentage <= 75) return 3;
  return 4;
};

export const MdsUpdrsIV = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, viewing, setViewingObj, setEditingObj, fm } = myServContext;
  const mdsUpdrsIVData = (editing?.data || viewing?.data) as IMdsUpdrsIV & IControlProps;

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const mainField = field.split('.')[0];
    const deeperField = field.split('.')?.[1];
    const value = Object.values(values)[0];
    switch (mainField) {
      case 'timeWithDyskinesias':
      case 'timeInOffState':
      case 'painfulOffStateDystonia': {
        setEditingData?.({
          ...mdsUpdrsIVData,
          [mainField]: {
            ...mdsUpdrsIVData[mainField],
            [deeperField]: value === 'UR' ? value : parseInt(`${value}`),
          },
        });
        break;
      }
      default: {
        setEditingData?.({
          ...mdsUpdrsIVData,
          [field]: mainField === 'date' || value === 'UR' ? value : parseInt(`${value}`),
        });
        break;
      }
    }
  };

  React.useEffect(() => {
    if (
      editing &&
      (mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake || mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake === 0) &&
      (mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours ||
        mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours === 0)
    ) {
      const percentage = Math.round(
        mdsUpdrsIV.calculators.calcRatioPercentage(
          mdsUpdrsIVData.timeWithDyskinesias.dyskinesiaHours,
          mdsUpdrsIVData.timeWithDyskinesias.hoursAwake,
        ),
      );
      if (percentage !== mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeWithDyskinesias: { ...mdsUpdrsIVData.timeWithDyskinesias, dyskinesiaPercentage: percentage },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake, mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours]);

  React.useEffect(() => {
    if (
      editing &&
      (mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage ||
        mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage === 0)
    ) {
      const newScore = getMdsUpdrsIVScore(mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage);
      if (newScore !== mdsUpdrsIVData?.timeWithDyskinesias?.value) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeWithDyskinesias: { ...mdsUpdrsIVData.timeWithDyskinesias, value: newScore },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage]);

  React.useEffect(() => {
    if (
      editing &&
      (mdsUpdrsIVData?.timeInOffState?.hoursAwake || mdsUpdrsIVData?.timeInOffState?.hoursAwake === 0) &&
      (mdsUpdrsIVData?.timeInOffState?.hoursOff || mdsUpdrsIVData?.timeInOffState?.hoursOff === 0)
    ) {
      const percentage = Math.round(
        mdsUpdrsIV.calculators.calcRatioPercentage(
          mdsUpdrsIVData.timeInOffState.hoursOff,
          mdsUpdrsIVData.timeInOffState.hoursAwake,
        ),
      );
      if (percentage !== mdsUpdrsIVData?.timeInOffState?.offPercentage) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeInOffState: { ...mdsUpdrsIVData.timeInOffState, offPercentage: percentage },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeInOffState?.hoursAwake, mdsUpdrsIVData?.timeInOffState?.hoursOff]);

  React.useEffect(() => {
    if (
      editing &&
      (mdsUpdrsIVData?.timeInOffState?.offPercentage || mdsUpdrsIVData?.timeInOffState?.offPercentage === 0)
    ) {
      const newScore = getMdsUpdrsIVScore(mdsUpdrsIVData?.timeInOffState?.offPercentage);
      if (newScore !== mdsUpdrsIVData?.timeInOffState?.value) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeInOffState: { ...mdsUpdrsIVData.timeInOffState, value: newScore },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeInOffState?.offPercentage]);

  React.useEffect(() => {
    if (
      editing &&
      (mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff || mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff === 0) &&
      (mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia ||
        mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia === 0)
    ) {
      const percentage = Math.round(
        mdsUpdrsIV.calculators.calcRatioPercentage(
          mdsUpdrsIVData.painfulOffStateDystonia.hoursOffDystonia,
          mdsUpdrsIVData.painfulOffStateDystonia.hoursOff,
        ),
      );
      if (percentage !== mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          painfulOffStateDystonia: { ...mdsUpdrsIVData.painfulOffStateDystonia, offDystoniaPercentage: percentage },
        });
      }
    }
  }, [mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff, mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia]);

  React.useEffect(() => {
    if (
      editing &&
      (mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage ||
        mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage === 0)
    ) {
      const newScore = getMdsUpdrsIVScore(mdsUpdrsIVData.painfulOffStateDystonia.offDystoniaPercentage);
      if (newScore !== mdsUpdrsIVData?.painfulOffStateDystonia?.value) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          painfulOffStateDystonia: { ...mdsUpdrsIVData.painfulOffStateDystonia, value: newScore },
        });
      }
    }
  }, [mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage]);

  // Track page width and breakpoints
  const mdOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down(1401));

  return (
    <>
      <div>
        <p>
          {fm('myService.parkinson.mdsUpdrsIVForm.instructions', {
            strong: (chunks) => <span style={{ fontWeight: '600' }}>{chunks}</span>,
          })}
        </p>
        <p>
          {fm('myService.parkinson.mdsUpdrsIVForm.instructions2', {
            br: <br />,
            u: (chunks) => <span style={{ textDecoration: 'underline' }}>{chunks}</span>,
          })}
        </p>
        <p>
          {fm('myService.parkinson.mdsUpdrsIVForm.instructions3', {
            br: <br />,
          })}
        </p>
        <p>
          {fm('myService.parkinson.mdsUpdrsIVForm.instructions4', {
            br: <br />,
          })}
        </p>
        <p>{fm('myService.parkinson.mdsUpdrsIVForm.instructions5')}</p>
        <p>{fm('myService.parkinson.mdsUpdrsIVForm.instructions6')}</p>
      </div>

      <BlockWrapper
        title={viewing ? formatPartialDate(mdsUpdrsIVData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'mdsUpdrsIV', data: mdsUpdrsIVData });
                  },
                },
              ]
            : undefined
        }
      >
        <StyledInfo mdOrSmaller={mdOrSmaller}>
          <p>{fm('myService.parkinson.mdsUpdrsIVForm.mdsPermission')}</p>
        </StyledInfo>
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: mdsUpdrsIVData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <FormSection header={`myService.parkinson.mdsUpdrsIVForm.categoryA`}>
          <StyledQuestionAndInfo field={'timeWithDyskinesias'} fm={fm} />
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.timeWithDyskinesiasHoursAwake'}>
            <InputHandler
              name="timeWithDyskinesias.hoursAwake"
              type="NumberField"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake}
              formData={{
                document: { ['timeWithDyskinesias.hoursAwake']: mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.timeWithDyskinesiasDyskinesiaHours'}>
            <InputHandler
              name="timeWithDyskinesias.dyskinesiaHours"
              type="NumberField"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours}
              formData={{
                document: {
                  ['timeWithDyskinesias.dyskinesiaHours']: mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.timeWithDyskinesiasDyskinesiaPercentage'}>
            <InputHandler
              name="timeWithDyskinesias.dyskinesiaPercentage"
              type="NumberField"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage}
              formData={{
                document: {
                  ['timeWithDyskinesias.dyskinesiaPercentage']:
                    mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.score'}>
            <InputHandler
              name="timeWithDyskinesias.value"
              type="Radio"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.value}
              formData={{
                document: { ['timeWithDyskinesias.value']: mdsUpdrsIVData?.timeWithDyskinesias?.value },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'timeWithDyskinesias', fm)}
              editing={!!editing}
            />
          </FormRow>
          <StyledQuestionAndInfo field={'functionalImpactOfDyskinesias'} fm={fm} />
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.score'}>
            <InputHandler
              name="functionalImpactOfDyskinesias"
              type="Radio"
              formData={{
                document: { functionalImpactOfDyskinesias: mdsUpdrsIVData?.functionalImpactOfDyskinesias },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'functionalImpactOfDyskinesias', fm)}
              editing={!!editing}
            />
          </FormRow>
        </FormSection>
        <FormSection header={'myService.parkinson.mdsUpdrsIVForm.categoryB'}>
          <StyledQuestionAndInfo field={'timeInOffState'} fm={fm} />
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.timeInOffStateHoursAwake'}>
            <InputHandler
              name="timeInOffState.hoursAwake"
              type="NumberField"
              value={mdsUpdrsIVData?.timeInOffState?.hoursAwake}
              formData={{
                document: { ['timeInOffState.hoursAwake']: mdsUpdrsIVData?.timeInOffState?.hoursAwake },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.timeInOffStateHoursOff'}>
            <InputHandler
              name="timeInOffState.hoursOff"
              type="NumberField"
              value={mdsUpdrsIVData?.timeInOffState?.hoursOff}
              formData={{
                document: {
                  ['timeInOffState.hoursOff']: mdsUpdrsIVData?.timeInOffState?.hoursOff,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.timeInOffStateOffPercentage'}>
            <InputHandler
              name="timeInOffState.offPercentage"
              type="NumberField"
              value={mdsUpdrsIVData?.timeInOffState?.offPercentage}
              formData={{
                document: {
                  ['timeInOffState.offPercentage']: mdsUpdrsIVData?.timeInOffState?.offPercentage,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.score'}>
            <InputHandler
              name="timeInOffState.value"
              type="Radio"
              value={mdsUpdrsIVData?.timeInOffState?.value}
              formData={{
                document: { ['timeInOffState.value']: mdsUpdrsIVData?.timeInOffState?.value },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'timeInOffState', fm)}
              editing={!!editing}
            />
          </FormRow>
          <StyledQuestionAndInfo field={'functionalImpactOfFluctuations'} fm={fm} />
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.score'}>
            <InputHandler
              name="functionalImpactOfFluctuations"
              type="Radio"
              formData={{
                document: { functionalImpactOfFluctuations: mdsUpdrsIVData?.functionalImpactOfFluctuations },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'functionalImpactOfFluctuations', fm)}
              editing={!!editing}
            />
          </FormRow>
          <StyledQuestionAndInfo field={'complexityOfMotorFluctuations'} fm={fm} />
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.score'}>
            <InputHandler
              name="complexityOfMotorFluctuations"
              type="Radio"
              formData={{
                document: { complexityOfMotorFluctuations: mdsUpdrsIVData?.complexityOfMotorFluctuations },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'complexityOfMotorFluctuations', fm)}
              editing={!!editing}
            />
          </FormRow>
        </FormSection>
        <FormSection header={'myService.parkinson.mdsUpdrsIVForm.categoryC'}>
          <StyledQuestionAndInfo field={'painfulOffStateDystonia'} fm={fm} />
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.painfulOffStateDystoniaHoursOff'}>
            <InputHandler
              name="painfulOffStateDystonia.hoursOff"
              type="NumberField"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff}
              formData={{
                document: { ['timeWithDyskinesias.hoursOff']: mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.painfulOffStateDystoniaHoursOffDystonia'}>
            <InputHandler
              name="painfulOffStateDystonia.hoursOffDystonia"
              type="NumberField"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia}
              formData={{
                document: {
                  ['painfulOffStateDystonia.hoursOffDystonia']:
                    mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.painfulOffStateDystoniaOffDystoniaPercentage'}>
            <InputHandler
              name="painfulOffStateDystonia.offDystoniaPercentage"
              type="NumberField"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage}
              formData={{
                document: {
                  ['painfulOffStateDystonia.offDystoniaPercentage']:
                    mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={'myService.parkinson.mdsUpdrsIVForm.score'}>
            <InputHandler
              name="painfulOffStateDystonia.value"
              type="Radio"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.value}
              formData={{
                document: { ['painfulOffStateDystonia.value']: mdsUpdrsIVData?.painfulOffStateDystonia?.value },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'painfulOffStateDystonia', fm)}
              editing={!!editing}
            />
          </FormRow>
        </FormSection>
      </BlockWrapper>
      <StyledInfo mdOrSmaller={mdOrSmaller}>
        <p>{fm('myService.parkinson.mdsUpdrsIVForm.copyright')}</p>
      </StyledInfo>
    </>
  );
};
