import { TFieldConfig } from 'Routes/MyService/Document/config';

export const treatmentsFields: Array<TFieldConfig> = [
  {
    name: 'amountOfTreatmentSessions',
    type: 'NumberField',
    // placeholder: '' ,
  },
  {
    name: 'isSkinIntactUnderElectrodes',
    type: 'Radio',
    options: ['yes', 'no'],
  },
];

export const deviceFields: Array<TFieldConfig> = [
  {
    name: 'deviceBatteryCharge',
    type: 'NumberField',
    // placeholder: '' ,
  },
  {
    name: 'deviceUses',
    type: 'NumberField',
    // placeholder: '' ,
  },
];

export const sideEffectsFields: Array<TFieldConfig> = [
  {
    name: 'sideEffectsDuringTreatments',
    type: 'Radio',
    options: ['yes', 'no'],
  },
  {
    name: 'sideEffectsDuringTreatmentsDetails',
    type: 'TextArea',
    // placeholder: '' ,
  },
];
