import * as React from 'react';
import { sortAndTakeLatest } from '../utils';
import { useIntl } from 'react-intl';
import { isPartialDate, formatPartialDate } from 'neuro-utils';
import { styled } from '@mui/system';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { EndingDate } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';

const MainTitle = styled('div')({
  fontSize: '1.8rem',
  fontWeight: 600,
});
const MainValue = styled('div')({
  fontSize: '1.8rem',
  fontWeight: 600,
});
const Title = styled('div')({
  marginTop: '0.5rem',
});
const Value = styled('div')({
  marginTop: '0.5rem',
});

const PulmonaryDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  const latestChestCircumference = sortAndTakeLatest(['chestCircumference'], documents)?.[0] as IChestCircumference;
  const latestVentilation = sortAndTakeLatest(['ventilationAid'], documents)?.[0] as IVentilation;
  const latestClearance = sortAndTakeLatest(['pulmonary'], documents)?.[0] as IPulmonary;

  const { formatMessage } = useIntl();

  const dashData = [];
  if (latestChestCircumference)
    dashData.push({
      date: isPartialDate(latestChestCircumference.date) ? formatPartialDate(latestChestCircumference.date) : '',
      title: (
        <>
          <MainTitle>{formatMessage({ id: 'pulmonary.chestCircumference' })}</MainTitle>
          <Title>{formatMessage({ id: 'pulmonary.expiration' })}</Title>
          <Title>{formatMessage({ id: 'pulmonary.inspiration' })}</Title>
        </>
      ),
      value: (
        <>
          <MainValue>&nbsp;</MainValue>
          <Value>{formatMessage({ id: 'pulmonary.cm' }, { value: latestChestCircumference?.fullExpiration })}</Value>
          <Value>{formatMessage({ id: 'pulmonary.cm' }, { value: latestChestCircumference?.fullInspiration })}</Value>
        </>
      ),
      key: 'chestCircumference',
    });

  if (latestVentilation)
    dashData.push({
      date: isPartialDate(latestVentilation.startDate) ? formatPartialDate(latestVentilation.startDate) : '',
      title: (
        <>
          <MainTitle>{formatMessage({ id: 'pulmonary.latestVentilation' })}</MainTitle>
          <Title>{formatMessage({ id: 'pulmonary.ventilationType' })}</Title>
          <Title>{formatMessage({ id: 'pulmonary.ventilationFrequency' })}</Title>
        </>
      ),
      value: (
        <>
          <MainValue>
            {isPartialDate(latestVentilation.endDate) ? (
              <EndingDate date={formatPartialDate(latestVentilation.endDate)} />
            ) : (
              <>&nbsp;</>
            )}
          </MainValue>
          {latestVentilation.type ? (
            <Value>{formatMessage({ id: 'pulmonary.opts.' + latestVentilation.type })}</Value>
          ) : (
            <Value>-</Value>
          )}
          {latestVentilation.frequency ? (
            <Value>{formatMessage({ id: 'pulmonary.opts.' + latestVentilation.frequency })}</Value>
          ) : (
            <Value>-</Value>
          )}
        </>
      ),
      key: 'latestVentilation',
    });

  if (latestClearance && dashData.length < 2)
    dashData.push({
      date: isPartialDate(latestClearance.date) ? formatPartialDate(latestClearance.date) : '',
      title: (
        <>
          <MainTitle>
            {formatMessage({ id: 'pulmonary.assistanceInAirwayClearanceorSecretionMobilisationShort' })}
          </MainTitle>
        </>
      ),
      value: (
        <>
          <div>
            {latestClearance.assistanceInAirwayClearanceorSecretionMobilisation
              ? formatMessage({ id: `general.${latestClearance.assistanceInAirwayClearanceorSecretionMobilisation}` })
              : '-'}
          </div>
        </>
      ),
      key: 'latestClearance',
    });

  return <TileContentMaker type="specificListMultipleRows" itemsCanFitAmount={2} data={dashData} />;
};

interface IOwnProps {
  documents: IPulmonary[];
}

export default PulmonaryDash;
