import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import * as React from 'react';
import colors from '../../config/theme/colors';
import { styled } from '@mui/material';

const StyledTooltip = styled(
  ({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />,
  { shouldForwardProp: (prop) => prop !== 'minWidth' },
)(
  ({
    width,
    height,
    minWidth,
    overflow,
  }: {
    minWidth?: string;
    width?: string;
    height?: string;
    overflow?: { x?: string; y?: string };
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.tooltipBackground,
      fontSize: '1.4rem',
      color: colors.tooltipFontColor,
      border: '1px solid',
      borderColor: colors.tooltipBorderColor,
      padding: '1.2rem',
      width,
      height,
      minWidth,
      maxWidth: width || '35rem',
      boxShadow: '1px 1px 2px -1px rgba(13, 18, 76, 0.55)',
      overflowX: overflow?.x || undefined,
      overflowY: overflow?.y || undefined,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: colors.tooltipBackground,
      '&:before': {
        border: '1px solid',
        borderColor: colors.tooltipBorderColor,
        boxShadow: '2px 2px 3px -2px rgba(13, 18, 76, 0.3)',
      },
    },
  }),
);

const HtmlTooltip = (
  props: TooltipProps & {
    hover: boolean;
    minWidth?: string;
    width?: string;
    height?: string;
    overflow?: { x?: string; y?: string };
  },
): JSX.Element => {
  const { hover, ...toolTipProps } = props;
  return (
    <StyledTooltip
      //sx={sx}
      open={hover ? undefined : props.open}
      disableFocusListener={hover ? false : true}
      disableHoverListener={hover ? false : true}
      disableTouchListener={hover ? false : true}
      {...toolTipProps}
    />
  );
};

const StyledTitle = styled('div')({
  fontWeight: 600,
  fontSize: '1.5rem',
  '* > &:not(:last-of-type)': {
    marginBottom: '0.5rem',
  },
});

const ToolTip = ({
  title,
  description,
  descriptionFontSize,
  content,
  open,
  cursor,
  placement,
  arrow = true,
  hover = false,
  flipTitleAndDescStyle = false,
  disabled = false,
  disableInteractive = true,
  ...other
}: IOwnProps): JSX.Element => {
  return (
    <HtmlTooltip
      style={{ cursor: `${cursor ? cursor : 'Pointer'}` }}
      title={
        !flipTitleAndDescStyle ? (
          <div>
            {title && <StyledTitle>{title}</StyledTitle>}
            {description && <div style={{ fontSize: `${descriptionFontSize ?? '1.5'}rem` }}>{description}</div>}
          </div>
        ) : (
          <div>
            {title && <div style={{ fontSize: `${descriptionFontSize ?? '1.5'}rem` }}>{title}</div>}
            {description && (
              <StyledTitle style={{ justifyContent: 'center', textAlign: 'center' }}>{description}</StyledTitle>
            )}
          </div>
        )
      }
      placement={placement ? placement : 'top'}
      arrow={arrow}
      hover={hover}
      open={open}
      disableInteractive={disableInteractive}
      PopperProps={
        disabled
          ? { style: { display: 'none' } }
          : {
              onClick: (e) => {
                e.stopPropagation(); // Disable clicking through the tooltip
              },
            }
      }
      {...other}
    >
      {/* Child cannot be fragment, or else the tooltip wont render */}
      <div style={{ all: 'unset' }}>{content}</div>
    </HtmlTooltip>
  );
};

interface IOwnProps extends Omit<TooltipProps, 'content' | 'title' | 'children' | 'style'> {
  title?: string | JSX.Element;
  description?: JSX.Element | string | string[] | undefined;
  descriptionFontSize?: number;
  content: string | JSX.Element;
  cursor?: 'Pointer' | 'Default';
  hover?: boolean;
  flipTitleAndDescStyle?: boolean;
  disabled?: boolean;
  minWidth?: string;
  width?: string;
  height?: string;
  overflow?: { x?: string; y?: string };
}

export default ToolTip;
