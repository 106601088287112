import * as React from 'react';
import { styled as muiStyled } from '@mui/material';
import { IGraphDimensions, TXTick } from 'Components/sq-graphics/interfaces';

const StyledContainer = muiStyled('div')(({ dimensions }: { dimensions: IGraphDimensions }) => ({
  width: '100%',
  height: `${dimensions.timeline.height}rem`,
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  zIndex: 2,
  marginTop: `${dimensions.timeline.marginTop}rem`,
  marginBottom: `${dimensions.timeline.marginBottom}rem`,
}));

const StyledColumn = muiStyled('div')((props: { width: number }) => ({
  width: `${props.width}rem`,
  height: '100%',
  position: 'relative',
  zIndex: 2,
}));

const Timeline = ({ timeframeWidth, xTicks, side, dimensions }: IOwnProps): JSX.Element => {
  return (
    <StyledContainer dimensions={dimensions}>
      {/** TODO: tää vois olla viel parempi muuttaa sillai et paddingia ei tartteis näis jokases huomioida */}
      <StyledColumn width={dimensions.leftColumn.width}></StyledColumn>
      {/** TODO: ainakin nää värit vois viedä johonkin conffiin */}
      <StyledColumn
        style={{ backgroundColor: '#045A8B', borderRadius: '0.2rem', boxSizing: 'border-box' }}
        width={timeframeWidth}
      >
        <svg viewBox={`0 0 ${timeframeWidth * 10} ${dimensions.timeline.height * 10}`}>
          {xTicks.map((xTick, i) => (
            <g transform={`translate(${xTick.x}, 0)`} key={xTick.value + ` #${i}`}>
              <line
                x1={0}
                x2={0}
                y1={side === 'bottom' ? '0%' : '100%'}
                y2={xTick.priority === 'low' ? '50%' : side === 'bottom' ? '100%' : '0%'}
                stroke="white"
                strokeWidth={1}
                strokeDasharray={xTick.priority === 'low' ? '10 4' : undefined}
              />
              {xTick.priority !== 'low' ? (
                <text
                  fontSize="12"
                  fontWeight={xTick.priority === 'high' ? 'bold' : 'normal'}
                  fill="white"
                  y="50%"
                  x={5}
                  dominantBaseline="middle"
                  style={{ userSelect: 'none' }}
                >
                  {xTick.value}
                </text>
              ) : null}
            </g>
          ))}
        </svg>
      </StyledColumn>
      <StyledColumn width={dimensions.rightColumn.width}></StyledColumn>
    </StyledContainer>
  );
};

interface IOwnProps {
  timeframeWidth: number;
  xTicks: TXTick[];
  side: 'top' | 'bottom';
  dimensions: IGraphDimensions;
}

export default React.memo(Timeline);
