import { createAction, createReducer } from '@reduxjs/toolkit';

import { deleteAppointment, fetchAppointments, patchAppointment, postAppointment } from './apiFetchers';
import { TDispatch } from '..';

const LOAD_APPOINTMENTS = 'neuro-ui/appointments/LOAD_APPOINTMENTS';
const CLEAR_APPOINTMENTS = 'neuro-ui/appointments/CLEAR_APPOINTMENTS';
const CREATE_APPOINTMENT = 'neuro-ui/appointments/CREATE_APPOINTMENT';
const CHANGE_APPOINTMENT = 'neuro-ui/appointments/CHANGE_APPOINTMENT';
const REMOVE_APPOINTMENT = 'neuro-ui/appointments/REMOVE_APPOINTMENT';

const loadAppointmentsAction = createAction(LOAD_APPOINTMENTS, (apps: IAppointment[]) => {
  return {
    payload: { apps },
  };
});

const clearAppointmentsAction = createAction(CLEAR_APPOINTMENTS, () => {
  return {
    payload: null,
  };
});

const createAppointmentAction = createAction(CREATE_APPOINTMENT, (app: IAppointment) => {
  return {
    payload: { app },
  };
});

const changeAppointmentAction = createAction(CHANGE_APPOINTMENT, (app: IAppointment) => {
  return {
    payload: { app },
  };
});

const removeAppointmentAction = createAction(REMOVE_APPOINTMENT, (id: string) => {
  return {
    payload: { id },
  };
});

const loadAppointments =
  () =>
  (dispatch: TDispatch): void => {
    fetchAppointments()
      .then((data) => data && dispatch(loadAppointmentsAction(data)))
      .catch(() => {
        return;
      });
  };

const createAppointment =
  (data: IAppointment) =>
  async (dispatch: TDispatch): Promise<string | null> => {
    return await postAppointment(data)
      .then((app: IAppointment | null) => {
        if (app) {
          dispatch(createAppointmentAction(app));
          return app.id;
        }
        return null;
      })
      .catch(() => {
        return null;
      });
  };

const changeAppointmentData =
  (data: IAppointment) =>
  async (dispatch: TDispatch): Promise<string | null> => {
    return await patchAppointment(data)
      .then((app: IAppointment | null) => {
        if (app) {
          dispatch(changeAppointmentAction(app));
          return app.id;
        }
        return null;
      })
      .catch(() => {
        return null;
      });
  };

const deleteExistingAppointment =
  (id: string) =>
  async (dispatch: TDispatch): Promise<boolean> => {
    return await deleteAppointment(id)
      .then((deleted: boolean) => {
        if (deleted) {
          dispatch(removeAppointmentAction(id));
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  };

export const actions = {
  loadAppointments,
  clearAppointmentsAction,
  createAppointment,
  changeAppointmentData,
  deleteExistingAppointment,
};

const initialState: IAppointmentsStore = { appointments: [] };

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadAppointmentsAction, (state, action) => {
      state.appointments = action.payload.apps;
    })
    .addCase(clearAppointmentsAction, (state) => {
      state.appointments = [];
    })
    .addCase(createAppointmentAction, (state, action) => {
      state.appointments = [action.payload.app, ...(state.appointments || [])];
    })
    .addCase(changeAppointmentAction, (state, action) => {
      const changedApp = action.payload.app;
      const changedAppointments = state?.appointments?.map((a) => (a.id === changedApp.id ? changedApp : a));
      state.appointments = changedAppointments;
    })
    .addCase(removeAppointmentAction, (state, action) => {
      const changedAppointments = state?.appointments?.filter((a) => a.id !== action.payload.id);
      state.appointments = changedAppointments;
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
