export const papSettingsFields: {
  [key: string]: Array<{
    name: string;
    type: 'NumberField' | 'Select' | 'TextField' | 'Radio';
    options?: (string | number)[];
    title?: string;
    condition?: (formData?: IPapSetting) => boolean;
  }>;
} = {
  constantPressureCPAP: [
    { name: 'cpap', type: 'NumberField' },
    { name: 'epr', type: 'Select', options: [0, 1, 2, 3] },
  ],
  selfAdjustingCPAP: [
    { name: 'epr', type: 'Select', options: [0, 1, 2, 3] },
    { name: 'epapMin', type: 'NumberField' },
    { name: 'epapMax', type: 'NumberField' },
  ],
  autosetForHer: [
    { name: 'epr', type: 'NumberField' },
    { name: 'epapMin', type: 'NumberField' },
    { name: 'epapMax', type: 'NumberField' },
  ],
  constantPressure2PV: [
    { name: 'mode', type: 'Select', options: ['S', 'S/T', 'T'] },
    { name: 'ipap', type: 'NumberField' },
    { name: 'epap', type: 'NumberField' },
    { name: 'epapMin', type: 'NumberField', title: 'epapMinAlt' },
    { name: 'riseTime', type: 'NumberField' },
    { name: 'inhalationTimeMax', type: 'NumberField' },
    { name: 'inhalationTimeMin', type: 'NumberField' },
    { name: 'backgroundFrequency', type: 'NumberField' },
    { name: 'trigger', type: 'TextField' },
    { name: 'cycle', type: 'TextField' },
  ],
  selfAdjusting2PV: [
    { name: 'pressureSupport', type: 'NumberField' },
    { name: 'settingPressure', type: 'NumberField' },
    { name: 'ipapMax', type: 'NumberField', title: 'ipapMaxIfOtherThan25' },
    { name: 'epapMin', type: 'NumberField', title: 'epapMinIfOtherThan4' },
    { name: 'riseTime', type: 'NumberField', title: 'riseTimeConstantPressure' },
    { name: 'inhalationTimeMax', type: 'NumberField' },
    { name: 'inhalationTimeMin', type: 'NumberField' },
    { name: 'trigger', type: 'TextField' },
    { name: 'cycle', type: 'TextField', title: 'cycleConstantPressure' },
  ],
  adaptiveServoVentilator: [
    { name: 'eep', type: 'NumberField' },
    { name: 'psMin', type: 'NumberField', title: 'psMinIfOtherThan3' },
    { name: 'psMax', type: 'NumberField', title: 'psMaxIfOtherThan10' },
  ],
  automaticAdaptiveServoVentilator: [
    { name: 'epapMin', type: 'NumberField', title: 'epapMinIfOtherThan4' },
    { name: 'epapMax', type: 'NumberField', title: 'epapMaxIfOtherThan15' },
    { name: 'psMin', type: 'NumberField', title: 'psMinIfOtherThan3' },
    { name: 'psMax', type: 'NumberField', title: 'psMaxIfOtherThan15' },
  ],
  VAPS: [
    { name: 'targetFrequency', type: 'NumberField' },
    { name: 'targetVa', type: 'NumberField' },
    { name: 'mv', type: 'NumberField' },
    { name: 'vt', type: 'NumberField' },
    { name: 'ibw', type: 'NumberField' },
    { name: 'epapAuto', type: 'Radio', options: ['yes', 'no'] },
    { name: 'epapMin', type: 'NumberField', condition: (data?: IPapSetting) => data?.epapAuto === 'yes' },
    { name: 'epapMax', type: 'NumberField', condition: (data?: IPapSetting) => data?.epapAuto === 'yes' },
    { name: 'epap', type: 'NumberField', condition: (data?: IPapSetting) => data?.epapAuto === 'no' },
    { name: 'psMin', type: 'NumberField' },
    { name: 'psMax', type: 'NumberField' },
    { name: 'tiMin', type: 'NumberField' },
    { name: 'tiMax', type: 'NumberField' },
    { name: 'riseTime', type: 'NumberField' },
    { name: 'trigger', type: 'TextField' },
    { name: 'cycle', type: 'TextField' },
  ],
};

export const papTreatmentResponseFields: {
  [key: string]: Array<{
    name: string;
    type: 'NumberField' | 'Radio' | 'PartialDate';
    options?: (string | number)[];
    title?: string;
    condition?: (formData?: IPapSetting) => boolean;
  }>;
} = {
  constantPressureCPAP: [
    { name: 'startDateOfPeriodUnderReview', type: 'PartialDate' },
    { name: 'machineOperatingHours', type: 'NumberField' },
    { name: 'patientHoursOfUsePerDay', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUse', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUsePercentage', type: 'NumberField' },
    { name: 'ahi', type: 'NumberField' },
    { name: 'aiTotal', type: 'NumberField' },
    { name: 'aiCentral', type: 'NumberField' },
    { name: 'maskLeakage95PercentilePerMinute', type: 'NumberField' },
    {
      name: 'maskLeakage95PercentilePerSecond',
      type: 'NumberField',
      condition: (data?: IPapTreatmentResponse) => !!data?.maskLeakage95PercentilePerSecond,
    },
  ],
  selfAdjustingCPAP: [
    { name: 'startDateOfPeriodUnderReview', type: 'PartialDate' },
    { name: 'machineOperatingHours', type: 'NumberField' },
    { name: 'patientHoursOfUsePerDay', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUse', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUsePercentage', type: 'NumberField' },
    { name: 'pressure95Percentile', type: 'NumberField' },
    { name: 'pressureMax', type: 'NumberField' },
    { name: 'pressureMedian', type: 'NumberField' },
    { name: 'maskLeakage95PercentilePerMinute', type: 'NumberField' },
    {
      name: 'maskLeakage95PercentilePerSecond',
      type: 'NumberField',
      condition: (data?: IPapTreatmentResponse) => !!data?.maskLeakage95PercentilePerSecond,
    },
    { name: 'maskLeakageMedian', type: 'NumberField' },
    { name: 'ahi', type: 'NumberField' },
    { name: 'ai', type: 'NumberField' },
    { name: 'hi', type: 'NumberField' },
    { name: 'aiCentral', type: 'NumberField' },
  ],
  // autosetForHer: Same as selfAdjustingCPAP ?
  constantPressure2PV: [
    { name: 'startDateOfPeriodUnderReview', type: 'PartialDate' },
    { name: 'machineOperatingHours', type: 'NumberField' },
    { name: 'patientHoursOfUsePerDay', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUse', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUsePercentage', type: 'NumberField' },
    { name: 'maskLeakage95PercentilePerMinute', type: 'NumberField' },
    { name: 'maskLeakageMedian', type: 'NumberField' },
    { name: 'ahi', type: 'NumberField' },
    { name: 'ai', type: 'NumberField' },
    { name: 'tidalVolume95Percentile', type: 'NumberField' },
    { name: 'tidalVolumeMedian', type: 'NumberField' },
    { name: 'respiratoryRate', type: 'NumberField' },
    { name: 'respiratoryRate95Percentile', type: 'NumberField' },
    { name: 'respiratoryRateMedian', type: 'NumberField' },
    { name: 'respiratoryRateMin', type: 'NumberField' },
    { name: 'respiratoryRateMax', type: 'NumberField' },
    { name: 'minuteVentilation95Percentile', type: 'NumberField' },
    { name: 'minuteVentilationMedian', type: 'NumberField' },
    { name: 'spontT', type: 'NumberField' },
    { name: 'spontC', type: 'NumberField' },
  ],
  // selfAdjusting2PV, adaptiveServoVentilator, automaticAdaptiveServoVentilator: same as constantPressure2PV
  VAPS: [
    { name: 'startDateOfPeriodUnderReview', type: 'PartialDate' },
    { name: 'machineOperatingHours', type: 'NumberField' },
    { name: 'patientHoursOfUsePerDay', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUse', type: 'NumberField' },
    { name: 'daysWithLessThan4HoursOfUsePercentage', type: 'NumberField' },
    { name: 'maskLeakage95PercentilePerMinute', type: 'NumberField' },
    { name: 'maskLeakageMedian', type: 'NumberField' },
    { name: 'ahi', type: 'NumberField' },
    { name: 'ai', type: 'NumberField' },
    { name: 'tidalVolume95Percentile', type: 'NumberField' },
    { name: 'tidalVolumeMedian', type: 'NumberField' },
    { name: 'respiratoryRate', type: 'NumberField' },
    { name: 'respiratoryRate95Percentile', type: 'NumberField' },
    { name: 'respiratoryRateMedian', type: 'NumberField' },
    { name: 'respiratoryRateMax', type: 'NumberField' },
    {
      name: 'targetVentilation',
      type: 'NumberField',
      condition: (data?: IPapTreatmentResponse) => !!data?.targetVentilation,
    },
    { name: 'targetVentilationAchievedInPlusMinus10PercentPrecision', type: 'Radio', options: ['yes', 'no'] },
    { name: 'minuteVentilation95Percentile', type: 'NumberField' },
    { name: 'minuteVentilationMedian', type: 'NumberField' },
    { name: 'spontT', type: 'NumberField' },
    { name: 'spontC', type: 'NumberField' },
  ],
};
