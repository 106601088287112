import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Paper, PaperProps } from '@mui/material';
import { styled } from '@mui/system';
import { all, equals, gt, includes, uniq } from 'ramda';
import * as React from 'react';
import { useIntl } from 'react-intl';
import colors from '../../config/theme/colors';
import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../config/theme/componentTheme';
import { arrLength, isArrayOfGivenType } from 'neuro-utils';
import { isBoolean, isString, isObject } from '../../utility/typeGuards';
import ActionButton from '../ActionButton';
import { Container, Item } from '../../components/Grid';
import { sortAlphaObject } from '../../utility/string';
import { makeLog } from '../../utility/logger';
import { appendIDs } from '../../utility/appendIDs';
import { objectFromArrays } from '../../utility/object';
import { isIE } from '../../utility/isIE';

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'hoverable' && prop !== 'selected',
})<IListItem>(({ hoverable, selected }: IListItem) => ({
  fontSize: '1.6rem',
  padding: '0.2rem 2rem !important',
  backgroundColor: selected ? `${colors.gray} !important` : 'inherit',
  '&:hover':
    hoverable !== false
      ? {
          backgroundColor: `${colors.primary} !important`,
          color: `${colors.white} !important`,
        }
      : undefined,
  cursor: hoverable !== false ? 'pointer' : undefined,
  userSelect: 'none',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
}));

interface IListItem {
  hoverable?: boolean;
  selected?: boolean;
}

const DialogPaper = (props: PaperProps): JSX.Element => <Paper square={true} {...props} />;

const newDialogFetch = (state: IState, setState: TSetState, searchFn: TSearchFn): void => {
  if (state.currentSearchString && state.currentSearchString.length > 2 && !state.searching) {
    setState({ searching: true });
    searchFn(state.currentSearchString)
      .then((responses: Array<TSearchResult>) =>
        setState({ searchResults: uniq(responses).sort(sortAlphaObject('name')) }),
      )
      .catch((err: Error) => makeLog('Error', err));
    setState({ searching: false });
  }
};

const Title = ({
  title,
  info,
  onChangeSearch,
  fm,
  customDialog,
}: Pick<IComponentProps & IOwnProps, 'title' | 'info' | 'onChangeSearch' | 'fm' | 'customDialog'>): JSX.Element => (
  <DialogTitle
    style={{ ...dialogTitle, paddingRight: customDialog ? 0 : undefined, paddingLeft: customDialog ? 0 : undefined }}
  >
    <React.Fragment>
      {title ? <div style={{ fontWeight: 600, marginBottom: '1rem' }}>{fm(title)}</div> : null}
      {info ? <div style={{ color: colors.tertiaryText, fontSize: '1.6rem' }}>{fm(info)}</div> : null}
      <div style={{ marginTop: '3rem' }}>
        <input
          id="searchField"
          placeholder={fm('general.search')}
          onChange={onChangeSearch}
          style={{ padding: '0.7rem 0.5rem', width: '30rem' }}
        />
      </div>
    </React.Fragment>
  </DialogTitle>
);

const ContentList = ({
  searchResults,
  selected,
  selectItem,
  chooseItem,
  fm,
  customDialog,
}: Pick<
  IComponentProps & IOwnProps,
  'searchResults' | 'selected' | 'selectItem' | 'chooseItem' | 'fm' | 'customDialog'
>): JSX.Element => {
  let timer: NodeJS.Timeout;
  const onClickHandler =
    (c: { id: string; value: TSearchResult }) =>
    (e: React.MouseEvent<HTMLLIElement>): void => {
      clearTimeout(timer);
      if (e.detail === 1) {
        timer = setTimeout(
          () => selectItem(objectFromArrays(Object.keys(c.value), Object.values(c.value)) as TSearchResult),
          1,
        );
        if (customDialog) chooseItem(objectFromArrays(Object.keys(c.value), Object.values(c.value)) as TSearchResult);
      } else if (!customDialog && e.detail === 2) {
        chooseItem();
      } else if (!customDialog && isIE()) {
        // Logging e.detail into console in ie gives you a zero. Therefore check here if ie is used and call the selectItem-function
        timer = setTimeout(
          () => selectItem(objectFromArrays(Object.keys(c.value), Object.values(c.value)) as TSearchResult),
          1,
        );
      }
    };

  return (
    <React.Fragment>
      {arrLength(0, gt)(searchResults) ? (
        appendIDs(searchResults).map((c: { id: string; value: TSearchResult }) => (
          <React.Fragment key={c.id}>
            {isObject(c.value) && isArrayOfGivenType(Object.values(c.value), 'string') ? (
              <StyledListItem
                key={c.id}
                selected={
                  all(isObject, [selected, c.value]) &&
                  equals(Object.values(selected as object), Object.values(c.value))
                    ? true
                    : undefined
                }
                onClick={onClickHandler(c)}
              >
                <Container>
                  <Item xs={3} style={{ fontWeight: '600' }}>
                    {((isString(Object.values(c.value)[0]) as boolean)
                      ? (Object.values(c.value)[0] as string)
                      : ''
                    ).toUpperCase()}
                  </Item>
                  <Item xs={3} style={{ padding: '0 1rem' }}>
                    {Object.values(c.value)[1]}
                  </Item>
                  <Item xs={6}>{Object.values(c.value)[2]}</Item>
                </Container>
              </StyledListItem>
            ) : null}
          </React.Fragment>
        ))
      ) : (
        <StyledListItem hoverable={false}>{fm('general.noResults')}</StyledListItem>
      )}
    </React.Fragment>
  );
};

const Content = ({
  searchResults,
  selected,
  selectItem,
  chooseItem,
  fm,
  customDialog,
}: Pick<
  IComponentProps & IOwnProps,
  'searchResults' | 'selected' | 'selectItem' | 'chooseItem' | 'fm' | 'customDialog'
>): JSX.Element => (
  <DialogContent
    style={{ ...dialogContent, paddingRight: customDialog ? 0 : undefined, paddingLeft: customDialog ? 0 : undefined }}
  >
    <List style={{ border: `1px solid #9e9e9e`, minHeight: '40vh', padding: '0' }}>
      <ContentList
        searchResults={searchResults}
        selected={selected}
        selectItem={selectItem}
        chooseItem={chooseItem}
        fm={fm}
        customDialog={customDialog}
      />
    </List>
  </DialogContent>
);

const Action = ({
  cancelSearch,
  fm,
  chooseItem,
  selected,
}: Pick<IComponentProps, 'cancelSearch' | 'fm' | 'chooseItem' | 'selected'>): JSX.Element => (
  <DialogActions style={dialogActions}>
    <div style={dialogCancel} onClick={cancelSearch}>
      {fm('general.cancel')}
    </div>
    <ActionButton
      text={'general.choose'}
      onClick={() => chooseItem()}
      width={12}
      height={3}
      fontSize={16}
      disabled={!selected}
    />
  </DialogActions>
);

const SearchDialog = ({
  formData,
  searchFn,
  resultToDoc,
  openCloseButton,
  openCloseButtonText,
  title,
  info,
  customDialog,
}: IOwnProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [currentSearchString, setCurrentSearchString] = React.useState<string>('');
  const [selected, setSelected] = React.useState<TSearchResult | undefined>(undefined);
  const [searching, setSearching] = React.useState<boolean>(false);
  const [searchResults, setSearchResults] = React.useState<Array<TSearchResult>>([]);

  const [prevState, setPrevState] = React.useState<IState>({
    dialogOpen: dialogOpen,
    currentSearchString: currentSearchString,
    selected: selected,
    searching: searching,
    searchResults: searchResults,
  } as IState);

  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const setState = (state: IState): void => {
    if (isBoolean(state.dialogOpen)) setDialogOpen(state.dialogOpen);
    if (isString(state.currentSearchString)) setCurrentSearchString(state.currentSearchString);
    setSelected(state.selected);
    if (isBoolean(state.searching)) setSearching(state.searching);
    setSearchResults(state.searchResults ?? []);
  };

  const openCloseDialog = (): void => {
    setDialogOpen(!dialogOpen);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setCurrentSearchString(e.currentTarget.value);

  const selectItem = (item: TSearchResult): void => setSelected(item);

  const cancelSearch = (): void => setState({ dialogOpen: false, currentSearchString: '', selected: undefined });

  const chooseItem = (item?: TSearchResult): void => {
    const newValue = item ? item : selected;
    if (isObject(newValue)) {
      // Change doc data from given fields
      Object.keys(resultToDoc).forEach(
        (key: string) =>
          formData.onChange?.({
            [resultToDoc[key]]: includes(key, Object.keys(newValue)) ? newValue[key] : [],
          }),
      );
    }
    if (!customDialog) setState({ dialogOpen: false, currentSearchString: '', selected: undefined, searchResults: [] });
  };

  const fetch = (): void =>
    newDialogFetch({ currentSearchString: currentSearchString, searching: searching }, setState, searchFn);

  const handleEnter = (e: React.BaseSyntheticEvent & { key: string }): void => {
    if (e.target.id === 'searchField') return;
    if (e.key === 'Enter') chooseItem();
  };
  const intl = useIntl();
  const { formatMessage } = intl;
  const fm = (id: string): string => formatMessage({ id });

  React.useEffect(() => {
    const currState: IState = {
      dialogOpen: dialogOpen,
      currentSearchString: currentSearchString,
      selected: selected,
      searching: searching,
      searchResults: searchResults,
    };
    if (!equals(prevState, currState)) {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(fetch, 750);
      setPrevState(currState);
    }
  }, [currentSearchString]);

  return customDialog ? (
    <React.Fragment>
      <Title title={title} info={info} onChangeSearch={onChangeSearch} fm={fm} customDialog />
      <Content
        searchResults={searchResults}
        selected={selected}
        selectItem={selectItem}
        chooseItem={chooseItem}
        fm={fm}
        customDialog
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Dialog
        open={dialogOpen}
        fullWidth={true}
        maxWidth={'md'}
        PaperComponent={DialogPaper}
        scroll={'body'}
        onKeyDown={handleEnter}
      >
        <Title title={title} info={info} onChangeSearch={onChangeSearch} fm={fm} />
        <Content
          searchResults={searchResults}
          selected={selected}
          selectItem={selectItem}
          chooseItem={chooseItem}
          fm={fm}
        />

        <Action cancelSearch={cancelSearch} fm={fm} chooseItem={chooseItem} selected={selected} />
      </Dialog>

      {/* Show this button when dialog is closed */}

      <ActionButton
        text={openCloseButtonText ?? openCloseButton?.text ?? 'general.new'}
        onClick={openCloseButton?.onClick ?? openCloseDialog}
        width={openCloseButton?.width ?? 20}
        height={openCloseButton?.height ?? 5}
        fontSize={openCloseButton?.fontSize ?? 18}
        disabled={openCloseButton?.disabled}
        disabledTooltip={openCloseButton?.disabledTooltip}
      />
    </React.Fragment>
  );
};

type TSearchResult = { [key: string]: string };
type TResultToDoc = { [key: string]: string };
type TSetState = (state: IState) => void;
type TSearchFn = (currentSearchString: string) => Promise<Array<TSearchResult>>;

interface IComponentProps {
  title?: string;
  info?: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fm: (s: string) => string;
  searchResults: Array<TSearchResult>;
  selected?: TSearchResult;
  selectItem: (i: TSearchResult) => void;
  chooseItem: (i?: TSearchResult) => void;
  cancelSearch: () => void;
}

interface IState {
  dialogOpen?: boolean;
  currentSearchString?: string;
  selected?: TSearchResult;
  searching?: boolean;
  searchResults?: Array<TSearchResult>;
}

interface IButtonProps {
  onClick?: () => void;
  width?: number;
  height?: number;
  fontSize?: number;
  text?: string;
  disabled?: boolean;
  disabledTooltip?: JSX.Element;
}

interface IOwnProps {
  formData: { document: any; onChange: IFormData['onChange'] };
  openCloseButton?: IButtonProps;
  searchFn: TSearchFn;
  resultToDoc: TResultToDoc;
  openCloseButtonText?: string;
  title?: string;
  info?: string;
  /** Enables search usage in a custom dialog. Currently used for a single purpose so may not be too versatile.
   *  Example usage: Create your own dialog and add SearchDialog somewhere in it as if it were a FormRow or something...
   *  - Displays search title, field and results
   *  - Hides "Cancel / Accept" buttons
   *  - Disables selection by double click (single click to select and confirm value)
   */
  customDialog?: boolean;
}

export default SearchDialog;
