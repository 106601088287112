import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { formatPartialDate, isPartialDate } from 'neuro-utils';

export const surgicalComplications = [
  'openWound',
  'surfaceInfection',
  'cerebrospinalFluidLeakage',
  'accumulationOfLiquid',
  'lambooInfection',
  'meningitis',
  'absesis',
  'antisepticMeningitis',
  'infectionFromForeignObject',
  'epiduralHematoma',
  'subduralHematoma',
  'cerebralParenchymalLeakage',
  'cerebralVentricularLeakage',
  'brainTissueIschemia',
  'hydrocephalus',
  'cerebral',
  'invalidPositionOfElectrode',
  'otherSurgical',
];

export const neurologicalComplications = [
  'hemiparesis',
  'Monoparesis',
  'lackOfSensation',
  'lackOfFieldOfView',
  'cerebralNerveParesis',
  'linguisticDisorder',
  'memoryImpairment',
  'otherNeurological',
];

export const otherComplications = [
  'deepVeinThrombosis',
  'pulmonaryEmbolism',
  'urinaryTractInfection',
  'pneumonia',
  'anemia',
  'shortnessOfBreath',
  'heartFailure',
  'severeMetabolicEndocrineDisorder',
  'psychiatricDisorder',
  'other',
];

export const infoFields = [
  'surgicalOtherInfo',
  'cerebralNerveParesisInfo',
  'neurologicalOtherInfo',
  'severeMetabolicEndocrineDisorderInfo',
  'psychiatricDisorderInfo',
  'otherInfo',
];

const StepContent = ({ d, fm }: { d: IComplications; fm: (id: string) => string }): JSX.Element => {
  const path = 'surgicalTreatment.complications';

  return (
    <React.Fragment>
      {d.complication && (
        <StepperHeaderValuePair
          header={<>{fm(`${path}.complication.title`)}</>}
          value={
            surgicalComplications.includes(d.complication)
              ? fm(`${path}.surgical.opts.${d.complication}`)
              : neurologicalComplications.includes(d.complication)
                ? fm(`${path}.neurological.opts.${d.complication}`)
                : otherComplications.includes(d.complication)
                  ? fm(`${path}.other.opts.${d.complication}`)
                  : ''
          }
        />
      )}

      {Object.entries(d).map((value: [string, any]): JSX.Element | null => {
        if (infoFields.includes(value[0]) && value[1])
          return (
            <StepperHeaderValuePair key={value[0]} header={<>{fm(`${path}.${value[0]}.title`)}</>} value={value[1]} />
          );
        else return null;
      })}

      {d.complicationStart && (
        <StepperHeaderValuePair
          header={fm(`${path}.complicationStart`)}
          value={isPartialDate(d.complicationStart) ? formatPartialDate(d.complicationStart) : '-'}
        />
      )}
      {d.date && <StepperHeaderValuePair header={<>{fm(`${path}.date`)}</>} value={formatPartialDate(d.date)} />}
      {d.complicationCededDate && (
        <StepperHeaderValuePair
          header={<>{fm(`${path}.complicationCededDate`)}</>}
          value={isPartialDate(d.complicationCededDate) ? formatPartialDate(d.complicationCededDate) : '-'}
        />
      )}
    </React.Fragment>
  );
};

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<IComplications>;
  editing: boolean;
  fm?: (id: string) => string;
}): JSX.Element => {
  const path = 'surgicalTreatment.complications';

  const complication = formData.document.complication;

  return (
    <React.Fragment>
      <FormRow title={`${path}.surgical.title`}>
        <InputHandler
          type="Radio"
          name="complication"
          editing={editing}
          formData={formData}
          options={surgicalComplications}
          optionFormatter={(id: string | number): string => (fm ? fm(`${path}.surgical.opts.${id}`) : '')}
          optionSpecificElements={[
            {
              otherSurgical: (
                <InputHandler
                  type="TextArea"
                  name="surgicalOtherInfo"
                  editing={editing}
                  formData={formData}
                  placeholder={`${path}.surgicalOtherInfo.placeholder`}
                />
              ),
            },
          ]}
          dependentFieldsList={(): string[] => [...infoFields, 'complicationCededDate']}
        />
      </FormRow>

      <FormRow title={`${path}.neurological.title`}>
        <InputHandler
          type="Radio"
          name="complication"
          editing={editing}
          formData={formData}
          options={neurologicalComplications}
          optionFormatter={(id: string | number): string => (fm ? fm(`${path}.neurological.opts.${id}`) : '')}
          optionSpecificElements={[
            {
              cerebralNerveParesis: (
                <InputHandler
                  type="TextArea"
                  name="cerebralNerveParesisInfo"
                  editing={editing}
                  formData={formData}
                  placeholder={`${path}.cerebralNerveParesisInfo.placeholder`}
                />
              ),
            },
            {
              otherNeurological: (
                <InputHandler
                  type="TextArea"
                  name="neurologicalOtherInfo"
                  editing={editing}
                  formData={formData}
                  placeholder={`${path}.neurologicalOtherInfo.placeholder`}
                />
              ),
            },
          ]}
          dependentFieldsList={(): string[] => [...infoFields]}
        />
      </FormRow>

      <FormRow title={`${path}.other.title`}>
        <InputHandler
          type="Radio"
          name="complication"
          editing={editing}
          formData={formData}
          options={otherComplications}
          optionFormatter={(id: string | number): string => (fm ? fm(`${path}.other.opts.${id}`) : '')}
          optionSpecificElements={[
            {
              severeMetabolicEndocrineDisorder: (
                <InputHandler
                  type="TextArea"
                  name={`severeMetabolicEndocrineDisorderInfo`}
                  editing={editing}
                  formData={formData}
                  placeholder={`${path}.severeMetabolicEndocrineDisorderInfo.placeholder`}
                />
              ),
            },
            {
              psychiatricDisorder: (
                <InputHandler
                  type="TextArea"
                  name="psychiatricDisorderInfo"
                  editing={editing}
                  formData={formData}
                  placeholder={`${path}.psychiatricDisorderInfo.placeholder`}
                />
              ),
            },
            {
              other: (
                <InputHandler
                  type="TextArea"
                  name="otherInfo"
                  editing={editing}
                  formData={formData}
                  placeholder={`${path}.otherInfo.placeholder`}
                />
              ),
            },
          ]}
          dependentFieldsList={(): string[] => [...infoFields, 'complicationCededDate']}
        />
      </FormRow>
      <FormRow title={`${path}.complicationStart`} condition={neurologicalComplications.includes(complication || '')}>
        <InputHandler
          type="PartialDate"
          name="complicationStart"
          editing={editing}
          formData={formData}
          dateHook={{
            dateHookCeiling: formData.document.complicationCededDate
              ? formData.document.complicationCededDate
              : undefined,
          }}
        />
      </FormRow>
      <FormRow title={`${path}.date`}>
        <InputHandler type="PartialDate" name="date" editing={editing} formData={formData} dateDefault="now" />
      </FormRow>
      <FormRow
        title={`${path}.complicationCededDate`}
        condition={neurologicalComplications.includes(complication || '')}
      >
        <InputHandler
          type="PartialDate"
          name="complicationCededDate"
          editing={editing}
          formData={formData}
          dateHook={{ dateHookFloor: formData.document.date }}
        />
      </FormRow>
    </React.Fragment>
  );
};

const Complications = ({ formData, editing, view, fm }: IFormContext<ISurgicalTreatment>): JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const document = formData.document;

  const path = 'surgicalTreatment.complications';

  return (
    <EventStepper
      name="complications"
      formData={formData as IFormData<ISurgicalTreatment>}
      stepLabelText={(d: IComplications): string => formatPartialDate(d.date)}
      stepContent={(d: IComplications): JSX.Element => <StepContent d={d} fm={fm} />}
      addNewTextHeader={`${path}.new`}
      addNewTextButton={`${path}.add`}
      previousEventsTextHeader={`${path}.previous`}
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
        const complication = document.complications?.[index];
        return (
          <React.Fragment>
            {complication ? (
              <EditingElement formData={{ document: complication, onChange: onChange }} editing={editingDoc} fm={fm} />
            ) : undefined}
          </React.Fragment>
        );
      }}
    />
  );
};

export default withFormContext(Complications);
