import { equals, filter, path, sort } from 'ramda';
import { sortPartialDate } from 'neuro-utils';
import { capitalize } from '../../../utility/string';

/**
 * Find active VNS treatment
 * @param {IVNS[]} documents - All VNS documents
 * @returns {IVNS[]} - Active VNS documents
 */
export const findActive = (documents: IVNS[]): IVNS[] => filter((d: IVNS) => d.hasEnded?.[0] !== true, documents);

/**
 * Find ended VNS
 * @param {IVNS[]} documents - All VNS documents
 * @returns {IVNS[]} Ended VNS documents
 */
export const findEnded = (documents: IVNS[]): IVNS[] => documents.filter((item: IVNS) => item.hasEnded?.[0] === true);

/**
 * Get latest event from discontinuations
 * @param {IVNSDiscontinuationEvent[]} events - Discontinuations array
 * @returns {IVNSDiscontinuationEvent | undefined} - Latest discontinuation event or undefined
 */
export const getLatestDiscontinuation = (events?: IVNSDiscontinuationEvent[]): IVNSDiscontinuationEvent | undefined => {
  if (!events || events.length === 0) {
    return undefined;
  }
  const latestActive = sort(
    (n1: IVNSDiscontinuationEvent, n2: IVNSDiscontinuationEvent) => sortPartialDate(n2.date, n1.date),
    events,
  ).find((d) => !d.endDate);
  return latestActive;
};

/**
 * Find latest VNS generator
 * @param {IVNS} document - The VNS where the generator is looked for
 * @returns {IVNSGenerator | undefined} - The latest VNS generator, undefined if no generator is found
 */
export const getLatestGenerator = (document: IVNS): IVNSGenerator | undefined =>
  path(
    [0],
    sort((a, b) => sortPartialDate(b.date, a.date), document?.generators ?? []),
  ) ?? undefined;

/**
 * Find the generator that follows given generator chronologically
 * @param {IVNSGenerator} generator - The generator from which to start looking for the next generator
 * @param {IVNS} document - The VNS document where the next generator is looked for
 * @returns {IVNSGenerator | undefined} - The next generator if found, undefined if not
 */
export const findNextGenerator = (generator: IVNSGenerator, document: IVNS): IVNSGenerator | undefined => {
  const generatorsSorted = sort((a, b) => sortPartialDate(b.date, a.date), document?.generators ?? []);
  const currentIndex = generatorsSorted.findIndex((g) => equals(generator, g));

  return currentIndex > 0 ? generatorsSorted[currentIndex - 1] : undefined;
};

/**
 * Handle discontinuations and continuations in VNS form and set treatment status accordingly
 * @param {IFormData['onChange']} onChange - onChange function for updating document values
 * @param {IVNSDiscontinuationEvent[] | undefined} discontinuations - Array of discontinuation events in the document
 * @param {IVNSGenerator[] | undefined} generators - Array of generators in the document
 * @param {string} status - Current status of the treatment
 * @param {boolean} hasEnded - True if treatment has been marked as ended, false if not
 */
export const discontinuationHandler = (
  onChange: IFormData['onChange'],
  discontinuations: Array<IVNSDiscontinuationEvent> | undefined,
  generators: Array<IVNSGenerator> | undefined,
  status: string,
  hasEnded: boolean,
): void => {
  const newDiscontinuations = JSON.parse(JSON.stringify(discontinuations ?? []));
  const removalDiscontinuations = newDiscontinuations.filter(
    (d: IVNSDiscontinuationEvent) => d.type !== 'generatorSwitchedOff',
  );

  const newGenerators = JSON.parse(JSON.stringify(generators ?? []));
  const latestDiscontinuation = getLatestDiscontinuation(newDiscontinuations);

  const updateDiscontinuationEndDates = (): void => {
    newGenerators.forEach((g: IVNSGenerator): void => {
      newDiscontinuations.forEach((d: IVNSDiscontinuationEvent, i: number): void => {
        if (d.type !== 'generatorSwitchedOff' && sortPartialDate(g.date, d.date) >= 0) {
          newDiscontinuations[i] = { ...newDiscontinuations[i], endDate: g.date };
          return;
        }
      });
    });
  };

  const removeDiscontinuationEndDates = (): void => {
    removalDiscontinuations
      .filter(
        (d: IVNSDiscontinuationEvent) =>
          !newGenerators.slice(0, newGenerators.length - 1).some((g: IVNSGenerator) => equals(d.endDate, g.date)),
      )
      .forEach((r: IVNSDiscontinuationEvent) => {
        const targetIndex = newDiscontinuations.indexOf(r);
        if (targetIndex >= 0) {
          newDiscontinuations[targetIndex] = { ...newDiscontinuations[targetIndex], endDate: undefined };
        }
      });
  };

  if (hasEnded) {
    status !== 'ended' && onChange?.({ status: 'ended' });
  }

  if (!Array.isArray(newDiscontinuations) || newDiscontinuations.length < 1) {
    !hasEnded && status !== 'inProgress' && onChange?.({ status: 'inProgress' });
  }

  if (latestDiscontinuation) {
    if (latestDiscontinuation.type === 'generatorSwitchedOff') {
      !hasEnded && status !== 'suspended' && onChange?.({ status: 'suspended' });
    } else {
      if (newGenerators.length < removalDiscontinuations.length + 1) {
        !hasEnded && status !== 'suspended' && onChange?.({ status: 'suspended' });
      }
      if (newGenerators.length >= removalDiscontinuations.length + 1) {
        updateDiscontinuationEndDates();
        removeDiscontinuationEndDates();

        !equals(newDiscontinuations, discontinuations) && onChange?.({ discontinuations: newDiscontinuations });
      }
      if (removalDiscontinuations.find((d: IVNSDiscontinuationEvent) => !d.endDate)) {
        !hasEnded && status !== 'suspended' && onChange?.({ status: 'suspended' });
      }
    }
  } else {
    if (newGenerators.length >= removalDiscontinuations.length + 1) {
      !hasEnded && status !== 'inProgress' && onChange?.({ status: 'inProgress' });

      updateDiscontinuationEndDates();
      removeDiscontinuationEndDates();

      !equals(newDiscontinuations, discontinuations) && onChange?.({ discontinuations: newDiscontinuations });
    } else {
      !hasEnded && status !== 'suspended' && onChange?.({ status: 'suspended' });

      removeDiscontinuationEndDates();

      !equals(newDiscontinuations, discontinuations) && onChange?.({ discontinuations: newDiscontinuations });
    }
  }
};

/**
 * Manage generator settings based on actions/changes done to generator(s)
 * @param {IFormData} formData - formData that has VNS documents and onChange function for updating the values
 * @param {'generator_deleted' | 'generator_changed' | 'generator_date_changed'} action - type of action done to generator(s)
 * @param {IVNSGenerator} generator - the generator related to the change
 */
export const generatorSettingHandler = (
  formData: IFormData,
  action: 'generator_deleted' | 'generator_changed' | 'generator_date_changed',
  generator?: IVNSGenerator,
): void => {
  const arraysAreValid = Array.isArray(formData.document.settings) && Array.isArray(formData.document.generators);
  switch (action) {
    case 'generator_deleted':
    case 'generator_changed': {
      if (arraysAreValid) {
        const newSettings = formData.document.settings.filter(
          (setting: IVNSSetting) => setting.generator !== generator?.id,
        );
        formData.onChange?.({ settings: newSettings });
      }
      break;
    }
    default: {
      return;
    }
  }
};

/**
 * Determine if VNS settings row column has number field in it
 * @param {string} column - Column name
 * @param {string} row - Row name
 * @param {boolean | undefined} night - True if night row differs from default row
 * @returns {string} - True if column has number field, false if not
 */
export const columnHasNumberField = (column: string, row: string, night?: boolean): boolean => {
  if (column === 'initialization') {
    return false;
  }
  if (
    ['autoStimMode', 'magnetMode'].includes(column) &&
    ['offTime', 'efficiencyCycle', 'autoStimThreshold'].includes(row)
  ) {
    return false;
  }
  if (['autoStimMode', 'magnetMode'].includes(column) && row === 'rate' && night) {
    return false;
  }
  return true;
};

/**
 * Get object property keys for different types of events
 * @param {string} type - Event type
 * @returns {string} - List of object property keys for given event
 */
export const keysByType = (type: string): Array<string> => {
  switch (type) {
    case 'modeActivation':
      return ['activationsPerDay', 'additionalInformation'];
    case 'postoperativeComplication':
      return ['complications', 'additionalInformation'];
    case 'adverseEffect':
      return ['effects', 'additionalInformation'];
    case 'technicalIssue':
      return ['issue', 'additionalInformation'];
    case 'discontinuation':
      return ['reason', 'type', 'lengthOfRemainingLead', 'additionalInformation', 'endDate'];
    default:
      return [];
  }
};

/**
 * Convert object property keys to header ids used in localization
 * @param {string} key - Object property key
 * @returns {string} - Header id for given key
 */
export const keyToHeader = (key: string): string => {
  switch (key) {
    case 'effects':
      return 'adverseEffects';
    case 'issue':
      return 'technicalIssue';
    case 'reason':
    case 'type':
    case 'endDate':
      return `discontinuation${capitalize(key)}`;
    default:
      return key;
  }
};
