/**
 * Type guard functions for type validation purposes
 */

import { IMSReport, TMSREPORT_MSCOURSE } from 'neuro-data-structures';
import PlatformCapabilities, { TPlatformCapability } from '../config/capabilities';
import { platforms } from '../config/platformSettings';

/**
 * Test if given parameter is number.
 * @param {any} n
 * @returns {n is number}
 */
export const isNumber = (n: any): n is number => typeof n === 'number';

/**
 * Test if given parameter is string.
 * @param {any} s
 * @returns {s is string}
 */
export const isString = (s: any): s is string => typeof s === 'string';

/**
 * Test if given parameter is boolean.
 * @param {any} b
 * @returns {b is boolean}
 */
export const isBoolean = (b: any): b is boolean => typeof b === 'boolean';

/**
 * Test if given parameter is object.
 * @param {any} o
 * @returns {o is object}
 */
export const isObject = (o: any): o is object => typeof o === 'object';

/**
 * Generic typeguard for pure arrays. Example of use:
 * `isArrayOf<string>(foo, isString)` to guard `foo` to be of type `string[]`.
 */
export function isArrayOf<T>(value: unknown, validator: (n: unknown) => n is T): value is Array<T> {
  if (!Array.isArray(value)) return false;
  return value.every((n) => validator(n));
}

export const isTPlatformCapability = (value: unknown): value is TPlatformCapability => {
  if (!isString(value)) return false;
  if (Object.values<string>(PlatformCapabilities).includes(value)) return true;
  else return false;
};

export const isPlatform = (value: unknown): value is Platform => {
  if (!isString(value)) return false;
  const goodValues: string[] = [...platforms];
  if (goodValues.includes(value)) return true;
  else return false;
};

export const isIControlProps = (n: unknown): n is IControlProps => {
  const testable = n as IControlProps;
  return (
    isString(testable._id) &&
    isString(testable._type) &&
    isString(testable._cid) &&
    isNumber(testable._cdate) &&
    isBoolean(testable._editing)
  );
};

export const isIDiagnosis = (n: unknown): n is IDiagnosis => isIControlProps(n) && n._type === 'diagnosis';

/** Helper typeguard for main typeguard `isIMSReport`. */
const hasIMSReportTopLevelKeys = (n: unknown): n is Record<keyof IMSReport, unknown> => {
  const requiredKeys: Array<keyof IMSReport> = [
    'activeDrugs',
    'age',
    'diagnosis',
    'edss',
    'gender',
    'msCourse',
    'msLength',
    'name',
    'patientCount',
  ];
  return requiredKeys.every((key) => Object.prototype.hasOwnProperty.call(n, key));
};

/**
 * Check whether `n` has the top level keys specified in `IMSReport`.
 * Note: not checking nested keys nor any values.
 */
export const isIMSReport = (n: unknown): n is IMSReport => {
  if (typeof n !== 'object' || n === null) return false;
  if (!hasIMSReportTopLevelKeys(n)) return false;
  return true;
};

/** Typeguard for `keyof IMSReport.msCourse`. */
export const isIMSReportMsCourse = (n: unknown): n is keyof TMSREPORT_MSCOURSE => {
  const legalKeys: Array<keyof TMSREPORT_MSCOURSE> = ['SP', 'RR', 'PP', 'unspecified', 'UNS', 'NA'];
  return typeof n === 'string' && legalKeys.includes(n as keyof TMSREPORT_MSCOURSE);
};
