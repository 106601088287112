import { fromPairs, path } from 'ramda';
import { injectIntl, IntlShape } from 'react-intl';
import * as React from 'react';

import { presetOptFormatter, presetOpts } from './components/PresetHandler';
import ConfirmationDialog from '../ConfirmationDialog';
import { exists } from 'neuro-utils';
import { omit } from 'Utility/ramdaReplacement';

// Use this to see if staticValue is valid. False is a valid value for staticValue
const isNotUndefined = (n: any): boolean => (n || n === false || n === 0 ? true : false);

const valueFormatter = (value: any, type?: TInputTypes): any => {
  if (type === 'RangeSlider') return value;
  switch (value) {
    case undefined:
      return '';
    case null:
      if (type === 'PartialDate') return null;
      return '';
    case true:
      return 'true';
    case false:
      return 'false';
    case 0:
      return ['Radio', 'RadioButtonRow'].includes(type ?? '') ? 0 : '0';
    default:
      return value;
  }
};

const someFieldsHaveValues = (fields: string[], data?: { [key: string]: any }): boolean => {
  return fields.some((f) => data && exists(data[f]));
};

class InputHandler extends React.Component<TInputProperties & IInputHandler, IOwnState> {
  constructor(props: IInputHandler & TInputProperties) {
    super(props);
    this.state = {
      confirmationOpen: false,
      confirmationAccept: null,
    };
  }

  cancelConfirmation = (): void => {
    this.setState({
      confirmationOpen: false,
      confirmationAccept: null,
    });
  };

  confirmation = (): void => {
    this.state.confirmationAccept?.forEach((c) => c());
  };

  /*
   * Take dependentFieldsList from InputHandler (if exists) and nullify those fields when changes are made to the main field
   * Does not nullify here if dependent fields are changed on the component level
   *
   * * If warning is enabled, open warning dialog and do changes if confirmed. Use item in formData to warn about changes inside array items
   */
  clearDependentFields = (): IFormData['onChange'] => {
    const { formData, dependentFieldsList, dependentFieldsRemovalWarning } = this.props;
    const onChange = formData?.onChange;
    const document = formData?.document;
    const item = formData?.item;
    if (!onChange) return undefined;
    return (e: TOnChangeValues, changedDependentFields?: { [field: string]: TFieldValue }): void => {
      if (changedDependentFields) {
        onChange({ ...e, ...changedDependentFields });
      } else {
        // Get the first changed value
        const name = Object.keys(e)[0];
        const value = e[name]; // Value the field is changed to
        const prevValue = formData.document[name]; // Value the field has

        const depFields = dependentFieldsList && dependentFieldsList(value, prevValue);
        const nulledFields =
          Array.isArray(depFields) && depFields.length > 0 ? fromPairs(depFields.map((f) => [f, null])) : undefined;

        if (
          (depFields && dependentFieldsRemovalWarning && someFieldsHaveValues(depFields, document)) ||
          (depFields && dependentFieldsRemovalWarning && item && someFieldsHaveValues(depFields, item))
        ) {
          this.setState({
            confirmationOpen: true,
            confirmationAccept: [
              (): void => onChange({ ...e, ...(nulledFields ? nulledFields : {}) }),
              (): void => this.setState({ confirmationOpen: false }),
            ],
          });
        } else {
          onChange({ ...e, ...(nulledFields ? nulledFields : {}) });
        }
      }
    };
  };

  render(): React.ReactNode {
    const {
      name,
      formData,
      dependentFieldsRemovalWarning,
      dependentFieldsRemovalWarningText,
      type,
      editing,
      staticValue,
      preset,
      placeholder,
      disablePlaceholderFormatting,
      noValueFormatting,
    } = this.props;

    const restProps = omit(
      ['formData', 'preset', 'staticValue', 'placeholder', 'disablePlaceholderFormatting'],
      this.props,
    );

    const options = 'options' in this.props && this.props.options;
    const optionFormatter = 'optionFormatter' in this.props && this.props.optionFormatter;

    const { formatMessage } = this.props.intl;

    return (
      <>
        {React.createElement(
          require(`./components/${type}`).default,
          {
            onChange: this.clearDependentFields(),
            value:
              !editing && isNotUndefined(staticValue)
                ? staticValue
                : noValueFormatting
                  ? path(['document', ...name.split('.')], formData)
                  : valueFormatter(path(['document', ...name.split('.')], formData), type),
            options: preset ? presetOpts(preset) : options,
            optionFormatter: preset ? presetOptFormatter(preset) : optionFormatter,
            placeholder:
              placeholder && typeof placeholder === 'string' && !disablePlaceholderFormatting
                ? formatMessage({ id: placeholder })
                : placeholder
                  ? placeholder
                  : '',
            ...restProps,
          },
          null,
        )}
        {dependentFieldsRemovalWarning && (
          <ConfirmationDialog
            open={this.state.confirmationOpen}
            text={
              dependentFieldsRemovalWarningText && typeof dependentFieldsRemovalWarningText === 'string'
                ? formatMessage({ id: dependentFieldsRemovalWarningText })
                : dependentFieldsRemovalWarningText
                  ? dependentFieldsRemovalWarningText
                  : formatMessage({ id: 'general.dependentFieldsRemovalWarning' })
            }
            cancel={{ callback: this.cancelConfirmation }}
            confirm={{ callback: this.confirmation }}
          />
        )}
      </>
    );
  }
}

interface IOwnState {
  confirmationOpen: boolean;
  confirmationAccept: Array<() => any> | null;
}

export interface IInputHandler {
  preset?:
    | 'yesno'
    | 'yesnoForceEn'
    | 'yesForceEn'
    | 'yes'
    | 'yesNoUnknown'
    | 'yesNoUnknownForceEn'
    | 'yesNoUnknownEmpty'
    | 'positiveNegativeUnknown';
  formData?: IFormData<{ [key: string]: any }>;
  intl: IntlShape;
}

export default injectIntl(InputHandler) as React.FC<TInputProperties & Omit<IInputHandler, 'intl'>>;
