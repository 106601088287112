import * as React from 'react';

import { getNinmtTreatmentPeriodsDateValues } from 'Components/DashboardGraph/Utils';
import {
  IAddon,
  IData,
  IDataPoint,
  IEvent,
  IItem,
  TDescriptionTable,
  TEventPriority,
} from 'Components/sq-graphics/interfaces';
import {
  dateFromPartial,
  formatPartialDate,
  isPartialDate,
  partialDateFromDate,
  partialDateToValue,
  sortPartialDate,
} from 'neuro-utils';
import { equals, isEmpty, uniq } from 'ramda';
import { fieldNameToCodeString } from 'Routes/Background/utils';
import { subjectOfTreatmentNames } from 'Routes/DoctorsOrders/Document/config';
import { gafScoreToClass, getMadrsDescription, madrsTotalScore } from 'Routes/InterviewSurveys/utils';
import { calculateValue } from 'Routes/Rtms/Document/functions';
import { findPrecedingDoctorsOrder, getSessionNumber, valueDiffersFromDoctorsOrders } from 'Utility/ninmtUtil';
import { ITreatmentMonitoringInquiry } from 'neuro-schemas';
import { sortDocuments } from 'Utility/randomUtil';

/**
 * NOTE! Function not completed, graph breakpoints with treatmentPeriodDocs needs to be implemented.
 * @param docs
 * @param dateFromPartialUpdateTimeframe
 * @param fm
 * @param treatmentPeriodDocs
 * @returns
 */
export const convertNumberOfPulsesToGraph = (
  docs: Array<IDoctorsOrder>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsArr: Array<IDataPoint[]> = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const rtmsTargetTreatments = d?.rtms?.subjectOfTreatment;
    if (d.date && rtmsTargetTreatments) {
      rtmsTargetTreatments.forEach((t) => {
        const treatmentTargetPoints: IDataPoint[] = [];
        const rtmsPulses = calculateValue(t, 'numberOfPulses');
        const descString = t.numberOfPulses?.toString().replace(/,/g, ' + ');
        if (
          (rtmsPulses || rtmsPulses === 0) &&
          d.date &&
          dataPointsArr.find((d) => d.some((p) => p.id === `${t.name}`))
        ) {
          dataPointsArr
            .find((d) => d.some((p) => p.id === `${t.name}`))
            ?.push({
              date: dateFromPartialUpdateTimeframe(d.date),
              value: rtmsPulses,
              id: `${t.name}`,
              description: descString,
            });
        } else if ((rtmsPulses || rtmsPulses === 0) && d.date) {
          treatmentTargetPoints.push({
            date: dateFromPartialUpdateTimeframe(d.date),
            value: rtmsPulses,
            id: `${t.name}`,
            description: descString,
          });
        }
        !isEmpty(treatmentTargetPoints) ? dataPointsArr.push(treatmentTargetPoints) : undefined;
      });
    }
  });
  const finalData: IData[] = dataPointsArr.map((dp) => {
    return {
      dataPoints: dp,
      id: `numberOfPulses-${dp[0].id}`,
      legend: fm(`doctorsOrders.subjectOfTreatmentNames.${dp[0].id}`),
      type: 'lineGraph',
    };
  });
  return finalData;
};

const dayMillis = 86400000;
const weekMillis = 7 * dayMillis;

const getLatestMondayTimestamp = (date: Date): number => {
  return date.getDay() === 1
    ? date.getTime()
    : date.getDay() === 0
      ? date.getTime() - 6 * dayMillis
      : date.getTime() - (date.getDay() - 1) * dayMillis;
};

export const convertTreatmentSessionsPerWeekToGraph = (
  docs: Array<IRTMS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const lastDocs = docs.sort((d1, d2) => sortPartialDate(d1?.date, d2?.date));

  const firstDocWithSessions = lastDocs.find((d) => d?.sessions);
  if (!firstDocWithSessions) return;
  const firstSessionWithLowestDate = firstDocWithSessions?.sessions
    ?.map((s) => partialDateToValue(s.date))
    .sort((a, b) => a - b)[0];
  if (!firstSessionWithLowestDate) return;
  const firstDate = new Date(firstSessionWithLowestDate);
  const firstMondayValue = getLatestMondayTimestamp(firstDate);
  const weeklyCounts: Array<{ [key: string]: number }> = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    d.sessions?.forEach((s) => {
      if (!s.date || !isPartialDate(s.date)) return;
      const thisSessionWeekIndex = Math.floor((partialDateToValue(s.date) - firstMondayValue) / weekMillis);
      s.subjectOfTreatment &&
        s.subjectOfTreatment.forEach((t) => {
          if (t.deleted) return;
          const treatmentTarget: string = t.specifier ? `${t.name}-${t.specifier}` : t.name;
          const countedSessionsThisTreatmentThisWeek: number =
            weeklyCounts.length > thisSessionWeekIndex &&
            weeklyCounts[thisSessionWeekIndex] &&
            weeklyCounts[thisSessionWeekIndex][treatmentTarget]
              ? weeklyCounts[thisSessionWeekIndex][treatmentTarget]
              : 0;
          if (countedSessionsThisTreatmentThisWeek > 0) {
            weeklyCounts[thisSessionWeekIndex][treatmentTarget] = countedSessionsThisTreatmentThisWeek + 1;
          } else {
            weeklyCounts[thisSessionWeekIndex] = { ...weeklyCounts[thisSessionWeekIndex], ...{ [treatmentTarget]: 1 } };
          }
        });
    });
  });
  const finalData: IData[] = [];
  weeklyCounts.forEach((w, i) => {
    for (const [key, value] of Object.entries(w)) {
      const treatMentTargetDataPoints: IDataPoint[] = [];
      let prevSessAmount = 0;
      for (let j = 0; j < i; j++) {
        if (weeklyCounts[j] && key in weeklyCounts[j]) {
          prevSessAmount += weeklyCounts[j][key];
        }
      }
      const sessionsSoFar = value + (Number.isNaN(prevSessAmount) ? 0 : prevSessAmount);
      treatMentTargetDataPoints.push({
        id: key,
        date: dateFromPartialUpdateTimeframe(partialDateFromDate(new Date(firstMondayValue + i * weekMillis))),
        title: `${value} ${fm('graph.timesPerWeekTotal')} (${fm('graph.total')} ${sessionsSoFar})`,
        value: value,
        alternativeDateString: `${formatPartialDate(
          partialDateFromDate(new Date(firstMondayValue + i * weekMillis)),
        )} - ${formatPartialDate(partialDateFromDate(new Date(firstMondayValue + (i + 1) * weekMillis - dayMillis)))}`,
      });
      const nameSplitted = key.split('-');
      const legend = nameSplitted[0];
      const otherName = !subjectOfTreatmentNames.includes(legend);
      const specifier = nameSplitted.length === 2 ? nameSplitted[1] : undefined;
      const customLegend = otherName ? `${legend}${specifier ? ` ${specifier}` : ''}` : undefined;
      treatMentTargetDataPoints.length > 0 &&
        finalData.push({
          dataPoints: treatMentTargetDataPoints,
          id: key,
          legend: customLegend
            ? customLegend
            : specifier
              ? `${fm(`rtms.subjectOfTreatmentNames.${legend}`)} ${specifier}`
              : fm(`rtms.subjectOfTreatmentNames.${legend}`),
          type: 'stackedBarChart',
          stackedBarChartProps: { interval: 'weekly' },
        });
    }
    i === 0 &&
      finalData.push({
        dataPoints: [],
        id: 'fillerToShowSingleLegendAlso',
        legend: '',
        type: 'stackedBarChart',
        stackedBarChartProps: { interval: 'weekly' },
      });
  });
  return finalData;
};

export const madrsGraphDescription = (madrs: IMADRS, totalScore: string) => (
  <>
    <div>{totalScore}</div>
    {madrs.details && <div>{madrs.details}</div>}
  </>
);

export const convertMadrsToGraph = (
  docs: Array<IMADRS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  measurementDocs: Array<IMeasurement>,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];

  const sortedTreatmentPeriods = treatmentPeriodDocs?.sort((d1, d2) => sortPartialDate(d2?.date, d1?.date));
  const xLineBreaks = sortedTreatmentPeriods
    .map((tp) => (tp?.date ? dateFromPartialUpdateTimeframe(tp.date) : undefined))
    .filter((v) => v) as Array<Date>;

  let madrsMeasurements = measurementDocs
    .filter((m) => fieldNameToCodeString('madrs') === m?.code)
    .map((madrs) => ({ ...madrs, value: madrs?.value && parseFloat(madrs?.value) }))
    .filter((madrs) => isPartialDate(madrs.date) && (madrs.value || madrs.value === 0));

  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const totalScore = madrsTotalScore(d);
    if (totalScore === 'notCounted') return;
    // Filter same date values from measurement docs
    madrsMeasurements = madrsMeasurements.filter((madrs) => !equals(madrs.date, d.date));
    if (totalScore || totalScore === 0) {
      // In case datapointssets need to be reimplemented, refer to previous commits
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: totalScore,
        title: `${totalScore}`,
        id: 'madrs',
        description: madrsGraphDescription(
          d,
          fm(`interviewSurveys.madrs.scoreDescriptions.${getMadrsDescription(totalScore)}`),
        ),
      });
    }
  });

  madrsMeasurements.forEach((madrs) => {
    if (madrs.date && (madrs.value || madrs.value === 0))
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(madrs.date),
        value: madrs.value,
        title: `${madrs.value}`,
        id: 'madrs',
        description: fm(`interviewSurveys.madrs.scoreDescriptions.${getMadrsDescription(madrs.value)}`),
      });
  });

  const finalData: IData = {
    dataPoints: dataPoints,
    id: 'madrs',
    legend: fm('interviewSurveys.madrs.title'),
    type: 'lineGraph',
    linegraphProps: {
      xAxisLineBreaks: xLineBreaks,
    },
  };
  return [finalData];
};

export const convertGafToGraph = (
  docs: Array<IGAF>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  measurementDocs: Array<IMeasurement>,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  const sortedTreatmentPeriods = treatmentPeriodDocs?.sort((d1, d2) => sortPartialDate(d2?.date, d1?.date));
  const xLineBreaks = sortedTreatmentPeriods
    .map((tp) => (tp?.date ? dateFromPartialUpdateTimeframe(tp.date) : undefined))
    .filter((v) => v) as Array<Date>;

  let gafMeasurements = measurementDocs
    .filter((m) => fieldNameToCodeString('gaf') === m?.code)
    .map((gaf) => ({ ...gaf, value: gaf?.value && parseFloat(gaf?.value) }))
    .filter((gaf) => isPartialDate(gaf.date) && (gaf.value || gaf.value === 0));

  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!(d.gafValue || d.gafValue === 0)) return;

    gafMeasurements = gafMeasurements.filter((gaf) => !equals(gaf.date, d.date));

    if (d.gafClass && d.gafValue) {
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.gafValue,
        title: `${d.gafValue}`,
        id: 'gaf',
        description: fm(`interviewSurveys.opts.gaf.${d.gafClass}`),
      });
    }
  });

  gafMeasurements.forEach((gaf) => {
    if (gaf.date && (gaf.value || gaf.value === 0))
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(gaf.date),
        value: gaf.value,
        title: `${gaf.value}`,
        id: 'gaf',
        description: fm(`interviewSurveys.opts.gaf.${gafScoreToClass(gaf.value)}`),
      });
  });

  const finalData: IData = {
    dataPoints: dataPoints,
    id: 'gaf',
    legend: fm('interviewSurveys.gaf.title'),
    type: 'lineGraph',
    linegraphProps: {
      xAxisLineBreaks: xLineBreaks,
    },
  };

  return [finalData];
};

export const convertDoctorsOrdersToTimeline = (
  docs: IDoctorsOrder[],
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  const events: Array<IEvent> = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const desc: TDescriptionTable = [];
    if (d.treatmentType?.includes('rtms')) {
      desc.push({ title: fm('rtms.title'), style: { fontWeight: 600 } });
      if (d.rtms?.endRTMS) {
        desc.push({ title: fm('graph.treatmentEnded') });
      }
      if (d.rtms?.device) {
        desc.push({
          title: fm('doctorsOrders.rtms.device.title'),
          values: [fm(`doctorsOrders.rtms.device.opts.${d.rtms.device}`)],
        });
        if (d.rtms.rmt) {
          desc.push({
            title: fm('doctorsOrders.rtms.rmt.title'),
            values: [`${d.rtms.rmt} %`],
          });
        }
      }
      if (d.rtms?.treatmentFrequency) {
        desc.push({
          title: fm('doctorsOrders.rtms.treatmentFrequency.title'),
          values: [d.rtms.treatmentFrequency],
        });
      }
      if (d.rtms?.subjectOfTreatment) {
        const values = d.rtms.subjectOfTreatment.map((s) => {
          if (s.nameOther) return `${s.nameOther}${s.specifier ? ` ${s.specifier}` : ''}`;
          return `${fm(`rtms.subjectOfTreatmentNames.${s.name}`)}${s.specifier ? ` ${s.specifier}` : ''}`;
        });
        const newRowValues = values.length > 2 ? values.slice(2, values.length) : undefined;
        const firstRowValues = newRowValues ? values.slice(0, 2) : undefined;
        if (newRowValues) {
          desc.push(
            {
              title: fm('doctorsOrders.rtms.subjectOfTreatment.titleShort'),
              values: firstRowValues,
              style: { width: '45rem' },
            },
            {
              title: '',
              values: newRowValues,
            },
          );
        } else {
          desc.push({
            title: fm('doctorsOrders.rtms.subjectOfTreatment.titleShort'),
            values: values,
            style: values.length > 1 ? { width: '45rem' } : undefined,
          });
        }
      }
      if (d.rtms?.otherOrder) {
        desc.push({
          title: fm('doctorsOrders.rtms.otherOrder.title'),
          values: [d.rtms.otherOrder],
        });
      }
    }
    if (d.treatmentType?.includes('tdcs')) {
      desc.push({ title: fm('tdcs.title'), style: { fontWeight: 600 } });
      if (d.tdcs?.endTDCS) {
        desc.push({ title: fm('graph.treatmentEnded') });
      }
      if (d.tdcs?.treatmentFrequency) {
        desc.push({
          title: fm('doctorsOrders.tdcs.treatmentFrequency.title'),
          values: [d.tdcs.treatmentFrequency],
        });
      }
      if (d.tdcs?.subjectOfTreatment) {
        const values = d.tdcs.subjectOfTreatment.map((s) => {
          if (s.nameOther) return `${s.nameOther}${s.specifier ? ` ${s.specifier}` : ''}`;
          return `${fm(`tdcs.subjectOfTreatmentNames.${s.name}`)}${s.specifier ? ` ${s.specifier}` : ''}`;
        });
        const newRowValues = values.length > 2 ? values.slice(2, values.length) : undefined;
        const firstRowValues = newRowValues ? values.slice(0, 2) : undefined;
        if (newRowValues) {
          desc.push(
            {
              title: fm('doctorsOrders.tdcs.subjectOfTreatment.titleShort'),
              values: firstRowValues,
              style: { width: '45rem' },
            },
            {
              title: '',
              values: newRowValues,
            },
          );
        } else {
          desc.push({
            title: fm('doctorsOrders.tdcs.subjectOfTreatment.titleShort'),
            values: values,
            style: values.length > 1 ? { width: '45rem' } : undefined,
          });
        }
      }
      if (d.tdcs?.otherOrder) {
        desc.push({
          title: fm('doctorsOrders.tdcs.otherOrder.title'),
          values: [d.tdcs.otherOrder],
        });
      }
    }
    if (d.nextRMT || d.nextInterview || d.ninmtMeeting || d.requestFollowUp) {
      desc.push({
        title: fm('doctorsOrders.other.header'),
        style: { fontWeight: 600 },
      });
      if (d.nextRMT) {
        desc.push({
          title: fm('doctorsOrders.other.nextRMT.title'),
          values: [formatPartialDate(d.nextRMT)],
        });
      }
      if (d.nextInterview) {
        desc.push({ title: fm('doctorsOrders.other.nextInterview.title'), values: [d.nextInterview] });
      }
      if (d.ninmtMeeting) {
        desc.push({
          title: fm('doctorsOrders.other.ninmtMeeting.title'),
          values: [fm(`doctorsOrders.other.ninmtMeeting.opts.${d.ninmtMeeting}`)],
        });
      }
      if (d.requestFollowUp) {
        desc.push({
          title: fm('doctorsOrders.other.requestFollowUp.title'),
          values: [fm(`doctorsOrders.other.requestFollowUp.opts.${d.requestFollowUp}`)],
        });
      }
    }
    events.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      eventType: 'start',
      title: fm('rtms.doctorsOrder'),
      description: desc,
    });
  });
  addons.push({
    id: 'doctorsOrders',
    title: fm('doctorsOrders.title'),
    titleDescription: undefined,
    items: [],
    events: events,
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertTreatmentsToTimeline = (
  docs: Array<IRTMS | ITDCS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  doctorsOrdersDocs: IDoctorsOrder[],
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;

  const addons: Array<IAddon> = [];
  const allRtmsDocs = docs.filter((d) => d._type === 'rtms');
  const allTdcsDocs = docs.filter((d) => d._type === 'tdcs');
  docs.forEach((doc) => {
    switch (doc._type) {
      case 'rtms': {
        const rtmsDoc: IRTMS = JSON.parse(JSON.stringify(doc));
        if (!isPartialDate(rtmsDoc.date)) return;
        if (Array.isArray(rtmsDoc.sessions) && rtmsDoc.sessions.length > 0) {
          rtmsDoc.sessions = rtmsDoc.sessions?.filter((s: IRTMSSession) => !(s.removeTS || s.removeInfo));
        }
        const items: IItem[] = [];
        const events: IEvent[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(rtmsDoc.date),
          end: rtmsDoc.hasEnded
            ? rtmsDoc.endDate
              ? dateFromPartialUpdateTimeframe(rtmsDoc.endDate)
              : Array.isArray(rtmsDoc.sessions) && isPartialDate(rtmsDoc.sessions[0]?.date)
                ? dateFromPartialUpdateTimeframe(rtmsDoc?.sessions?.[0]?.date)
                : undefined
            : undefined,
          title: rtmsDoc.type ? fm(`rtms.opts.${rtmsDoc.type}`) : '-',
          themeId: rtmsDoc.type === 'intense' ? 'mystic' : undefined,
        });
        let prio = 'low';
        (rtmsDoc.sessions || []).forEach((s, ind) => {
          if (!isPartialDate(s.date)) return;

          const desc: TDescriptionTable = [];
          prio = 'low';

          s.subjectOfTreatment?.forEach((t, i) => {
            const fields: Array<keyof IRTMSTreatmentEvent> = [
              'stimulationType',
              'peelingDepth',
              'pulseFrequency',
              'pulseFrequency3PulseBurst',
              'frequencyBetweenBursts',
              'amountOfBurstsInSet',
              'amountOfSetsInSequence',
              'timeBetweenBurstsets',
              'pulseIntensity',
              'numberOfPulses',
              'pauseLength',
              'electricFieldStrength',
            ];
            let differs: { [key: string]: any }[] = [];
            if (!t.deleted) {
              differs = fields
                .map<{ [key: string]: any }>((f) => {
                  const value = t[f];
                  const valueDiffers = Array.isArray(value)
                    ? value.map((val: any, index: number) => {
                        return valueDiffersFromDoctorsOrders(s, i, f, val, index, true);
                      })
                    : valueDiffersFromDoctorsOrders(s, i, f, value, undefined, true);
                  return { [f]: valueDiffers };
                })
                .filter((obj) => {
                  const value = Object.values(obj)[0];
                  if (Array.isArray(value) && value.length > 1) {
                    return value.some((value: number) => value);
                  } else {
                    if (value === undefined) {
                      return true;
                    } else return value;
                  }
                });
            }

            const descAdditional: TDescriptionTable =
              t.deleted && t.deleteReason
                ? [
                    {
                      title: fm(
                        `graph.${
                          t.deleteReason === 'partOfTreatmentProtocol'
                            ? 'noTreatmentProtocolDeviationDelete'
                            : 'doctorsOrdersDeviation'
                        }`,
                      ),
                    },
                  ]
                : differs.length === 0
                  ? [{ title: fm('graph.noTreatmentProtocolDeviation') }]
                  : differs
                      .map((d) => {
                        const key: keyof IRTMSTreatmentEvent = Object.keys(d)[0] as any;
                        if (Array.isArray(Object.values(d)[0])) {
                          const v = t[key];
                          if (!Array.isArray(v)) return { title: 'filter', values: [1, 2] };
                          const values: (number | null)[] = v.filter((v: number | null) => v);
                          const valueString = key === 'numberOfPulses' ? values.join(' + ') : values.join(' - ');
                          const refValues: (number | string | null)[] =
                            t.reference?.origin === 'doctorsOrder'
                              ? (t.reference?.values?.[key] as any)
                              : (t.reference?.doctorsOrderValues?.[key] as any);
                          const refValueString =
                            key === 'numberOfPulses' && refValues
                              ? refValues.join(' + ')
                              : refValues && refValues.join(' - ');
                          return {
                            title: fm(`rtms.subjectOfTreatment.${Object.keys(d)}`),
                            values: [valueString, `(${refValueString})*`],
                          };
                        } else {
                          const v = t[key];
                          if (Array.isArray(v)) return { title: 'filter', values: [1, 2] };
                          return {
                            title: fm(`rtms.subjectOfTreatment.${Object.keys(d)}`),
                            values: [v, `(${d[key] === undefined ? '-' : d[key]})*`],
                          };
                        }
                      })
                      .filter((t) => t.title !== 'filter');

            if (t.deleted && t.deleteReason === 'otherReason') prio = 'deleted';
            else if (differs.length > 0) prio = 'high';

            const allRtmsSessions = allRtmsDocs.flatMap((d) => d.sessions).filter((s) => s) as IRTMSSession[];
            const currentIndex = allRtmsSessions.findIndex((e) => equals(e, s));
            const sessionNumber = getSessionNumber(allRtmsSessions, currentIndex - 1, t);

            desc.push(
              {
                title: t.nameOther ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{`${t.nameOther}${t.specifier ? ` ${t.specifier}` : ''}`}</span>
                    <span>{`(${sessionNumber}. ${fm('graph.session')})`}</span>
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      {`${fm(`rtms.subjectOfTreatmentNames.${t.name}`)}${t.specifier ? ` ${t.specifier}` : ''}`}
                    </span>
                    <span>{`(${sessionNumber}. ${fm('graph.session')})`}</span>
                  </div>
                ),
                ...(t.deleted ? { style: { textDecorationLine: 'line-through' } } : {}),
              },
              ...descAdditional.map((desc) => ({ ...desc, style: { paddingLeft: '2rem' } })),
            );
          });

          const latestDrOrder =
            doctorsOrdersDocs &&
            rtmsDoc.sessions &&
            findPrecedingDoctorsOrder('rtms', doctorsOrdersDocs, rtmsDoc.sessions, ind);

          if (
            latestDrOrder &&
            s.subjectOfTreatment &&
            latestDrOrder.rtms &&
            latestDrOrder.rtms.subjectOfTreatment &&
            latestDrOrder.rtms?.subjectOfTreatment?.length > s.subjectOfTreatment?.length
          ) {
            const drOrderTargets = latestDrOrder?.rtms?.subjectOfTreatment?.map((st) => {
              return `${st.name}-${st.specifier}`;
            });
            const sessionTargets = s.subjectOfTreatment?.map((tar) => {
              return `${tar.name}-${tar.specifier}`;
            });
            const missingTargets = drOrderTargets?.filter((t) => !sessionTargets?.includes(t));
            missingTargets?.forEach((mt) => {
              const splitted = mt.split('-');
              const specifierFound = splitted[1] && splitted[1].length === 1;
              desc.push({
                title: `${fm(`rtms.subjectOfTreatmentNames.${splitted[0]}`)} ${specifierFound ? splitted[1] : ''}`,
                style: { textDecorationLine: 'line-through' },
              });
            });
          }

          if (prio === 'high') {
            desc.push({
              title: `*${fm('graph.docOrdersValueInfo')}`,
              values: [''],
              style: { fontStyle: 'italic', padding: '1rem 0 0 2rem' },
            });
          }

          if (s.additionalInformation) {
            desc.push({
              title: fm('rtms.additionalInformation'),
              values: s.additionalInformation,
            });
          }

          events.push({
            date: dateFromPartialUpdateTimeframe(s.date),
            eventType: 'treatment',
            title: fm('rtms.session'),
            description: desc,
            priority: (prio === 'deleted' ? 'high' : prio) as TEventPriority,
            secondaryTitle: fm('graph.doctorsOrdersDeviation'),
          });
        });
        (rtmsDoc.unusedSessions || []).forEach((u) => {
          if (!isPartialDate(u.date)) return;
          u.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(u.date),
              eventType: 'treatment',
              title: fm('rtms.unusedSession'),
              description: [
                {
                  title: u.reason ? fm(`rtms.opts.unusedSessionReason.${u.reason}`) : undefined,
                  condition: !!u.reason,
                },
                {
                  title: u.reasonDetails
                    ? `${fm(`rtms.opts.unusedSessionReasonDetails.${u.reasonDetails}`)}${
                        u.reasonDetails === 'other' && u.reasonDetailsOther && u.reasonDetailsOther.length > 0
                          ? `: ${u.reasonDetailsOther}`
                          : ''
                      }`
                    : undefined,
                  condition: !!(u.reason && u.reasonDetails),
                },
              ],
              priority: 'normal',
            });
        });
        (rtmsDoc.complications || []).forEach((comp) => {
          if (!isPartialDate(comp.date)) return;
          comp.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(comp.date),
              eventType: 'technicalIssue',
              description: [
                ...(comp.complications?.map((c) => ({
                  title: fm(`rtms.opts.complications.${c}`),
                })) || []),
                ...(comp.additionalInformation
                  ? [{ title: fm('rtms.additionalInformationComplications'), values: comp.additionalInformation }]
                  : []),
              ],
              title: fm('graph.complication'),
            });
        });
        (rtmsDoc.adverseEffects || []).forEach((a) => {
          if (!isPartialDate(a.date)) return;
          const desc: IEvent['description'] = a.adverseEffects?.map((e) => {
            return { title: fm(`rtms.opts.adverseEffects.${e}`) };
          });
          if (a.additionalInformation) {
            desc?.push({ title: fm('rtms.additionalInformationAdverseEffects'), values: a.additionalInformation });
          }
          a.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(a.date),
              eventType: 'adverseEffect',
              description: desc,
              title: fm('graph.sideEffect'),
            });
        });
        addons.push({
          id: doc._type,
          title: fm('rtms.titleShort'),
          items: items,
          events: events,
        });
        break;
      }
      case 'tdcs': {
        const tdcsDoc: ITDCS = JSON.parse(JSON.stringify(doc));
        if (!isPartialDate(tdcsDoc.date)) return;
        if (Array.isArray(tdcsDoc.sessions) && tdcsDoc.sessions.length > 0) {
          tdcsDoc.sessions = tdcsDoc.sessions?.filter((s) => !(s.removeTS || s.removeInfo));
        }
        const items: IItem[] = [];
        const events: IEvent[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(tdcsDoc.date),
          end:
            tdcsDoc.hasEnded && isPartialDate(tdcsDoc.endDate)
              ? dateFromPartialUpdateTimeframe(tdcsDoc.endDate)
              : undefined,
          title: tdcsDoc.type ? fm(`tdcs.opts.${tdcsDoc.type}`) : '-',
          themeId: tdcsDoc.type === 'intense' ? 'mystic' : undefined,
        });
        let prio = 'low';
        (tdcsDoc.sessions || []).forEach((s, ind) => {
          if (!isPartialDate(s.date)) return;

          const desc: TDescriptionTable = [];
          prio = 'low';

          s.subjectOfTreatment?.forEach((t, i) => {
            const fields: Array<keyof ITDCSTreatmentEvent> = [
              'type',
              'cathode',
              'cathodeOther',
              'anode',
              'anodeOther',
              'current',
              'currentDuration',
            ];
            let differs: { [key: string]: any }[] = [];
            if (!t.deleted) {
              differs = fields
                .map((f) => {
                  return {
                    [f]: valueDiffersFromDoctorsOrders(s, i, f, s.subjectOfTreatment?.[i]?.[f], undefined, true),
                  };
                })
                .filter((pair) => Object.values(pair).every((v) => v));
            }

            const descAdditional =
              t.deleted && t.deleteReason
                ? [
                    {
                      title: fm(
                        `graph.${
                          t.deleteReason === 'partOfTreatmentProtocol'
                            ? 'noTreatmentProtocolDeviationDelete'
                            : 'doctorsOrdersDeviation'
                        }`,
                      ),
                    },
                  ]
                : differs.length === 0
                  ? [{ title: fm('graph.noTreatmentProtocolDeviation') }]
                  : differs.map((d) => {
                      return {
                        title: fm(`tdcs.subjectOfTreatment.${Object.keys(d)[0]}`),
                        values: [t[Object.keys(d)[0] as keyof ITDCSTreatment], `(${Object.values(d)[0]})*`],
                      };
                    });

            if (t.deleted && t.deleteReason === 'otherReason') prio = 'deleted';
            else if (differs.length > 0) prio = 'high';

            const allTdcsSessions = allTdcsDocs.flatMap((d) => d.sessions).filter((s) => s) as ITDCSSession[];
            const currentIndex = allTdcsSessions.findIndex((e) => equals(e, s));
            const sessionNumber = getSessionNumber(allTdcsSessions, currentIndex - 1, t);

            desc.push(
              {
                title: t.nameOther ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{`${t.nameOther}${t.specifier ? ` ${t.specifier}` : ''}`}</span>
                    <span>{`(${sessionNumber}. ${fm('graph.session')})`}</span>
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      {`${fm(`tdcs.subjectOfTreatmentNames.${t.name}`)}${t.specifier ? ` ${t.specifier}` : ''}`}
                    </span>
                    <span>{`(${sessionNumber}. ${fm('graph.session')})`}</span>
                  </div>
                ),
                ...(t.deleted ? { style: { textDecorationLine: 'line-through' } } : {}),
              },
              ...descAdditional.map((desc) => ({ ...desc, style: { paddingLeft: '2rem' } })),
            );
          });
          const latestDrOrder =
            doctorsOrdersDocs &&
            tdcsDoc.sessions &&
            findPrecedingDoctorsOrder('tdcs', doctorsOrdersDocs, tdcsDoc.sessions, ind);

          if (
            latestDrOrder &&
            s.subjectOfTreatment &&
            latestDrOrder.rtms &&
            latestDrOrder.rtms.subjectOfTreatment &&
            latestDrOrder.rtms?.subjectOfTreatment?.length > s.subjectOfTreatment?.length
          ) {
            const drOrderTargets = latestDrOrder?.rtms?.subjectOfTreatment?.map((st) => {
              return `${st.name}-${st.specifier}`;
            });
            const sessionTargets = s.subjectOfTreatment?.map((tar) => {
              return `${tar.name}-${tar.specifier}`;
            });
            const missingTargets = drOrderTargets?.filter((t) => !sessionTargets?.includes(t));
            missingTargets?.forEach((mt) => {
              const splitted = mt.split('-');
              const specifierFound = splitted[1] && splitted[1].length === 1;
              desc.push({
                title: `${fm(`tdcs.subjectOfTreatmentNames.${splitted[0]}`)} ${specifierFound ? splitted[1] : ''}`,
                style: { textDecorationLine: 'line-through' },
              });
            });
          }

          if (prio === 'high') {
            desc.push({
              title: `*${fm('graph.docOrdersValueInfo')}`,
              values: [''],
              style: { fontStyle: 'italic', padding: '1rem 0 0 2rem' },
            });
          }

          if (s.additionalInformation) {
            desc.push({
              title: fm('tdcs.additionalInformation'),
              values: s.additionalInformation,
            });
          }

          events.push({
            date: dateFromPartialUpdateTimeframe(s.date),
            eventType: 'treatment',
            title: fm('tdcs.opts.eventTypes.session'),
            description: desc,
            priority: (prio === 'deleted' ? 'high' : prio) as TEventPriority,
            secondaryTitle: fm('graph.doctorsOrdersDeviation'),
          });
        });
        (tdcsDoc.unusedSessions || []).forEach((u) => {
          if (!isPartialDate(u.date)) return;
          u.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(u.date),
              eventType: 'treatment',
              title: fm('tdcs.unusedSession'),
              description: [
                {
                  title: u.reason ? fm(`tdcs.opts.unusedSessionReason.${u.reason}`) : undefined,
                  condition: !!u.reason,
                },
                {
                  title: u.reasonDetails
                    ? `${fm(`tdcs.opts.unusedSessionReasonDetails.${u.reasonDetails}`)}${
                        u.reasonDetails === 'other' && u.reasonDetailsOther && u.reasonDetailsOther.length > 0
                          ? `: ${u.reasonDetailsOther}`
                          : ''
                      }`
                    : undefined,
                  condition: !!(u.reason && u.reasonDetails),
                },
              ],
              priority: 'normal',
            });
        });
        (tdcsDoc.otherEvents || []).forEach((oe) => {
          if (!isPartialDate(oe.date)) return;
          const priorityColor: TEventPriority =
            oe.type === 'doctorInterview' ? 'normal' : oe.type === 'nurseReception' ? 'low' : 'high';
          oe.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(oe.date),
              eventType: 'activity',
              title: fm(`tdcs.opts.eventTypes.${oe.type}`),
              description: oe.details,
              priority: priorityColor,
            });
        });
        (tdcsDoc.complications || []).forEach((comp) => {
          if (!isPartialDate(comp.date)) return;
          comp.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(comp.date),
              eventType: 'technicalIssue',
              description: [
                ...(comp.complications?.map((c) => ({
                  title: fm(`tdcs.opts.complications.${c}`),
                })) || []),
                ...(comp.additionalInformation
                  ? [{ title: fm('tdcs.additionalInformationComplications'), values: comp.additionalInformation }]
                  : []),
              ],
              title: fm('graph.complication'),
            });
        });
        (tdcsDoc.adverseEffects || []).forEach((a) => {
          if (!isPartialDate(a.date)) return;
          const desc: IEvent['description'] = a.adverseEffects?.map((e) => {
            return { title: fm(`tdcs.opts.adverseEffects.${e}`) };
          });
          if (a.additionalInformation) {
            desc?.push({ title: fm('tdcs.additionalInformationAdverseEffects'), values: a.additionalInformation });
          }
          a.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(a.date),
              eventType: 'adverseEffect',
              description: desc,
              title: fm('graph.sideEffect'),
            });
        });
        addons.push({
          id: doc._type,
          title: fm('tdcs.titleShort'),
          items: items,
          events: events,
        });
        break;
      }
      default: {
        break;
      }
    }
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertTreatmentVisitsPerWeekToGraph = (
  docs: Array<IRTMS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
): IData[] | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsBox: IDataPoint[] = [];
  const dataPointSetsLine: Array<IDataPoint[]> = Array.from({ length: treatmentPeriodDocs.length ?? 1 }, () => []);
  const treatmentPeriodsDateValues = getNinmtTreatmentPeriodsDateValues(treatmentPeriodDocs);
  const lastDocs = docs.sort((d1, d2) => sortPartialDate(d1?.date, d2?.date));

  const firstDocWithSessions = lastDocs.find((d) => d?.sessions);
  if (!firstDocWithSessions) return;
  if (!isPartialDate(firstDocWithSessions.date)) return;
  const firstDocWithSessionsDateValuesSorted = firstDocWithSessions.sessions
    ?.map((s) => partialDateToValue(s.date))
    .sort((a, b) => a - b);
  const firstSessionDate =
    firstDocWithSessionsDateValuesSorted && firstDocWithSessionsDateValuesSorted[0]
      ? new Date(firstDocWithSessionsDateValuesSorted[0])
      : undefined;
  const firstMondayPoint = firstSessionDate ? getLatestMondayTimestamp(firstSessionDate) : undefined;
  if (!firstMondayPoint) return;

  const weeklyCounts: Array<number> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    doc.sessions &&
      doc.sessions.forEach((s) => {
        if (!isPartialDate(s.date)) return;
        const thisSessionDateValue = partialDateToValue(s.date);
        let weekNumber = 0;
        while (thisSessionDateValue >= firstMondayPoint + (weekNumber + 1) * dayMillis * 7) {
          weekNumber++;
        }
        weeklyCounts[weekNumber] = weeklyCounts[weekNumber] ? weeklyCounts[weekNumber] + 1 : 1;
      });
  });
  weeklyCounts.forEach((w, i) => {
    dataPointsBox.push({
      date: dateFromPartialUpdateTimeframe(partialDateFromDate(new Date(firstMondayPoint + i * dayMillis * 7))),
      value: w,
      id: 'treatmentVisitsPerWeek',
      title: `${w}`,
      alternativeDateString: `${formatPartialDate(
        partialDateFromDate(new Date(firstMondayPoint + i * dayMillis * 7)),
      )} - ${formatPartialDate(partialDateFromDate(new Date(firstMondayPoint + (i + 1) * dayMillis * 7 - dayMillis)))}`,
    });
  });
  if (weeklyCounts.length >= 4) {
    const slicer = 4;
    const fourWeekSet = [];
    for (let i = 0; i < weeklyCounts.length; i += slicer) {
      fourWeekSet.push(weeklyCounts.slice(i, i + slicer));
    }
    const fourWeekSetFiltered = fourWeekSet.filter((set) => set.length === 4);

    let indexChecker = 0;

    fourWeekSetFiltered.forEach((s, ind) => {
      const dataPointSetIndex = treatmentPeriodsDateValues.findIndex(
        (dv, i) =>
          partialDateToValue(partialDateFromDate(new Date(firstMondayPoint + (ind + 1) * 4 * dayMillis * 7))) >= dv &&
          partialDateToValue(partialDateFromDate(new Date(firstMondayPoint + (ind + 1) * 4 * dayMillis * 7))) <
            (treatmentPeriodsDateValues[i + 1] ?? Number.MAX_SAFE_INTEGER),
      );

      const realLength = s.filter((v) => v).length;

      let totalValue = 0;
      s.forEach((v) => (totalValue += v));
      const average = totalValue / 4;
      dataPointSetIndex >= 0 &&
        ((realLength < 4 && indexChecker === dataPointSetIndex) || realLength === 4) &&
        dataPointSetsLine[dataPointSetIndex].push({
          date: dateFromPartialUpdateTimeframe(
            partialDateFromDate(new Date(firstMondayPoint + (ind + 1) * 4 * dayMillis * 7)),
          ),
          value: average,
          id: 'treatmentVisitsFourWeekAverage',
          alternativeDateString: `${formatPartialDate(
            partialDateFromDate(new Date(firstMondayPoint + ind * 4 * dayMillis * 7)),
          )} - ${formatPartialDate(
            partialDateFromDate(new Date(firstMondayPoint + (ind + 1) * 4 * dayMillis * 7 - dayMillis)),
          )}`,
        });
      indexChecker = dataPointSetIndex;
    });
  }
  const finalAverageData: IData[] = dataPointSetsLine.map((dp, i) => {
    return {
      dataPoints: dp,
      id: `treatmentVisitsFourWeekAverage${i}`,
      legend: fm('graph.treatmentVisitsPerWeekFourWeekAverage'),
      type: 'lineGraph',
    };
  });
  return [
    {
      dataPoints: dataPointsBox,
      id: 'treatmentVisitsPerWeek',
      legend: fm('graph.treatmentVisitsPerWeek'),
      type: 'stackedBarChart',
      stackedBarChartProps: { interval: 'weekly' },
    },
    ...finalAverageData,
  ];
};

export const convertEffectIntensityToGraph = (
  docs: Array<IRTMS | ITDCS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
): Array<{ data: IData[]; id: string }> | undefined => {
  if (docs.length === 0) return undefined;
  const sortedTreamentPeriods = treatmentPeriodDocs.sort((d1, d2) => sortPartialDate(d1?.date, d2?.date));
  const allLocationsData: Array<{ data: IData[]; id: string }> = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!d.sessions) return;
    d.sessions.forEach((s) => {
      if (!isPartialDate(s.date)) return;
      if (!s.patientsRating) return;
      s.patientsRating.forEach((r) => {
        if (!r.ratingByLocation) return;
        const dataPointsAfter: IDataPoint[] = [];
        const dataPointsBefore: IDataPoint[] = [];
        r.ratingByLocation.forEach((rl) => {
          s.date &&
            (rl.intensity?.afterTreatment || rl.intensity?.afterTreatment === 0) &&
            rl.symptomsAndLocationsId &&
            dataPointsAfter.push({
              date: dateFromPartialUpdateTimeframe(s.date),
              value: rl.intensity.afterTreatment,
              title: `${rl.intensity.afterTreatment.toFixed(1)}`,
              id: `${rl.symptomsAndLocationsId}@after${sortedTreamentPeriods.findIndex(
                (p) => partialDateToValue(d.date) >= partialDateToValue(p.date),
              )}`,
              additionalValue: `${fm(`diagnosis.ninmt.symptomsAndLocations.opts.${r.symptom}`)}${
                rl.location
                  ? ': ' + fm(`diagnosis.ninmt.symptomsAndLocations.opts.${rl.location}`)
                  : ': ' + (rl.description ?? '')
              }`,
            });

          s.date &&
            (rl.intensity?.beforeTreatment || rl.intensity?.beforeTreatment === 0) &&
            rl.symptomsAndLocationsId &&
            dataPointsBefore.push({
              date: dateFromPartialUpdateTimeframe(s.date),
              value: rl.intensity.beforeTreatment,
              title: `${rl.intensity.beforeTreatment.toFixed(1)}`,
              id: `${rl.symptomsAndLocationsId}@before${sortedTreamentPeriods.findIndex(
                (p) => partialDateToValue(d.date) >= partialDateToValue(p.date),
              )}`,
              additionalValue: `${fm(`diagnosis.ninmt.symptomsAndLocations.opts.${r.symptom}`)}${
                rl.location
                  ? ': ' + fm(`diagnosis.ninmt.symptomsAndLocations.opts.${rl.location}`)
                  : ': ' + (rl.description ?? '')
              }`,
            });
        });

        dataPointsBefore.forEach((p) => {
          const pointLocationId = p.id.split('@')[0];
          const locationIndex = allLocationsData.findIndex((d) => d.id === pointLocationId);
          if (locationIndex >= 0) {
            const dataIndex = allLocationsData[locationIndex].data.findIndex((d) =>
              d.dataPoints.find((dp) => dp.id === p.id),
            );
            if (dataIndex >= 0) {
              allLocationsData[locationIndex].data[dataIndex].dataPoints.push(p);
            } else {
              allLocationsData[locationIndex].data.push({
                id: p.id,
                type: 'lineGraph',
                dataPoints: [p],
                legend: fm('graph.intensityBefore'),
              });
            }
          } else {
            allLocationsData.push({
              id: pointLocationId,
              data: [{ id: p.id, type: 'lineGraph', dataPoints: [p], legend: fm('graph.intensityBefore') }],
            });
          }
        });

        dataPointsAfter.forEach((p) => {
          const pointLocationId = p.id.split('@')[0];
          const locationIndex = allLocationsData.findIndex((d) => d.id === pointLocationId);
          if (locationIndex >= 0) {
            const dataIndex = allLocationsData[locationIndex].data.findIndex((d) =>
              d.dataPoints.find((dp) => dp.id === p.id),
            );
            if (dataIndex >= 0) {
              allLocationsData[locationIndex].data[dataIndex].dataPoints.push(p);
            } else {
              allLocationsData[locationIndex].data.push({
                id: p.id,
                type: 'lineGraph',
                dataPoints: [p],
                legend: fm('graph.intensityAfter'),
              });
            }
          } else {
            allLocationsData.push({
              id: pointLocationId,
              data: [
                {
                  id: p.id,
                  type: 'lineGraph',
                  dataPoints: [p],
                  legend: fm('graph.intensityAfter'),
                },
              ],
            });
          }
        });
      });
    });
  });
  allLocationsData.forEach((ld) => {
    ld.data.forEach((d) => {
      if (d.id.includes('before')) {
        d.dataPoints
          .slice()
          .sort((a, b) => b.date.getTime() - a.date.getTime())
          .forEach((dp, i, arr) => {
            if (i !== arr.length - 1) {
              dp.description = [
                {
                  title: fm('graph.beforeFirstTreatment'),
                  values: parseInt(`${arr[arr.length - 1].value}`).toFixed(1),
                },
              ];
              const percentageChange = (1 - parseInt(`${dp.value}`) / parseInt(`${arr[arr.length - 1]?.value}`)) * 100;
              if (Number.isFinite(percentageChange)) {
                dp.title = `${parseInt(`${dp.value}`).toFixed(1)} (${(0 - percentageChange).toFixed(1)} %)`;
              } else {
                dp.title = `${parseInt(`${dp.value}`).toFixed(1)} (${fm('graph.unableToCalculatePercentage')})`;
              }
            }
          });
      }
    });
  });
  return allLocationsData;
};

export const convertEffectHarmToGraph = (
  docs: Array<IRTMS | ITDCS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
): Array<{ data: IData[]; id: string }> | undefined => {
  if (docs.length === 0) return undefined;
  const sortedTreamentPeriods = treatmentPeriodDocs.sort((d1, d2) => sortPartialDate(d1?.date, d2?.date));
  const allLocationsData: Array<{ data: IData[]; id: string }> = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!d.sessions) return;
    d.sessions.forEach((s) => {
      if (!isPartialDate(s.date)) return;
      if (!s.patientsRating) return;
      s.patientsRating.forEach((r) => {
        if (!r.ratingByLocation) return;
        const dataPointsAfter: IDataPoint[] = [];
        const dataPointsBefore: IDataPoint[] = [];
        r.ratingByLocation.forEach((rl) => {
          s.date &&
            (rl.harm?.afterTreatment || rl.harm?.afterTreatment === 0) &&
            rl.symptomsAndLocationsId &&
            dataPointsAfter.push({
              date: dateFromPartialUpdateTimeframe(s.date),
              value: rl.harm.afterTreatment,
              title: `${rl.harm.afterTreatment.toFixed(1)}`,
              id: `${rl.symptomsAndLocationsId}@after${sortedTreamentPeriods.findIndex(
                (p) => partialDateToValue(d.date) >= partialDateToValue(p.date),
              )}`,
              additionalValue: `${fm(`diagnosis.ninmt.symptomsAndLocations.opts.${r.symptom}`)}${
                rl.location
                  ? ': ' + fm(`diagnosis.ninmt.symptomsAndLocations.opts.${rl.location}`)
                  : ': ' + (rl.description ?? '')
              }`,
            });

          s.date &&
            (rl.harm?.beforeTreatment || rl.harm?.beforeTreatment === 0) &&
            rl.symptomsAndLocationsId &&
            dataPointsBefore.push({
              date: dateFromPartialUpdateTimeframe(s.date),
              value: rl.harm.beforeTreatment,
              title: `${rl.harm.beforeTreatment.toFixed(1)}`,
              id: `${rl.symptomsAndLocationsId}@before${sortedTreamentPeriods.findIndex(
                (p) => partialDateToValue(d.date) >= partialDateToValue(p.date),
              )}`,
              additionalValue: `${fm(`diagnosis.ninmt.symptomsAndLocations.opts.${r.symptom}`)}${
                rl.location
                  ? ': ' + fm(`diagnosis.ninmt.symptomsAndLocations.opts.${rl.location}`)
                  : ': ' + (rl.description ?? '')
              }`,
            });
        });

        dataPointsBefore.forEach((p) => {
          const pointLocationId = p.id.split('@')[0];
          const locationIndex = allLocationsData.findIndex((d) => d.id === pointLocationId);
          if (locationIndex >= 0) {
            const dataIndex = allLocationsData[locationIndex].data.findIndex((d) =>
              d.dataPoints.find((dp) => dp.id === p.id),
            );
            if (dataIndex >= 0) {
              allLocationsData[locationIndex].data[dataIndex].dataPoints.push(p);
            } else {
              allLocationsData[locationIndex].data.push({
                id: p.id,
                type: 'lineGraph',
                dataPoints: [p],
                legend: fm('graph.harmBefore'),
              });
            }
          } else {
            allLocationsData.push({
              id: pointLocationId,
              data: [
                {
                  id: p.id,
                  type: 'lineGraph',
                  dataPoints: [p],
                  legend: fm('graph.harmBefore'),
                },
              ],
            });
          }
        });

        dataPointsAfter.forEach((p) => {
          const pointLocationId = p.id.split('@')[0];
          const locationIndex = allLocationsData.findIndex((d) => d.id === pointLocationId);
          if (locationIndex >= 0) {
            const dataIndex = allLocationsData[locationIndex].data.findIndex((d) =>
              d.dataPoints.find((dp) => dp.id === p.id),
            );
            if (dataIndex >= 0) {
              allLocationsData[locationIndex].data[dataIndex].dataPoints.push(p);
            } else {
              allLocationsData[locationIndex].data.push({
                id: p.id,
                type: 'lineGraph',
                dataPoints: [p],
                legend: fm('graph.harmAfter'),
              });
            }
          } else {
            allLocationsData.push({
              id: pointLocationId,
              data: [
                {
                  id: p.id,
                  type: 'lineGraph',
                  dataPoints: [p],
                  legend: fm('graph.harmAfter'),
                },
              ],
            });
          }
        });
      });
    });
  });
  return allLocationsData;
};

type TSymptom = 'pain' | 'tinnitus' | 'other';
type TMinMax = { min: number | null; max: number | null };
interface ICommonDataForSymptomsHandling {
  _id: string;
  date?: PartialDate;
  type: 'pre' | 'monitor';
  symptom: TSymptom;
  symptomLocation?: string;
  symptomDescription?: string;
  averageInAWeek?: number;
  averageHarmInAWeek?: number;
  lowestHighestInAWeek?: TMinMax;
  lowestHighestHarmInAWeek?: TMinMax;
}

/** Since preInquiry documents and treatment documents are composed differently, we need to get the data out of them in common format */
const convertPreInquiriesAndTreatmentMonitoringsToCommonData = (
  inputDocs: Array<(ITreatmentMonitoringInquiry | ININMTPreInquiry) & IControlProps>,
): Array<ICommonDataForSymptomsHandling> => {
  const isPreInquiry = (
    maybePIDoc: (ITreatmentMonitoringInquiry | ININMTPreInquiry) & IControlProps,
  ): maybePIDoc is ININMTPreInquiry & IControlProps => maybePIDoc._type === 'ninmtPreInquiry';

  const returnData: Array<ICommonDataForSymptomsHandling> = [];

  inputDocs.forEach((document) => {
    let data: ICommonDataForSymptomsHandling;
    if (!document.date) return;
    if (isPreInquiry(document)) {
      // Preinquiry docs should only have one symptom (pääasiallinen oire)
      if (!document.primarySymptom?.symptom) return;
      if (Array.isArray(document.primarySymptom?.currentMostIntenseSymptoms)) {
        document.primarySymptom?.currentMostIntenseSymptoms.forEach((cmi) => {
          // Pain(s)
          data = {
            _id: document._id + 'pain',
            type: 'pre',
            date: document.date,
            symptom: 'pain',
            symptomLocation: cmi.symptomLocation,
            averageInAWeek: cmi.symptomIntensityAverage,
            averageHarmInAWeek: cmi.symptomHarmAverage,
            lowestHighestInAWeek: cmi.symptomIntensityMinMax,
            lowestHighestHarmInAWeek: cmi.symptomHarmMinMax,
          };
          returnData.push(data);
        });
      } else if (document.primarySymptom?.symptom === 'other') {
        // Other
        const cmi = document.primarySymptom?.currentMostIntenseSymptoms;
        data = {
          _id: document._id + 'other',
          type: 'pre',
          date: document.date,
          symptom: 'other',
          symptomLocation: 'other@' + (document.primarySymptom.symptomDescription || ''),
          averageInAWeek: cmi?.symptomIntensityAverage,
          averageHarmInAWeek: cmi?.symptomHarmAverage,
          lowestHighestInAWeek: cmi?.symptomIntensityMinMax,
          lowestHighestHarmInAWeek: cmi?.symptomHarmMinMax,
        };
        returnData.push(data);
      } else {
        // Tinnitus
        const cmi = document.primarySymptom?.currentMostIntenseSymptoms;
        data = {
          _id: document._id + 'tinnitus',
          type: 'pre',
          date: document.date,
          symptom: 'tinnitus',
          symptomLocation: 'tinnitus',
          averageInAWeek: cmi?.symptomIntensityAverage,
          averageHarmInAWeek: cmi?.symptomHarmAverage,
          lowestHighestInAWeek: cmi?.symptomIntensityMinMax,
          lowestHighestHarmInAWeek: cmi?.symptomHarmMinMax,
        };
        returnData.push(data);
      }
    } else {
      (['pain', 'tinnitus', 'other'] as Array<TSymptom>).forEach((forEachSymptom) => {
        if (document.symptom?.[forEachSymptom]) {
          const symptomData = document.symptom?.[forEachSymptom];
          if (!symptomData) return;
          if (forEachSymptom === 'other') {
            Array.isArray(symptomData) &&
              symptomData.forEach((sd, i) => {
                data = {
                  _id: document._id + 'other' + i,
                  type: 'monitor',
                  date: document.date,
                  symptom: 'other',
                  symptomLocation: 'other@' + (sd.symptomDescription || ''),
                  symptomDescription: sd.symptomDescription,
                  averageInAWeek: sd.symptomWeek?.averageInAWeek,
                  averageHarmInAWeek: sd.symptomWeek?.averageHarmInAWeek,
                  lowestHighestInAWeek: sd.symptomWeek?.lowestHighestInAWeek,
                  lowestHighestHarmInAWeek: sd.symptomWeek?.lowestHighestHarmInAWeek,
                };
                returnData.push(data);
              });
          } else if (forEachSymptom === 'pain') {
            'symptomWeek' in symptomData &&
              Array.isArray(symptomData.symptomWeek) &&
              symptomData.symptomWeek.forEach((sw, i) => {
                data = {
                  _id: document._id + 'pain' + i,
                  type: 'monitor',
                  date: document.date,
                  symptom: 'pain',
                  symptomLocation: sw.location,
                  averageInAWeek: sw.averageInAWeek,
                  averageHarmInAWeek: sw.averageHarmInAWeek,
                  lowestHighestInAWeek: sw.lowestHighestInAWeek,
                  lowestHighestHarmInAWeek: sw.lowestHighestHarmInAWeek,
                };
                returnData.push(data);
              });
          } else {
            // Tinnitus
            if ('symptomWeek' in symptomData && !Array.isArray(symptomData.symptomWeek)) {
              const sw = symptomData.symptomWeek;
              data = {
                _id: document._id + 'tinnitus',
                type: 'monitor',
                date: document.date,
                symptom: 'tinnitus',
                symptomLocation: 'tinnitus',
                averageInAWeek: sw?.averageInAWeek,
                averageHarmInAWeek: sw?.averageHarmInAWeek,
                lowestHighestInAWeek: sw?.lowestHighestInAWeek,
                lowestHighestHarmInAWeek: sw?.lowestHighestHarmInAWeek,
              };
              returnData.push(data);
            }
          }
        }
      });
    }
  });
  return returnData;
};

export const convertSymptomsIntensityOrHarmToGraph = (
  treatmentMonitoringInquiries: Array<ITreatmentMonitoringInquiry & IControlProps>,
  ninmtPreInquiries: Array<ININMTPreInquiry & IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  intensityOrHarm: 'intensity' | 'harm',
  symptomsAndLocationsDocs?: ISymptomsAndLocations[],
): Array<{ data: IData[]; id: string }> | undefined => {
  if (treatmentMonitoringInquiries.length === 0 && ninmtPreInquiries.length === 0) return undefined;
  const allLocationsData: Array<{ data: IData[]; id: string }> = [];

  const averageField = intensityOrHarm === 'harm' ? 'averageHarmInAWeek' : 'averageInAWeek';
  const lowestHighestInAWeekField = intensityOrHarm === 'harm' ? 'lowestHighestHarmInAWeek' : 'lowestHighestInAWeek';

  const handledSymptoms: {
    id: string;
    type: 'pre' | 'monitor';
    symptom: string;
    symptomLocation?: string;
    date: Date;
    lowestHighestInAWeek: { max: number | null; min: number | null } | undefined;
    averageInAWeek: number | undefined;
    averageBeforeTreatment?: number | null;
  }[] = [];

  const averageBeforeTreatment = (
    date: Date,
    symptom: 'pain' | 'tinnitus' | 'other',
    intensityOrHarm: 'intensity' | 'harm',
    symptomLocation?: string,
    other?: string,
  ) => {
    if (!symptomsAndLocationsDocs) return null;
    // Find relevant symptoms and locations doc
    const slDoc = symptomsAndLocationsDocs
      .sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'startDate' }])(n1, n2))
      .find((sl) => {
        if (symptom !== sl.symptom) return false;
        if (symptom === 'pain' && symptomLocation && symptomLocation !== sl.symptomLocation) return false; // Pain
        if (other && other !== sl.symptomDescription) return false; // Other

        const slStartDate = sl.startDate ? dateFromPartial(sl.startDate) : undefined;
        const slEndDate = sl.endDate ? dateFromPartial(sl.endDate) : undefined;

        if (!slStartDate || slStartDate.valueOf() > date.valueOf()) return false;
        if (slEndDate && slEndDate.valueOf() < date.valueOf()) return false;
        return true;
      });
    return slDoc?.[intensityOrHarm === 'intensity' ? 'averageSymptomIntensity' : 'averageSymptomHarm'];
  };

  const preinquiriesAndTreatmentMonitoringsArranged: Array<ICommonDataForSymptomsHandling> =
    convertPreInquiriesAndTreatmentMonitoringsToCommonData([...treatmentMonitoringInquiries, ...ninmtPreInquiries]);

  preinquiriesAndTreatmentMonitoringsArranged.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const date = dateFromPartialUpdateTimeframe(d.date);

    handledSymptoms.push({
      id: d._id,
      type: d.type,
      symptom: d.symptom,
      symptomLocation: d.symptomLocation,
      date: date,
      lowestHighestInAWeek: d[lowestHighestInAWeekField],
      averageInAWeek: d[averageField],
      averageBeforeTreatment: averageBeforeTreatment(
        date,
        d.symptom,
        intensityOrHarm,
        d.symptomLocation,
        d.symptomDescription,
      ),
    });
  });

  const uniqueLocations = uniq(
    handledSymptoms.map((hs) => (hs.averageInAWeek || hs.lowestHighestInAWeek ? hs.symptomLocation : null)),
  );

  uniqueLocations.forEach((loc) => {
    if (!loc) return;
    const locationData = handledSymptoms
      .filter((hs) => hs.symptomLocation === loc)
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());
    const painLocation =
      loc.substring(0, 5) === 'other'
        ? fm(`diagnosis.ninmt.symptomsAndLocations.opts.other`) + ': ' + loc.split('@')[1]
        : loc === 'tinnitus'
          ? fm(`diagnosis.ninmt.symptomsAndLocations.opts.${loc}`)
          : fm(`diagnosis.ninmt.symptomsAndLocations.opts.pain`) +
            ': ' +
            fm(`diagnosis.ninmt.symptomsAndLocations.opts.${loc}`);
    allLocationsData.push({
      id: loc,
      data: [
        {
          id: 'highest',
          type: 'lineGraph',
          dataPoints: locationData
            .map((ld) => ({
              id: loc + 'highest',
              additionalValue: painLocation,
              title: ld.lowestHighestInAWeek?.max?.toFixed(1),
              value: ld.lowestHighestInAWeek?.max,
              description: ld.type === 'pre' ? fm('graph.preinquiry') : fm('graph.treatmentMonitoring'),
              date: ld.date,
              pointType: ld.type === 'pre' ? 'rect' : undefined,
            }))
            .filter((dp) => dp.value || dp.value === 0) as IDataPoint[],
          legend: intensityOrHarm === 'harm' ? fm('graph.harmHighest') : fm('graph.painHighest'),
        },
        {
          id: 'average',
          type: 'lineGraph',
          dataPoints: locationData
            .map((ld) => {
              return {
                id: loc + 'average',
                additionalValue: painLocation,
                value: ld.averageInAWeek,
                title:
                  ld.type === 'pre'
                    ? ld.averageInAWeek?.toFixed(1)
                    : `${ld.averageInAWeek?.toFixed(1)} ${
                        ld.averageBeforeTreatment === 0
                          ? `(${fm('graph.unableToCalculatePercentage')})`
                          : ld.averageBeforeTreatment && (ld.averageInAWeek || ld.averageInAWeek === 0)
                            ? ld.averageBeforeTreatment < ld.averageInAWeek
                              ? '(+' +
                                `${(
                                  ((ld.averageInAWeek - ld.averageBeforeTreatment) / ld.averageInAWeek) *
                                  100
                                ).toFixed(1)} %)`
                              : '(-' +
                                `${(
                                  ((ld.averageBeforeTreatment - ld.averageInAWeek) / ld.averageBeforeTreatment) *
                                  100
                                ).toFixed(1)} %)`
                            : ''
                      }`,
                date: ld.date,
                description: (
                  <>
                    {/** Dont show averages before treatment in preinquiry data */}
                    {ld.type === 'pre' ? undefined : ld.averageBeforeTreatment || ld.averageBeforeTreatment === 0 ? (
                      <div>
                        {fm('graph.intensityAverageBeforeTreatment') + ': ' + ld.averageBeforeTreatment.toFixed(1)}
                      </div>
                    ) : undefined}
                    <div>{ld.type === 'pre' ? fm('graph.preinquiry') : fm('graph.treatmentMonitoring')}</div>
                  </>
                ),
                pointType: ld.type === 'pre' ? 'rect' : undefined,
              };
            })
            .filter((dp) => dp.value || dp.value === 0) as IDataPoint[],
          legend: intensityOrHarm === 'harm' ? fm('graph.harmAverage') : fm('graph.painAverage'),
        },
        {
          id: 'lowest',
          type: 'lineGraph',
          dataPoints: locationData
            .map((ld) => ({
              id: loc + 'lowest',
              additionalValue: painLocation,
              title: ld.lowestHighestInAWeek?.min?.toFixed(1),
              value: ld.lowestHighestInAWeek?.min,
              description: ld.type === 'pre' ? fm('graph.preinquiry') : fm('graph.treatmentMonitoring'),
              date: ld.date,
              pointType: ld.type === 'pre' ? 'rect' : undefined,
            }))
            .filter((dp) => dp.value || dp.value === 0) as IDataPoint[],
          legend: intensityOrHarm === 'harm' ? fm('graph.harmLowest') : fm('graph.painLowest'),
        },
      ],
    });
  });
  return allLocationsData;
};
