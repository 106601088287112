import * as React from 'react';
import { styled as muiStyled } from '@mui/material';
import { IDashboardGraphProps, IGraphDimensions, TGraphSettings } from 'Components/sq-graphics/interfaces';
import SingleAddon from './SingleAddon';
import ExpansionPanel from './ExpansionPanel';
import { getSingleAddonTheme, getExpansionPanelTheme } from 'Components/sq-graphics/config';

const StyledContainer = muiStyled('div')({
  width: '100%',
  height: 'auto',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: 2,
});

const AddonArea = ({ data, timeframeWidth, xPoint, settings, dimensions, totalTimeframe }: IOwnProps): JSX.Element => {
  if (Object.keys(data).length === 0) return <></>;
  return (
    <StyledContainer>
      {Object.entries(data).map(([key, d]) => {
        const theme = settings?.addonSettings?.[key]?.theme;
        return d?.type === 'single' ? (
          <div key={key}>
            <SingleAddon
              data={d}
              timeframeWidth={timeframeWidth}
              xPoint={xPoint}
              theme={getSingleAddonTheme(theme)}
              dimensions={dimensions}
              totalTimeframe={totalTimeframe}
            />
          </div>
        ) : d?.type === 'expansionPanel' ? (
          <div key={key}>
            <ExpansionPanel
              data={d}
              timeframeWidth={timeframeWidth}
              xPoint={xPoint}
              theme={getExpansionPanelTheme(theme)}
              settings={settings}
              hiddenByDefault={(settings.expansionPanelsHiddenByDefault || []).includes(key)}
              dimensions={dimensions}
              totalTimeframe={totalTimeframe}
            />
          </div>
        ) : null;
      })}
    </StyledContainer>
  );
};

interface IOwnProps {
  data: IDashboardGraphProps['addonData'];
  timeframeWidth: number;
  xPoint: (d: Date | undefined) => number | undefined;
  settings: Required<TGraphSettings>;
  dimensions: IGraphDimensions;
  totalTimeframe: [Date, Date];
}

export default React.memo(AddonArea);
