// These are mapped from (= the key in the key-value pairs is from) TSessionData

export const mappedDeviceFields: { [key: string]: keyof IPapDevice } = {
  'deviceID.device.deviceType': 'type',
  'deviceID.mode': 'mode',
  // [none]: 'manufacturer' <-- static mapping to 'ResMed',
  'deviceID.device.deviceTypeDesc': 'deviceModel',
  'deviceID.device.serialNo': 'resMedId',
};

export const mappedSettingFields: { [key: string]: keyof IPapSetting } = {
  press: 'cpap',
  // EPAP: 'eep',
  IPAP: 'ipap',
  maxIPAP: 'ipapMax',
  tgtAlveolarVent: 'targetVa',
  tgtPatientRate: 'targetFrequency',
  EPAP: 'epap',
  EPAPAutoEnable: 'epapAuto',
  minPress: 'epapMin',
  maxPress: 'epapMax',
  respRate: 'respiratoryRate',
  // [unknown]: 'mv',
  tidalVolume: 'vt',
  tiMin: 'tiMin',
  tiMax: 'tiMax',
  minPS: 'psMin',
  maxPS: 'psMax',
  pressureAssist: 'pressureSupport',
  startEPAP: 'settingPressure',
  riseTime: 'riseTime',
  // tiMin: 'inhalationTimeMin',
  // tiMax: 'inhalationTimeMax',
  backupRate: 'backgroundFrequency',
  // 'deviceID.mode': 'mode', TODO: Do not map this to settings for now as it is included in device?
  EPRLevel: 'epr',
  triggerSensivity: 'trigger',
  cycleSensivity: 'cycle',
};

/**
 * Note: All keys except for 'respEvents' are properties of 'clinicalMetrics'
 *
 * Example structure:
 * {
 *   clinicalMetrics: {
 *     tgtIPAP: {
 *       95: '0.0',
 *     }
 *   },
 *   respEvents: {
 *     AHI: '0.0',
 *   }
 * }
 */
export const mappedTreatmentResponseFields: { [key: string]: keyof IPapTreatmentResponse } = {
  // [unknown]: 'machineOperatingHours',

  // [unknown]: 'patientHoursOfUsePerDay', // Calculated from 'usage.duration'
  // [unknown]: 'daysWithLessThan4HoursOfUse', // TODO: Calculated from 'usage.duration'?
  // [unknown]: 'daysWithLessThan4HoursOfUsePercentage', // TODO: Calculated from 'usage.duration'?

  'tgtIPAP.95': 'pressure95Percentile',
  'tgtIPAP.max': 'pressureMax',
  'tgtIPAP.50': 'pressureMedian',

  // [unknown]: 'maskLeakage95PercentilePerMinute', // Calculated from 'leak.95'
  'leak.95': 'maskLeakage95PercentilePerSecond',
  'leak.50': 'maskLeakageMedian',

  'respEvents.AHI': 'ahi',
  'respEvents.AI': 'ai',
  // 'respEvents.AI': 'aiTotal',
  'respEvents.CAI': 'aiCentral',
  'respEvents.HI': 'hi',

  'tidalVol.95': 'tidalVolume95Percentile',
  'tidalVol.50': 'tidalVolumeMedian',

  // [unknown]: 'respiratoryRate',
  'respRate.95': 'respiratoryRate95Percentile',
  'respRate.50': 'respiratoryRateMedian',
  // [unknown]: 'respiratoryRateMin',
  'respRate.max': 'respiratoryRateMax',

  // 'alveolarVentilation.50': 'targetVentilation', // TODO: Is this supposed to be median or what?
  // [unknown]: 'targetVentilationAchievedInPlusMinus10PercentPrecision',

  'minuteVent.95': 'minuteVentilation95Percentile',
  'minuteVent.50': 'minuteVentilationMedian',

  spontTriggBreaths: 'spontT',
  spontCycledBreaths: 'spontC',
};
