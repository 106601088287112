/**
 * Fetch currently used language from browser
 * @return {string} 'fi'|'en'|'se' etc locale string
 */

const browserLanguage = (
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage
)
  .toLowerCase()
  .split(/[_-]+/)[0];

const neuroLanguage =
  browserLanguage === 'fi' || browserLanguage === 'sv' || browserLanguage === 'en' ? browserLanguage : 'fi';

export default neuroLanguage;
