import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { styled } from '@mui/material/styles';

import { TDocument } from '../utils';
import { isEnded } from '../../../utility/isEnded';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { ellipsis } from 'Components/DashboardTile/TileContentMaker/components/common/commonStyles';

const DietTitle = styled('div')({
  ...ellipsis(38),
});

const DietTherapyDash = ({ documents }: IOwnProps): JSX.Element => {
  const sortedArr = documents?.sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate)).reverse();
  const activeDiets = sortedArr ? sortedArr.filter((at: IDietTherapy) => !isEnded(at?.endDate)) : [];
  const endedDiets = sortedArr ? sortedArr.filter((et: IDietTherapy) => isEnded(et?.endDate)) : [];
  const diets = [...activeDiets, ...endedDiets];
  const diet = diets[0];

  return (
    <React.Fragment>
      {diet && (
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={
            diet.diet ? (
              <DietTitle>
                <FormattedMessage id={`dietTherapy.opts.${diet.diet}`} />
              </DietTitle>
            ) : (
              '-'
            )
          }
          bigHeaderTextSubheader={<FormattedMessage id="dietTherapy.diet" />}
          data={[
            {
              title: <FormattedMessage id="dietTherapy.startDate" />,
              value: isPartialDate(diet.startDate) ? formatPartialDate(diet.startDate) : null,
              key: 'startDate',
            },
            ...(isEnded(diet.endDate)
              ? [
                  {
                    title: <FormattedMessage id="dietTherapy.endDate" />,
                    value: isPartialDate(diet.endDate) ? formatPartialDate(diet.endDate) : null,
                    key: 'endDate',
                  },
                ]
              : []),
            {
              title: <FormattedMessage id="dietTherapy.reason" />,
              value: (
                <div>
                  {Array.isArray(diet.reason) && diet.reason.length > 0
                    ? diet.reason.map((r, i) => <div key={i}>{<FormattedMessage id={`dietTherapy.opts.${r}`} />}</div>)
                    : '-'}
                </div>
              ),
              key: 'reason',
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default DietTherapyDash;
