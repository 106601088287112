export const diagnosticExamCodes: Partial<Record<TDiagnosticExamKeys, ICode>> = {
  neostigmineTest: {
    codeSystem: 'http://snomed.info/sct',
    code: '1004087002',
    display: 'Neostigmiinitesti',
  },
  iceCubeTest: {
    codeSystem: 'http://snomed.info/sct',
    code: '1004088007',
    display: 'Jääpalatesti',
  },
};
