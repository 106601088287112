import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import { RootState } from '../../store';

interface CensorableProps {
  children: React.ReactText | undefined;
}

const makeCensoredString = (symbol: string, length: number) => {
  let string = '';
  for (let index = 0; index < length; index++) {
    string = string + symbol;
  }
  return string;
};

/**
 * Replace censored content with a string made of boxes when
 * censoring is active in the app's state.
 */
const Censorable = ({ children }: CensorableProps): JSX.Element | null => {
  const censoredStringLength = 8;
  const censorSymbol = '█';
  const shouldCensor = useSelector((s: RootState) => s.session.shouldCensor);
  if (!children) return null;
  const content = `${children}`; // number to string
  return shouldCensor ? <>{makeCensoredString(censorSymbol, censoredStringLength)}</> : <>{content}</>;
};

export default Censorable;
