/** Save data into session storage */
export const sessionStorageSave = (name: string, data: string): void => window.sessionStorage.setItem(name, data);

/** Get data from session storage */
export const sessionStorageGet = (name: string): string | null => window.sessionStorage.getItem(name);

/** Remove data from session storage */
export const sessionStorageRemove = (name: string): void => window.sessionStorage.removeItem(name);

/** Clear all data from session storage */
export const sessionStorageClear = (): void => window.sessionStorage.clear();
