import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import store from '../../../store';
import { dateFromPartial, formatPartialDate, formatTime, sortPartialDate } from 'neuro-utils';
import { styled } from '@mui/system';
import colors from '../../../config/theme/colors';

export const isISeizure = (d: unknown): d is ISeizure => {
  return (d as { _type: unknown })._type === 'seizure';
};

const isIPatientReportedSeizure = (d: unknown): d is IPatientReportedSeizure & IControlProps => {
  return (d as { _type: unknown })._type === 'patientReportedSeizure';
};

export const isISeizureFreePeriod = (d: unknown): d is ISeizureFreePeriod => {
  return (d as { _type: unknown })._type === 'seizureFreePeriod';
};

/**
 * Get date of the latest confirmed seizure.
 * @param documents Seizure documents from which to find the latest occurence date.
 * @returns Date of the latest seizure or undefined.
 */
export const getLastSeizureDate = (documents: any[] | undefined): PartialDate | undefined => {
  if (!documents) return;
  const seizureDocs: ISeizureDoc[] = documents.filter(
    (d) => (isISeizure(d) || isIPatientReportedSeizure(d)) && !d._editing,
  );
  const actualSeizures = filterNotSeizureDocs(seizureDocs);
  const sorted = actualSeizures.sort((a, b) => {
    const aDate =
      a._type === 'patientReportedSeizure' ? (a as IPatientReportedSeizure).startDate : (a as ISeizure).date;
    const bDate =
      b._type === 'patientReportedSeizure' ? (b as IPatientReportedSeizure).startDate : (b as ISeizure).date;
    return sortPartialDate(bDate, aDate);
  });
  return sorted[0]?._type === 'patientReportedSeizure'
    ? (sorted[0] as IPatientReportedSeizure)?.startDate
    : (sorted[0] as ISeizure)?.date;
};

/**
 * Get patient's own seizure types' classifications from store.
 * @returns An array of seizure type classifications (strings).
 */
export const getOwnSeizureTypesClassifications = (): Array<string> => {
  const seizureTypeDocs =
    store.getState().documents.sortedAndMergedDocuments?.filter((d) => d._type === 'seizureType') || [];
  const classifications = seizureTypeDocs.map((d) => d.classification?.classification).filter((n) => n) as string[];

  const uniqueClassifications = [...new Set(classifications)];
  return uniqueClassifications;
};

/**
 * Filter out those ISeizure documents that are classified as `"notSeizure"`.
 * @returns The remaining documents after filtering.
 */
export const filterNotSeizureDocs = (documents: ISeizureDoc[] | undefined): ISeizureDoc[] => {
  if (!documents) return [];
  return documents.filter((d) =>
    d._type === 'patientReportedSeizure'
      ? (d as IPatientReportedSeizure).seizureType !== 'notSeizure'
      : (d as ISeizure).classification !== 'notSeizure',
  ) as ISeizure[];
};

/**
 * Filter out ISeizures that are not dated withing year
 * @param seizures Array of ISeizure[]
 * @returns Array of ISeizure[] filtered within year
 */
export const filterSeizuresWithinYear = (seizures: ISeizure[] | undefined): ISeizure[] => {
  const dateNow = new Date();
  const dateYearAgo = new Date(dateNow.getFullYear() - 1, dateNow.getMonth(), dateNow.getDate());
  if (!seizures) return [];
  return seizures.filter((seizure) => seizure.date && dateFromPartial(seizure.date) > dateYearAgo) as ISeizure[];
};
/**
 * Get the total amount of seizures from seizures array
 * @param seizures Array of ISeizure
 * @returns The amount of total seizures of the argument seizures array seizures
 */
export const getTotalCountOfSeizures = (seizures: ISeizure[] | undefined): number | undefined => {
  let totalCount = 0;
  if (!seizures) return undefined;
  seizures.forEach((s) => {
    totalCount = totalCount + (s.count ? s.count : 0);
  });
  return totalCount;
};

/**
 * Function for getting localized form of seizure classification
 * @param classification classification
 * @param shortOrLong This tells if we need just the short version of the classification, eg 2.1.1.1 Automatismit or if we want the long version, eg 2.1.1.1 Paikallisalkuinen kohtaus ilman tajunnanhämärtymistä / Motorisin oirein / Automatismit
 * @returns localized form of the classification
 */
export const formatSeizureClassification = (
  classification: ISeizure['classification'],
  shortOrLong: 'short' | 'long' = 'short',
  history?: boolean,
  textOnly?: boolean,
): string | JSX.Element => {
  // If the classification is undefined, return just a dash
  if (!classification) return '-';

  // If the classification is 'notSeizure', return this
  if (classification === 'notSeizure') {
    return <FormattedMessage id="seizure.notSeizure" />;
  }

  // If we need just the short form, return this
  if (shortOrLong === 'short') {
    return (
      <span>
        {!textOnly ? `${classification} ` : ''}
        <FormattedMessage id={`seizure.opts.${classification}`} />
      </span>
    );
  } else {
    // Make array of classes of the classification. Eg. 2.1.1.1 -> [2.1, 2.1.1, 2.1.1.1]
    const parts: NonNullable<ISeizure['classification']>[] = [];
    // QUick hack
    classification.length >= 6 && parts.push(classification.substring(0, 7) as TSeizureClassification);
    classification.length >= 4 && parts.push(classification.substring(0, 5) as TSeizureClassification);
    classification.length >= 2 && parts.push(classification.substring(0, 3) as TSeizureClassification);
    const partsReversed = parts.slice().reverse();
    return (
      <>
        {!textOnly ? `${classification} ` : ''}
        {partsReversed.map((p, i) => (
          <span key={p}>
            <FormattedMessage id={`seizure.opts.${p}`} />
            {i < parts.length - 1 ? ' / ' : ''}
            {history && <br />}
          </span>
        ))}
      </>
    );
  }
};

/**
 * Format row data.
 * @param key Data field's name.
 * @param value Data field's value.
 * @returns Value formatted.
 */
export const formatRowData = (key: string, value: any): React.ReactNode => {
  let result: React.ReactNode;
  const noData = '-';
  if (value) {
    switch (key) {
      case 'date':
        result = formatPartialDate(value);
        break;
      case 'time':
        result = formatTime(value);
        break;
      case 'medicCompliance':
        result = <FormattedMessage id={`general.${value}`} />;
        break;
      case 'seizureFreePeriod':
        result = !value[1]
          ? formatPartialDate(value[0])
          : `${formatPartialDate(value[0])} - ${formatPartialDate(value[1])}`;
        break;
      case 'seizureFreePeriodLength':
        result = typeof value === 'object' && (
          <React.Fragment>
            <FormattedMessage values={{ N: value.years }} id={'general.years'} />{' '}
            <FormattedMessage values={{ N: value.months }} id={'general.months'} />{' '}
            <FormattedMessage values={{ N: value.days }} id={'general.days'} />
          </React.Fragment>
        );
        break;
      case 'classification':
        {
          result = formatSeizureClassification(value, 'long', true);
        }
        break;
      default:
        result = value;
        break;
    }
  }
  return !result || result === '' ? noData : result;
};

/**
 * Indent options that are subclass of another number e.g. 1.0.1 is intended under 1.0
 * @param o
 * @returns Intended option if subnumber else default
 */
export const formatOptionIndent = (o: string): JSX.Element => {
  const classificationString = o;
  const regex = /^[0-9]\.[0-9]\.[0-9]\.[0-9]/;
  const regex2 = /^[0-9]\.[0-9]\.[0-9]\s/;
  const indentOption = classificationString && classificationString.match(regex);
  const indentOption2 = classificationString && classificationString.match(regex2);
  const splittedAndShifted = indentOption && classificationString.split(' ');
  splittedAndShifted && splittedAndShifted.shift();
  const splittedAndShifted2 = indentOption2 && classificationString.split(' ');
  splittedAndShifted2 && splittedAndShifted2.shift();
  const final = splittedAndShifted && splittedAndShifted.join(' ');
  const final2 = splittedAndShifted2 && splittedAndShifted2.join(' ');
  const indentedOption = splittedAndShifted && `${indentOption} ${final}`;
  const indentedOption2 = splittedAndShifted2 && `${indentOption2} ${final2}`;
  if (indentOption) {
    return (
      <React.Fragment key={classificationString}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {formatSeizureClassification(indentedOption?.split(' ')[0] as TSeizureClassification, 'short')}
      </React.Fragment>
    );
  } else if (indentOption2) {
    return (
      <React.Fragment key={classificationString}>
        &nbsp;&nbsp;&nbsp;
        {formatSeizureClassification(indentedOption2?.split(' ')[0] as TSeizureClassification, 'short')}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment key={classificationString}>
        {formatSeizureClassification(classificationString?.split(' ')[0] as TSeizureClassification, 'short')}
      </React.Fragment>
    );
  }
};

export const GroupHeader = styled('div')({
  position: 'sticky' as const,
  height: '3.6rem',
  fontSize: '1.0rem',

  top: '-1px', // Counter padding and sticky needs this
  paddingTop: '0.7rem',
  color: colors.darkGray,
  backgroundColor: colors.white,
  alignItems: 'center',
  marginTop: '0rem',
  '& .MuiMenuItem-root.Mui-disabled': {
    opacity: 1,
    fontSize: '1.4rem',
  },
  zIndex: 100,
});
