import * as React from 'react';
import { styled } from '@mui/system';
import colors from '../../../../../config/theme/colors';
import ActionButtonRounded from 'Components/ActionButtonRounded';
//import { MyServiceContext } from '../..';
import { exists, formatPartialDate, nowPartialDate } from 'neuro-utils';
import { MyServiceContext } from '../..';
import { useDispatch } from 'react-redux';
import { actions } from 'Store/myapp/actions';
import { getJWTData } from 'Utility/jwtAuthTools';

const StyledTable = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columns',
})(({ columns = 2 }: { columns?: 2 | 3 }) => ({
  width: columns === 3 ? '70%' : '50%',
  border: `1px solid ${colors.appBlue.lightest}`,
  marginBottom: '4.5rem',
}));

const StyledTableRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columns',
})(({ columns = 2 }: { columns?: 2 | 3 }) => ({
  display: 'grid',
  gridTemplateColumns: columns === 3 ? '3fr 2fr 2fr 2fr' : '3fr 2fr 2fr',
  padding: '0.5rem 0 0.5rem 0',
}));

const StyledTableHeader = styled('div')({
  padding: '0.5rem 1.5rem 0.5rem 1.5rem',
  color: colors.white,
  backgroundColor: colors.appBlue.medium,
  borderBottom: `1px solid ${colors.appBlue.lightest}`,
});

const StyledTableItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'colSpan',
})(({ colSpan = 1 }: { colSpan?: 1 | 2 }) => ({
  gridColumn: colSpan > 1 ? '2 / span 2' : undefined,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

/**
 * For unresolved supplies requests
 */
const SuppliesHistoryWindow = ({
  supplyShortagesInquiryDoc,
  fmSupplyShortagesInquiry,
}: {
  supplyShortagesInquiryDoc: ISupplyShortagesInquiry & IControlProps;
  fmSupplyShortagesInquiry: (id: string) => string;
}): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj } = myServContext;

  const { suppliesNeeded, suppliesDetails } = supplyShortagesInquiryDoc;

  const [updating, setUpdating] = React.useState<'completed' | 'rejected' | false>(false);
  const dispatch = useDispatch();

  const handleStatusChange = async (status?: ISupplyShortagesInquiry['status']) => {
    setUpdating(status === 'completed' || status === 'rejected' ? status : false);
    const useruuid = getJWTData()?.useruuid || null;
    await actions.putNewCommit(
      'supplyShortagesInquiry',
      supplyShortagesInquiryDoc._id,
      {
        ...supplyShortagesInquiryDoc,
        status: status ?? 'pending',
        resolutionDate: status && status !== 'pending' ? nowPartialDate() : null,
        resolverId: status && status !== 'pending' ? useruuid : null,
      },
      dispatch,
    );
    setUpdating(false);
  };

  // Ensure supplies are displayed in same order
  // salineSolution is duplicated to display different details (= packagings/sizes) on different rows
  const suppliesNeededSorted = [
    'electrodePads',
    'salineSolution',
    'salineSolution',
    'syringes',
    'capCleaner',
    'batteries',
    'cables',
    'electrodes',
    'customsCertificate',
    'newTDCSCap',
    'other',
  ].filter((s) => suppliesNeeded?.includes(s as (typeof suppliesNeeded)[0]));

  return (
    <StyledTable columns={2} style={{ paddingBottom: '1rem' }}>
      <StyledTableHeader>{`${fm('myService.orderDate')} ${formatPartialDate(
        supplyShortagesInquiryDoc.date,
      )}`}</StyledTableHeader>
      {Array.isArray(suppliesNeeded) &&
        suppliesNeededSorted.map((supply, index, array) => {
          // Leave "specifier" out
          const name = fmSupplyShortagesInquiry(`opts.${supply}`).includes(' (')
            ? fmSupplyShortagesInquiry(`opts.${supply}`).split(' (')[0]
            : fmSupplyShortagesInquiry(`opts.${supply}`);

          const suppliesWithDetails = ['electrodePads', 'salineSolution', 'other'];

          let details: string | undefined = undefined;

          // For differentiating which salineSolution field is handled
          const isSalineSolution500ml =
            supply === 'salineSolution' && array.slice(index + 1).includes('salineSolution');

          if (suppliesDetails) {
            switch (supply) {
              case 'electrodePads':
                if (!exists(suppliesDetails.electrodePads)) break;
                details = `${suppliesDetails.electrodePads} ${fmSupplyShortagesInquiry('opts.packets')}`;
                break;
              case 'salineSolution': {
                if (isSalineSolution500ml) {
                  if (!exists(suppliesDetails.salineSolution500ml)) break;
                  details = `${suppliesDetails.salineSolution500ml} ${fmSupplyShortagesInquiry('opts.bottles')}`;
                } else {
                  if (!exists(suppliesDetails.salineSolution20ml)) break;
                  details = `${suppliesDetails.salineSolution20ml} ${fmSupplyShortagesInquiry('opts.ampoules')}`;
                }
                break;
              }
              case 'other':
                if (!exists(suppliesDetails.other)) break;
                details = suppliesDetails.other;
                break;
              default:
                break;
            }
          }

          return (
            <StyledTableRow
              key={index}
              columns={2}
              style={{
                backgroundColor: index % 2 !== 0 ? colors.lightestGray : undefined,
                margin: '0 1.5rem 0 1.5rem',
                marginBottom: index === array.length - 1 ? '1rem' : undefined,
                paddingTop: index === 0 ? '1.5rem' : undefined,
              }}
            >
              <StyledTableItem style={{ paddingLeft: '0.5rem', color: colors.tertiaryText, fontWeight: 'bold' }}>
                <div style={{ marginBottom: supply === 'salineSolution' ? '-0.25rem' : undefined }}>{name}</div>
                <div style={{ fontWeight: 400 }}>
                  {supply === 'salineSolution'
                    ? fm(`myService.ninmt.salineSolution${isSalineSolution500ml ? '500ml' : '20ml'}Description`)
                    : ''}
                </div>
              </StyledTableItem>
              <StyledTableItem colSpan={2} style={{ color: colors.primaryText, fontWeight: 600 }}>
                {suppliesWithDetails.includes(supply) && details ? details : ''}
              </StyledTableItem>
            </StyledTableRow>
          );
        })}
      <StyledTableRow columns={2}>
        <StyledTableItem
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0 4rem 0 1.5rem',
            overflow: 'visible',
          }}
        >
          <ActionButtonRounded
            text="myService.reject"
            width={8}
            height={4}
            fontSize={16}
            onClick={() => handleStatusChange('rejected')}
            loading={updating === 'rejected'}
          />
          <ActionButtonRounded
            text="general.edit"
            width={8}
            height={4}
            fontSize={16}
            onClick={() => setEditingObj({ type: 'supplyShortagesInquiry', data: supplyShortagesInquiryDoc })}
          />
        </StyledTableItem>
        <StyledTableItem />
        <StyledTableItem
          style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '1.5rem', overflow: 'visible' }}
        >
          <ActionButtonRounded
            text="myService.completed"
            width={8}
            height={4}
            fontSize={16}
            onClick={() => handleStatusChange('completed')}
            filled
            loading={updating === 'completed'}
          />
        </StyledTableItem>
      </StyledTableRow>
    </StyledTable>
  );
};

export default SuppliesHistoryWindow;
