// Medtronic Model 3387 Lead (4 solid, no tip electrode)
// Medtronic Model 3389 Lead (4 solid, no tip electrode)
// Boston Scientific Vercise Standard Lead (8 solid, no tip electrode)
// Boston Scientific Verdise Cartesia Directional Lead (TIP!, https://www.bostonscientific.com/en-US/medical-specialties/neurological-surgery/dbs/vercise-directional-dbs-system/_jcr_content/maincontent-par/image.img.vercise-dbs-systems-cartesia-dir-lead-image.jpg)
// Abbott St.Jude Medical Infinity 4-channel Lead (4 solid, no tip electrode)
// Abbott St.Jude Medical Infinity 8-channel Directional Lead (https://www.neuromodulation.abbott/content/dam/bss/divisionalsites/nm/images/products/8.3.2_Directional%20leads%20for%20Infinity%20DBS%20System_304x304@1x.jpg)

export const leadConfiguration: {
  [key: string]: { [platform: string]: { rechargeable: boolean; leads: string[] } };
} = {
  parkinson: {
    'Boston Scientific Vercise Gevia': {
      rechargeable: true,
      leads: [
        'Boston Scientific Vercise Cartesia Directional Lead',
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Boston Scientific Vercise': {
      rechargeable: false,
      leads: [
        'Boston Scientific Vercise Standard Lead',
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Boston Scientific Vercise PC': {
      rechargeable: false,
      leads: [
        'Boston Scientific Vercise Cartesia Directional Lead',
        'Boston Scientific Vercise Standard Lead',
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Boston Scientific Vercise Genus R': {
      rechargeable: true,
      leads: [
        'Boston Scientific Vercise Cartesia Directional Lead',
        'Boston Scientific Vercise Standard Lead',
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Boston Scientific Vercise Genus P': {
      rechargeable: false,
      leads: [
        'Boston Scientific Vercise Cartesia Directional Lead',
        'Boston Scientific Vercise Standard Lead',
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Medtronic Activa PC': {
      rechargeable: false,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Medtronic Activa RC': {
      rechargeable: true,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Medtronic Percept PC': {
      rechargeable: false,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
        'Medtronic SenSight (0.5 mm Spaced Lead Assembly)',
        'Medtronic SenSight (1.5 mm Spaced Lead Assembly)',
      ],
    },
    'Medtronic Percept RC': {
      rechargeable: true,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
        'Medtronic SenSight (0.5 mm Spaced Lead Assembly)',
        'Medtronic SenSight (1.5 mm Spaced Lead Assembly)',
      ],
    },
    'Abbott St.Jude Medical Infinity 6660': {
      rechargeable: false,
      leads: [
        'Abbott St.Jude Medical Infinity 4-channel Lead (1, 2, 3, 4)',
        'Abbott St.Jude Medical Infinity 4-channel Lead (9, 10, 11, 12)',
        'Abbott St.Jude Medical Infinity 8-channel Directional Lead (1, 2, 3, 4)',
        'Abbott St.Jude Medical Infinity 8-channel Directional Lead (9, 10, 11, 12)',
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Abbott St.Jude Medical Infinity 6661': {
      rechargeable: false,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Abbott St.Jude Medical Infinity 6662': {
      rechargeable: false,
      leads: [
        'Abbott St.Jude Medical Infinity 4-channel Lead (1, 2, 3, 4)',
        'Abbott St.Jude Medical Infinity 4-channel Lead (9, 10, 11, 12)',
        'Abbott St.Jude Medical Infinity 8-channel Directional Lead (1, 2, 3, 4)',
        'Abbott St.Jude Medical Infinity 8-channel Directional Lead (9, 10, 11, 12)',
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Abbott St.Jude Medical Infinity 6663': {
      rechargeable: false,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Other rechargeable': {
      rechargeable: true,
      leads: ['4-channel'],
    },
    'Other battery': {
      rechargeable: false,
      leads: ['4-channel'],
    },
  },
  epilepsy: {
    'Medtronic Activa PC': {
      rechargeable: false,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
      ],
    },
    'Medtronic Percept PC': {
      rechargeable: false,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
        'Medtronic SenSight (0.5 mm Spaced Lead Assembly)',
        'Medtronic SenSight (1.5 mm Spaced Lead Assembly)',
      ],
    },
    'Medtronic Percept RC': {
      rechargeable: true,
      leads: [
        'Medtronic Model 3387 Lead (0, 1, 2, 3)',
        'Medtronic Model 3387 Lead (8, 9, 10, 11)',
        'Medtronic Model 3389 Lead (0, 1, 2, 3)',
        'Medtronic Model 3389 Lead (8, 9, 10, 11)',
        'Medtronic SenSight (0.5 mm Spaced Lead Assembly)',
        'Medtronic SenSight (1.5 mm Spaced Lead Assembly)',
      ],
    },
  },
};

// Lead configuration instructions:
// lead: Lead type
// Type 1: Three electrodes in zone
// Type 2: One electrode in zone
// Type 3: Bottom w/o electrode; give id '200A'
// Type 4: Bottom with electrode; give id '1'
// id: Amount of electrodes
// ids must be unique
export const electrodeConfiguration: ElectrodeConfiguration[] = [
  {
    lead: 'Boston Scientific Vercise Cartesia Directional Lead',
    zones: [
      { type: 2, id: ['8'] },
      { type: 1, id: ['5', '6', '7'] },
      { type: 1, id: ['2', '3', '4'] },
      { type: 4, id: ['1'] },
    ],
  },
  {
    lead: 'Boston Scientific Vercise Standard Lead',
    zones: [
      { type: 2, id: ['8'] },
      { type: 2, id: ['7'] },
      { type: 2, id: ['6'] },
      { type: 2, id: ['5'] },
      { type: 2, id: ['4'] },
      { type: 2, id: ['3'] },
      { type: 2, id: ['2'] },
      { type: 2, id: ['1'] },
      { type: 3, id: ['100A'] },
    ],
  },

  {
    lead: 'Medtronic Model 3387 Lead (0, 1, 2, 3)',
    zones: [
      { type: 2, id: ['3'] },
      { type: 2, id: ['2'] },
      { type: 2, id: ['1'] },
      { type: 2, id: ['0'] },
      { type: 3, id: ['100A'] },
    ],
  },
  {
    lead: 'Medtronic Model 3387 Lead (8, 9, 10, 11)',
    zones: [
      { type: 2, id: ['11'] },
      { type: 2, id: ['10'] },
      { type: 2, id: ['9'] },
      { type: 2, id: ['8'] },
      { type: 3, id: ['100A'] },
    ],
  },
  {
    lead: 'Medtronic Model 3389 Lead (0, 1, 2, 3)',
    zones: [
      { type: 2, id: ['3'] },
      { type: 2, id: ['2'] },
      { type: 2, id: ['1'] },
      { type: 2, id: ['0'] },
      { type: 3, id: ['100A'] },
    ],
  },
  {
    lead: 'Medtronic Model 3389 Lead (8, 9, 10, 11)',
    zones: [
      { type: 2, id: ['11'] },
      { type: 2, id: ['10'] },
      { type: 2, id: ['9'] },
      { type: 2, id: ['8'] },
      { type: 3, id: ['100A'] },
    ],
  },
  {
    lead: 'Medtronic SenSight (0.5 mm Spaced Lead Assembly)',
    zones: [
      { type: 2, id: ['3'] },
      { type: 1, id: ['2C', '2B', '2A'] },
      { type: 1, id: ['1C', '1B', '1A'] },
      { type: 2, id: ['0'] },
      { type: 3, id: ['200A'] },
    ],
  },
  {
    lead: 'Medtronic SenSight (1.5 mm Spaced Lead Assembly)',
    zones: [
      { type: 2, id: ['3'] },
      { type: 1, id: ['2C', '2B', '2A'] },
      { type: 1, id: ['1C', '1B', '1A'] },
      { type: 2, id: ['0'] },
      { type: 3, id: ['200A'] },
    ],
  },
  {
    lead: 'Abbott St.Jude Medical Infinity 4-channel Lead (1, 2, 3, 4)',
    zones: [
      { type: 2, id: ['4'] },
      { type: 2, id: ['3'] },
      { type: 2, id: ['2'] },
      { type: 2, id: ['1'] },
      { type: 3, id: ['100A'] },
    ],
  },
  {
    lead: 'Abbott St.Jude Medical Infinity 4-channel Lead (9, 10, 11, 12)',
    zones: [
      { type: 2, id: ['12'] },
      { type: 2, id: ['11'] },
      { type: 2, id: ['10'] },
      { type: 2, id: ['9'] },
      { type: 3, id: ['100A'] },
    ],
  },
  {
    lead: 'Abbott St.Jude Medical Infinity 8-channel Directional Lead (1, 2, 3, 4)',
    zones: [
      { type: 2, id: ['4'] },
      { type: 1, id: ['3B', '3C', '3A'] },
      { type: 1, id: ['2B', '2C', '2A'] },
      { type: 2, id: ['1'] },
      { type: 3, id: ['200A'] },
    ],
  },
  {
    lead: 'Abbott St.Jude Medical Infinity 8-channel Directional Lead (9, 10, 11, 12)',
    zones: [
      { type: 2, id: ['12'] },
      { type: 1, id: ['11B', '11C', '11A'] },
      { type: 1, id: ['10B', '10C', '10A'] },
      { type: 2, id: ['9'] },
      { type: 3, id: ['200A'] },
    ],
  },
  {
    lead: '4-channel',
    zones: [
      { type: 2, id: ['4'] },
      { type: 2, id: ['3'] },
      { type: 2, id: ['2'] },
      { type: 2, id: ['1'] },
      { type: 3, id: ['100A'] },
    ],
  },
];

export const defaultUnits: { [key: string]: string } = {
  amplitude: 'mA',
  pulseWidth: 'µS',
  rate: 'Hz',
  cyclicONTime: 's',
  cyclicOFFTime: 'min',
};

// In electroConf and unitConf: Generators must be identical
export const unitConfiguration: { [key: string]: string[] } = {
  'Boston Scientific Vercise Gevia': ['mA'],
  'Boston Scientific Vercise': ['mA'],
  'Boston Scientific Vercise PC': ['mA'],
  'Boston Scientific Vercise Genus R': ['mA'],
  'Boston Scientific Vercise Genus P': ['mA'],
  'Medtronic Activa PC': ['V', 'mA'],
  'Medtronic Activa RC': ['V', 'mA'],
  'Medtronic Percept PC': ['mA'],
  'Medtronic Percept RC': ['mA'],
  'Abbott St.Jude Medical Infinity 6660': ['mA'],
  'Abbott St.Jude Medical Infinity 6661': ['mA'],
  'Abbott St.Jude Medical Infinity 6662': ['mA'],
  'Abbott St.Jude Medical Infinity 6663': ['mA'],
  Other: ['V', 'mA'],
};

interface Zone {
  type: number;
  id: string[];
}

interface ElectrodeConfiguration {
  lead: string;
  zones: Zone[];
}
