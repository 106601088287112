export const leadsWithOneConnector = ['304-20', '304-30', '302-20', '302-30', 'unspecifiedLeadWithOneConnector'];

export const leadsWithTwoConnectors = ['300', 'unspecifiedLeadWithTwoConnectors'];

export const generatorsWithOneConnector = [
  'LivaNova Model 102 Pulse',
  'LivaNova Model 103 Demipulse',
  'LivaNova Model 105 AspireHC',
  'LivaNova Model 106 AspireSR',
  'LivaNova Model 1000 SenTiva',
];

export const generatorsWithTwoConnectors = [
  'LivaNova Model 102R Pulse Duo',
  'LivaNova Model 104 Demipulse Duo',
  'LivaNova Model 1000-D SenTiva DUO',
];

export const generatorsWithAutoStimSettings = ['LivaNova Model 106 AspireSR'];

export const generatorsWithAutoStimNightAndScheduledSettings = [
  'LivaNova Model 1000 SenTiva',
  'LivaNova Model 1000-D SenTiva DUO',
];

export const defaultUnits: { [key: string]: string } = {
  outputCurrent: 'mA',
  rate: 'Hz',
  pulseWidth: 'µS',
  onTime: 's',
  offTime: 'min',
  efficiencyCycle: '%',
  autoStimThreshold: '%',
};
