import * as React from 'react';
import store from '../../store/index';
import { FormattedMessage } from 'react-intl';
import { INeuroDocument } from 'neuro-data-structures';

/**
 * Checks whether the user has read permission to a document
 * @param {IControlProps} document - Any document that has controlprops
 * @returns {boolean} - Does user have read permission
 */
const hasReadPermission = (document: IControlProps): boolean => {
  const reduxStore: IState = store.getState();
  const sessionData = reduxStore.session.data;
  const grants = reduxStore.patient.grants || [];
  const org = sessionData?.orgid;

  if (org === document._ownerOrg) return true;

  const relevantGrant = grants.find((g) => g.granteeOrg === org && g.grantorOrg === document._ownerOrg);
  if (!relevantGrant) return false;

  if ((relevantGrant.allow_read || []).includes(document._type)) return true;

  return false;
};

/**
 * Checks whether the user has write permission to a document
 * @param {IControlProps} document - Any document that has controlprops
 * @returns {boolean} - Does user have write permission
 */
const hasWritePermission = (document: IControlProps): boolean => {
  const reduxStore: IState = store.getState();
  const sessionData = reduxStore.session.data;
  const grants = reduxStore.patient.grants || [];
  const org = sessionData?.orgid;

  if (org === document._ownerOrg) return true;

  const relevantGrant = grants.find((g) => g.granteeOrg === org && g.grantorOrg === document._ownerOrg);
  if (!relevantGrant) return false;

  if ((relevantGrant.allow_write || []).includes(document._type)) return true;

  return false;
};

/**
 * Checks whether the user can start editing the document
 * Editing might be disabled if someone else if actively editing the same document
 * @param {IControlProps} document - Any document that has controlprops
 * @returns {boolean} - Whether the user can start editing
 */
const canStartEditing = (document: IControlProps): boolean => {
  const reduxStore: IState = store.getState();
  const subscribers: { useruuid: string }[] = reduxStore.session.websocket.subscribers;
  const myuserId = reduxStore.session.data?.useruuid;

  const thisReduxDocument: INeuroDocument | undefined = reduxStore.documents.documents?.find(
    (rd) => rd.documentId === document._id,
  );

  if (subscribers.length > 1) {
    const otherSubs = subscribers.filter((sub) => sub.useruuid !== myuserId);
    if (
      thisReduxDocument?.commits.some((c) => !c.commitDate && otherSubs.some((sub) => sub.useruuid === c.creatorId))
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Write permission container mainly for buttons that accept disabled as a prop
 * @param {IControlProps} document - Any document that has controlprops
 * @param {JSX.Element} children - Child element such as a button
 * @param {JSX.Element} disabled - Optional disabled setting which takes priority
 * @param {JSX.Element} disabledTooltip - Optional disabled tooltip contents
 * @returns {JSX.Element} Rendered children with disabled prop and disabledToolTip prop
 */
const WritePermissionContainer = ({
  document,
  children,
  disabled,
  disabledTooltip,
}: {
  document?: IControlProps;
  children: JSX.Element;
  disabled?: boolean;
  disabledTooltip?: JSX.Element;
}): JSX.Element => {
  const writePermission = document ? hasWritePermission(document) : true;
  const editingPossible = document ? canStartEditing(document) : true;
  return React.cloneElement(children, {
    disabled: disabled || !(writePermission && editingPossible),
    disabledTooltip:
      (disabled && disabledTooltip) ||
      (!writePermission ? (
        <FormattedMessage id="general.documentLocked" />
      ) : !editingPossible ? (
        <FormattedMessage id="general.documentBeingEdited" />
      ) : (
        <FormattedMessage id="general.documentLocked" />
      )),
  });
};

export { hasReadPermission, hasWritePermission, WritePermissionContainer, canStartEditing };
