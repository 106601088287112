import { path, find, filter } from 'ramda';
import { exists, sortPartialDate } from 'neuro-utils';

/**
 * Check that the value exists and it's not null or an object with only a nonexistent value
 * @param  {any} value - Any value to be checked
 * @returns {boolean} True if value exists and isn't null, false if it doesn't exist or is null
 */
export const existsAndIsNotNull = (value: any): boolean => {
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    const objectValue = value.value;
    if (Array.isArray(objectValue)) {
      return objectValue.length > 0 && exists(objectValue[0]) && objectValue[0] !== null;
    }
    return exists(objectValue) && objectValue !== null;
  }
  if (Array.isArray(value)) {
    return value.length > 0 && exists(value[0]) && value[0] !== null;
  }
  return exists(value) && value !== null;
};

/**
 * Merge all documents to one document that contains the latest values recorded and their respective dates
 * @param  {{[key:string]:any}[]} documents - All documents
 * @returns {{ [key: string]: any }} A document that has the latest values as date-value pairs combined from all documents
 */
export const mergeDocuments = (documents: { [key: string]: any }[]): { [key: string]: any } => {
  const merged = Object.assign({}, Array.isArray(documents) ? documents[0] : {});

  Object.keys(merged).map(
    (key) =>
      key.charAt(0) !== '_' && key !== 'date' && (merged[key] = { date: documents[0]['date'], value: merged[key] }),
  );
  documents && Array.isArray(documents)
    ? documents?.slice(1).map((document) => {
        return Object.keys(document)
          .filter((key) => {
            return key.charAt(0) !== '_';
          })
          .forEach((key) => {
            merged && !existsAndIsNotNull(merged[key])
              ? Object.assign(merged, { [key]: { date: document['date'], value: document[key] } })
              : undefined;
          });
      })
    : undefined;
  return merged;
};

/**
 * Sort documents by date (latest first) and filter by type(s)
 * @param  {string[]} type - Document type(s) to be included
 * @param  {TDocument[]} documents - All documents
 * @returns {TDocument[]} A sorted array of documents of given type
 */
export const sortAndTakeLatest = (type: string[], documents?: TDocument[]): TDocument[] => {
  if (documents) {
    return documents
      .sort((n1, n2) => n1._cdate - n2._cdate)
      .sort((n1, n2) =>
        sortPartialDate(
          'date' in n1 ? n1.date : 'startDate' in n1 ? n1?.startDate : undefined,
          'date' in n2 ? n2.date : 'startDate' in n2 ? n2?.startDate : undefined,
        ),
      )
      .reverse()
      .filter((d) => type.some((t) => d._type === t), documents);
  } else return [];
};

/**
 * Find document that is being edited and get its type
 * @param  {TDocument[]} documents - All documents
 * @param  {string} editing - Id of document that is being edited
 * @returns {string | undefined} Type of document that is being edited
 */
export const docType = (documents: TDocument[], editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : undefined;

/**
 * Filter documents by type
 * @param  {string} type - Document type to be included
 * @param  {TDocument[]} documents - All documents
 * @returns {TDocument[]} All documents filtered by type
 */
export const filterDocs = (type: string, documents: TDocument[]): TDocument[] =>
  documents ? filter((d) => d._type === type, documents) : [];

export type TDocument = IChestCircumference | IVentilation | IPulmonary;
