/**
 * Capitalize a string. (turn the first letter into big letter)
 * @param  {string} s - String to capitalize
 * @return {string} Capitalized string
 */
export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

/**
 * Changes array to strings
 * @param {Array<string|number>} arr - Array to change to strings
 * @returns {string} String
 */
export const a2String = (arr: Array<string | number>): string => {
  let str = '';
  arr.forEach((a: string | number) => (str = str + a));
  return str;
};

/**
 * Sorter function for sorting alphabetically by object prop
 * @param {string} prop - Property name
 * @returns {(string1, string2) => number} Comparative number
 */
export const sortAlphaObject =
  (prop: string) =>
  (obj1?: any, obj2?: any): number => {
    const string1 = (obj1?.[prop] || '').toUpperCase();
    const string2 = (obj2?.[prop] || '').toUpperCase();
    return string1 < string2 ? -1 : string1 > string2 ? 1 : 0;
  };
