import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { Container, Item } from '../../../../components/Grid';

const StyledLabel = styled('div')({
  fontWeight: 600,
});

const instruction = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '-2.5rem' }}>
      <Item xs={2}>
        <StyledLabel>Instructions</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage id={'functionalPerformance.labels.uhdrsFunctionalCapacity.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

export const steps: Array<keyof IUHDRSFunctionalCapacity> = [
  'occupation',
  'finances',
  'domesticChores',
  'adl',
  'careLevel',
];

export const getStepContent = (
  stepName: string,
): {
  opts: number[];
  optsLocale: string;
  info?: string;
  assets?: number[];
  height?: number;
  instruction?: JSX.Element;
} => {
  switch (stepName) {
    case 'occupation':
    case 'finances':
    case 'adl': {
      return { opts: [0, 1, 2, 3], optsLocale: stepName, instruction: instruction(`${stepName}Instruction`) };
    }
    case 'domesticChores':
    case 'careLevel': {
      return { opts: [0, 1, 2], optsLocale: stepName, instruction: instruction(`${stepName}Instruction`) };
    }
    default: {
      return { opts: [0], optsLocale: '' };
    }
  }
};
