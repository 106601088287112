import { partialDateToValue, nowPartialDate } from 'neuro-utils';

// Find out if medication/therapy/etc has ended already
export const isEnded = (endDate?: PartialDate): boolean => {
  if (endDate?.[0]) {
    const nowDate = partialDateToValue(nowPartialDate());
    const endedDate = partialDateToValue(endDate);
    if (endedDate < nowDate) return true;
    else return false;
  } else return false;
};
