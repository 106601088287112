import { createListenerMiddleware } from '@reduxjs/toolkit';
import { loadSessionAction } from './index';
import { ws_manager } from '../index';

/**
 * Invalidate peer browsing contexts on session load.
 */
export const invalidatePeerOnLoad = createListenerMiddleware();
invalidatePeerOnLoad.startListening({
  actionCreator: loadSessionAction,
  effect: async (action) => {
    ws_manager.post_session_orchestration_message({ type: action.type });
  },
});
