import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import ToolTip from '../ToolTip';
import { Container, Item } from '../Grid';

const InformationIcon = ({ title, info }: IOwnProps): JSX.Element => {
  return (
    <ToolTip
      title={title}
      description={info}
      content={
        <div style={{ width: '100%', height: '100%' }}>
          <Container
            style={{ width: 'inherit', height: 'inherit', cursor: 'pointer' }}
            justifyContent="center"
            alignItems="center"
          >
            <Item>
              <InfoIcon style={{ fontSize: '2.5rem', display: 'block' }} color="primary" />
            </Item>
          </Container>
        </div>
      }
      hover={true}
    />
  );
};

interface IOwnProps {
  title?: JSX.Element | string;
  info?: JSX.Element | string;
}

export default InformationIcon;
