import { dmdDiagnoses } from 'Routes/Diagnosis/utils/definitions';
import { ClinicalArchive } from '.';
import { LoadableDocConfig } from '../generalSettings';
import { isPartialDate, sortPartialDate } from 'neuro-utils';

export const dmdDocumentTypes: LoadableDocConfig[] = [
  { name: 'diagnosis' },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  { name: 'motor', subTypes: ['mwt', 'nsaa', 'mfm'], secondaryDocs: ['diagnosis'] },
  { name: 'motorFunctionAndMobilityAid', subTypes: ['motorfunction', 'mobilityAid'] },
  { name: 'nutrition' },
  { name: 'pulmonary', subTypes: ['chestCircumference', 'ventilationAid', 'pulmonary'] },
  { name: 'therapy' },
  { name: 'imaging', subTypes: ['cmap', 'dexa', 'muscleImaging', 'echocardiography', 'scoliosis', 'pelvis', 'mri'] },
  { name: 'clinicalstudy', subTypes: ['fvc', 'enmg', 'nocturnalPolygraphy'] },
  { name: 'examination' },
  { name: 'comorbidity', subTypes: ['comorbidity', 'hospitalization'], secondaryDocs: ['medication'] }, // Form needs medication names
  { name: 'generalhealth' },
  { name: 'background', subTypes: ['background', 'measurement'] },
  { name: 'myService', title: 'titleShort' },
];

export const dmdGraphDocTypes = [
  'diagnosis',
  'medication',
  'adverseEffect',
  'comorbidity',
  'hospitalization',
  'nsaa',
  'mfm',
  'mwt',
  'background',
  'measurement',
  'motorfunction',
  'therapy',
  'ventilationAid',
  'mobilityAid',
  'nocturnalPolygraphy',
  'enmg',
  'fvc',
  'pelvis',
  'scoliosis',
  'muscleImaging',
  'dexa',
  'nutrition',
  'cmap',
  'echocardiography',
];

export const dmdAlerts: IAlert[] = [
  {
    // Notification to the user if a DMD diagnosis document symptomOnsetDate is before date
    content: 'alerts.dmd.symptomOnsetDateAfterDiagnosisDate',
    id: 'dmdSymptomOnsetDateAfterDiagnosisDate',
    severity: 'warning',
    popup: true,
    rule: (docs?: Array<IControlProps & Record<string, unknown>>): boolean => {
      return (docs || []).some(
        (d) =>
          d._type === 'diagnosis' &&
          'diagnosis' in d &&
          dmdDiagnoses.includes((d as IDiagnosis).diagnosis ?? '') &&
          isPartialDate(d.date) &&
          isPartialDate(d.symptomOnsetDate) &&
          sortPartialDate(d.symptomOnsetDate, d.date) > 0,
      );
    },
  },
];

/** Clinical archive types of DMD platform. */
export const dmdClinicalArchiveTypes: readonly ClinicalArchive[] = ['LNEU', 'TOI', 'FYST'] as const;
