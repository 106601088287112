import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import colors from '../../../../../config/theme/colors';
import { IMyServiceContext } from '../..';

const NewTreatmentDecisionCell = ({ fm, date }: { fm: IMyServiceContext['fm']; date: PartialDate }): JSX.Element => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ width: '50%', height: '2px', backgroundColor: colors.appBlue.medium }} />
      <div
        style={{
          width: '28rem',
          height: '90%',
          backgroundColor: colors.appBlue.medium,
          color: colors.white,
          textAlign: 'center',
          fontSize: '12px',
          borderRadius: '0.3rem',
        }}
      >
        {fm('myService.ninmt.newTreatmentDecision')} {formatPartialDate(date)}
      </div>
      <div style={{ width: '50%', height: '2px', backgroundColor: colors.appBlue.medium }} />
    </div>
  );
};

export default NewTreatmentDecisionCell;
