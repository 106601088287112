import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'Components/ActionButton';
import { Container, Item } from 'Components/Grid';

import { dialogActions, dialogContent, dialogTitle } from '../../../config/theme/componentTheme';

const CodeArea = ({ values }: { values: string[] }): JSX.Element => {
  return (
    <Container>
      {values &&
        values.map((v: string, i: number) => {
          return (
            <Item
              key={i}
              style={{
                border: '2px solid lightgray',
                fontSize: 24,
                marginRight: '0.5rem',
                borderRadius: '0.2rem',
                padding: '0 0.5rem',
              }}
            >
              {v}
            </Item>
          );
        })}
    </Container>
  );
};

const OTPDialog = ({ dialogOpen, setDialogOpen, otpData }: IOTPDialog): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  const expirationTime = otpData ? new Date(otpData.expiration) : undefined;
  const hours = expirationTime
    ? expirationTime.getHours() < 10
      ? '0' + expirationTime.getHours()
      : expirationTime.getHours()
    : undefined;
  const minutes = expirationTime
    ? expirationTime.getMinutes() < 10
      ? '0' + expirationTime.getMinutes()
      : expirationTime.getMinutes()
    : undefined;
  const seconds = expirationTime
    ? expirationTime.getSeconds() < 10
      ? '0' + expirationTime.getSeconds()
      : expirationTime.getSeconds()
    : undefined;

  const codeArr = otpData?.code.split('');

  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth={'xs'} fullWidth>
      <DialogTitle style={dialogTitle}>{fm('myService.createOTP')}</DialogTitle>
      <DialogContent style={dialogContent}>
        <div>
          {fm('myService.yourOTP')}:{codeArr ? <CodeArea values={codeArr} /> : '-'}
          {fm('myService.expiration')}:{' '}
          {expirationTime ? <span style={{ fontWeight: 600 }}>{`${hours}:${minutes}:${seconds}`}</span> : '-'}
        </div>
      </DialogContent>
      <DialogActions style={dialogActions}>
        <Container justifyContent={'flex-end'}>
          <Item>
            <ActionButton
              text="myService.close"
              onClick={() => setDialogOpen(false)}
              fontSize={16}
              width={15}
              height={4}
            />
          </Item>
        </Container>
      </DialogActions>
    </Dialog>
  );
};

interface IOTPDialog {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  otpData?: { code: string; expiration: number };
}

export default OTPDialog;
