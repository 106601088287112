import { CSSObject, Theme } from '@mui/material/styles';

const height = '3.2rem';
const compactHeight = '2.7rem';
const border = (theme?: Theme): string => '1px solid ' + theme?.palette.grey[500];

const table: CSSObject = {
  margin: 0,
  padding: 0,
  borderSpacing: 0,
  width: '100%',
  emptyCells: 'show',
};

const ellipsis = (width = 18): React.CSSProperties => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  maxWidth: `${width}rem`, // This enables ellipsis with the desired width when no value
});

const firstCell = (theme: Theme) => ({
  color: theme.customComponents.tile.fonts.normalText.secondaryColor,
  margin: '0 0 0 -0.6rem',
  padding: '0 0 0 0.6rem',
});
const middleCell = (theme: Theme, hasFirstCell: boolean, hasLastCell: boolean) => {
  const marginLeft = hasFirstCell ? '0' : '-0.6rem';
  const marginRight = hasLastCell ? '0' : '-0.6rem';

  const paddingLeft = hasFirstCell ? '0' : '0.6rem';
  const paddingRight = hasLastCell ? '0' : '0.6rem';
  return {
    fontSize: theme.customComponents.tile.fonts.normalText.size,
    color: theme.customComponents.tile.fonts.normalText.secondaryColor,
    margin: `0 ${marginRight} 0 ${marginLeft}`,
    padding: `0 ${paddingRight} 0 ${paddingLeft}`,
  };
};
const lastCell = (theme: Theme) => ({
  fontSize: theme.customComponents.tile.fonts.normalText.size,
  color: theme.customComponents.tile.fonts.normalText.color,
  margin: '0 -0.6rem 0 0',
  padding: '0 0.6rem 0 0',
});

const dateCell = (theme?: Theme) => ({
  ...(theme ? firstCell(theme) : {}),
  fontSize: theme?.customComponents.tile.fonts.smallText.size,
  color: theme?.customComponents.tile.fonts.smallText.color,
  width: '9rem',
  minWidth: '9rem',
});

const titleCell = (theme?: Theme, hasFirstCell = true, hasLastCell = true): React.CSSProperties => ({
  ...(theme ? middleCell(theme, hasFirstCell, hasLastCell) : {}),
});

const valueCell = (theme?: Theme): React.CSSProperties => ({
  ...(theme ? lastCell(theme) : {}),
  fontWeight: 600,
  textAlign: 'right',
});

export { height, compactHeight, border, table, ellipsis, dateCell, titleCell, valueCell };
