import { styled } from '@mui/system';
import * as React from 'react';
import VerticalVas from '../../../../public/assets/images/VAS-vertical.svg';

const StyledSlider = styled('input', { shouldForwardProp: (prop) => prop !== 'isValue' })(
  ({ isValue }: { isValue: boolean }) => ({
    appearance: 'none' as const,
    WebkitAppearance: 'none' as const,
    height: 100,
    width: 473,
    transform: 'rotate(-90deg) translateX(2px)',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    marginLeft: -96.5,
    marginTop: 195,
    '::-webkit-slider-runnable-track': {
      height: 0,
    },
    '::-moz-range-track': {
      height: 0,
    },
    '::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      height: 11,
      width: 11,
      borderRadius: 5.5,
      backgroundColor: !isValue ? 'transparent' : '#FF0000',
      marginTop: -5.5,
    },
    '::-moz-range-thumb': {
      WebkitAppearance: 'none',
      height: 11,
      width: 11,
      border: 'none',
      borderRadius: 5.5,
      backgroundColor: '#FF0000',
    },
  }),
);

// Vertical VAS for EQ5D, not customizable
const RangeSlider = ({
  editing = false,
  name,
  onChange,
  value,
  infoMax,
  infoMin,
  infoValueDisplay,
}: IInputBasics & IVerticalVas): JSX.Element => {
  const [sliderValue, setSliderValue] = React.useState<number | undefined>(value);
  const sliderRef = React.useRef<HTMLInputElement>(null);

  const handleChange = () => {
    if (sliderRef.current?.value) setSliderValue(parseInt(sliderRef.current?.value));
  };

  const handleSaveValue = () => {
    if (editing && value !== sliderValue && onChange) {
      onChange({ [name]: sliderValue });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {editing && (
          <div style={{ textAlign: 'center' }}>
            <div>{infoMax ?? 'moi'}</div>
            <img src={VerticalVas} style={{ height: 500 }} draggable={false} />
            <div
              style={{
                width: 100,
                height: 500,
                position: 'relative',
                bottom: 500,
                marginBottom: -500,
              }}
            >
              <StyledSlider
                ref={sliderRef}
                type="range"
                onChange={handleChange}
                value={sliderValue}
                step={1}
                onMouseUp={handleSaveValue}
                isValue={!!(sliderValue || sliderValue === 0)}
              />
            </div>
            <div>{infoMin ?? 'alin'}</div>
          </div>
        )}
        <div
          style={{
            marginLeft: '5rem',
            textTransform: 'uppercase',
          }}
        >
          <span>{`${infoValueDisplay} =`}</span>
        </div>
        <div
          style={{
            border: '1px solid black',
            width: 50,
            height: 40,
            marginLeft: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>{sliderValue}</span>
        </div>
      </div>
    </>
  );
};

export default RangeSlider;
