import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import * as React from 'react';

import { RenderValue } from './common/commonComponents';
import { border, compactHeight, ellipsis, height, table, titleCell, valueCell } from './common/commonStyles';
import { IData, TEllipsis } from './definitions';
import { take } from 'Utility/ramdaReplacement';

const DataTable = styled('table')(table);

const DataRow = styled('tr', {
  shouldForwardProp: (prop) => prop !== 'compact',
})(({ compact }: { compact?: boolean }) => ({
  height: compact ? compactHeight : height,
  maxHeight: compact ? compactHeight : height,
}));

const DataCellTitle = styled('td', {
  shouldForwardProp: (prop) => prop !== 'hasValue' && prop !== 'ellipsisLength',
})(({ theme, hasValue, ellipsisLength }: { theme?: Theme; hasValue: boolean; ellipsisLength?: number }) => ({
  ...titleCell(theme, false, hasValue),
  ...(ellipsisLength ? ellipsis(ellipsisLength) : {}),
  borderTop: border(theme),
}));

const DataCellValue = styled('td', {
  shouldForwardProp: (prop) => prop !== 'ellipsisLength',
})(({ theme, ellipsisLength }: { theme?: Theme; ellipsisLength?: number }) => ({
  ...valueCell(theme),
  ...(ellipsisLength ? ellipsis(ellipsisLength) : ellipsis(18)),
  borderTop: border(theme),
  wordBreak: 'break-all' as const,
}));

const SpecificListNoDate = ({ data, ellipsis, compact }: ISpecificListNoDate) => {
  const itemsCanFitAmount = 6;
  const shownItems = take(itemsCanFitAmount, data);
  const renderValueCol = data.some((d) => d.endDate || d.value);
  return (
    <DataTable>
      <tbody>
        {shownItems.map((d) => (
          <DataRow compact={compact} key={d.key}>
            <DataCellTitle hasValue={!!d.value} ellipsisLength={ellipsis?.title}>
              {d.title}
            </DataCellTitle>
            {renderValueCol && (
              <DataCellValue ellipsisLength={ellipsis?.value}>
                <RenderValue value={d.value} endDate={d.endDate} />
              </DataCellValue>
            )}
          </DataRow>
        ))}
      </tbody>
    </DataTable>
  );
};

export interface ISpecificNoDateData {
  title: IData['title'];
  value?: IData['value'];
  endDate?: IData['endDate'];
  key: IData['key'];
}

export interface ISpecificListNoDate {
  type: 'specificListNoDate';
  ellipsis?: TEllipsis;
  data: Array<ISpecificNoDateData>;
  compact?: boolean;
}

export default SpecificListNoDate;
