import { v4 as uuid, v1 as timeStamp } from 'uuid';

/**
 * Used to create ids for values that need a key for iterating
 * @param {any[]} values - Any values array
 * @returns {{id: uuid, value: any}[]} Object array with id and value
 */
export const appendIDs = (values: any[]): { id: string; value: any }[] =>
  values.map((v) => ({
    id: uuid(),
    value: v,
  }));

/**
 * Used to create id based on time stamp
 * @returns - uuid string
 */
export const createID = (): string => timeStamp({ msecs: new Date().getTime() });
