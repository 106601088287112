import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { diagnosticExamCodes } from '../utils';
import { equals } from 'ramda';

const DiagnosticTestsDash = ({ documents }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const diagnosticExamDocs = documents.filter((d) => d._type === 'diagnosticExamination') as IDiagnosticExamination[];
  const cnpTileDiagnosticExams = diagnosticExamDocs
    .filter((d) => Object.values(diagnosticExamCodes).some((ec) => equals(ec, d.code)))
    .sort((r1, r2) => sortPartialDate(r2.date, r1.date));

  return (
    <TileContentMaker
      type="recentList"
      data={cnpTileDiagnosticExams.map((d) => ({
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: fm(`diagnosticTests.${d?.code?.code}`),
        value:
          d?.finding?.supportDiagnosis === true
            ? fm('diagnosticTests.opts.support')
            : d?.finding?.supportDiagnosis === false
              ? fm('diagnosticTests.opts.noSupport')
              : '',
        key: d._id,
      }))}
    />
  );
};

interface IOwnProps {
  documents: Array<IDiagnosticExamination>;
}

export default DiagnosticTestsDash;
