import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector as useSelector } from 'Store/index';
import { formatPartialDate, getSSNAgeInWholeMonths, isPartialDate } from 'neuro-utils';

import { getPatientAgeInMonths } from '../../../utility/patientInfo';
import Censorable from '../../../components/Censorable';
import TileContentMaker from '../../../components/DashboardTile/TileContentMaker';
import { ellipsis } from 'Components/DashboardTile/TileContentMaker/components/common/commonStyles';

const ProfileDash = (): JSX.Element | undefined => {
  const { formatMessage } = useIntl();

  const session = useSelector((s: { session: ISessionStore }) => s.session.data);
  const patientData = useSelector((s: { patient: IPatientStore }) => s.patient.data);

  const ageInMonths =
    patientData?.isDead && isPartialDate(patientData?.dateOfDeath)
      ? getSSNAgeInWholeMonths(session?.patientssn, patientData.dateOfDeath)
      : getPatientAgeInMonths();

  const _name = patientData ? `${patientData.lastNames?.toUpperCase()}, ${patientData.firstNames}` : '';
  const name = <Censorable>{_name}</Censorable>;

  const _ssn = session ? `${session.patientssn}` : '';
  const ssn = <Censorable>{_ssn}</Censorable>;

  const age = `${ageInMonths < 60 ? ageInMonths : Math.floor(ageInMonths / 12)}`;

  const gender = patientData?.gender ? (
    <FormattedMessage
      id={`general.${patientData?.gender === 'female' || patientData.gender === 'male' ? patientData?.gender : 'na'}`}
    />
  ) : (
    ''
  );

  const ageHeader = formatMessage({
    id:
      patientData?.isDead && ageInMonths < 60
        ? 'profile.ageOfDeathMonths'
        : patientData?.isDead
          ? 'profile.ageOfDeath'
          : !patientData?.isDead && ageInMonths < 60
            ? 'profile.ageMonths'
            : 'profile.ageYears',
  });

  return (
    <TileContentMaker
      type="bigHeaderInfo"
      bigHeaderText={<div style={{ ...ellipsis(38) }}>{name}</div>}
      data={[
        { title: formatMessage({ id: 'general.ssn' }), value: ssn, key: 'ssn' },
        { title: formatMessage({ id: 'profile.gender' }), value: gender, key: 'gender' },
        {
          title: ageHeader,
          value: age,
          key: 'age',
        },
        ...(patientData?.isDead
          ? [
              {
                title: formatMessage({ id: 'profile.dateOfDeath' }),
                value: isPartialDate(patientData?.dateOfDeath)
                  ? formatPartialDate(patientData?.dateOfDeath)
                  : undefined,
                key: 'dateOfDeath',
              },
              {
                title: formatMessage({ id: 'profile.causeOfDeath' }),
                value: patientData?.reasonOfDeath
                  ? patientData?.reasonOfDeath.reason !== 'other' && patientData?.reasonOfDeath?.reasonCode?.code
                    ? `${patientData.reasonOfDeath?.reasonCode?.code} ${patientData.reasonOfDeath?.reasonCode?.icd10description}`
                    : patientData?.reasonOfDeath?.reasonOther
                      ? patientData.reasonOfDeath?.reasonOther
                      : undefined
                  : undefined,
                key: 'causeOfDeath',
              },
              // {
              //   header: formatMessage({ id: 'profile.deathDetail' }),
              //   value: patientData?.reasonOfDeath?.reasonDetail || undefined,
              //   key: 'deathDetail',
              // },
            ]
          : []),
      ]}
    />
  );
};

export default ProfileDash;
