import * as React from 'react';

type TPromiseChildProps<T> = {
  pending: boolean;
  error: boolean | null;
  value: T | null;
};

/**
 * Allows rendering a component after a promise is resolved
 * @param props.promiseValue - Any thennable (Promise) or regular value
 * @param props.children - A render function that implements the TPromiseState and returns Element
 */
const RenderPromise = <T,>({ promiseValue, children }: IOwnProps<T>): JSX.Element => {
  const [resolved, setResolved] = React.useState<TPromiseChildProps<T>>({ pending: true, error: null, value: null });

  React.useEffect(() => {
    let isMounted = true;

    Promise.resolve(promiseValue).then(
      (value: T) => (isMounted ? setResolved({ pending: false, error: false, value }) : null),
      (value: T) => (isMounted ? setResolved({ pending: false, error: true, value }) : null),
    );

    // cleanup function called when component unmounts
    return (): void => {
      isMounted = false;
    };
  }, [promiseValue]);

  return React.createElement(children, resolved);
};

type PromiseChildren<T> = (props: TPromiseChildProps<T>) => JSX.Element;

interface IOwnProps<T> {
  promiseValue: T | Promise<T>;
  children: PromiseChildren<T>;
}

export default RenderPromise;
