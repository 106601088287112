/**
 * Tools for handling authentication JWTs
 */

const jwtSearchExp = /neurojwt=([^;]*);?/;

const atobUtf8 = (str: string): string => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
};

/**
 * Parse neurojwt cookie contents from document.cookie
 * @param {string} cookie - Optional cookie, otherwise use document.cookie
 * @return {string|false} Return neurojwt cookie contents, or false if theres nothing
 */
export const parseJWTFromCookie = (cookie: string = document.cookie): string | false => {
  const matchArray = cookie.match(jwtSearchExp);
  return matchArray && matchArray.length > 0 ? matchArray[1] : false;
};

const parseJSONFromJWT = (JWT: string | false): IJWTBody | false =>
  JWT ? JSON.parse(atobUtf8(JWT.split('.')[1])) : JWT;

/**
 * Check expiration on jwt
 * @param {string} cookie - Optional cookie, otherwise use document.cookie
 * @return {true|false} Return boolean indicating if the jwt is still valid
 */
export const checkJWTNotExpired = (cookie: string = document.cookie): boolean => {
  const jwtData = parseJSONFromJWT(parseJWTFromCookie(cookie));
  return jwtData && jwtData.exp > Date.now();
};

/**
 * Return JWT data
 * @param {string} cookie - Optional cookie, otherwise use document.cookie
 * @return {IJWTBody|false} JWTBody, or false
 */
export const getJWTData = (cookie: string = document.cookie): IJWTBody | undefined =>
  parseJSONFromJWT(parseJWTFromCookie(cookie)) || undefined;
