import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PlatformConditional from '../../../components/PlatformConditional';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import {
  TDocument,
  mocaTotalScore,
  mmseTotalScore,
  sortAndTakeLatest,
  getCdrClass,
  getFtldCdrClass,
} from '../utils/index';
import { Container, Item } from 'Components/Grid';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { sortDocuments } from '../../../utility/randomUtil';
import { uniqBy } from 'ramda';

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const getMocaValue = (doc: IMOCA): number | JSX.Element | string => {
  const value = (
    <Item xs={true} style={{ textAlign: 'right' }}>
      {doc.unableToPerform ? (
        <FormattedMessage id="cognition.tmt.unableToPerformShort" />
      ) : doc.totalScoreOnly?.[0] === 'totalScoreOnly' ? (
        doc.totalScore
      ) : (
        mocaTotalScore(doc)
      )}
    </Item>
  );
  return value ?? '-';
};

const getMmseValue = (doc: IMMSE): number | string | JSX.Element => {
  const value = (
    <Item xs={true} style={{ textAlign: 'right' }}>
      {doc.unableToPerform ? (
        <FormattedMessage id="cognition.tmt.unableToPerformShort" />
      ) : doc.totalScoreOnly?.[0] === 'totalScoreOnly' ? (
        doc.totalScore
      ) : (
        mmseTotalScore(doc)
      )}
    </Item>
  );
  return value;
};

const getTmtValue = (doc: ITMT, part: 'a' | 'b'): JSX.Element | undefined => {
  if (part === 'a' && !(doc.tmtaUnableToPerform || doc.tmtaTime)) return undefined;
  if (part === 'b' && !(doc.tmtbUnableToPerform || doc.tmtbTime)) return undefined;
  const valueOfLatest = (
    <React.Fragment>
      <Container>
        <Item xs={true} style={{ textAlign: 'right' }}>
          {doc[part === 'a' ? 'tmtaUnableToPerform' : 'tmtbUnableToPerform']?.[0] === 'unableToPerform' ? (
            <FormattedMessage id="cognition.tmt.unableToPerformShort" />
          ) : doc[part === 'a' ? 'tmtaTime' : 'tmtbTime'] || doc[part === 'a' ? 'tmtaTime' : 'tmtbTime'] === 0 ? (
            `${doc[part === 'a' ? 'tmtaTime' : 'tmtbTime']} s`
          ) : (
            '-'
          )}
        </Item>
      </Container>
    </React.Fragment>
  );
  return valueOfLatest;
};

const getCdrValue = (doc: ICDR): JSX.Element => {
  const cdrClass = getCdrClass(doc);
  const ftldCdrClass = getFtldCdrClass(doc);
  return (
    <>
      <div>{cdrClass ?? '-'}</div>
      <div>{ftldCdrClass ?? '-'}</div>
    </>
  );
};

const getSdmtValue = (doc: ISDMT): JSX.Element => {
  const valueOfLatest = (
    <React.Fragment>
      <Container>
        <Item xs={true} style={{ textAlign: 'right' }}>
          {doc.correctAnswers ?? '-'}
          {' / '}
          {doc.allAnswers ?? '-'}
        </Item>
      </Container>
    </React.Fragment>
  );
  return valueOfLatest;
};

const latestDocumentsByType = (documents: IOwnProps['documents']) => {
  const sortedDocs = documents.sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2));
  const uniqDocs = uniqBy((d) => d._type, sortedDocs);
  return uniqDocs;
};

const CognitionDash = ({ documents }: IOwnProps): JSX.Element => {
  const docs = latestDocumentsByType(documents);
  return (
    <React.Fragment>
      <PlatformConditional platform={['parkinson']}>
        <TileContentMaker
          type="specificList"
          data={docs
            .filter((doc) => ['moca', 'mmse', 'tmt', 'cdr', 'sdmt'].includes(doc._type))
            .map((d) => {
              switch (d._type) {
                case 'moca':
                  return {
                    date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                    title: fm('cognition.moca.moca'),
                    value: getMocaValue(d as IMOCA),
                    key: 'moca',
                  };

                case 'mmse':
                  return {
                    date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                    title: fm('cognition.mmse.mmse'),
                    value: getMmseValue(d),
                    key: 'mmse',
                  };

                case 'tmt':
                  return {
                    date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                    title: (
                      <>
                        {getTmtValue(d, 'a') && <div>{fm('cognition.tmt.tmta')}</div>}
                        {getTmtValue(d, 'b') && <div>{fm('cognition.tmt.tmtb')}</div>}
                      </>
                    ),
                    value: (
                      <>
                        {getTmtValue(d, 'a') && <div>{getTmtValue(d, 'a')}</div>}
                        {getTmtValue(d, 'b') && <div>{getTmtValue(d, 'b')}</div>}
                      </>
                    ),
                    key: 'tmtCombined',
                  };

                case 'cdr':
                  return {
                    date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                    title: (
                      <>
                        <div>{fm('cognition.cdr.titleShort')}</div>
                        <div>{fm('cognition.cdr.ftldShort')}</div>
                      </>
                    ),
                    value: getCdrValue(d as ICDR),
                    key: 'cdr',
                  };

                case 'sdmt':
                  return {
                    date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                    title: fm('cognition.sdmt.sdmt'),
                    value: getSdmtValue(d),
                    key: 'sdmt',
                  };

                default:
                  return {
                    date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                    title: fm('cognition.default'),
                    key: 'default',
                  };
              }
            })}
        />
      </PlatformConditional>
      <PlatformConditional platform={'ms'}>
        <TileContentMaker
          type="recentList"
          data={(sortAndTakeLatest(['sdmt'], documents) as ISDMT[]).slice(0, 6).map((d) => ({
            date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
            title: fm('cognition.sdmt.sdmt'),
            value: getSdmtValue(d),
            key: d._id,
          }))}
        />
      </PlatformConditional>
      <PlatformConditional platform={'huntington'}>
        <TileContentMaker
          type="recentList"
          data={sortAndTakeLatest(['sdmt'], documents).map((s) => ({
            date: isPartialDate(s.date) ? formatPartialDate(s.date) : '',
            title: fm('cognition.sdmt.sdmt'),
            value: getSdmtValue(s),
            key: s._id,
          }))}
        />
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default CognitionDash;
