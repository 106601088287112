import { partialDateFromDate } from 'neuro-utils';
import { path } from 'ramda';
import { RESMED_AVX_INTG_SOURCE } from '../../config/generalSettings';
import { IReadonlyProps } from './readonlyGlobals';
import { createID } from 'Utility/appendIDs';
import { TSessionData } from 'Routes/Treatment/Document/Form/PAPTherapy/interfaces';
import { mappedSettingFields, mappedTreatmentResponseFields } from 'Routes/Treatment/Document/Form/PAPTherapy/config';

// Used to round values in sessionData
const roundToDecimals = (num: number, decimals: number = 2) =>
  Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);

interface IMappedPapDevice extends Omit<IPapDevice, 'type'>, IReadonlyProps {}

export const mapSessionDataToDevices = (sessionData: Array<TSessionData>): Array<IMappedPapDevice> => {
  const uniqueDeviceKeys = new Set<string>();

  const devices: Array<IMappedPapDevice> = sessionData
    .filter((session) => {
      const mode = session.deviceID?.mode;
      const deviceType = session.deviceID?.device?.deviceType;

      const uniqueKey = `${deviceType}_${mode}`;

      if (mode && deviceType && !uniqueDeviceKeys.has(uniqueKey)) {
        uniqueDeviceKeys.add(uniqueKey);
        return true;
      }
      return false;
    })
    .map((session) => {
      return {
        _id: createID(),
        _type: 'resMedDevice',
        _source: RESMED_AVX_INTG_SOURCE,
        id: createID(),
        date: session.sessionDate ? partialDateFromDate(new Date(session.sessionDate)) : undefined,
        type: session.deviceID?.device?.deviceType,
        mode: session.deviceID?.mode,
        manufacturer: 'ResMed',
        deviceModel: session.deviceID?.device?.deviceTypeDesc,
        resMedId: session.deviceID?.device?.serialNo,
      };
    });

  return devices;
};

interface IMappedPapSetting extends IPapSetting, IReadonlyProps {}

// Convert session data values to match our setting data or convert numeric string values to numbers with two decimal places where applicable
const convertSettingValue = (key: string, value?: string): string | number | undefined => {
  switch (key) {
    case 'EPAPAutoEnable':
      // TODO: More info needed - What are the values for this?
      return value;
    case 'EPRLevel':
      // TODO: More info needed - Based on example data, might not be actual
      return (
        {
          ZERO: 0,
          ONE: 1,
          TWO: 2,
          THREE: 3,
        }[value ?? ''] ?? undefined
      );
    case 'triggerSensivity':
    case 'cycleSensivity':
      // TODO: More info needed - The title in Neuro is "...if other than medium", example values contain 'Med', just return the value for now
      return value;
    default:
      return value ? roundToDecimals(parseFloat(value)) : undefined;
  }
};

export const mapSessionDataToSettings = (sessionData: Array<TSessionData>): Array<IMappedPapSetting> => {
  const uniqueSets = new Set<string>();

  // Filter sessions to keep only those with unique 'set' configurations
  const uniqueSessions = sessionData.filter((session) => {
    if (!session.set) return false;

    // Create a consistent string representation of the 'set' object
    const setString = JSON.stringify(
      Object.keys(session.set)
        .sort()
        .reduce((obj, key) => {
          Object.assign(obj ?? {}, { [key]: session.set?.[key as keyof typeof session.set] });
          return obj;
        }, {}),
    );

    // Track unique 'set' configurations
    if (!uniqueSets.has(setString)) {
      uniqueSets.add(setString);
      return true;
    }

    return false;
  });

  // Map filtered sessions to settings
  const settings = uniqueSessions.map((session) => {
    const mappedSetting: IMappedPapSetting = {
      _id: createID(),
      _type: 'resMedSetting',
      _source: RESMED_AVX_INTG_SOURCE,
      id: createID(),
      date: session.sessionDate ? partialDateFromDate(new Date(session.sessionDate)) : undefined,
      // device
      // deviceId
    };

    const { set } = session;
    if (!set) return mappedSetting;

    // Map the 'set' fields to the corresponding settings
    Object.keys(mappedSettingFields).forEach((key) => {
      const field = mappedSettingFields[key];
      const value = set[key as keyof typeof set];
      if (typeof value === 'string' && field) {
        Object.assign(mappedSetting, { [field]: convertSettingValue(key, value) });
      }
    });

    return mappedSetting;
  });

  return settings;
};

interface IMappedPapTreatmentResponse extends IPapTreatmentResponse, IReadonlyProps {}

// Convert session data numeric string values to numbers with two decimal places where applicable
const convertTreatmentResponseValue = (_key: string, value?: string): string | number | undefined => {
  return value ? roundToDecimals(parseFloat(value)) : undefined;
};

export const mapSessionDataToTreatmentResponses = (
  sessionData: Array<TSessionData>,
): Array<IMappedPapTreatmentResponse> => {
  // Map all sessions to treatment responses
  const treatmentResponses = sessionData.map((session) => {
    const mappedTreatmentResponse: IMappedPapTreatmentResponse = {
      _id: createID(),
      _type: 'resMedTreatmentResponse',
      _source: RESMED_AVX_INTG_SOURCE,
      id: createID(),
      date: session.sessionDate ? partialDateFromDate(new Date(session.sessionDate)) : undefined,
      // device
      // deviceId
    };

    const { clinicalMetrics, respEvents } = session;
    if (!clinicalMetrics && !respEvents) return mappedTreatmentResponse;

    // Map the 'usage' fields to the corresponding treatment response fields (patientHoursOfUsePerDay)
    const duration = parseFloat(session.usage?.duration ?? '');
    if (typeof duration === 'number') {
      Object.assign(mappedTreatmentResponse, { patientHoursOfUsePerDay: roundToDecimals(duration / 60) });
    }

    // Map the 'clinicalMetrics' fields to the corresponding treatment responses
    Object.keys(mappedTreatmentResponseFields).forEach((key) => {
      const field: string | undefined = path([key], mappedTreatmentResponseFields);

      const pathArr = key.split('.');

      let value: unknown;

      // 'respEvents' are not a part of 'clinicalMetrics'
      if (pathArr[0] === 'respEvents') {
        value = path([pathArr[1]], respEvents);
      } else {
        value = path(pathArr, clinicalMetrics);
      }

      if (typeof value === 'string' && field) {
        const newValues = { [field]: convertTreatmentResponseValue(key, value) };

        const newValue = newValues[field];
        // Map related calculated fields using converted values
        if (field === 'maskLeakage95PercentilePerSecond' && typeof newValue === 'number') {
          newValues.maskLeakage95PercentilePerMinute = roundToDecimals(newValue * 60);
        }
        if (field === 'tidalVolume95Percentile' && typeof newValue === 'number') {
          newValues.tidalVolume95Percentile = roundToDecimals(newValue * 1000);
        }

        Object.assign(mappedTreatmentResponse, newValues);
      }
    });

    return mappedTreatmentResponse;
  });

  return treatmentResponses;
};
