import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import PlatformConditional from '../../../components/PlatformConditional';
import {
  TDocument,
  hineMMScore,
  hfmsEScore,
  chopINTENDScore,
  rulmScore,
  rulmSuperiorHand,
  nsaaScore,
  isHineMMComplete,
  mfmScore,
} from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const sortAndTakeFirst = (type: string, documents?: IOwnProps['documents']): TDocument | undefined => {
  if (documents) {
    return documents
      .sort((n1, n2) => n1._cdate - n2._cdate)
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()
      .find((d) => d._type === type, documents);
  } else return undefined;
};

const MotorDash = ({ documents }: IOwnProps): JSX.Element => {
  const diagnoses = (documents || []).filter((d) => d._type === 'diagnosis').map((d: IDiagnosis) => d.diagnosis);
  const hineDoc = sortAndTakeFirst('hine_mm', documents) as IHINEMM;
  const rulmDoc = sortAndTakeFirst('rulm', documents) as IRULM;
  const chopDoc = sortAndTakeFirst('chop_intend', documents) as ICHOPINTEND;
  const hfmsDoc = sortAndTakeFirst('hfms_e', documents) as IHFMSE;
  const mwtDoc =
    diagnoses.includes('G12.1') || diagnoses.includes('G71.06')
      ? (sortAndTakeFirst('mwt', documents) as I6MWT)
      : undefined;
  const nsaaDoc = sortAndTakeFirst('nsaa', documents) as INSAA;
  const mfmDoc = sortAndTakeFirst('mfm', documents) as IMFM;

  const smaData = [];
  const dmdData = [];

  hineDoc &&
    smaData.push({
      date: isPartialDate(hineDoc.date) ? formatPartialDate(hineDoc.date) : '',
      title: fm('motor.hineMM'),
      value: isHineMMComplete(hineDoc) ? (hineMMScore(hineDoc) === 0 ? '0' : hineMMScore(hineDoc)) : null,
      key: 'hineMM',
    });
  rulmDoc &&
    smaData.push({
      date: isPartialDate(rulmDoc.date) ? formatPartialDate(rulmDoc.date) : '',
      title: fm('motor.rulm'),
      // Check that at least one of the hands has some valid RULM-test score value
      value: rulmSuperiorHand(rulmDoc)
        ? rulmScore(
            // Calculate the RULMtest score for the hand with a superior RULM-test score value
            rulmDoc,
            rulmSuperiorHand(rulmDoc),
          ) === 0
          ? '0'
          : rulmScore(rulmDoc, rulmSuperiorHand(rulmDoc))
        : null,
      key: 'rulm',
    });
  chopDoc &&
    smaData.push({
      date: isPartialDate(chopDoc.date) ? formatPartialDate(chopDoc.date) : '',
      title: fm('motor.chopINTEND'),
      value:
        chopDoc.manualScore || chopDoc.manualScore === 0
          ? `${chopDoc.manualScore}`
          : chopINTENDScore(chopDoc) === 'notCounted'
            ? null
            : `${chopINTENDScore(chopDoc)}`,
      key: 'chopINTEND',
    });
  hfmsDoc &&
    smaData.push({
      date: isPartialDate(hfmsDoc.date) ? formatPartialDate(hfmsDoc.date) : '',
      title: fm('motor.hfmsE'),
      value: hfmsEScore(hfmsDoc) === 'notCounted' ? null : `${hfmsEScore(hfmsDoc)}`,
      key: 'hfmsE',
    });

  if (mwtDoc) {
    smaData.push({
      date: isPartialDate(mwtDoc.date) ? formatPartialDate(mwtDoc.date) : '',
      title: fm('motor.MWT'),
      value:
        mwtDoc?.meters || mwtDoc?.meters === 0 ? (
          <FormattedMessage id="motor.meter" values={{ meter: mwtDoc?.meters }} />
        ) : null,
      key: 'MWT',
    });
    dmdData.push({
      date: isPartialDate(mwtDoc.date) ? formatPartialDate(mwtDoc.date) : '',
      title: fm('motor.MWT'),
      value:
        mwtDoc?.meters || mwtDoc?.meters === 0 ? (
          <FormattedMessage id="motor.meter" values={{ meter: mwtDoc?.meters }} />
        ) : null,
      key: 'MWT',
    });
  }

  nsaaDoc &&
    dmdData.push({
      date: isPartialDate(nsaaDoc.date) ? formatPartialDate(nsaaDoc.date) : '',
      title: fm('motor.nsaa'),
      value: nsaaScore(nsaaDoc) === 'notCounted' ? null : `${nsaaScore(nsaaDoc)}`,
      key: 'nsaa',
    });

  if (mfmDoc) {
    smaData.push({
      date: isPartialDate(mfmDoc.date) ? formatPartialDate(mfmDoc.date) : '',
      title: fm('motor.mfm'),
      value: mfmScore(mfmDoc).total,
      key: 'mfm',
    });
    dmdData.push({
      date: isPartialDate(mfmDoc.date) ? formatPartialDate(mfmDoc.date) : '',
      title: fm('motor.mfm'),
      value: mfmScore(mfmDoc).total,
      key: 'mfm',
    });
  }

  return (
    <React.Fragment>
      <PlatformConditional platform="sma">
        <TileContentMaker type="specificList" data={smaData} />
      </PlatformConditional>
      <PlatformConditional platform="dmd">
        <TileContentMaker type="specificList" data={dmdData} />
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default MotorDash;
