import { createAction, createReducer } from '@reduxjs/toolkit';
import { TDispatch } from '..';
import { extendedSearch, search } from './apiFetchers';

const SEARCH = 'neuro-ui/settings/SEARCH';
const EXTENDED_SEARCH = 'neuro-ui/settings/EXTENDED_SEARCH';
const CLEAR_SEARCH_RESULTS = 'neuro-ui/settings/CLEAR_RESULTS';

const searchAction = createAction(SEARCH, (results: TNeuroPersonData[]) => {
  return {
    payload: { results },
  };
});

const extendedSearchAction = createAction(EXTENDED_SEARCH, (results: IExtendedSearchResults[]) => {
  return {
    payload: { results },
  };
});

const clearSearchResultsAction = createAction(CLEAR_SEARCH_RESULTS, () => {
  return {
    payload: null,
  };
});

const clearSearchResults = (dispatch: TDispatch) => {
  dispatch(clearSearchResultsAction());
};

const doPatientSearch =
  (searchString: string, wildcard: string) =>
  (dispatch: TDispatch): Promise<true> => {
    return search(searchString, wildcard).then((data) => {
      data && dispatch(searchAction(data));
      return true; // Return indication that search has finished
    });
  };

const doExtendedSearch =
  (searchString: string) =>
  (dispatch: TDispatch): Promise<true> => {
    return extendedSearch(searchString).then((data) => {
      data && dispatch(extendedSearchAction(data));
      return true; // Return indication that search has finished
    });
  };

export const actions = {
  doPatientSearch,
  doExtendedSearch,
  clearSearchResults,
};

const initialState: IPatientsearchStore = { searchResults: null };

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchAction, (state, action) => {
      state.searchResults = action.payload.results;
    })
    .addCase(extendedSearchAction, (state, action) => {
      state.searchResults = action.payload.results;
    })
    .addCase(clearSearchResultsAction, (state) => {
      state.searchResults = null;
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
