import { filter, find, path, pick, sum, values } from 'ramda';
import { exists } from 'neuro-utils';
import { steps as functionalAssessmentSteps } from '../Document/Form/functionalAssessmentSettings';
import { steps as functionalCapacitySteps } from '../Document/Form/functionalCapacitySettings';

export type TDocument =
  | IUHDRSFunctionalAssessment
  | IUHDRSIndependenceScale
  | IUHDRSFunctionalCapacity
  | IModifiedRankinScale
  | IModifiedChildrensGlobalAssessmentScale;

const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : undefined;

export const filterDocs = (type: string, documents: TDocument[]): TDocument[] =>
  documents ? filter((d) => d._type === type, documents) : [];

export const getHeaderId = (documents: Array<TDocument>, editing: any, viewing: any): string => {
  // handle both editing and viewing modes
  const docId = editing ? editing : viewing;
  // if the user is in editing or viewing mode and docType returns the doctype, return correct title for the header
  if (docType(documents, docId)) {
    return `functionalPerformance.${docType(documents, docId)}`;
  }
  return 'functionalPerformance.title';
};

export const isFunctionalAssessmentComplete = (doc?: IUHDRSFunctionalAssessment): boolean => {
  if (!doc) return false;
  return (
    functionalAssessmentSteps.filter((key) => {
      return exists(path([key], doc)) && doc[key as keyof IUHDRSFunctionalAssessment] !== '';
    }).length === functionalAssessmentSteps.length
  );
};

const replaceNan = (s: number): number => (Number.isNaN(s) || typeof s !== 'number' ? 0 : s);

export const countFunctionalAssessmentScore = (doc?: IUHDRSFunctionalAssessment): number => {
  if (!doc) return 0;

  return doc ? sum((values(pick(functionalAssessmentSteps, doc)) as number[]).map(replaceNan)) : 0;
};

export const isFunctionalCapacityComplete = (doc?: IUHDRSFunctionalCapacity): boolean => {
  if (!doc) return false;
  return (
    functionalCapacitySteps.filter((key) => {
      return exists(path([key], doc)) && doc[key as keyof IUHDRSFunctionalCapacity] !== '';
    }).length === functionalCapacitySteps.length
  );
};

export const countFunctionalCapacityScore = (doc?: IUHDRSFunctionalCapacity): number => {
  if (!doc) return 0;

  return doc ? sum((values(pick(functionalCapacitySteps, doc)) as number[]).map(replaceNan)) : 0;
};
