/** Save data into local storage */
export const localStorageSave = (name: string, data: string): void => window.localStorage.setItem(name, data);

/** Get data from local storage */
export const localStorageGet = (name: string): string | null => window.localStorage.getItem(name);

/** Remove data from local storage */
export const localStorageRemove = (name: string): void => window.localStorage.removeItem(name);

/** Clear all data from local storage */
export const localStorageClear = (): void => window.localStorage.clear();
