import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { getAtcCodesByPlatform } from 'Routes/Medication/Document/config';

/*
const filterPbaSDataPoints = (dp: IPoint, timeframe: [Date, Date] | undefined): boolean => {
  if (!timeframe) return true;
  if (
    timeframe &&
    dp.date &&
    new Date(dp.date).setHours(0, 0).valueOf() >= minDate(timeframe).setHours(0, 0).valueOf() &&
    new Date(dp.date).setHours(0, 0).valueOf() <= maxDate(timeframe).setHours(0, 0).valueOf()
  )
    return true;
  return false;
};*/

const pbaSFields = [
  'depressedMood',
  'suicidalIdeation',
  'anxiety',
  'irritability',
  'angryOrAggressiveBehaviour',
  'apathy',
  'perseveration',
  'obsessiveCompulsiveBehaviours',
  'delusionsOrParanoidThinking',
  'hallucinations',
  'disorientedBehaviour',
];

const huntingtonData = (
  z82Diagnosis: boolean,
  fm: (id: string) => string,
  uiLang: Locale,
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
): IDiseaseHistoryData => {
  const clinicalMeters = fm('graph.clinicalMeters');
  const patientSelfReport = fm('graph.patientSelfReport');

  const atcCodes = getAtcCodesByPlatform(medicationSettings, 'huntington');
  const atcOpts = medicationSettings?.huntington.localizations[uiLang as 'fi' | 'en'];

  const baseData: IDiseaseHistoryData = {
    graphData: {
      common: {
        uhdrsDiagnosticConfidenceLevel: {
          data: [],
          title: 'UHDRS DCL',
          scale: {
            type: 'custom',
            customScale: ['Normal', '<50%', '50-89%', '90-98%', '≥99%'],
          },
        },
        uhdrsIndependenceScale: {
          data: [],
          title: fm('functionalPerformance.uhdrsIndependenceScaleShort'),
          scale: {
            type: 'custom',
            customScale: [
              '10',
              '15',
              '20',
              '25',
              '30',
              '35',
              '40',
              '45',
              '50',
              '55',
              '60',
              '65',
              '70',
              '75',
              '80',
              '85',
              '90',
              '95',
              '100',
            ],
            formatter: (title: string) => (title.slice(-1) === '5' ? '' : title),
          },
        },
        uhdrsFunctionalAssessment: {
          data: [],
          title: 'UHDRS F.A.',
          scale: {
            type: 'linear',
            linearScale: [0, 25],
          },
        },
        uhdrsFunctionalCapacity: {
          data: [],
          title: fm('functionalPerformance.uhdrsFunctionalCapacityShort'),
          scale: {
            type: 'linear',
            linearScale: [0, 13],
          },
        },
        uhdrsMotorRatingScale1: {
          data: [],
          title: 'UHDRS Motor I',
          scale: {
            type: 'linear',
            linearScale: [0, 124],
          },
        },
        uhdrsMotorRatingScale2: {
          data: [],
          title: 'UHDRS Motor II',
          scale: {
            type: 'custom',
            customScale: ['0', '1', '2', '3', '4'],
          },
        },
        pbaS: {
          data: [],
          title: 'PBA-s',
          scale: {
            type: 'custom',
            customScale: pbaSFields.map((f) => fm(`behaviour.labels.pbaS.${f}`)).reverse(),
          },
        },
        sdmt: {
          data: [],
          title: 'SDMT',
          scale: {
            type: 'linear',
            linearScale: [0, 110],
          },
        },
        bmi: {
          data: [],
          title: 'BMI',
          scale: {
            type: 'linear',
            linearScaleCalculator: (max: number): [number, number] => (max > 40 ? [0, 80] : [0, 40]),
          },
        },
      },
      selfReporting: {
        eq5d: {
          title: 'EQ-5D-5L',
          data: [],
          scale: {
            type: 'custom',
            customScale: [],
          },
        },
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: false,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    graphMenuSettings: {
      onlyOneOpen: true,
    },
    addonData: {
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('graph.medications'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          Object.keys(atcOpts ?? {})
            .filter((code) => atcCodes.includes(code))
            .map((code) => {
              return { title: `${code} - ${atcOpts?.[code]}` };
            }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('graph.endedMedications'),
        addons: [],
      },
      patientSelfReport: {
        id: 'patientSelfReport',
        type: 'single',
        name: fm('graph.patientSelfReport'),
        addons: [],
      },
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('diagnosis.title'),
        addons: [],
      },
      appointments: {
        id: 'appointments',
        type: 'single',
        name: fm('appointments.appointments'),
        addons: [],
      },
    },
  };
  if (!z82Diagnosis && baseData.graphData.common) {
    delete baseData.graphData.common['uhdrsDiagnosticConfidenceLevel'];
  }
  return baseData;
};

export default huntingtonData;
