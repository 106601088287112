import { mergeLeft } from 'ramda';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { ISaveDraft } from '../FormEditingHandler';

/* History context */

const HistoryContext = React.createContext<IHistoryContext | null>(null);
const HistoryContextProviderFC = ({ context, children }: IHistoryProps): JSX.Element => {
  const intl = useIntl();
  return (
    <HistoryContext.Provider value={mergeLeft(context, { fm: (id: string) => intl.formatMessage({ id: id }) })}>
      {children}
    </HistoryContext.Provider>
  );
};
export const HistoryContextProvider = HistoryContextProviderFC;

type TOmitPropsHistory = Omit<IHistoryContext, 'documents'>; // Omit types that can be overridden with props

export const withHistoryContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<Omit<P, keyof TOmitPropsHistory>> =>
  (props): JSX.Element => (
    <HistoryContext.Consumer>
      {(context: IHistoryContext | null): JSX.Element | null => <Component {...context} {...(props as P)} />}
    </HistoryContext.Consumer>
  );
export interface IHistoryContext<TDoc = any, TAdditional = any> {
  documents?: Array<TDoc>;
  startEdit?: (document: { _id?: string }, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
  additionalData?: TAdditional;
  fm: (id: string) => string;
}

type TProviderContextHistory = Omit<IHistoryContext, 'fm'>;
interface IHistoryProps {
  context: TProviderContextHistory;
  children: JSX.Element | null;
}

/* Next Form context */

export const FormContext = React.createContext<IFormContext | null>(null);
const ContextProvider = ({ context, children }: IFormProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <FormContext.Provider
      value={mergeLeft(context, {
        fm: (id: string, values?: { [key: string]: any }) => formatMessage({ id: id }, values) as string,
      })}
    >
      {children}
    </FormContext.Provider>
  );
};
export const FormContextProvider = ContextProvider;

type TOmitProps = Omit<IFormContext, 'documents'>; // Omit types that can be overridden with props

export const withFormContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<Omit<P, keyof TOmitProps>> =>
  (props): JSX.Element => (
    <FormContext.Consumer>
      {(context: IFormContext | null): JSX.Element | null => (
        <Component
          {...context}
          {...(props as P)} /* Props should always come after context so that they can be overridden */
        />
      )}
    </FormContext.Consumer>
  );
export interface IFormContext<TFormData = any, TDoc = any> {
  documents?: Array<TDoc>;
  formData: IFormData<TFormData>;
  editing?: string;
  view?: IView;
  fm: (id: string, values?: { [key: string]: any }) => string;
  startEdit?: IHistoryContext['startEdit'];
  saveDraft?: ISaveDraft;
}
type TProviderContext = Omit<IFormContext, 'fm'>;
interface IFormProps {
  context: TProviderContext;
  children: JSX.Element | null;
}
