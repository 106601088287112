import { IData, IDataPoint, TDescriptionTable } from 'Components/sq-graphics/interfaces';
import { isPartialDate } from 'neuro-utils';
import {
  countFunctionalAssessmentScore,
  countFunctionalCapacityScore,
  isFunctionalAssessmentComplete,
  isFunctionalCapacityComplete,
} from 'Routes/FunctionalPerformance/utils';
import {
  uhdrsMotorScore,
  uhdrsMotorDysarthriaScore,
  uhdrsMotorRetropulsionPullTestScore,
  uhdrsMotorGaitScore,
  uhdrsMotorTandemWalkingScore,
} from 'Routes/MotorFunctionHuntington/utils';

export const convertUhdrsIndependenceScaleToGraph = (
  docs: Array<IUHDRSIndependenceScale>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    d.date &&
      d.value &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.value,
        id: 'uhdrsIndependence',
        title: d.value,
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'uhdrsIndependence',
      type: 'lineGraph',
      legend: fm('functionalPerformance.uhdrsIndependenceScale'),
    },
  ];
};

export const convertUhdrsFunctionalAssessmentToGraph = (
  docs: Array<IUHDRSFunctionalAssessment>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) || !isFunctionalAssessmentComplete(d)) return;
    d.date &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: countFunctionalAssessmentScore(d),
        title: countFunctionalAssessmentScore(d).toString(),
        id: 'uhdrsFA',
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'uhdrsFA',
      type: 'lineGraph',
      legend: fm('functionalPerformance.uhdrsFunctionalAssessment'),
    },
  ];
};

export const convertUhdrsFunctionalCapacityToGraph = (
  docs: Array<IUHDRSFunctionalCapacity>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) || !isFunctionalCapacityComplete(d)) return;
    d.date &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: countFunctionalCapacityScore(d),
        id: 'uhdrsFC',
        title: countFunctionalCapacityScore(d).toString(),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      type: 'lineGraph',
      legend: fm('functionalPerformance.uhdrsFunctionalCapacity'),
      id: 'uhdrsFC',
    },
  ];
};

export const convertUhdrsMotorRatingScaleToGraph1 = (
  docs: Array<IUHDRSMotorRatingScale>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) || uhdrsMotorScore(d) === 'cantCount') return;
    const score = uhdrsMotorScore(d);
    const descriptionManual: TDescriptionTable = [
      {
        title: fm('motorFunctionHuntington.uhdrsTotalScoreOnly'),
        values: d.totalScoreOnly?.[0] === true ? fm('general.yes') : fm('general.no'),
      },
      {
        title: fm('motorFunctionHuntington.uhdrsScore'),
        values: score,
      },
    ];
    const descriptionFilled: TDescriptionTable = [
      {
        title: fm('motorFunctionHuntington.uhdrsScore'),
        values: `${score}`,
      },
      {
        title: fm('motorFunctionHuntington.uhdrsMotorDysarthria'),
        values: uhdrsMotorDysarthriaScore(d) === 0 ? '0' : uhdrsMotorDysarthriaScore(d),
      },
      {
        title: fm('motorFunctionHuntington.uhdrsMotorRetropulsionPullTest'),
        values: uhdrsMotorRetropulsionPullTestScore(d) === 0 ? '0' : uhdrsMotorRetropulsionPullTestScore(d),
      },
      {
        title: fm('motorFunctionHuntington.uhdrsMotorGait'),
        values: uhdrsMotorGaitScore(d) === 0 ? '0' : uhdrsMotorGaitScore(d),
      },
      {
        title: fm('motorFunctionHuntington.uhdrsMotorTandemWalking'),
        values: uhdrsMotorTandemWalkingScore(d) === 0 ? '0' : uhdrsMotorTandemWalkingScore(d),
      },
    ];
    score !== 'cantCount' &&
      score !== 'notCounted' &&
      d.date &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: score,
        id: 'uhdrsMotorRatingScale1',
        title: score.toString(),
        description:
          d.totalScoreOnly?.[0] === true && d.manualScore
            ? descriptionManual
            : !d.totalScoreOnly?.[0]
              ? descriptionFilled
              : undefined,
      });
  });
  return [
    {
      dataPoints: dataPoints,
      type: 'lineGraph',
      legend: 'UHDRS Motor I',
      id: 'uhdrsMotor1',
    },
  ];
};

export const convertUhdrsMotorRatingScaleToGraph2 = (
  docs: Array<IUHDRSMotorRatingScale>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsDysa: IDataPoint[] = [];
  const dataPointsRetropulsion: IDataPoint[] = [];
  const dataPointsGait: IDataPoint[] = [];
  const dataPointsTandem: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    if (d.dysarthria || d.dysarthria === 0) {
      d.date &&
        dataPointsDysa.push({
          date: dateFromPartialUpdateTimeframe(d.date),
          value: (d.dysarthria ?? 0).toString(),
          id: 'dysarthria',
          title: (d.dysarthria ?? 0).toString(),
        });
    }
    if (d.retropulsionPullTest || d.retropulsionPullTest === 0) {
      d.date &&
        dataPointsRetropulsion.push({
          date: dateFromPartialUpdateTimeframe(d.date),
          value: (d.retropulsionPullTest ?? 0).toString(),
          title: (d.retropulsionPullTest ?? 0).toString(),
          id: 'retropulsionPullTest',
        });
    }
    if (d.gait || d.gait === 0) {
      d.date &&
        dataPointsGait.push({
          date: dateFromPartialUpdateTimeframe(d.date),
          value: (d.gait ?? 0).toString(),
          id: 'gait',
          title: (d.gait ?? 0).toString(),
        });
    }
    if (d.tandemWalking || d.tandemWalking === 0) {
      d.date &&
        dataPointsTandem.push({
          date: dateFromPartialUpdateTimeframe(d.date),
          value: (d.tandemWalking ?? 0).toString(),
          id: 'tandemWalking',
          title: (d.tandemWalking ?? 0).toString(),
        });
    }
  });
  return [
    {
      dataPoints: dataPointsDysa,
      type: 'lineGraph',
      id: 'dysarthria',
      legend: fm('motorFunctionHuntington.uhdrsMotorDysarthria'),
    },
    {
      dataPoints: dataPointsRetropulsion,
      type: 'lineGraph',
      id: 'retropulsionPullTest',
      legend: fm('motorFunctionHuntington.uhdrsMotorRetropulsionPullTest'),
    },
    {
      dataPoints: dataPointsGait,
      type: 'lineGraph',
      id: 'gait',
      legend: fm('motorFunctionHuntington.uhdrsMotorGait'),
    },
    {
      dataPoints: dataPointsTandem,
      type: 'lineGraph',
      id: 'tandemWalking',
      legend: fm('motorFunctionHuntington.uhdrsMotorTandemWalking'),
    },
  ];
};

export const convertUhdrsConfidenceIndexToGraph = (
  docs: Array<IUHDRSDiagnosticConfidenceIndex>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) && !d.index) return;
    let value: string | undefined = undefined;
    switch (d.index) {
      case '0': {
        value = 'Normal';
        break;
      }
      case '1': {
        value = '<50%';
        break;
      }
      case '2': {
        value = '50-89%';
        break;
      }
      case '3': {
        value = '90-98%';
        break;
      }
      case '4': {
        value = '≥99%';
        break;
      }
    }
    d.date &&
      value &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        title: fm(`diagnosis.huntington.uhdrs.form.opts.${d.index}`),
        id: 'uhdrsDCL',
      });
  });
  return [
    {
      dataPoints: dataPoints,
      type: 'lineGraph',
      legend: fm('diagnosis.huntington.uhdrs.title'),
      id: 'uhdrsDiagnosticConfidenceLevel',
    },
  ];
};

const pbaSFields = [
  'depressedMood',
  'suicidalIdeation',
  'anxiety',
  'irritability',
  'angryOrAggressiveBehaviour',
  'apathy',
  'perseveration',
  'obsessiveCompulsiveBehaviours',
  'delusionsOrParanoidThinking',
  'hallucinations',
  'disorientedBehaviour',
];

const isPbaSScoreValid = (score: any): boolean =>
  (typeof score === 'number' || typeof score === 'string') &&
  (score === 0 ||
    score === 1 ||
    score === 2 ||
    score === 3 ||
    score === 4 ||
    score === 8 ||
    score === 9 ||
    score === 'U' ||
    score === 'N');

// If score is 8, 9, U or N, score for pbas field is not calculated
const isPbaSScoreAcceptable = (score: any): boolean =>
  (typeof score === 'number' || typeof score === 'string') && ![8, 9, 'U', 'N'].includes(score);

export const convertPbasToGraph = (
  docs: Array<IPBAS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];

  docs.forEach((d) => {
    pbaSFields.forEach((f) => {
      const additionalValue =
        isPbaSScoreValid(d[(f + 'Severity') as keyof IPBAS]) &&
        isPbaSScoreValid(d[(f + 'Frequency') as keyof IPBAS]) &&
        isPbaSScoreAcceptable(d[(f + 'Severity') as keyof IPBAS]) &&
        isPbaSScoreAcceptable(d[(f + 'Frequency') as keyof IPBAS])
          ? (
              parseInt((d[(f + 'Severity') as keyof IPBAS] || 0).toString()) *
              parseInt((d[(f + 'Frequency') as keyof IPBAS] || 0).toString())
            ).toString()
          : isPbaSScoreValid(d[(f + 'Severity') as keyof IPBAS]) &&
              isPbaSScoreValid(d[(f + 'Frequency') as keyof IPBAS])
            ? 'N/A'
            : '-';
      const description: TDescriptionTable = [
        {
          title: `${fm(`behaviour.labels.pbaS.${f}Severity`)}: `,
          values:
            d[(f + 'Severity') as keyof IPBAS] || d[(f + 'Severity') as keyof IPBAS] === 0
              ? d[(f + 'Severity') as keyof IPBAS]?.toString()
              : '-',
        },
        {
          title: `${fm(`behaviour.labels.pbaS.${f}Frequency`)}: `,
          values:
            d[(f + 'Frequency') as keyof IPBAS] || d[(f + 'Frequency') as keyof IPBAS] === 0
              ? d[(f + 'Frequency') as keyof IPBAS]?.toString()
              : '-',
        },
        {
          title: `${fm(`behaviour.labels.pbaS.${f}Worst`)}: `,
          values:
            d[(f + 'Worst') as keyof IPBAS] || d[(f + 'Worst') as keyof IPBAS] === 0
              ? d[(f + 'Worst') as keyof IPBAS]?.toString()
              : '-',
        },
      ];
      d.date &&
        dataPoints.push({
          date: dateFromPartialUpdateTimeframe(d.date),
          id: f,
          value: fm('behaviour.labels.pbaS.' + f),
          additionalValue: additionalValue,
          description: description,
          title: additionalValue,
        });
    });
  });
  return [{ dataPoints, id: 'pbaS', legend: 'PBA-s', type: 'heatGraph' }];
};
