/* eslint-disable no-console */

import type { SerializedError } from '@reduxjs/toolkit';
import { fetchWithOptions } from './fetch';
import { parseJWTFromCookie, getJWTData } from './jwtAuthTools';

const postLog = (errorObject: {
  level: 'Info' | 'Warning' | 'Error';
  error: Error | SerializedError;
  other: TAnyObject;
}): void => {
  let body: string;
  if (errorObject.error instanceof Error) {
    body = JSON.stringify({
      level: errorObject.level,
      name: errorObject.error.name,
      message: errorObject.error.message,
      other: errorObject.other,
      // stack: errorObject.error.stack,
      cause: (<Error>errorObject.error.cause)?.message,
    });
  } else {
    body = JSON.stringify(errorObject);
  }

  fetchWithOptions('/api/log/v1/error', { neurojwt: parseJWTFromCookie() }, { body }).catch((e) => {
    console.error('Unexpected error sending exception data to server', e);
  });
};

const isDev = process.env.NODE_ENV === 'development';

/**
 * Send errors/warnings to logging service and print them in console
 * @param  {string} level - Error level (info/warning/error/custom)
 * @param  {Error} error - Error object
 * @param  {any|undefined} other - Other data
 * @returns void
 */
export const makeLog = (level: 'Info' | 'Warning' | 'Error', error: Error | SerializedError, other?: any): void => {
  const timestamp = new Date().toISOString();
  const location = window.location.href;

  const { orgid, useruuid, userfirstnames, userlastnames } = getJWTData() || {
    orgid: '',
    useruuid: '',
    userfirstnames: '',
    userlastnames: '',
  };
  const user = { orgid, useruuid, userfirstnames, userlastnames };

  switch (level) {
    case 'Info':
      !isDev &&
        console.info({
          error,
          other: { location, ...other },
        });
      !isDev && postLog({ level, error, other: { timestamp, location, user, ...other } });
      break;

    case 'Warning':
      console.warn({
        error,
        other: { location, ...other },
      });

      !isDev && postLog({ level, error, other: { timestamp, location, user, ...other } });
      break;

    case 'Error':
      console.error({
        error,
        other: { location, ...other },
      });

      !isDev && postLog({ level, error, other: { timestamp, location, user, ...other } });
      break;

    default:
      console.log({
        level,
        error,
        other: { location, ...other },
      });
  }
};
