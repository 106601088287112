export const steps: Array<keyof IMGC> = [
  'ptosis',
  'doubleVision',
  'eyeClosure',
  'talking',
  'chewing',
  'swallowing',
  'breathing',
  'neckFlexion',
  'shoulderAbduction',
  'hipFlexion',
];

export const getStepsLocalesGetter = (fm: (s: string) => string) => (step: string | number) =>
  fm('functionalPerformanceMgravis.labels.mgc.' + step);

export const getStepContent =
  (fm: (s: string) => string) =>
  (
    stepName: string,
  ): {
    opts: Array<number | string>;
    optsLocale: (o: string | number, i?: number) => string;
    info?: string;
    assets?: number[];
    height?: number;
    instruction?: JSX.Element;
    treatValuesAsNumbers?: true;
  } => {
    switch (stepName) {
      case 'ptosis': {
        return {
          opts: [0, 1, 2, 3],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.ptosis.' + o),
        };
      }
      case 'doubleVision': {
        return {
          opts: [0, 1, 3, 4],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.doubleVision.' + o),
        };
      }
      case 'eyeClosure': {
        return {
          opts: [0, '0', 1, 2],
          optsLocale: (o: string | number, i) => {
            if (i === 1) {
              return fm('functionalPerformanceMgravis.opts.mgc.eyeClosure.02');
            }
            return fm('functionalPerformanceMgravis.opts.mgc.eyeClosure.' + o);
          },
          treatValuesAsNumbers: true,
        };
      }
      case 'talking': {
        return {
          opts: [0, 2, 4, 6],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.talking.' + o),
        };
      }
      case 'chewing': {
        return {
          opts: [0, 2, 4, 6],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.chewing.' + o),
        };
      }
      case 'swallowing': {
        return {
          opts: [0, 2, 5, 6],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.swallowing.' + o),
        };
      }
      case 'breathing': {
        return {
          opts: [0, 2, 4, 9],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.breathing.' + o),
        };
      }
      case 'neckFlexion': {
        return {
          opts: [0, 1, 3, 4],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.neckFlexion.' + o),
        };
      }
      case 'shoulderAbduction': {
        return {
          opts: [0, 2, 4, 5],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.shoulderAbduction.' + o),
        };
      }
      case 'hipFlexion': {
        return {
          opts: [0, 2, 4, 5],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.hipFlexion.' + o),
        };
      }
      default: {
        return {
          opts: [0, 1, 2, 3],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgc.' + o),
        };
      }
    }
  };
