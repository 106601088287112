import { dmdClinicalArchiveTypes } from './dmd';
import { epilepsyClinicalArchiveTypes } from './epilepsy';
import { parkinsonClinicalArchiveTypes } from './parkinson';
import { sleepApneaClinicalArchiveTypes } from './sleepApnea';
import { smaClinicalArchiveTypes } from './sma';

/** All clinical archive document types supported by Neuro. */
export type ClinicalArchive = 'NEU' | 'HOI' | 'LNEU' | 'TOI' | 'FYST' | 'KEU';

/** Platforms mapped to their respective clinical archive doc types. */
export const PlatformToArchiveMap: { [key in Platform]: readonly ClinicalArchive[] } = {
  sma: smaClinicalArchiveTypes,
  dmd: dmdClinicalArchiveTypes,
  epilepsy: epilepsyClinicalArchiveTypes,
  huntington: [],
  ms: [],
  parkinson: parkinsonClinicalArchiveTypes,
  sleepApnea: sleepApneaClinicalArchiveTypes,
  ninmt: [],
  mgravis: [],
} as const;

/** All platforms in Neuro. */
export const platforms: Readonly<Array<Platform>> = [
  'sma',
  'dmd',
  'parkinson',
  'ms',
  'huntington',
  'epilepsy',
  'sleepApnea',
  'ninmt',
  'mgravis',
];
