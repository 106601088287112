import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import ActionButton, { IActionButton } from '../ActionButton';
import { WritePermissionContainer } from 'Containers/ReadWritePermissions';
import ConfirmationDialog from 'Components/ConfirmationDialog';

const ConditionalWritePermissionContainer = ({
  condition,
  document,
  buttonProps,
  children,
}: {
  condition: boolean;
  document?: IControlProps;
  buttonProps: React.ComponentProps<typeof ActionButton>;
  children: JSX.Element;
}): JSX.Element =>
  condition ? (
    <WritePermissionContainer
      document={document}
      disabled={buttonProps.disabled}
      disabledTooltip={buttonProps.disabledTooltip}
    >
      {children}
    </WritePermissionContainer>
  ) : (
    children
  );

const DocumentCreationButton = ({
  name,
  document,
  newStyle,
  creationConfirmationText,
  creationConfirmationButtons,
  enabled,
  ...buttonProps
}: IDocumentCreationButton): JSX.Element => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState<boolean>(false);

  const isCreating = useSelector((s: IState) => s.documents.isCreating);

  const loading = document ? isCreating?.id === document._id : isCreating?.name === name && isCreating?.id === 'noid';

  return (
    <>
      <ConditionalWritePermissionContainer condition={!enabled} document={document} buttonProps={buttonProps}>
        {newStyle ? (
          <ActionButtonRounded
            loading={loading}
            {...{
              ...buttonProps,
              onClick: creationConfirmationText ? () => setConfirmationDialogOpen(true) : buttonProps.onClick,
            }}
          />
        ) : (
          <ActionButton
            loading={loading}
            {...{
              ...buttonProps,
              onClick: creationConfirmationText ? () => setConfirmationDialogOpen(true) : buttonProps.onClick,
            }}
          />
        )}
      </ConditionalWritePermissionContainer>
      {creationConfirmationText && confirmationDialogOpen && (
        <ConfirmationDialog
          open={confirmationDialogOpen}
          text={creationConfirmationText}
          cancel={{
            callback: creationConfirmationButtons?.cancel?.callback?.() ?? (() => setConfirmationDialogOpen(false)),
            text: creationConfirmationButtons?.cancel?.text,
          }}
          confirm={{
            callback: creationConfirmationButtons?.confirm?.callback ?? buttonProps.onClick ?? null,
            text: creationConfirmationButtons?.confirm?.text,
            loading: loading,
          }}
        />
      )}
    </>
  );
};

interface IDocumentCreationButton extends IActionButton, IActionButtonRounded {
  name: string;
  document?: IControlProps;
  newStyle?: boolean;
  creationConfirmationText?: JSX.Element | string;
  creationConfirmationButtons?: {
    cancel?: { callback?: () => void; text?: JSX.Element | string };
    confirm?: { callback?: (() => void) | ((e: React.MouseEvent<Element, MouseEvent>) => void) | null; text?: string };
  };
  enabled?: boolean;
}

export default DocumentCreationButton;
