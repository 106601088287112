import * as React from 'react';
import { fm } from 'Components/FormatMessage';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { getLatestDiscontinuation, getLatestGenerator } from '../utils';
import { generatorsWithTwoConnectors } from '../Document/config';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const VnsDash = ({ documents }: IOwnProps): JSX.Element => {
  const d = documents?.[0];
  const generator = getLatestGenerator(d) ? getLatestGenerator(d)?.generator ?? '-' : '-';

  return (
    d && (
      <TileContentMaker
        type="bigHeaderInfo"
        bigHeaderText={
          <div style={{ display: 'grid' }}>
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {generator}
              {generatorsWithTwoConnectors.includes(generator) && <span> {fm('vns.generatorTwoConnectors')}</span>}
            </div>
          </div>
        }
        bigHeaderTextSubheader={fm('vns.generator')}
        data={[
          {
            title: fm('vns.started'),
            value: isPartialDate(d.date) ? formatPartialDate(d.date) : null,
            key: 'startDate',
          },
          {
            title: fm('vns.status'),
            value: d.status ? fm('vns.opts.status.' + d.status) : undefined,
            key: 'status',
          },
          ...(d.status === 'inProgress'
            ? [
                ...(getLatestGenerator(d)
                  ? [
                      {
                        title: fm('vns.generatorChanged'),
                        value: isPartialDate(getLatestGenerator(d)?.date)
                          ? formatPartialDate(getLatestGenerator(d)?.date)
                          : null,
                        key: 'generatorChanged',
                      },
                    ]
                  : []),
                ...(getLatestGenerator(d) && getLatestGenerator(d)?.generatorNextChangeDate
                  ? [
                      {
                        title: fm('vns.generatorNextChangeDate'),
                        value: isPartialDate(getLatestGenerator(d)?.generatorNextChangeDate)
                          ? formatPartialDate(getLatestGenerator(d)?.generatorNextChangeDate)
                          : null,
                        key: 'generatorNextChangeDate',
                      },
                    ]
                  : []),
              ]
            : d.status === 'suspended'
              ? [
                  ...(Array.isArray(d.discontinuations) && getLatestDiscontinuation(d.discontinuations)
                    ? [
                        {
                          title: fm('vns.discontinuationDate'),
                          value: isPartialDate(getLatestDiscontinuation(d.discontinuations)?.date)
                            ? formatPartialDate(getLatestDiscontinuation(d.discontinuations)?.date)
                            : null,
                          key: 'discontinuationDate',
                        },
                      ]
                    : []),
                  ...(Array.isArray(d.discontinuations) &&
                  getLatestDiscontinuation(d.discontinuations) &&
                  getLatestDiscontinuation(d.discontinuations)?.reason
                    ? [
                        {
                          title: fm('vns.discontinuationReason'),
                          value: fm(
                            `vns.opts.discontinuationReason.${getLatestDiscontinuation(d.discontinuations)?.reason}`,
                          ),
                          key: 'discontinuationReasonShort',
                        },
                      ]
                    : []),
                ]
              : d.status === 'ended'
                ? [
                    ...(d.endReason
                      ? [
                          {
                            title: fm('vns.endReason'),
                            value: d.endReason ? fm(`vns.opts.endReason.${d.endReason}`) : null,
                            key: 'endReason',
                          },
                        ]
                      : []),
                    ...(d.removed === 'yes'
                      ? [
                          {
                            title: fm('vns.removalDate'),
                            value: isPartialDate(d.removalDate) ? formatPartialDate(d.removalDate) : null,
                            key: 'removalDate',
                          },
                          {
                            title: fm('vns.removalType'),
                            value: d.removalType ? fm(`vns.opts.removalType.${d.removalType}`) : null,
                            key: 'removalType',
                          },
                        ]
                      : []),
                  ]
                : []),
        ]}
      />
    )
  );
};

interface IOwnProps {
  documents: IVNS[];
}

export default VnsDash;
