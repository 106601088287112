import { fetchWithOptions } from 'Utility/fetch';
import { getJWTData, parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { makeLog } from 'Utility/logger';

export const search = async (searchString: string, wildcard: string): Promise<TNeuroPersonData[] | null> => {
  const orgid = getJWTData()?.orgid;
  if (!orgid) return null;
  return fetchWithOptions(
    `/api/patient/v2/search/${orgid}?search=${searchString}&wildcard=${wildcard}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then(
      (res: Response) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw { status: res.status, fullResponse: res };
        }
      },
      (error: Error) => {
        throw error;
      },
    )
    .catch((err: Error) => {
      makeLog('Error', err);
      return null;
    });
};

export const extendedSearch = async (searchString: string): Promise<IExtendedSearchResults[] | null> => {
  const orgid = getJWTData()?.orgid;
  if (!orgid) return null;
  return fetchWithOptions(
    `/api/advancedsearch/query/${orgid}?` + searchString,
    {
      neurojwt: parseJWTFromCookie(document.cookie),
    },
    { method: 'GET' },
  )
    .then(
      (res: Response) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw { status: res.status, fullResponse: res };
        }
      },
      (error: Error) => {
        throw error;
      },
    )
    .catch((err: Error) => {
      makeLog('Error', err);
      return null;
    });
};
