import { nowPartialDate, partialDateToValue } from 'neuro-utils';

export const otherSymptoms: { [key: string]: string[] } = {
  mentalSymptoms: ['anxiety', 'moodSymptoms', 'delusions', 'suicidalThoughts', 'obsessions', 'other'],
  cognitiveSymptoms: [
    'recallDegradation',
    'learningDisabilities',
    'skillLossOrDegradation',
    'difficultyConcentrating',
    'socialInteractionProblems',
    'other',
  ],
  developmentDeceleration: ['motoric', 'linguistic', 'wide', 'other'],
  neurologicalSymptoms: ['vertigo', 'headache', 'visualImpairment', 'tremor', 'other'],
  behavioralDisorders: ['aggressiveness', 'impulsiveness', 'hyperactivity', 'defiance', 'other'],
  eatingProblems: ['lackOfAppetite', 'increaseOfAppetite', 'swallowingProblems', 'other'],
  otherSymptoms: ['difficultySleeping', 'fatigue', 'other'],
};

// Find out if the other symptom has disappeared (has an endDate which is not in the future)
export const isEnded = (endDate?: PartialDate): boolean => {
  if (endDate?.[0]) {
    const nowDate = partialDateToValue(nowPartialDate());
    const endedDate = partialDateToValue(endDate);
    if (endedDate < nowDate) return true;
    else return false;
  } else return false;
};
