import { isPartialDate, formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const NutritionDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  /* Show two latest nutritions if exists. */

  const { formatMessage } = useIntl();

  return documents?.[0]?.feedingTubes ? (
    <TileContentMaker
      type="recentListMultipleRows"
      itemsCanFitAmount={2}
      data={documents[0].feedingTubes.map((f) => ({
        date: isPartialDate(f.startDate) ? formatPartialDate(f.startDate) : '',
        title: (
          <div>
            <div style={{ fontSize: '1.6rem', fontWeight: 600 }}>
              {f?.feedingTubeType ? formatMessage({ id: `nutrition.opts.${f?.feedingTubeType}` }) : ''}
            </div>
            <div>{f?.feedingTubePractice ? formatMessage({ id: `nutrition.opts.${f?.feedingTubePractice}` }) : ''}</div>
          </div>
        ),
        endDate: isPartialDate(f.endDate) ? formatPartialDate(f.endDate) : null,
        key: f.id,
      }))}
    />
  ) : (
    <></>
  );
};

interface IOwnProps {
  documents: INutrition[];
}

export default NutritionDash;
