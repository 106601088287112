import { getSSNAgeInMonths, getSSNAgeInWholeMonths, validateSsn } from 'neuro-utils';
import { getJWTData } from './jwtAuthTools';

/**
 * Return the SSN of the current patient from JWT
 * @return SSN or empty string if missing from JWT
 */
const getPatientSSN = (): string => {
  const ssn = getJWTData()?.patientssn;
  if (!ssn || !validateSsn(ssn)) {
    return '';
  }
  return ssn;
};

/**
 * Calculates the age of the patient in whole months at a given day
 * @param atThisDate Age calculated atThisDate, if omitted at current day
 * @return Age as number of months
 */
export const getPatientAgeInMonths = (atThisDate?: PartialDate): number => {
  return getSSNAgeInWholeMonths(getPatientSSN(), atThisDate);
};

/**
 * Calculates the age of the patient in months with the precision of one decimal
 * @param atThisDate Age calculated atThisDate, if omitted at current day
 * @return String containing Age as number of months
 */
export const getPatientAgeInMonthsWithOneDecimal = (atThisDate?: PartialDate): string => {
  return getSSNAgeInMonths(getPatientSSN(), atThisDate).toFixed(1);
};
