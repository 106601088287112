import * as React from 'react';
import { Container, Item } from '../Grid';

import QuestionItem from './OptionItem';

const optionOnClick = (name: string, value: number | string, onChange?: IFormData['onChange']) => (): void => {
  if (onChange) {
    onChange({ [name]: value });
  }
};

const OptionChooser = ({
  formData,
  name,
  opts,
  optionFormatter,
  numberFormatter,
  viewing = false,
  instructionFormatter,
  isSecondaryOption,
  allInstructionsOpenAtOnce,
  horizontal,
  minHeight,
  qNumberHidden,
}: IOptionChooser): JSX.Element => {
  const [instructionsOpenIndex, setInstructionsOpenIndex] = React.useState<number | undefined>(undefined);
  const handleInstructionsOpenClick = (state: number | undefined): void => {
    setInstructionsOpenIndex(state === instructionsOpenIndex ? undefined : state);
  };
  let longestOptLength = 0;
  opts.forEach((opt) => {
    if (typeof opt === 'string' && opt.length > longestOptLength) longestOptLength = opt.length;
    if (typeof opt === 'number' && opt.toString().length > longestOptLength) longestOptLength = opt.toString().length;
  });

  return (
    <Container>
      {opts.map((o: string | number, i: number) => (
        <Item xs={horizontal ? 12 / opts.length : 12} key={o}>
          <QuestionItem
            qNumber={numberFormatter ? numberFormatter(o) : (o as number)}
            text={optionFormatter(o)}
            onClick={!viewing ? optionOnClick(name, o, formData.onChange) : undefined}
            selected={formData.document[name] === o}
            viewing={viewing}
            index={i}
            instructionsOpenIndex={instructionsOpenIndex}
            handleInstructionsOpenClick={handleInstructionsOpenClick}
            instruction={instructionFormatter ? instructionFormatter(o) : undefined}
            secondary={isSecondaryOption ? isSecondaryOption(o) : false}
            allInstructionsOpenAtOnce={allInstructionsOpenAtOnce ?? false}
            longestValueLength={longestOptLength}
            horizontal={horizontal}
            minHeight={minHeight}
            qNumberHidden={qNumberHidden}
          />
        </Item>
      ))}
    </Container>
  );
};

interface IOptionChooser {
  formData: IFormData<any>;
  name: string;
  opts: Array<string | number>;
  optionFormatter: (name: string | number) => JSX.Element;
  numberFormatter?: (name: string | number) => number;
  instructionFormatter?: (value: string | number) => JSX.Element | string | undefined;
  viewing?: boolean;
  isSecondaryOption?: (opt: string | number) => boolean; // Function that decides if an option should be "secondary"
  allInstructionsOpenAtOnce?: boolean;
  horizontal?: boolean;
  /** Minimum height for option cards (when horizontal) */
  minHeight?: number;
  /** If true, hides the question number circle */
  qNumberHidden?: boolean;
}

export default OptionChooser;
