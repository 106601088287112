import { fetchWithOptions } from '../../utility/fetch';
import { getJWTData, parseJWTFromCookie } from '../../utility/jwtAuthTools';
import { makeLog } from '../../utility/logger';

/**
 * Fetch appointments for a patient per organization
 * @returns {IAppointment[]|void} Appointments or void
 */
export const fetchAppointments = async (): Promise<IAppointment[] | null> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid) return null;
  return fetchWithOptions(
    `/api/appointment/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .then((apps: IAppointment[]) => {
      return apps;
    })
    .catch((res) => {
      makeLog('Error', { name: 'Get appointments', message: 'Get failed' }, res);
      return null;
    });
};

/**
 * Create new appointment for a patient per organization
 * @param {IAppointment} data - Appointment data
 * @returns {Promise<IAppointment|null>} Appointment or null
 */
export const postAppointment = async (data: IAppointment): Promise<IAppointment | null> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid) return null;
  return await fetchWithOptions(
    `/api/appointment/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(data) },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .then((data: IAppointment) => {
      return data;
    })
    .catch((res) => {
      makeLog('Error', { name: 'Post appointment', message: 'Post failed' }, res);
      return null;
    });
};

/**
 * Change appointment data
 * @param {IAppointment} data - Appointment data
 * @returns {Promise<IAppointment|null>} Appointment or null
 */
export const patchAppointment = async (data: IAppointment): Promise<IAppointment | null> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid || !data.id) return null;
  return await fetchWithOptions(
    `/api/appointment/${orgid}/${patientid}/${data.id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PATCH', body: JSON.stringify(data) },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .then((data: IAppointment) => {
      return data;
    })
    .catch((res) => {
      makeLog('Error', { name: 'Patch appointment', message: 'Patch failed' }, res);
      return null;
    });
};

/**
 * Delete appointment data
 * @param {string} id - Appointment id
 * @returns {Promise<boolean>} Removed or not
 */
export const deleteAppointment = async (id: string): Promise<boolean> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid || !id) return false;
  return await fetchWithOptions(
    `/api/appointment/${orgid}/${patientid}/${id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'DELETE' },
  )
    .then((res) => {
      if (res.status === 200) {
        return true;
      } else throw res;
    })
    .catch((res) => {
      makeLog('Error', { name: 'Delete appointment', message: 'Delete failed' }, res);
      return false;
    });
};
