import * as React from 'react';
import { IGroupACLMapping } from '../../config/definitions/accessControl';
import { mapGroupsToCapabilities } from '../../config/groupMapping';

const CapabilityContext = React.createContext<ICapabilityContextProps | null>(null);

const CapabilityProvider = CapabilityContext.Provider;
const CapabilityConsumer = CapabilityContext.Consumer;

const CapabilityHandler = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [value, setValue] = React.useState<ICapabilityContextProps | null>(null);

  React.useEffect(() => {
    mapGroupsToCapabilities()
      .then((p) => setValue({ capabilityGroups: p || {} }))
      .catch(() => {
        return;
      });
  }, []);

  return <CapabilityProvider value={value}>{children}</CapabilityProvider>;
};

export const withCapabilities = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props: P): JSX.Element => (
    <CapabilityConsumer>
      {(context: ICapabilityContextProps | null): JSX.Element => (
        <Component capabilityGroups={context?.capabilityGroups ?? undefined} {...props} />
      )}
    </CapabilityConsumer>
  );
};

export interface ICapabilityContextProps {
  capabilityGroups?: IGroupACLMapping;
}

export default CapabilityHandler;
