import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { fetchWithOptions } from 'Utility/fetch';
import { getJWTData, parseJWTFromCookie } from 'Utility/jwtAuthTools';

// Sort docs latest --> oldest
const sortDocs = (documents: Array<IDoctorsOrder>): Array<IDoctorsOrder> =>
  documents.sort((s1, s2) => sortPartialDate(s2.date, s1.date));

/**
 * Find previous doc with (backup) subject(s) of treatment
 * @param {Array<IDoctorsOrder>} documents - All doctorsOrders documents
 * @param {'rtms' | 'tdcs'} treatmentType - Treatment type to look for
 * @param {'subjectOfTreatment' | 'backupSubjectOfTreatment'} treatmentName - Treatment target to look for
 * @returns {IDoctorsOrder | undefined} - Previous doc with (backup) subject(s) of treatment if found, undefined if not
 */
export const getPreviousDocWithTreatments = (
  documents: Array<IDoctorsOrder>,
  treatmentType: 'rtms' | 'tdcs',
  treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment',
): IDoctorsOrder | undefined => {
  const docs = sortDocs(documents).filter((d) => !d._editing);
  if (docs.length === 0) return undefined;

  const previousDoc = docs.find(
    (d) =>
      d &&
      d[treatmentType] &&
      Array.isArray(d[treatmentType]?.[treatmentName]) &&
      (d[treatmentType]?.[treatmentName]?.length ?? 0) > 0,
  );

  return previousDoc;
};

/**
 * Find and categorize (backup) subjects of treatments from previous Doctor's orders to list them in SelectByCategory component
 * @param {Array<IDoctorsOrder>} documents - All doctorsOrders documents
 * @param {'rtms' | 'tdcs'} treatmentType - Type of treatment, 'rtms' or 'tdcs'
 * @param {'subjectOfTreatment' | 'backupSubjectOfTreatment' | 'both'} treatmentName - Treatments to include in the search
 * @param {Array<string>} previousSubjectsOfTreatmentsIdsInUse - Ids of subjects of treatments in use that have already been copied (exact match of all fields except id) to be filtered out
 * @param {(m: string) => string} fm - Message formatting function for localizing and displaying category titles correctly
 * @returns {Array<{ title: string; values: string[] }>} - Treatments ids in a categorized format supported by SelectByCategory (title-values pairs)
 */
export const findAndCategorizePreviousSubjectsOfTreatmentsIds = (
  documents: Array<IDoctorsOrder>,
  treatmentType: 'rtms' | 'tdcs',
  treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment' | 'both',
  previousSubjectsOfTreatmentIdsInUse: Array<string>,
  fm: (m: string) => string,
): Array<{ title: string; values: string[] }> => {
  const previousDocWithSOT = getPreviousDocWithTreatments(documents, treatmentType, 'subjectOfTreatment');
  const previousDocWithBSOT = getPreviousDocWithTreatments(documents, treatmentType, 'backupSubjectOfTreatment');

  let previousTreatmentsIds: Array<string> = [];
  let previousBackupTreatmentsIds: Array<string> = [];

  if (previousDocWithSOT && ['subjectOfTreatment', 'both'].includes(treatmentName)) {
    previousTreatmentsIds = previousDocWithSOT[treatmentType]?.subjectOfTreatment?.map((t) => t.id) as string[];
    if (treatmentName === 'both' && Array.isArray(previousDocWithSOT[treatmentType]?.backupSubjectOfTreatment)) {
      previousBackupTreatmentsIds = previousDocWithSOT[treatmentType]?.backupSubjectOfTreatment?.map(
        (t) => t.id,
      ) as string[];
    }
  }
  if (previousDocWithBSOT && treatmentName === 'backupSubjectOfTreatment') {
    previousBackupTreatmentsIds = previousDocWithBSOT[treatmentType]?.backupSubjectOfTreatment?.map(
      (t) => t.id,
    ) as string[];
  }

  const previousTreatmentIdsByCategory: Array<{ title: string; values: string[] }> = [];

  if (previousTreatmentsIds.length > 0)
    previousTreatmentIdsByCategory.push({
      title: `${fm(`doctorsOrders.${treatmentType}.subjectOfTreatment.titleShort`)} - ${formatPartialDate(
        previousDocWithSOT?.date,
      )}`,
      values: previousTreatmentsIds.filter((id) => !previousSubjectsOfTreatmentIdsInUse.includes(id)),
    });
  if (previousBackupTreatmentsIds.length > 0)
    previousTreatmentIdsByCategory.push({
      title: `${fm(`doctorsOrders.${treatmentType}.backupSubjectOfTreatment.titleShort`)} - ${formatPartialDate(
        previousDocWithBSOT?.date,
      )}`,
      values: previousBackupTreatmentsIds.filter((id) => !previousSubjectsOfTreatmentIdsInUse.includes(id)),
    });
  return previousTreatmentIdsByCategory;
};

/**
 * Fetch current/active organization's list of users
 * @returns {Promise<any>} - Promise with user data or promise with empty string if res status !== 200
 */
export const getOrganizationUserData = async (): Promise<any> => {
  const orgId = getJWTData()?.orgid;

  const res = await fetchWithOptions(
    `api/vault/v2/userless/${orgId}`,
    { neurojwt: parseJWTFromCookie(), requestorigin: 'neuro-ui' },
    { method: 'GET' },
  );
  if (res.status === 200) return res.json();
  else return '';
};

/**
 * Match user id with user names and return the names as string
 * @param {string} id - User id
 * @param {Array<{ [key: string]: string | boolean }>} data - User data array
 * @returns {string} - User names as string if found, '-' if not
 */
export const findUserNames = (id: string, data?: Array<{ [key: string]: string | boolean }>): string => {
  let names = '-';
  if (data) {
    const user = data.find((user) => user.userId === id);
    if (user) names = `${user.firstNames} ${user.lastNames}`;
  }
  return names;
};
