import { sortDate } from 'neuro-utils';
import { IData, IGraphData } from '../interfaces';

/**
 * Function for checking if we want to draw a graph menu
 * Currently checks only the "graphMenusToBeHiddenIfEmpty" list of graphsettings
 * @param data graphData object for which we want to check if it is wanted to be hidden
 * @param key key of the graphData object
 * @param graphMenusToBeHiddenIfEmpty list of keys to be hidden if they are empty
 * @returns true or false
 */
const shouldGraphMenuBeDrawn = (data: IGraphData, key: string, graphMenusToBeHiddenIfEmpty: string[]): boolean => {
  if (data.data.every((d) => 'data' in d)) {
    if (
      graphMenusToBeHiddenIfEmpty.includes(key) &&
      (data.data.length === 0 ||
        !(data.data as Array<{ data: IData[]; id: string }>).map((d) =>
          d.data.find((dd) => dd.dataPoints && dd.dataPoints.length > 0),
        ))
    )
      return false;
  } else if (
    graphMenusToBeHiddenIfEmpty.includes(key) &&
    (!data.data ||
      data.data.length === 0 ||
      !(data.data as IData[]).find((d) => d.dataPoints && d.dataPoints.length > 0))
  )
    return false;
  return true;
};

/**
 * Function for checking if dates received as argument are equal or not
 * @param d1 date 1
 * @param d2 date 2
 * @returns true if dates are equal (year, month and date equal)
 */
const areDatesEqual = (d1: Date, d2: Date): boolean => {
  if (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()) {
    return true;
  }
  return false;
};

/**
 * Function for checking if timeframes given as argument are equal or not
 * @param tf1 first timeframe
 * @param tf2 second timeframe
 * @returns true if timeframes are equal (year, month and date equal)
 */
const areTimeframesEqual = (tf1: [Date, Date], tf2: [Date, Date]): boolean => {
  tf1.sort((d1, d2) => sortDate(d1, d2));
  tf2.sort((d1, d2) => sortDate(d1, d2));
  if (areDatesEqual(tf1[0], tf2[0]) && areDatesEqual(tf1[1], tf2[1])) return true;
  return false;
};

/**
 * Function for checking if graph menu can be selected
 * @param graphData graphData object of the menu we want to check
 * @returns true or false
 */
const isDisabledGraphMenu = (graphData: IGraphData | undefined): boolean => {
  if (!graphData?.data.length) return true;
  if (graphData.data.every((data) => 'data' in data)) {
    if (
      !(graphData.data as Array<{ data: IData[]; id: string }>).some((data) =>
        data.data.find((d) => d.dataPoints.length > 0),
      )
    )
      return true;
  } else if (!((graphData?.data || []) as IData[]).find((d) => d.dataPoints.length > 0)) return true;
  return false;
};

/**
 * Function for checking whether a graphmenu is pinnable or not
 * @param referenceGraphsBelowEachOther Boolean telling if ref graphs are drawn below each other or just on the main graph. If true, all the graph menus are pinnable
 * @param graphData GraphData for which we want to check if it is pinnable or not
 * @param selectedGraphData graphData that is currently selected
 * @returns true or false
 */
const isPinnableGraphMenu = (
  referenceGraphsBelowEachOther: boolean,
  graphData: IGraphData | undefined,
  selectedGraphData: IGraphData | undefined,
): boolean => {
  // If we draw the graphs below each other, every graph is pinnable
  if (referenceGraphsBelowEachOther) return true;
  // If graph with tabs, it is not pinnable
  if (graphData?.data.every((d) => 'data' in d)) return false;
  // If we find heatgraph, it is not pinnable
  // TODO: Single graph types should be listed somewhere and they should be checked here instead of just heatgraph
  if ((graphData?.data || []).find((d) => 'type' in d && d.type === 'heatGraph')) return false;
  if ((selectedGraphData?.data || []).find((d) => 'type' in d && d.type === 'heatGraph')) return false;
  // If we find graph with more than one scale, the graph is not pinnable
  if (graphData?.additionalScale) return false;
  if (selectedGraphData?.additionalScale) return false;
  return true;
};

export { shouldGraphMenuBeDrawn, areDatesEqual, areTimeframesEqual, isDisabledGraphMenu, isPinnableGraphMenu };
