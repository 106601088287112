import { sortPartialDate } from 'neuro-utils';

export const sortAndTakeLatest = (type: string[], documents?: TDocument[]): TDocument[] => {
  if (documents) {
    return documents
      .sort((n1, n2) => n1._cdate - n2._cdate)
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()
      .filter((d) => type.some((t) => d._type === t), documents)
      .slice(0, 6);
  } else return [];
};

export const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing ? documents.find((d: TDocument) => d._id === editing)?._type || undefined : undefined;

export const filterDocs = (type: string, documents: TDocument[]): TDocument[] =>
  documents ? documents.filter((d) => d._type === type) : [];

export type TDocument =
  | IFVC
  | INocturnalPolygraphy
  | IENMG
  | IVEP
  | ISEP
  | IBAEP
  | IOCTSCAN
  | IECG
  | IEchocardiography
  | IBloodPressure
  | IHeartRate;
