import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector as useSelector } from 'Store/index';
import { filterNotSeizureDocs } from '../utils';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { ISpecificNoDateData } from 'Components/DashboardTile/TileContentMaker/components/SpecificListNoDate';

const SeizuresDash = (props: IDashProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const { documents } = props;

  const sortedAndMergedDocs = useSelector((state: IState) => state.myapp.sortedAndMergedDocuments);
  const seizureDocs: Array<ISeizure & IControlProps> = documents.filter((d) => d?._type === 'seizure');
  const patientReportedSeizureDocs: Array<IPatientReportedSeizure & IControlProps> =
    sortedAndMergedDocs?.filter((d) => d._type === 'patientReportedSeizure') || [];

  const unhandledPatRepSeizDocs = patientReportedSeizureDocs.filter((d) => !d?.status);
  const unhandledPatRepSeizAmount = unhandledPatRepSeizDocs.length;
  const latestUnhandledPatRepSeiz = unhandledPatRepSeizDocs.sort((a, b) =>
    sortPartialDate(b.startDate, a.startDate),
  )[0];

  const acceptedPatRepSeizDocs = patientReportedSeizureDocs.filter((d) => d?.status === 'accepted');

  const allSeizureDocsSorted = [...filterNotSeizureDocs(seizureDocs), ...acceptedPatRepSeizDocs].sort((a, b) =>
    sortPartialDate(
      'date' in b ? b.date : 'startDate' in b ? b.startDate : undefined,
      'date' in a ? a.date : 'startDate' in a ? a.startDate : undefined,
    ),
  );

  // latest seizure free period
  const seizureFreePeriods = documents.filter((d) => d._type === 'seizureFreePeriod') as ISeizureFreePeriod[];
  if (seizureFreePeriods.every((d) => d.startDate))
    seizureFreePeriods.sort((a, b) => sortPartialDate(b.startDate, a.startDate))[0];
  const latestPeriod = seizureFreePeriods.length > 0 ? seizureFreePeriods[0] : undefined;

  const listData: Array<ISpecificNoDateData> = [
    {
      title: fm('patientReportedSeizure.amountOfUnhandled'),
      value: unhandledPatRepSeizAmount,
      key: 'amountOfUnhandled',
    },
    {
      title: fm('seizure.seizureFreePeriod'),
      value:
        latestPeriod?.startDate || latestPeriod?.endDate ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
            <div style={{ textAlign: 'left' }}>
              <div>{formatPartialDate(latestPeriod?.startDate)} –</div>
              <div>{formatPartialDate(latestPeriod?.endDate)}</div>
            </div>
          </div>
        ) : undefined,
      key: 'latestSeizureFreePeriod',
    },
  ];

  if (unhandledPatRepSeizAmount > 0) {
    listData.unshift({
      title: fm('patientReportedSeizure.latestUnhandled'),
      value: formatPartialDate(latestUnhandledPatRepSeiz?.startDate),
      key: 'latestUnhandled',
    });
  }

  if (allSeizureDocsSorted.length > 0) {
    let date: PartialDate | undefined;
    if ('date' in allSeizureDocsSorted[0]) date = allSeizureDocsSorted[0].date;
    else if ('startDate' in allSeizureDocsSorted[0]) date = allSeizureDocsSorted[0].startDate;

    if (date) {
      listData.unshift({
        title: fm('seizure.latestAccepted'),
        value: formatPartialDate(date),
        key: 'latestAccepted',
      });
    }
  }

  return <TileContentMaker type="specificListNoDate" data={listData} />;
};

interface IDashProps {
  documents: Array<ISeizureDoc>;
}

export default SeizuresDash;
