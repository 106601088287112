import * as React from 'react';
import { styled } from '@mui/system';
import InputHandler from 'Components/InputHandler';
import colors from '../../../../../../config/theme/colors';
import { MyServiceContext } from '../../..';
import { isLocaleKey, ninmtPreInquiry } from 'neuro-schemas';
import {
  painFields,
  painIntenseSymptomFields,
  tinnitusFields,
  tinnitusIntenseSymptomFields,
  otherFields,
  otherIntenseSymptomFields,
  episodeFields,
  painLocations,
  tinnitusLocations,
} from './config';
import { exists } from 'neuro-utils';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import TableContent from '../components/TableContent';
import { capitalize } from 'Utility/string';

const StyledTable = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columns',
})(({ columns = 2 }: { columns?: 2 | 3 }) => ({
  width: columns === 3 ? '90%' : '70%',
}));

const StyledTableRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columns',
})(({ columns = 2 }: { columns?: 2 | 3 }) => ({
  display: 'grid',
  gridTemplateColumns: columns === 3 ? '3fr 2fr 2fr 2fr' : '3fr 2fr 2fr',
  padding: '1.5rem 0 1rem 0',
}));

const StyledTableItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'colSpan',
})(({ colSpan = 1 }: { colSpan?: 1 | 2 }) => ({
  gridColumn: colSpan > 1 ? '2 / span 2' : undefined,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const IntenseSymptomTable = ({
  symptom,
  intenseSymptoms,
  onChange,
  isEditing,
  fm,
  fmNINMTPreInquiry,
}: {
  symptom?: ININMTPreInquirySymptom['symptom'];
  intenseSymptoms?: IIntenseSymptom | IIntenseSymptom[];
  onChange: (e: TOnChangeValues) => void;
  isEditing: boolean;
  fm: (id: string) => string;
  fmNINMTPreInquiry: (id: string) => string;
}): JSX.Element => {
  const symptoms = intenseSymptoms ? (Array.isArray(intenseSymptoms) ? intenseSymptoms : [intenseSymptoms]) : [];

  const fields = {
    pain: painIntenseSymptomFields,
    tinnitus: tinnitusIntenseSymptomFields,
    other: otherIntenseSymptomFields,
  }[symptom ?? 'pain'];

  const locations = {
    pain: painLocations,
    tinnitus: tinnitusLocations,
    other: [],
  }[symptom ?? 'pain'];

  const getFieldValue = (symptom: (typeof symptoms)[0], name: string, subName?: 'min' | 'max') => {
    const value = symptom?.[name as keyof typeof symptom];
    const subValue = value && subName && value[subName as keyof typeof value];
    return exists(subValue) ? subValue : !subName && exists(value) ? value : null;
  };

  const handleFieldChange =
    (i: number, subName?: 'min' | 'max') =>
    (e: TOnChangeValues): void => {
      const name = Object.keys(e)[0];
      let value = e[name];

      const newIntenseSymptoms = JSON.parse(JSON.stringify(symptoms.length === 0 ? [{}] : symptoms));

      if (i > 0 && newIntenseSymptoms.length === 1) newIntenseSymptoms.push({});

      const currentValue = newIntenseSymptoms[i][name];
      if (subName) {
        const otherName = subName === 'min' ? 'max' : 'min';
        value = { [otherName]: currentValue?.[otherName] ?? null, [subName]: value };
      }
      if (Array.isArray(newIntenseSymptoms)) {
        if (i > 0 && value === undefined) {
          newIntenseSymptoms.splice(1, 1);
        } else {
          newIntenseSymptoms[i][name] = value;
        }
      } else newIntenseSymptoms[name] = value;
      onChange({
        currentMostIntenseSymptoms: symptom === 'pain' ? newIntenseSymptoms : newIntenseSymptoms[0],
      });
    };

  return (
    <TableContent
      // Force re-render if symptom changes to reset inputRefs
      key={symptom}
      width={100}
      currentIdStart={symptom !== 'other' ? '00' : '10'}
      titleRow={
        symptom !== 'other'
          ? {
              title: {
                content: fm(`myService.ninmt.current${capitalize(symptom ?? 'pain')}`),
                style: { fontWeight: 'normal' },
              },
              content: [0, 1].map((index: number) => ({
                content: (
                  tabIndex: TTabulationTools['tabIndex'],
                  inputRefs: TTabulationTools['inputRefs'],
                  id: TTabulationTools['identifier'],
                ) => (
                  <React.Fragment key={index}>
                    <div style={{ marginBottom: '1rem' }}>
                      {
                        {
                          0: fm(`myService.ninmt.mostIntense${capitalize(symptom ?? 'pain')}Location`),
                          1: symptom === 'pain' ? fm('myService.ninmt.secondIntensePainLocation') : '',
                        }[index]
                      }
                    </div>
                    {(index === 0 || symptom === 'pain') && (
                      <InputHandler
                        type="Select"
                        name="symptomLocation"
                        editing={isEditing}
                        formData={{
                          document: { symptomLocation: getFieldValue(symptoms[index], 'symptomLocation') },
                          onChange: (e) => handleFieldChange(index)(e),
                        }}
                        options={locations}
                        optionFormatter={(name: string | number) => fmNINMTPreInquiry(`opts.${name}`)}
                        placeholder="myService.ninmt.symptomLocationPlaceholder"
                        disabled={index > 0 && !symptoms[0]}
                        tabulationTools={{
                          tabIndex,
                          inputRefs,
                          identifier: id,
                        }}
                      />
                    )}
                  </React.Fragment>
                ),
              })),
            }
          : undefined
      }
    >
      {[
        ...(fields.map((field: (typeof fields)[0]) => ({
          title: {
            content:
              fmNINMTPreInquiry(`${symptom}.${field.name}`) +
              (!['symptomQuality', 'symptomContinuity'].includes(field.name)
                ? ` ${fm('myService.ninmt.withinAWeek').toLocaleLowerCase()}`
                : ''),
          },
          content: [0, 1].map((i: number) => ({
            content:
              i === 0 || symptom === 'pain' ? (
                (
                  tabIndex: TTabulationTools['tabIndex'],
                  inputRefs: TTabulationTools['inputRefs'],
                  id: TTabulationTools['identifier'],
                ) =>
                  field.type === 'NumberRange' ? (
                    <React.Fragment>
                      <InlineDiv>
                        <InputHandler
                          type="NumberField"
                          name={field.name}
                          editing={isEditing}
                          formData={{
                            document: { [field.name]: getFieldValue(symptoms[i], field.name, 'min') },
                            onChange: (e) => handleFieldChange(i, 'min')(e),
                          }}
                          width={6}
                          placeholder={Array.isArray(field.options) ? String(field.options[0]) : undefined}
                          disablePlaceholderFormatting
                          disabled={!symptoms[i] && symptom !== 'other'}
                          min={0}
                          max={10}
                          precision={1}
                          enableTrailingZero
                          tabulationTools={{
                            tabIndex,
                            inputRefs,
                            identifier: id + 0,
                          }}
                        />
                      </InlineDiv>
                      <InlineDiv style={{ padding: '0 1rem', color: !symptoms[i] ? colors.mediumGray : undefined }}>
                        ...
                      </InlineDiv>
                      <InlineDiv>
                        <InputHandler
                          type="NumberField"
                          name={field.name}
                          editing={isEditing}
                          formData={{
                            document: { [field.name]: getFieldValue(symptoms[i], field.name, 'max') },
                            onChange: (e) => handleFieldChange(i, 'max')(e),
                          }}
                          width={6}
                          placeholder={
                            Array.isArray(field.options) ? String(field.options[field.options.length - 1]) : undefined
                          }
                          disablePlaceholderFormatting
                          disabled={!symptoms[i] && symptom !== 'other'}
                          min={0}
                          max={10}
                          precision={1}
                          enableTrailingZero
                          tabulationTools={{
                            tabIndex,
                            inputRefs,
                            identifier: id + 1,
                          }}
                        />
                      </InlineDiv>
                    </React.Fragment>
                  ) : (
                    <InputHandler
                      type={field.type as any}
                      name={field.name}
                      editing={isEditing}
                      formData={{
                        document: { [field.name]: getFieldValue(symptoms[i], field.name) },
                        onChange: (e) => handleFieldChange(i)(e),
                      }}
                      options={field.options}
                      optionFormatter={
                        !field.name.includes('Average')
                          ? (name: string | number) => fmNINMTPreInquiry(`opts.${name}`)
                          : undefined
                      }
                      // width={field.type === 'NumberField' ? 15 : undefined}
                      placeholder={field.placeholder}
                      disablePlaceholderFormatting={
                        field.placeholder === '0' || field.placeholder === '0-10' ? true : undefined
                      }
                      limit={field.limit}
                      disabled={!symptoms[i] && symptom !== 'other'}
                      multiple={true}
                      min={0}
                      max={10}
                      precision={1}
                      enableTrailingZero
                      tabulationTools={{
                        tabIndex,
                        inputRefs,
                        identifier: id,
                      }}
                    />
                  )
              ) : (
                <></>
              ),
          })),
        })) as []),
        {
          title: {
            content: `0 = ${fm('myService.ninmt.ninmtPreInquiry.fieldMinInfo')} 10 = ${fm(
              'myService.ninmt.ninmtPreInquiry.fieldMaxInfo',
            )} (NRS)`,
            isInfo: true,
          },
          content: [
            {
              content: '',
            },
          ],
        },
      ]}
    </TableContent>
  );
};

const SymptomWindow = ({ parentDocument, document }: ISymptomWindow): JSX.Element => {
  const ninmtPreInquiryLocales = ninmtPreInquiry.localizations;
  const myServiceContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing } = myServiceContext;

  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmNINMTPreInquiry = (id: string) => ninmtPreInquiryLocales[useLocale][id];

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogData, setDeleteDialogData] = React.useState<{ [key: string]: any } | undefined>(undefined);
  const [deleteDialogText, setDeleteDialogText] = React.useState<string>('general.dependentFieldsRemovalWarning');

  const openDeleteDialog = (data: typeof deleteDialogData, text?: string): void => {
    setDeleteDialogOpen(true);
    setDeleteDialogData(data);
    setDeleteDialogText(text ? text : 'general.dependentFieldsRemovalWarning');
  };

  const deleteCancelCallback = (): void => {
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  const deleteConfirmCallback = (): void => {
    deleteDialogData?.data && setEditingData?.(deleteDialogData?.data);
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  const fields = document?.symptom
    ? {
        pain: painFields,
        tinnitus: tinnitusFields,
        other: otherFields,
      }[document?.symptom]
    : [painFields[0]];

  // Fields to nullify if symptom is changed
  const nulledFields = fields
    .map((f) => f.name)
    .concat(['isSymptomEpisodic'])
    .filter((f) => f !== 'symptom')
    .reduce((current, key) => {
      (current as { [key: string]: any })[key] = null;
      return current;
    }, {});

  // Fields to nullify if isSymptomEpisodic is changed from 'yes' to 'no'
  const nulledEpisodesFields = ['averageAmountOfSymptomEpisodes', 'averageDurationOfSymptomEpisodes'].reduce(
    (current, key) => {
      (current as { [key: string]: any })[key] = null;
      return current;
    },
    {},
  );

  const handleFieldChange = (e: TOnChangeValues): void => {
    const name = Object.keys(e)[0];
    const value = e[name];

    const requireNullification = name === 'symptom' && document.symptom;
    const requireEpisodesNullification =
      name === 'isSymptomEpisodic' &&
      value === 'no' &&
      (document.averageAmountOfSymptomEpisodes || document.averageDurationOfSymptomEpisodes);
    const newDocument: TAnyObject = {};

    let newData = { [name]: value };
    // Changing symptom requires all fields to be nullified
    if (requireNullification) {
      newData = { symptom: value, ...nulledFields };
    }
    // Changing isSymptomEpisodic to 'no' requires related fields to be nullified
    if (requireEpisodesNullification) {
      newData = { isSymptomEpisodic: 'no', ...nulledEpisodesFields };
    }

    newDocument.primarySymptom = { ...document, ...newData };

    if (requireNullification || requireEpisodesNullification) {
      openDeleteDialog({ data: { ...parentDocument, ...newDocument } });
    } else {
      setEditingData?.({ ...parentDocument, ...newDocument });
    }
  };

  const columns =
    Array.isArray(document?.currentMostIntenseSymptoms) && document.currentMostIntenseSymptoms.length > 2 ? 3 : 2;

  const isEditing = !viewing && !!editing;

  return (
    <React.Fragment>
      <StyledTable columns={columns} style={{ paddingBottom: !document?.symptom ? '4.5rem' : undefined }}>
        {fields.map((field: (typeof fields)[0], i: number) =>
          field.type && field.name !== 'currentMostIntenseSymptoms' ? (
            <StyledTableRow key={i} columns={columns}>
              <StyledTableItem style={{ color: colors.tertiaryText }}>
                {['symptom', 'symptomLocation'].includes(field.name)
                  ? fm(`myService.ninmt.${field.name}`)
                  : fmNINMTPreInquiry(`${document?.symptom}.${field.name}`)}
              </StyledTableItem>
              <StyledTableItem colSpan={['PartialDate', 'TextArea'].includes(field.type) ? 2 : 1}>
                <InputHandler
                  type={field.type as any}
                  name={field.name}
                  editing={isEditing}
                  formData={{
                    document: {
                      [field.name]:
                        field.name === 'symptomLocation'
                          ? // 'pain' requires array
                            Array.isArray(document?.currentMostIntenseSymptoms) &&
                            document?.currentMostIntenseSymptoms.length > 0
                            ? document?.currentMostIntenseSymptoms.map((s) => s?.symptomLocation)
                            : // 'tinnitus' and 'other' require object
                              typeof document?.currentMostIntenseSymptoms === 'object' &&
                                document?.currentMostIntenseSymptoms !== null
                              ? (document?.currentMostIntenseSymptoms as TAnyObject)?.symptomLocation
                              : undefined
                          : document?.[field.name as keyof typeof document],
                    },
                    onChange: handleFieldChange,
                  }}
                  options={field.options}
                  placeholder={field.placeholder}
                  optionFormatter={(name: string | number): string => fmNINMTPreInquiry(`opts.${name}`)}
                  limit={field.limit}
                  hideDaySelection={field.type === 'PartialDate' ? true : undefined}
                />
              </StyledTableItem>
              {!['PartialDate', 'TextArea'].includes(field.type) && <StyledTableItem />}
              {columns === 3 && <StyledTableItem style={{ paddingRight: '1.5rem' }} />}
            </StyledTableRow>
          ) : (
            <IntenseSymptomTable
              key={i}
              symptom={document?.symptom}
              intenseSymptoms={document.currentMostIntenseSymptoms}
              onChange={handleFieldChange}
              isEditing={isEditing}
              fm={fm}
              fmNINMTPreInquiry={fmNINMTPreInquiry}
            />
          ),
        )}
        {document?.symptom && document.symptom !== 'tinnitus' ? (
          <React.Fragment>
            <div style={{ fontSize: '1.8.rem', fontWeight: 600, color: colors.darkGray }}>
              {fm('myService.ninmt.episodes')}
            </div>
            <StyledTableRow columns={2} style={{ paddingBottom: 0 }}>
              <StyledTableItem style={{ color: colors.tertiaryText }}>
                {fm(`myService.ninmt.${document.symptom === 'pain' ? 'pain' : 'symptom'}HasBeenEpisodic`)}
              </StyledTableItem>
              <StyledTableItem style={{ overflow: 'visible' }}>
                <InputHandler
                  type="Radio"
                  name="isSymptomEpisodic"
                  editing={isEditing}
                  formData={{
                    document,
                    onChange: handleFieldChange,
                  }}
                  options={['yes', 'no']}
                  optionFormatter={(name: string | number) => fm(`general.${name}`)}
                />
              </StyledTableItem>
            </StyledTableRow>
            {document?.isSymptomEpisodic === 'yes' ? (
              <TableContent width={100}>
                {
                  episodeFields.map((field) => ({
                    title: {
                      content: fmNINMTPreInquiry(`${document?.symptom}.${field.name}`),
                    },
                    content: {
                      content: (
                        <InputHandler
                          type={field.type as any}
                          name={field.name}
                          editing={isEditing}
                          formData={{
                            document: { [field.name]: document?.[field.name as keyof typeof document] },
                            onChange: handleFieldChange,
                          }}
                          options={field.options}
                          optionFormatter={(id: string | number) => fmNINMTPreInquiry(`opts.${id}`)}
                          placeholder={field.placeholder}
                        />
                      ),
                      xs: 4,
                    },
                  })) as []
                }
              </TableContent>
            ) : (
              <div style={{ marginBottom: '3.5rem' }} />
            )}
          </React.Fragment>
        ) : document.symptom ? (
          <div style={{ marginBottom: '3.5rem' }} />
        ) : undefined}
      </StyledTable>
      <ConfirmationDialog
        open={deleteDialogOpen}
        text={fm(deleteDialogText)}
        confirm={{ callback: deleteConfirmCallback, text: 'general.continue' }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </React.Fragment>
  );
};

interface ISymptomWindow {
  parentDocument: ININMTPreInquiry & IControlProps;
  document: ININMTPreInquirySymptom;
}

export default SymptomWindow;
