import { IAddon, IData, IDataPoint, IItem, TDescriptionTable } from 'Components/sq-graphics/interfaces';
import { exists, isPartialDate } from 'neuro-utils';

import {
  chopINTENDScore,
  hineMMScore,
  hfmsEScore,
  isMfmComplete,
  isMfmManualComplete,
  mfmScore,
  nsaaScore,
  rulmScore,
  rulmSuperiorHand,
} from 'Routes/Motor/utils';
import { getDescription } from 'Utility/randomUtil';
import { capitalize } from 'Utility/string';

export const convertNsaaToGraph = (
  docs: Array<INSAA>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const score = nsaaScore(d);
    if (score === 'notCounted' || Number.isNaN(Number(score))) return;
    const value = Number(score);
    d.date &&
      nsaaScore(d) !== 'notCounted' &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        id: 'nsaa',
        title: value.toString(),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'nsaa',
      type: 'lineGraph',
      legend: 'NSAA',
    },
  ];
};

export const convertMfmToGraph = (
  docs: Array<IMFM>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  whichOne: 'mfm20' | 'mfm32',
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  // Filter docs based on the whichOne variable that tells the type of the data we want to return
  const filteredDocs = docs.filter((d) => {
    if (d?.mfm32OrMfm20 === whichOne) return true;
    return false;
  });
  filteredDocs.forEach((d) => {
    if (!isPartialDate(d.date) || !(isMfmManualComplete(d) || isMfmComplete(d))) return;
    const scores: { d1: string; d2: string; d3: string; total: string } = mfmScore(d);
    const value = parseFloat(scores.total);
    const description: TDescriptionTable = [];
    Object.keys(scores).forEach((k) => {
      if (k === 'total') return;
      description.push({ title: fm(`motor.opts.mfm.${k}`), values: (scores as { [key: string]: string })[k] });
    });
    d.date &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        id: whichOne,
        title: `${fm('motor.opts.mfm.total')}: ${scores.total}`,
        description: description,
      });
  });

  return [
    {
      dataPoints: dataPoints,
      id: whichOne,
      type: 'lineGraph',
      legend: whichOne === 'mfm20' ? 'MFM 20' : 'MFM 32',
    },
  ];
};

export const convert6mwtToGraph = (
  docs: Array<I6MWT>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string, values?: { [key: string]: string }) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    d.date &&
      d.meters &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.meters,
        id: '6mwt',
        title: fm('motor.meterValue', { value: d.meters.toString() }),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: '6mwt',
      type: 'lineGraph',
      legend: '6MWT',
    },
  ];
};

export const convertChopIntendToGraph = (
  docs: Array<ICHOPINTEND>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const score = chopINTENDScore(d);
    if (score === 'notCounted' || Number.isNaN(Number(score))) return;
    const value = Number(score);
    dataPoints.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      value: value,
      id: 'chopIntend',
      title: value.toString(),
    });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'chopIntend',
      type: 'lineGraph',
      legend: 'CHOP INTEND',
    },
  ];
};

export const convertHinemmToGraph = (
  docs: Array<IHINEMM>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const score = hineMMScore(d);
    if (score === 'notCounted' || Number.isNaN(Number(score))) return;
    const value = Number(score);
    d.date &&
      hineMMScore(d) !== 'notCounted' &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        id: 'hinemm',
        title: value.toString(),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'hinemm',
      type: 'lineGraph',
      legend: 'HINE MM',
    },
  ];
};

export const convertHfmseToGraph = (
  docs: Array<IHFMSE>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const score = hfmsEScore(d);
    if (score === 'notCounted' || Number.isNaN(Number(score))) return;
    const value = Number(score);
    d.date &&
      hfmsEScore(d) !== 'notCounted' &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        id: 'hfmse',
        title: value.toString(),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'hfmse',
      type: 'lineGraph',
      legend: 'HFMS-E',
    },
  ];
};

export const convertRulmToGraph = (
  docs: Array<IRULM>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const superiorHand = rulmSuperiorHand(d);
    const score = rulmScore(d, superiorHand);
    if (score === 'notCounted' || Number.isNaN(Number(score))) return;
    const value = Number(score);
    const description = superiorHand ? fm('motor.' + superiorHand.toLocaleLowerCase()) : '';
    d.date &&
      rulmScore(d, superiorHand) !== 'notCounted' &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        id: 'rulm',
        title: value.toString(),
        description: description,
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'rulm',
      type: 'lineGraph',
      legend: 'RULM',
    },
  ];
};

const motorFunctionSections = [
  'sittingWithoutSupport',
  'crawlingOnHandsAndKnees',
  'standingWithAssistance',
  'standingWithoutAssistance',
  'walkingWithAssistance',
  'walkingWithoutAssistance',
];

export const convertMotorFunctionToTimeline = (
  docs: Array<IMotorFunction>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    const functionsCareItems: IAddon[] = [];
    motorFunctionSections.forEach((s) => {
      const startDate = doc[(s + 'Start') as keyof IMotorFunction];
      const endDate = doc[(s + 'End') as keyof IMotorFunction];
      if (isPartialDate(startDate)) {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(startDate),
          end: isPartialDate(endDate) ? dateFromPartialUpdateTimeframe(endDate) : undefined,
          title: fm('motorFunctionAndMobilityAid.motorfunction.' + s),
        });
        functionsCareItems.push({
          id: s,
          title: fm('motorFunctionAndMobilityAid.motorfunction.' + s),
          titleDescription: undefined,
          items: items,
          events: [],
        });
      }
    });
    addons.push(...functionsCareItems);
  });
  return addons.length > 0 ? addons : undefined;
};

/** Entries of `ventilationAid` to make addon descriptions from. */
const addonDescriptionVentilationEntries: Array<keyof IVentilation> = ['type', 'frequency', 'hoursPerDay'];
export const convertVentilationUseToTimeline = (
  docs: Array<IVentilation>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string, values?: { [key: string]: string }) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.startDate)) return undefined;
    const items: IItem[] = [];
    items.push({
      start: dateFromPartialUpdateTimeframe(doc.startDate),
      end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
      title: fm('pulmonary.ventilation'),
      description: addonDescriptionVentilationEntries.reduce<TDescriptionTable>((acc, cur) => {
        if (cur === 'hoursPerDay') {
          acc.push({
            title: fm('pulmonary.hoursPerDayLowercase', { value: doc.hoursPerDay?.toString() ?? '' }),
            condition: exists(doc.hoursPerDay),
          });
        } else if (doc[cur]) {
          acc.push({
            title: fm(`pulmonary.opts.${doc[cur]}`),
            condition: exists(doc[cur]),
          });
        }
        return acc;
      }, []),
    });
    addons.push({
      id: 'ventilation',
      title: fm('pulmonary.ventilation'),
      titleDescription: undefined,
      items: items,
      events: [],
    });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertNutritionToTimeline = (
  docs: Array<INutrition>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    const feedingTubes = doc.feedingTubes?.filter((f: IFeedingTube) => isPartialDate(f.startDate));
    if (feedingTubes && feedingTubes.length > 0) {
      const items: IItem[] = [];
      feedingTubes?.forEach((f) => {
        f.startDate &&
          items.push({
            start: dateFromPartialUpdateTimeframe(f.startDate),
            end: f.endDate ? dateFromPartialUpdateTimeframe(f.endDate) : undefined,
            title: fm('nutrition.feedingTube'),
            description: ['feedingTubeType', 'feedingTubePractice'].map((name) => {
              return {
                title: exists(f[name as keyof IFeedingTube])
                  ? fm(`nutrition.opts.${f[name as keyof IFeedingTube]}`)
                  : '',
                condition: exists(f[name as keyof IFeedingTube]),
              };
            }),
          });
      });
      addons.push({
        id: 'feedingTube',
        title: fm('nutrition.feedingTube'),
        titleDescription: undefined,
        items: items,
        events: [],
      });
    }
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertMobilityAidToTimeline = (
  docs: Array<IAid>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.startDate)) return undefined;
    const description: TDescriptionTable = ['type', 'use'].reduce<TDescriptionTable>((acc, cur) => {
      const val = doc[cur as keyof IAid];
      if (val)
        acc.push({
          title: fm(`motorFunctionAndMobilityAid.aids.opts.${val}`),
          condition: exists(val),
        });
      return acc;
    }, []);
    switch (doc.aidType) {
      case 'wheelChair': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.startDate),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('motorFunctionAndMobilityAid.aids.wheelChairTitle'),
          description: description,
        });
        addons.push({
          id: 'wheelChair',
          title: fm('motorFunctionAndMobilityAid.aids.wheelChairTitle'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'walkingStand': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.startDate),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('motorFunctionAndMobilityAid.aids.walkingStandTitle'),
          description: description,
        });
        addons.push({
          id: 'walkingStand',
          title: fm('motorFunctionAndMobilityAid.aids.walkingStandTitle'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      case 'crutches': {
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.startDate),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('motorFunctionAndMobilityAid.aids.crutchesTitle'),
          description: description,
        });
        addons.push({
          id: 'crutches',
          title: fm('motorFunctionAndMobilityAid.aids.crutchesTitle'),
          titleDescription: undefined,
          items: items,
          events: [],
        });
        break;
      }
      default: {
        break;
      }
    }

    return;
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertHospitalizationToTimeline = (
  docs: Array<IHospitalization>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    const description = (icd10code?: string, description?: string) => [
      {
        title: fm('comorbidity.hospitalizationType'),
        values: doc.hospitalizationType ? fm(`comorbidity.opts.${doc.hospitalizationType}`) : undefined,
      },
      {
        title: fm('comorbidity.acuteReason'),
        values: icd10code ? `${doc.classification?.icd10code} - ${description}` : undefined,
        condition: doc.hospitalizationType === 'acute',
      },
      {
        title: fm(`comorbidity.acuteAdverse${capitalize(doc.acuteAdverseEffect ?? '')}`),
        condition: exists(doc.acuteAdverseEffect),
      },
      {
        title: fm('medication.medication'),
        values: doc.acuteAdverseEffectMedication,
        condition: doc.acuteAdverseEffect === 'yes',
      },
      {
        title: fm('comorbidity.details'),
        values: doc.hospitalizationDetails,
        condition: exists(doc.hospitalizationDetails),
      },
    ];
    if (!isPartialDate(doc.date)) return undefined;
    const items: IItem[] = [];
    items.push({
      start: dateFromPartialUpdateTimeframe(doc.date),
      end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
      title: fm('comorbidity.hospitalization'),
      description: doc.classification?.icd10code
        ? getDescription(doc.classification?.icd10code).then((v) =>
            description(doc.classification?.icd10code as string, v),
          )
        : description(),
    });
    addons.push({
      id: 'hospitalization',
      title: fm('comorbidity.hospitalization'),
      titleDescription: undefined,
      items: items,
      events: [],
    });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};
