import { append, clone, find, includes, map, not } from 'ramda';
import {
  dxCategories,
  cbdDiagnoses,
  dlbDiagnoses,
  dmdDiagnoses,
  msaDiagnoses,
  msDiagnoses,
  pdDiagnoses,
  pspDiagnoses,
  smaDiagnoses,
  huntingtonDiagnoses,
  epilepsyDiagnoses,
  parkinsonDiagnoses,
  sleepApneaDiagnoses,
  keoDiagnoses,
  reoDiagnoses,
  nmosdDiagnoses,
  mgravisDiagnoses,
} from '../definitions';

export const chooseDiagnosesByCategory = (category: string): Array<TDiagnosis> => {
  switch (category) {
    case 'diagnosis.opts.SMA':
      return smaDiagnoses;
    case 'diagnosis.opts.DMD':
      return dmdDiagnoses;
    case 'diagnosis.opts.PD':
      return pdDiagnoses;
    case 'diagnosis.opts.PSP':
      return pspDiagnoses;
    case 'diagnosis.opts.MSA':
      return msaDiagnoses;
    case 'diagnosis.opts.DLB':
      return dlbDiagnoses;
    case 'diagnosis.opts.CBD':
      return cbdDiagnoses;
    case 'diagnosis.opts.MS':
      return msDiagnoses;
    case 'diagnosis.opts.HD':
      return huntingtonDiagnoses;
    case 'diagnosis.opts.SR':
      return sleepApneaDiagnoses;
    default:
      return [];
  }
};

export const chooseCategoryTitle = (diagnosis: TDiagnosis): string => {
  const category = find((o: ICategoryOptions) => includes(diagnosis, o.values), dxCategories);
  return category?.title ?? '';
};

export const findMissingDiagnosis = (docDiagnosis: TDiagnosis, category: ICategoryOptions): boolean =>
  not(includes(docDiagnosis, category.values));

export const addMissingDiagnosis = (docDiagnosis: TDiagnosis, category: ICategoryOptions): Array<TDiagnosis> => {
  let values = clone(category.values);
  if (includes(docDiagnosis, chooseDiagnosesByCategory(category.title))) {
    values = append(docDiagnosis, category.values);
  }
  return values as Array<TDiagnosis>;
};

export const findIfCategoryIsMissing = (
  docDiagnosis: TDiagnosis,
  diagnosisCategoryOptions: Array<ICategoryOptions>,
): boolean =>
  not(
    find(
      (category: ICategoryOptions) => category.title === chooseCategoryTitle(docDiagnosis),
      diagnosisCategoryOptions,
    ),
  );

export const findAndAddMissingDiagnosis = (
  docDiagnosis: TDiagnosis,
  diagnosisCategoryOptions: Array<ICategoryOptions>,
): Array<ICategoryOptions> => {
  let options = clone(diagnosisCategoryOptions);
  if (findIfCategoryIsMissing(docDiagnosis, options)) {
    options = append({ title: chooseCategoryTitle(docDiagnosis), values: [docDiagnosis] } as ICategoryOptions, options);
  } else {
    map((category: ICategoryOptions) => {
      if (findMissingDiagnosis(docDiagnosis, category)) {
        category.values = addMissingDiagnosis(docDiagnosis, category);
      }
    }, options);
  }
  return options;
};

/**
 * Generate a diagnosis (i.e. "dx") to platform map, indicating which
 * platform a diagnosis belongs to.
 * @returns Diagnosis-to-platform map
 */
export const generateDxToPlatfMap = (): { [key in TDiagnosis]?: Platform } => {
  const dxToPlatfMap: { [key in TDiagnosis]?: Platform } = {};
  const platfToDxMap: { [key in Platform]: TDiagnosis[] } = {
    dmd: dmdDiagnoses,
    epilepsy: epilepsyDiagnoses,
    huntington: huntingtonDiagnoses,
    ms: [...msDiagnoses, ...keoDiagnoses, ...reoDiagnoses, ...nmosdDiagnoses],
    parkinson: parkinsonDiagnoses,
    sma: smaDiagnoses,
    sleepApnea: sleepApneaDiagnoses,
    ninmt: [],
    mgravis: [...mgravisDiagnoses],
  };

  (Object.keys(platfToDxMap) as Platform[]).forEach((plat) => {
    platfToDxMap[plat].forEach((dx) => {
      dxToPlatfMap[dx] = plat;
    });
  });

  return dxToPlatfMap;
};
