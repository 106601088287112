import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { HistoryEventListItem } from 'Components/_NewElements/HistoryEvents';
import { formatPartialDate, nowPartialDate, partialDateFromDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import { getThisInstanceRecurringEndDate, getThisInstanceRecurringStartDate } from 'neuro-calculation-commons';
import { actions } from 'Store/myapp/actions';
import { useAppDispatch } from 'Store/index';

const getWeekInterval = (repeat: string) => {
  let weekInterval = 86400000 * 7;
  if (repeat.includes('every-nth:2')) {
    weekInterval *= 2;
  } else if (repeat.includes('every-nth:4')) {
    weekInterval *= 4;
  }

  return weekInterval;
};

const RecurringTasksList = ({
  platform,
  recurringTasks,
  showEndButton,
}: {
  platform: Platform;
  recurringTasks: ITaskList[];
  showEndButton?: boolean;
}) => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj } = myServContext;

  const [openRecurring, setOpenRecurring] = React.useState<string>(recurringTasks?.[0].id ?? '');

  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleEndClick = async (taskList: ITaskList) => {
    setLoading(true);
    await actions.updateTasklist({ ...taskList, deadline: nowPartialDate() }, dispatch);
    setLoading(false);
  };

  return (
    <div style={{ marginBottom: '2rem' }}>
      <div>
        <HistoryRowSubHeader header={fm('myService.recurringTasks')} fontSize="small" />
      </div>
      <div style={{ padding: 1 }}>
        {recurringTasks.map((rt) => {
          const tasks = rt.tasks?.map((t) => fm(`myService.${platform}.opts.${t}`));
          if (!tasks) return;

          const weekInterval = rt.repeat_schedule && getWeekInterval(rt.repeat_schedule);

          // Get currently active recurring tasklist send date and deadline
          const currentDeadline =
            rt.repeat_schedule && rt.end_time
              ? getThisInstanceRecurringEndDate(
                  rt.start_time,
                  rt.repeat_schedule,
                  rt.end_time,
                  rt.task_duration ?? undefined,
                )
              : undefined;
          const currentSendingDate =
            rt.repeat_schedule && rt.end_time
              ? getThisInstanceRecurringStartDate(
                  rt.start_time,
                  rt.repeat_schedule,
                  rt.end_time,
                  rt.task_duration ?? undefined,
                )
              : undefined;

          // Get next sending date and deadline if possible
          let nextSendingDate: undefined | number = undefined;
          let nextDeadlineDate: undefined | number = undefined;
          if (weekInterval && currentSendingDate) {
            if (!rt.end_time || currentSendingDate + weekInterval < rt.end_time) {
              nextSendingDate = currentSendingDate + weekInterval;
            }
          }
          if (weekInterval && currentDeadline && nextSendingDate) {
            if (rt.end_time && currentDeadline + weekInterval > rt.end_time) {
              // Tasklist end comes before the next calculated deadline, then use end time
              nextDeadlineDate = rt.end_time;
            } else {
              nextDeadlineDate = currentDeadline + weekInterval;
            }
          }

          return (
            <>
              <BlockWrapper
                key={rt.id}
                title={tasks.join(', ')}
                contentMargin={1}
                collapse={{
                  open: openRecurring === rt.id,
                  collapseHandler: () => setOpenRecurring(openRecurring === rt.id ? '' : rt.id),
                }}
              >
                <Container>
                  <Item xs={true}>
                    <HistoryEventListItem
                      titleWidth={5}
                      title={'myService.recurrence'}
                      value={`${fm(`myService.opts.${rt.weeklySurveyInterval}`)}, ${fm('myService.until', {
                        until: formatPartialDate(rt.deadline),
                      })}`}
                    />
                    <HistoryEventListItem
                      titleWidth={5}
                      title={'myService.latestDeadline'}
                      value={
                        currentDeadline ? formatPartialDate(partialDateFromDate(new Date(currentDeadline))) : undefined
                      }
                    />
                    <HistoryEventListItem
                      titleWidth={5}
                      title={'myService.latestSendingDate'}
                      value={
                        currentSendingDate
                          ? formatPartialDate(partialDateFromDate(new Date(currentSendingDate)))
                          : undefined
                      }
                    />
                    <HistoryEventListItem
                      titleWidth={5}
                      title={'myService.nextDeadline'}
                      value={
                        nextSendingDate ? formatPartialDate(partialDateFromDate(new Date(nextSendingDate))) : undefined
                      }
                    />
                    <HistoryEventListItem
                      titleWidth={5}
                      title={'myService.nextSendingDate'}
                      value={
                        nextDeadlineDate
                          ? formatPartialDate(partialDateFromDate(new Date(nextDeadlineDate)))
                          : undefined
                      }
                    />
                  </Item>
                  {showEndButton ? (
                    <Item xs={3.5} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div style={{ marginTop: '1rem' }}>
                        <ActionButtonRounded
                          text="general.edit"
                          filled={true}
                          uppercase={false}
                          width={9.6}
                          height={3}
                          fontSize={16}
                          onClick={() => {
                            setEditingObj({ type: 'taskList', data: rt });
                          }}
                        />
                        <div style={{ marginTop: '1rem' }} />
                        <ActionButtonRounded
                          text="myService.endRecurring"
                          filled={false}
                          uppercase={false}
                          width={9.6}
                          height={3}
                          fontSize={16}
                          onClick={() => handleEndClick(rt)}
                          colorScheme="error"
                          loading={loading}
                        />
                      </div>
                    </Item>
                  ) : null}
                </Container>
              </BlockWrapper>
              <div style={{ marginBottom: '2rem' }} />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default RecurringTasksList;
