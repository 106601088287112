export const subjectOfTreatmentNames = [
  'M1.dx.footLeft',
  'M1.sin.footRight',
  'M1.feet',
  'M1.dx.handLeft',
  'M1.sin.handRight',
  'M1.dx.faceLeft',
  'M1.sin.faceRight',
  'M1.dx.body',
  'M1.sin.body',
  'M1.body',
  'M1.dx.genitalLeft',
  'M1.sin.genitalRight',
  'S2.dx',
  'S2.sin',
  'STG.dx.back',
  'STG.dx.front',
  'STG.dx.middle',
  'STG.sin.back',
  'STG.sin.front',
  'STG.sin.middle',
  'DLPF.dx',
  'DLPF.sin',
  'SMA.dx',
  'SMA.sin',
  'PMA.dx',
  'PMA.sin',
  'TPJ.dx',
  'TPJ.sin',
  'brocasArea.dx',
  'brocasArea.sin',
  'other',
];

export const basicDefaultValuesRTMS = {
  pulseFrequency: {
    originalRTMS: {
      M1: {
        dx: { footLeft: 10, handLeft: 10, faceLeft: 10, body: 10 },
        sin: { footRight: 10, handRight: 10, faceRight: 10, body: 10 },
        body: 10,
        feet: 10,
      },
      S2: { dx: 10, sin: 10 },
      STG: { dx: { back: 1, front: 1, middle: 1 }, sin: { back: 1, front: 1, middle: 1 } },
      DLPF: { sin: 10, dx: 1 },
    },
    iTBS: null,
    cTBS: null,
  },
  pulseFrequency3PulseBurst: {
    originalRTMS: null,
    iTBS: 50,
    cTBS: 50,
  },
  frequencyBetweenBursts: {
    originalRTMS: null,
    iTBS: 5,
    cTBS: 5,
  },
  amountOfBurstsInSet: {
    originalRTMS: null,
    iTBS: 10,
    cTBS: 200,
  },
  amountOfSetsInSequence: {
    originalRTMS: null,
    iTBS: 20,
    cTBS: 1,
  },
  timeBetweenBurstsets: {
    originalRTMS: null,
    iTBS: 8,
    cTBS: null,
  },
  pulseIntensity: {
    originalRTMS: {
      M1: {
        dx: { footLeft: [90, 120], handLeft: [80, 90], faceLeft: [80, 100], body: [80, 90] },
        sin: { footRight: [90, 120], handRight: [80, 90], faceRight: [80, 100], body: [80, 90] },
        body: [80, 90],
        feet: [90, 120],
      },
      S2: { dx: [80, 90], sin: [80, 90] },
      STG: {
        dx: { back: [90, 100], front: [90, 100], middle: [90, 100] },
        sin: { back: [90, 100], front: [90, 100], middle: [90, 100] },
      },
      DLPF: { sin: [90, 120], dx: [90, 100] },
    },
    iTBS: [70, 90],
    cTBS: [80, 120],
  },
  numberOfPulses: {
    originalRTMS: {
      M1: {
        dx: { footLeft: [750, 750], handLeft: [750, 750], faceLeft: [750, 750], body: [750, 750] },
        sin: { footRight: [750, 750], handRight: [750, 750], faceRight: [750, 750], body: [750, 750] },
        body: [750, 750],
        feet: [750, 750],
      },
      S2: { dx: [750, 750], sin: [750, 750] },
      STG: {
        dx: { back: [750, 750], front: [750, 750], middle: [750, 750] },
        sin: { back: [750, 750], front: [750, 750], middle: [750, 750] },
      },
      DLPF: { sin: [750, 750], dx: [750, 750] },
    },
    iTBS: [600, 600],
    cTBS: null,
  },
  pauseLength: {
    originalRTMS: {
      M1: {
        dx: { footLeft: 10, handLeft: 10, faceLeft: 10, body: 10 },
        sin: { footRight: 10, handRight: 10, faceRight: 10, body: 10 },
        body: 10,
        feet: 10,
      },
      S2: { dx: 10, sin: 10 },
      STG: { dx: { back: 10, front: 10, middle: 10 }, sin: { back: 10, front: 10, middle: 10 } },
      DLPF: { sin: 10, dx: 10 },
    },
    iTBS: 50,
    cTBS: null,
  },
};
