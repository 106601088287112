import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { isLocaleKey, passQuestion, IPassQuestion } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';

export const PassQuestion = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj, fm } = myServContext;
  const passQuestionData = (editing?.data || viewing?.data) as IPassQuestion & IControlProps;
  const passQuestionLocales = passQuestion.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...passQuestionData,
      [field]: value,
    });
  };

  return (
    <>
      <BlockWrapper
        title={viewing ? formatPartialDate(passQuestionData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'passQuestion', data: passQuestionData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: passQuestionData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <FormRow title={passQuestionLocales[useLocale]['satisfiedWithCurrentSituationPatient']}>
          <InputHandler
            type="Radio"
            name="satisfiedWithCurrentSituation"
            editing={!!editing}
            formData={{
              document: { satisfiedWithCurrentSituation: passQuestionData?.satisfiedWithCurrentSituation },
              onChange: onChangeSurveyForm,
            }}
            options={['yes', 'no']}
            optionFormatter={(o) => fm(`general.${o}`)}
          />
        </FormRow>
      </BlockWrapper>
    </>
  );
};
