import type { INeuroStimulus, INeuroTask } from 'neuro-data-structures';
import { dateFromPartial, partialDateFromDate, partialDateToValue } from 'neuro-utils';
import { getPlatformInviteFlavorString, matchesInviteFlavorString } from 'Routes/MyService/util';
import { fetchWithOptions } from 'Utility/fetch';
import { getJWTData, parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { localStorageGet } from 'Utility/localStorage';
import { makeLog } from 'Utility/logger';
import { sortAndMergeDocuments } from 'Utility/documentHandling';

export type TPatientDevice = {
  deviceId: string;
  userId: string;
  deviceModel: string;
  jwtId: string;
  expirationTime: number;
};

/**
 * Get array of devices for patient
 * @param {IPatientAPI['id']} patientId - Patient id
 * @returns {Promise<Array<TPatientDevice>>} Promise array of the devices for patient or empty array
 */
export const getPatientDevices = (patientId: IPatientAPI['id']): Promise<Array<TPatientDevice>> =>
  fetchWithOptions(`/api/devices/${patientId}`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      else return [];
    })
    .catch((error: Error) => {
      makeLog('Error', { name: 'Get patient devices', message: 'Failed to fetch the devices' }, error);
    });

const formatBody = (
  taskList: ITaskList,
  patientid: string,
  orgid: string,
  platform: string,
): Omit<INeuroTask, 'id'> => {
  let cronRepeatSchedule: string = '';
  if (taskList.recurring && taskList?.sendDate) {
    const sendDateDay = dateFromPartial(taskList.sendDate).getDay();
    cronRepeatSchedule = `cron:0 0 * * ${sendDateDay}`;
    if (taskList?.weeklySurveyInterval === 'everyOtherWeek') {
      cronRepeatSchedule = cronRepeatSchedule + ':every-nth:2';
    } else if (taskList?.weeklySurveyInterval === 'everyFourthWeek') {
      cronRepeatSchedule = cronRepeatSchedule + ':every-nth:4';
    }
  }
  return {
    patientId: patientid,
    org: orgid,
    task_type: taskList?.recurring ? 'recurring' : 'task',
    start_time: partialDateToValue(taskList.sendDate),
    end_time: taskList?.deadline ? partialDateToValue(taskList.deadline) : undefined,
    task_duration: taskList?.recurring
      ? partialDateToValue(taskList?.recurringTaskDeadline) - partialDateToValue(taskList?.sendDate)
      : partialDateToValue(taskList?.deadline) - partialDateToValue(taskList?.sendDate),
    repeat_schedule: cronRepeatSchedule || undefined,
    actions: taskList?.tasks || [],
    schedule: null,
    title: taskList?.title,
    description: taskList?.description,
    platform: platform,
  };
};

/**
 * Post tasklist
 * @param {ITaskList} taskList data to post
 */
export const sendTaskList = async (taskList: ITaskList): Promise<INeuroTask | null> => {
  const patientid = getJWTData()?.patientid || null;
  const orgid = getJWTData()?.orgid || null;
  const platform = localStorageGet('platform');

  if (!patientid || !orgid || !platform) return null;

  return fetchWithOptions(
    `/api/patient/v2/task/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'POST',
      body: JSON.stringify(formatBody(taskList, patientid, orgid, platform)),
    },
  )
    .then((res) => {
      if (res.status === 200) return res.json();
      throw res;
    })
    .catch((error: Error) => {
      makeLog('Error', { name: 'Post taskList', message: 'Failed to post a tasklist' }, error);
      return false;
    });
};

/**
 * Update tasklist
 * @param {ITaskList} taskList data to post
 */
export const updateTaskListFetcher = async (taskList: ITaskList): Promise<boolean | null> => {
  const patientid = getJWTData()?.patientid || null;
  const orgid = getJWTData()?.orgid || null;
  const platform = localStorageGet('platform');

  if (!patientid || !orgid || !platform) return null;

  return fetchWithOptions(
    `/api/patient/v2/task/${orgid}/${patientid}/${taskList.id}`,
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'PUT',
      body: JSON.stringify(formatBody(taskList, patientid, orgid, platform)),
    },
  )
    .then((res) => {
      if (res.status === 200) return true;
      throw res;
    })
    .catch((error: Error) => {
      makeLog('Error', { name: 'Update taskList', message: 'Failed to update a tasklist' }, error);
      return false;
    });
};

/**
 * Delete tasklist
 * @param {ITaskList} taskList - Tasklist to be deleted
 */
export const deleteTaskListFetcher = async (taskList: ITaskList): Promise<boolean | null> => {
  const patientid = getJWTData()?.patientid || null;
  const orgid = getJWTData()?.orgid || null;

  if (!patientid || !orgid) return null;

  return fetchWithOptions(
    `/api/patient/v2/task/${orgid}/${patientid}/${taskList.id}`,
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'DELETE',
    },
  )
    .then((res) => {
      if (res.status === 200) return true;
      throw res;
    })
    .catch((error: Error) => {
      makeLog('Error', { name: 'Delete taskList', message: 'Failed to delete a tasklist' }, error);
      return false;
    });
};

/**
 *
 * @param customCron tasklists repeat_schedule field from backend in format of E.g. "cron:0 0 * * 5:every-nth:2"
 * @returns {ITaskList['weeklySurveyInterval']}
 */
const cronToWeeklyInterval = (customCron: string): ITaskList['weeklySurveyInterval'] => {
  if (!customCron.includes('cron:')) return;
  if (customCron.includes('every-nth:2')) {
    return 'everyOtherWeek';
  } else if (customCron.includes('every-nth:4')) {
    return 'everyFourthWeek';
  } else return 'weekly';
};

export const getTaskLists = async (): Promise<Array<ITaskList> | null> => {
  const patientid = getJWTData()?.patientid || null;
  const orgid = getJWTData()?.orgid || null;

  if (!patientid || !orgid) return null;

  return fetchWithOptions(
    `/api/patient/v2/task/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      throw res;
    })
    .then((data: INeuroTask[]) => {
      if (data.length > 0) {
        return data.map<ITaskList>((d: INeuroTask) => {
          return {
            id: d.id,
            deadline: partialDateFromDate(d.end_time ? new Date(d.end_time) : new Date()),
            tasks: d.actions as string[] as ITaskList['tasks'],
            weeklySurveyInterval:
              d.task_type === 'recurring' && d.repeat_schedule ? cronToWeeklyInterval(d.repeat_schedule) : undefined,
            sendDate: partialDateFromDate(new Date(d.start_time)),
            description: d.description,
            title: d.title,
            recurring: d.task_type === 'recurring',
            recurringTaskDeadline:
              d.task_type === 'recurring'
                ? partialDateFromDate(new Date(d.start_time + (d.task_duration || 0)))
                : undefined,
            isEditing: false,
            start_time: d.start_time,
            end_time: d.end_time,
            task_duration: d.task_duration,
            repeat_schedule: d.repeat_schedule,
          };
        });
      } else return [];
    })
    .catch((error: Error) => {
      makeLog('Error', { name: "Get patient's task lists", message: "Failed to get patient's task lists" }, error);
      return null;
    });
};

/**
 * Send Invite to use MyService
 * @param {string} carrier email address or phone number
 * @param {string} medium  'EMAIL' | 'SMS'
 * @param {string} orgId organization ID
 * @param {Locale} lang - 2-letter ISO 639 code of the invite recipient language
 */
export const sendMyInvite = (carrier: string, medium: string, orgId: string, lang: Locale) =>
  fetchWithOptions(
    '/api/patient/v2/invite/',
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'POST',
      body: JSON.stringify({
        carrier: carrier,
        medium: medium,
        flavor: getPlatformInviteFlavorString(),
        org_id: orgId,
        locale: lang,
      }),
    },
  );

/**
 * Create new document with finished commit
 * @param {string} documentType documentType
 * @param {IControlProps} docData data of the document created
 * @returns object with documentId and commitId if creation was succesful
 */
export const createNewDocumentWithCommit = async (
  documentType: string,
  docData: TAnyObject,
): Promise<{ documentId: string; commitId: string } | null> => {
  return fetchWithOptions(
    `/api/mydocuments/${documentType}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PUT', body: JSON.stringify(docData) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw res;
      }
    })
    .catch((error: Error) => {
      makeLog('Error', { name: 'MY Document PUT', message: 'Response code not 200' }, error);
      return null;
    });
};

/**
 * Create a finished commit to a document
 * @param {string} documentType documentType
 * @param {string} documentId docId
 * @param {TAnyObject} commitData data of the commit
 * @returns Object with the new commitId if the creation was succesful
 */
export const createCommitToDocument = async (
  documentType: string,
  documentId: string,
  commitData: TAnyObject,
): Promise<{ commitId: string } | null> => {
  return fetchWithOptions(
    `/api/mydocuments/${documentType}/${documentId}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PUT', body: JSON.stringify(commitData) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw res;
      }
    })
    .catch((error: Error) => {
      makeLog('Error', { name: 'MY Commit PUT', message: 'Response code not 200' }, error);
      return null;
    });
};

/**
 * Delete document
 * @param {string} documentType documentType
 * @param {string} documentId docId
 * @returns Document deletion success
 */
export const deleteDocumentFetcher = async (
  documentType: string,
  documentId: string,
  reason: Record<string, string>,
): Promise<boolean> => {
  return fetchWithOptions(
    `/api/mydocuments/${documentType}/${documentId}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'DELETE', body: JSON.stringify({ reason, date: Date.now() }) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return true;
      } else {
        throw res;
      }
    })
    .catch((error: Error) => {
      makeLog('Error', { name: 'MY Document deletion', message: 'Response code not 200' }, error);
      return false;
    });
};

/**
 * Fetch invites (to become a MySQ user) sent to the patient (associated with
 * the current session).
 */
export async function getMyAppInvites<Invite extends { flavor: string }>(): Promise<Invite[]> {
  try {
    const res = await fetchWithOptions(
      '/api/patient/v2/stimulus', // API ~ neuro-api:0.19.4, neuro-patient-service:0.28.1
      { neurojwt: parseJWTFromCookie() },
      { method: 'GET' },
    );
    if (res.status === 200) {
      return (await res.json()).filter((s: INeuroStimulus) => matchesInviteFlavorString(s.flavor));
    } else return [];
  } catch (e: any) {
    makeLog('Error', e);
    return [];
  }
}

/**
 * Fetch MySQ user identity of the patient (associated with the current session).
 */
export async function getMyAppUserIdentity(): Promise<string | null> {
  try {
    const res = await fetchWithOptions(
      '/api/vault-mysq/v2/user/ssn/', // API ~ neuro-api:0.19.4, vault-core:1.12.0
      { neurojwt: parseJWTFromCookie() },
      { method: 'GET' },
    );
    if (res.status === 200) {
      const body: { userId: string } | null = await res.json();
      if (typeof body?.userId === 'string') {
        return body.userId;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e: any) {
    makeLog('Error', e);
    return null;
  }
}

/**
 * Get "My Service" data docs of the patient (associated with the current
 * session).
 */
export async function getMyAppDocs<Doc extends IControlProps>(): Promise<Doc[]> {
  try {
    const res = await fetchWithOptions(
      '/api/mydocuments', // API ~ neuro-api:0.19.4
      { neurojwt: parseJWTFromCookie() },
      { method: 'GET' },
    );
    if (res.status === 200) {
      const docs = await res.json();
      return sortAndMergeDocuments(docs);
    } else return [];
  } catch (e: any) {
    makeLog('Error', e);
    return [];
  }
}
