import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { treatmentMonitoringInquiry } from 'neuro-schemas';
import { getAtcCodesByPlatform } from 'Routes/Medication/Document/config';

const ninmtData = (
  fm: (id: string) => string,
  uiLang: Locale,
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
): IDiseaseHistoryData => {
  const clinicalMeters = fm('graph.clinicalMeters');
  const patientSelfReport = fm('graph.patientSelfReport');

  const atcCodes = getAtcCodesByPlatform(medicationSettings, 'ninmt');
  const atcOpts = medicationSettings?.ninmt.localizations[uiLang as 'fi' | 'en'];

  return {
    graphData: {
      common: {
        treatmentVisitsPerWeek: {
          data: [],
          title: fm('graph.treatmentVisitsPerWeek'),
          scale: {
            type: 'linear',
            linearScale: [0, 5],
          },
        },
        treatmentSessionsPerWeek: {
          data: [],
          title: fm('graph.treatmentSessionsPerWeek'),
          scale: {
            type: 'linear',
          },
        },
        // numberOfPulses: {
        //   data: [],
        //   title: fm('rtms.subjectOfTreatment.numberOfPulses'),
        //   scale: {
        //     id: 'numberOfPulses',
        //     type: 'linear',
        //   },
        // },
        // pulseIntensity: {
        //   data: [],
        //   title: fm('rtms.subjectOfTreatment.pulseIntensity'),
        //   scale: {
        //     id: 'pulseIntensity',
        //     type: 'linear',
        //   },
        // },
        madrs: {
          data: [],
          title: fm('interviewSurveys.madrs.title'),
          scale: {
            id: 'madrs',
            type: 'linear',
            linearScale: [0, 60],
          },
        },
        gaf: {
          data: [],
          title: fm('interviewSurveys.gaf.title'),
          scale: {
            id: 'gaf',
            type: 'linear',
            linearScale: [0, 100],
          },
        },
        ybocs: {
          data: [],
          title: fm('graph.ybocs'),
          scale: {
            id: 'ybocs',
            type: 'linear',
            linearScale: [0, 40],
          },
        },
        effectIntensity: {
          data: [],
          title: fm('graph.effectIntensity'),
          scale: {
            id: 'effectIntensity',
            type: 'linear',
            linearScale: [0, 10],
          },
          // Additional scale to prevent using reference graph
          additionalScale: {
            type: 'linear',
            linearScale: [0, 10],
            dontDrawHelperLine: true,
          },
        },
        effectHarm: {
          data: [],
          title: fm('graph.effectHarm'),
          scale: {
            id: 'effectHarm',
            type: 'linear',
            linearScale: [0, 10],
          },
          // Additional scale to prevent using reference graph
          additionalScale: {
            type: 'linear',
            linearScale: [0, 10],
            dontDrawHelperLine: true,
          },
        },
      },
      selfReporting: {
        tdcsTreatmentsPerWeek: {
          data: [],
          title: fm('graph.tdcsTreatmentsPerWeek'),
          scale: {
            id: 'tdcsTreatmentsPerWeek',
            type: 'linear',
            linearScale: [0, 14],
          },
        },
        symptomsIntensity: {
          data: [],
          title: fm('graph.symptomsIntensity'),
          scale: {
            id: 'symptomsIntensity',
            type: 'linear',
            linearScale: [0, 10],
          },
          // Additional scale to prevent using reference graph
          additionalScale: {
            type: 'linear',
            linearScale: [0, 10],
          },
        },
        symptomsHarm: {
          data: [],
          title: fm('graph.symptomsHarm'),
          scale: {
            id: 'symptomsHarm',
            type: 'linear',
            linearScale: [0, 10],
          },
          // Additional scale to prevent using reference graph
          additionalScale: {
            type: 'linear',
            linearScale: [0, 10],
          },
        },
        bdiAndBai: {
          data: [],
          title: 'BDI & BAI',
          scale: {
            id: 'bdi',
            type: 'linear',
            linearScale: [0, 63],
          },
        },
        gic: {
          data: [],
          title: 'GIC',
          scale: {
            id: 'gic',
            type: 'linear',
            linearScale: [-3, 3],
          },
        },
        sleep: {
          data: [],
          title: fm('graph.sleepQuantityAndQuality'),
          scale: {
            id: 'sleep',
            type: 'linear',
            linearScale: [0, 10],
          },
        },
        episodicSymptoms: {
          data: [],
          title: fm('graph.episodicSymptoms'),
          scale: {
            id: 'episodicSymptoms',
            type: 'custom',
            customScale: ['0', '1OrLess', '2-5', '6-10', 'moreThan10'],
            formatter: (title: string) => {
              if (title === '0') return title;
              if (title === '1OrLess') return '1';
              if (title === 'moreThan10') return '> 10';
              const locales = treatmentMonitoringInquiry.localizations[uiLang];
              return locales[`opts.${title}`];
            },
          },
          additionalScale: {
            type: 'custom',
            customScale: ['0', 'lessThan1Minute', '1-5Minutes', '5-60Minutes', 'moreThan60Minutes'],
            formatter: (title: string) => {
              const locales = treatmentMonitoringInquiry.localizations[uiLang];
              if (title === '0') return '0';
              return locales[`opts.${title}`];
            },
          },
        },
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: false,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    graphMenuSettings: {
      onlyOneOpen: true,
    },
    addonData: {
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('diagnosis.title'),
        addons: [],
      },
      patientReportedMedications: {
        id: 'patientReportedMedications',
        type: 'single',
        name: fm('graph.patientReportedMedications'),
        addons: [],
      },
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('graph.medications'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          Object.keys(atcOpts ?? {})
            .filter((code) => atcCodes.includes(code))
            // N01BX04 'kapsaisiini' filtered out as its part of M02
            .filter((c) => c !== 'N01BX04')
            .map((code) => {
              return { title: `${code} - ${atcOpts?.[code]}` };
            }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('medication.endedMedications'),
        addons: [],
      },
      treatments: {
        id: 'ninmtTreatments',
        type: 'expansionPanel',
        name: fm('graph.treatments'),
        addons: [],
      },
      doctorsOrders: {
        id: 'doctorsOrders',
        type: 'single',
        name: fm('doctorsOrders.title'),
        addons: [],
      },
      imaging: {
        id: 'imaging',
        type: 'single',
        name: fm('imaging.title'),
        addons: [],
      },
    },
  };
};

export default ninmtData;
