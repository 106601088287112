import { equals, path } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import EventSharpIcon from '@mui/icons-material/EventSharp';
import ClearIcon from '@mui/icons-material/Clear';
import Popover from '@mui/material/Popover';
import { connect } from 'react-redux';

import Select from './Select';

import {
  formatPartialDate,
  nowPartialDate,
  partialDateToValue,
  isPartialDate,
  ssnAge,
  subtractYears,
  addYears,
} from 'neuro-utils';
import colors from '../../../config/theme/colors';
import ToolTip from '../../ToolTip';
import { historyValue, field } from '../../../config/theme/componentTheme';
import { Container, Item } from '../../Grid';
import customHooks from '../../../utility/customHooks';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import IconButton from '@mui/material/IconButton';
import { getJWTData } from 'Utility/jwtAuthTools';

const StyledDiv = styled('div')({
  width: '100%',
});

const EventSharpIconButtonStyled = styled(IconButton)({
  color: colors.secondaryText,
  paddingTop: '0.4rem',
  cursor: 'pointer',
  '&:hover': {
    color: colors.secondary,
  },
});

const ClearIconButtonStyled = styled(IconButton)({
  color: colors.secondaryText,
  paddingTop: '0.4rem',
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

/**
 * Generate years options array
 * @param {number} addToYearFloor How many years into the past are generated
 * @param {number} addToYearCeiling How many years into the future
 * @return {number[]} - Number array
 */
const makeYearsArray = (
  addToYearFloor: number,
  addToYearCeiling = 0,
  dateHook?: IPartialDate['dateHook'],
): number[] => {
  const yearArray = [];
  const now = nowPartialDate();
  const dateHookFloor = dateHook?.dateHookFloor;
  const dateHookCeiling = dateHook?.dateHookCeiling;
  for (
    let i = dateHookCeiling ? dateHookCeiling[0] : addYears(now, addToYearCeiling)[0];
    i >= (dateHookFloor ? dateHookFloor[0] : subtractYears(now, addToYearFloor)[0]);
    i--
  ) {
    yearArray.push(i);
  }
  return yearArray;
};

// Format month names
const monthFormatter = (month: number | string): JSX.Element | string =>
  month !== '-' ? <FormattedMessage id={'general.monthNames.' + month} /> : '-';

const makeMonthsArray = (
  yearSameAsFloor?: boolean,
  yearSameAsCeiling?: boolean,
  dateHook?: IPartialDate['dateHook'],
): (string | number)[] => {
  let monthArray = [] as (string | number)[];
  // Floor and ceiling same
  if (
    yearSameAsFloor &&
    yearSameAsCeiling &&
    dateHook &&
    partialDateToValue(dateHook.dateHookFloor) < partialDateToValue(dateHook.dateHookCeiling)
  ) {
    monthArray.push('-');
    const floor: number = dateHook.dateHookFloor?.[1] || 1;
    const ceiling: number = dateHook.dateHookCeiling?.[1] || 12;
    for (let i = floor; i <= ceiling; i++) {
      monthArray.push(i);
    }
    // Floor same as values[0]
  } else if (yearSameAsFloor && dateHook) {
    monthArray.push('-');
    const floor: number = dateHook.dateHookFloor?.[1] || 1;
    const ceiling = 12;
    for (let i = floor; i <= ceiling; i++) {
      monthArray.push(i);
    }
    // Ceiling same as values[0]
  } else if (yearSameAsCeiling && dateHook) {
    monthArray.push('-');
    const floor = 1;
    const ceiling: number = dateHook.dateHookCeiling?.[1] || 12;
    for (let i = floor; i <= ceiling; i++) {
      monthArray.push(i);
    }
    // values[0] different than ceiling and floor or no ceiling or floor defined
  } else monthArray = ['-', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return monthArray;
};

// Return number of days in given month (of given year)
const amountOfDays = (year?: number, month?: number): number => {
  if (year && month) {
    if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) {
      return 31;
    } else if (month === 4 || month === 6 || month === 9 || month === 11) {
      return 30;
    } else if ((month === 2 && year % 4 === 0 && year % 100 != 0) || (month === 2 && year % 400 === 0)) {
      return 29;
    } else if (month === 2) {
      return 28;
    }
  }
  return 31;
};

// Validate that the selected date doesn't have more days than should in the given year+month
// Return highest amount of days if selected value is too high
const validateDate = (date: PartialDate): PartialDate => {
  if (date[0] && date[1] && date[2] && amountOfDays(date[0], date[1]) < date[2]) {
    return [date[0], date[1], amountOfDays(date[0], date[1])];
  }
  return date;
};

// Make days options array
const makeDaysArray = (
  monthAndYearSameAsFloor?: boolean,
  monthAndYearSameAsCeiling?: boolean,
  dateHook?: IPartialDate['dateHook'],
  values?: PartialDate | null,
): Array<number | string> => {
  const dayArray = [];
  // Floor and ceiling inside same month and value between them
  if (Array.isArray(values)) {
    if (
      monthAndYearSameAsFloor &&
      monthAndYearSameAsCeiling &&
      dateHook &&
      partialDateToValue(dateHook.dateHookFloor) < partialDateToValue(dateHook.dateHookCeiling)
    ) {
      dayArray.push('-');
      const floor: number = dateHook.dateHookFloor?.[2] || 1;
      const ceiling: number = dateHook.dateHookCeiling?.[2] || 31;
      for (let i = floor; i <= ceiling; i++) {
        dayArray.push(i);
      }
      // values[0] and values[1] same as floor -> array from floor to number of days in the current month
    } else if (monthAndYearSameAsFloor && dateHook) {
      dayArray.push('-');
      const floor: number = dateHook.dateHookFloor?.[2] || 1;
      const ceiling: number = amountOfDays(values?.[0], values?.[1] === null ? undefined : values?.[1]);
      for (let i = floor; i <= ceiling; i++) {
        dayArray.push(i);
      }
      // values[0] and values[1] same as ceiling -> array from 1 to ceiling
    } else if (monthAndYearSameAsCeiling && dateHook) {
      dayArray.push('-');
      const floor = 1;
      const ceiling: number =
        dateHook.dateHookCeiling?.[2] || amountOfDays(values?.[0], values?.[1] === null ? undefined : values?.[1]);
      for (let i = floor; i <= ceiling; i++) {
        dayArray.push(i);
      }
      // Otherwise array from 1 to number of days in the current month
    } else {
      dayArray.push(1);
      while (dayArray.length < amountOfDays(values?.[0], values?.[1] === null ? undefined : values?.[1])) {
        dayArray.push(dayArray[dayArray.length - 1] + 1);
      }
      (dayArray as Array<string | number>).unshift('-');
    }
  }
  return dayArray;
};

const parseNumber = (s: string): number | undefined => (isNaN(parseInt(s, 10)) ? undefined : parseFloat(s));

const onChangeDate = (onChange?: IInputBasics['onChange'], name?: string, values?: PartialDate | null) => {
  return (e: any): void => {
    if (onChange && name && Array.isArray(values) && typeof values[0] === 'number') {
      if (e.target.name === name + '_year' && typeof parseNumber(e.target.value) === 'number') {
        values[0] = parseNumber(e.target.value) as number;
      } else if (e.target.name === name + '_month') {
        values[1] = parseNumber(e.target.value) ?? null;
      } else if (e.target.name === name + '_day') {
        values[2] = parseNumber(e.target.value) ?? null;
      }
      onChange({ [name]: values });
    }
  };
};

interface IStateToProps {
  visitreason?: string;
}

const mapStateToProps = (state: { session: ISessionStore }): IStateToProps => ({
  visitreason: state.session.data?.visitreason ?? '',
});

const PartialDate = ({
  editing = false,
  name,
  value,
  onChange,
  addToYearCeiling = 1,
  addToYearFloor,
  dateDefault,
  dateHook,
  isNotCancellable,
  warningMessage,
  showMessage, // Optional function to decide if a warning message is shown
  visitreason,
  monthsAsNumbers,
  hideDatePicker,
  hideDaySelection,
  disableDateSelection,
  enabledDatePickerDates,
  dateFormat = 'yyyy-mm-dd',
}: IInputBasics & IPartialDate & IStateToProps): JSX.Element => {
  const [values, setValues] = React.useState<PartialDate | null | undefined>(null);
  const [calendarOpen, setCalendarOpen] = React.useState<boolean>(false);
  const [clearOpen, setClearOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [warningOpenCalendar, setWarningOpenCalendar] = React.useState<boolean>(false);
  const [warningOpenPartialDate, setWarningOpenPartialDate] = React.useState<boolean>(false);
  const now = nowPartialDate();

  const setValueForPartialDate = (): void => {
    if (equals(value, values) || !editing) return;
    let dateValues: PartialDate | null | undefined = undefined;

    // If date default is set, and no value has yet been entered. Null means the value has been cleared manually and should not use dateDefault to set it again
    if (dateDefault && !isPartialDate(value) && value !== null) {
      switch (dateDefault) {
        // Use current date
        case 'now':
          if (visitreason === 'retrospectiveDataTransfer') {
            dateValues = null;
            setValues(null);
          } else {
            if (dateHook?.dateHookFloor && partialDateToValue(now) < partialDateToValue(dateHook?.dateHookFloor)) {
              dateValues = dateHook.dateHookFloor;
              if (hideDaySelection) {
                dateValues[2] = null;
              }
              setValues(dateValues);
            } else {
              dateValues = now;
              if (hideDaySelection) {
                dateValues[2] = null;
              }
              setValues(dateValues);
            }
          }
          break;
        // Use other default date which needs to be PartialDate
        default:
          dateValues = [...dateDefault];
          // Coping immutable way. If not immutable, generator date will be copied to DBS start date which is wrong.
          setValues(dateValues);
          break;
      }
      // Update default date to form values
      onChangeDate(onChange, name, dateValues)({ target: { name: name, value: dateValues } });
    } else {
      if (isPartialDate(value)) setValues(value as PartialDate);
      // Set values to undefined, if no date default and no valid value.
      // This is needed if the values change within an array, but React does not render the component again. Happened in EventStepper when adding a new event.
      else setValues(null);
    }
  };

  customHooks.useWhenMounted(setValueForPartialDate, []);

  // Save redux value to values if it changes (this solves a bug with component mounting before the value is received from redux)
  React.useEffect(() => {
    setValueForPartialDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const sleep = (milliseconds: number): any => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // Change state on changes to PartialDate field
  const onChangePartialDate = (onChangeValues: TOnChangeValues): void => {
    const onChangeName = Object.keys(onChangeValues)[0];
    const onChangeValue = onChangeValues[onChangeName] as string;
    const currentValues = values === null ? [null, null, null] : values;

    if (onChange && name && currentValues) {
      let show = false;
      let newDate: PartialDate | null | undefined = undefined;
      // Change both field and store values
      if (onChangeName === name + '_year' && typeof parseNumber(onChangeValue) === 'number') {
        newDate = [parseNumber(onChangeValue) as number, currentValues[1], currentValues[2]];
        newDate = validateDate(newDate);
        setValues(newDate);
        onChange({ [name]: newDate });
        show = (showMessage && showMessage(newDate)) ?? false;
      } else if (onChangeName === name + '_month' && typeof currentValues[0] === 'number') {
        newDate = [currentValues[0], parseNumber(onChangeValue) ?? null, currentValues[2]];
        newDate = validateDate(newDate);
        setValues(newDate);
        onChange({ [name]: newDate });
        show = (showMessage && showMessage(newDate)) ?? false;
      } else if (onChangeName === name + '_day' && typeof currentValues[0] === 'number') {
        newDate = [currentValues[0], currentValues[1], parseNumber(onChangeValue) ?? null];
        newDate = validateDate(newDate);
        setValues(newDate);
        onChange({ [name]: newDate });
        show = (showMessage && showMessage(newDate)) ?? false;
      }
      // If dateHook is set and new date is off limits, change it to be within limits
      if (dateHook) {
        if (dateHook.dateHookFloor && partialDateToValue(newDate) < partialDateToValue(dateHook.dateHookFloor)) {
          setValues(dateHook.dateHookFloor);
          onChange({ [name]: dateHook.dateHookFloor });
          show = (showMessage && showMessage(dateHook.dateHookFloor)) ?? false;
        }
        if (dateHook.dateHookCeiling && partialDateToValue(newDate) > partialDateToValue(dateHook.dateHookCeiling)) {
          setValues(dateHook.dateHookCeiling);
          onChange({ [name]: dateHook.dateHookCeiling });
          show = (showMessage && showMessage(dateHook.dateHookCeiling)) ?? false;
        }
      }
      // Decide if a warning message is shown
      if (show) {
        if (!warningOpenPartialDate) {
          setWarningOpenPartialDate(true);
          sleep(6000)
            .then(() => {
              setWarningOpenPartialDate(false);
            })
            .catch(() => {
              setWarningOpenPartialDate(false);
            });
        } else if (warningOpenPartialDate) {
          setWarningOpenPartialDate(false);
        }
      }
    }
  };

  React.useEffect(() => {
    if (value && isPartialDate(value) && value.some((v, i) => v !== values?.[i])) {
      setValues(value);
    }
  }, [values]);

  // Automatically nullify day value if it exists but month value doesn't
  React.useEffect(() => {
    if (Array.isArray(values) && path([0], values) && !path([1], values) && path([2], values)) {
      onChangePartialDate({ [`${name}_day`]: null });
    }
  }, [!path([1], values)]);

  // Changes state on changes to date on calendar
  const onChangeCalendarDate = (date: unknown): void => {
    const dateWithType = date as { c: { day: number; month: number; year: number } };

    const show = (showMessage && showMessage([dateWithType.c.year, dateWithType.c.month, dateWithType.c.day])) ?? false;
    setValues([dateWithType.c.year, dateWithType.c.month, dateWithType.c.day]);
    onChange && onChange({ [name]: [dateWithType.c.year, dateWithType.c.month, dateWithType.c.day] });
    // Decide if a warning message is shown
    if (show) {
      if (!warningOpenCalendar) {
        setWarningOpenCalendar(true);
        sleep(4000)
          .then(() => {
            setWarningOpenCalendar(false);
          })
          .catch(() => {
            setWarningOpenCalendar(false);
          });
      } else if (warningOpenCalendar) {
        setWarningOpenCalendar(false);
      }
    }
  };

  // Handles clicking of the x-button, sets date to undefined
  const handleDeleteClick = (onChange?: IInputBasics['onChange']) => (): void => {
    if (!clearOpen) {
      setClearOpen(true);
      sleep(4000)
        .then(() => {
          setClearOpen(false);
        })
        .catch(() => {
          setClearOpen(false);
        });
    } else if (clearOpen) {
      setValues(null);
      if (onChange) {
        // Partialdate is set to null so that it can be differentiated from undefined
        // If undefined, it will use default date to set new value. Null means the partialdate has no value (has been cleared)
        onChange({ [name]: null });
      }
      setClearOpen(false);
    }
  };

  // Opens the calendar dialog
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setCalendarOpen(true);
    setAnchorEl(e?.currentTarget);
  };

  const closeCalendarDialog = (): void => {
    setCalendarOpen(false);
    setAnchorEl(null);
  };

  // Disables dates before datehook
  const disableDate = (date: DateTime): boolean => {
    const dateWithType = date;
    return Array.isArray(enabledDatePickerDates) && enabledDatePickerDates.length > 0
      ? !enabledDatePickerDates.some((d) => equals([dateWithType.year, dateWithType.month, dateWithType.day], d))
      : (dateHook != null &&
          dateHook.dateHookFloor != null &&
          dateHook.dateHookFloor[0] != null &&
          dateHook.dateHookFloor[1] != null &&
          dateHook.dateHookFloor[2] != null &&
          dateWithType.day < dateHook.dateHookFloor[2] &&
          dateWithType.month === dateHook.dateHookFloor[1] &&
          dateWithType.year === dateHook.dateHookFloor[0]) ||
          (dateHook != null &&
            dateHook.dateHookCeiling != null &&
            dateHook.dateHookCeiling[0] != null &&
            dateHook.dateHookCeiling[1] != null &&
            dateHook.dateHookCeiling[2] != null &&
            dateWithType.day > dateHook.dateHookCeiling[2] &&
            dateWithType.month === dateHook.dateHookCeiling[1] &&
            dateWithType.year === dateHook.dateHookCeiling[0]);
  };

  const dateFormatter = (value: PartialDate) =>
    ({
      'yyyy-mm-dd': formatPartialDate(value),
      'dd.mm.yyyy': formatPartialDate(value).split('-').reverse().join('.'),
    })[dateFormat];

  return !editing ? (
    <StyledDiv style={historyValue}>{dateFormatter(value as PartialDate)}</StyledDiv>
  ) : (
    <Container style={disableDateSelection ? { alignItems: 'center' } : undefined}>
      <Item>
        {disableDateSelection ? (
          <StyledDiv style={historyValue}>{dateFormatter(value as PartialDate)}</StyledDiv>
        ) : (
          <ToolTip
            title={<FormattedMessage id="general.checkDates" />}
            description={warningMessage ? <FormattedMessage id={warningMessage} /> : ''}
            cursor="Default"
            content={
              <div id="partialDate">
                <Container style={{ width: hideDaySelection ? '18.4rem' : field.width }}>
                  <Item style={{ width: hideDaySelection ? '42%' : '32%', paddingRight: '0.8rem' }}>
                    <Select
                      type="Select"
                      editing={true}
                      name={name + '_year'}
                      value={path([0], values) || ''}
                      placeholder={<FormattedMessage id="general.year" />}
                      onChange={onChangePartialDate}
                      options={makeYearsArray(
                        (ssnAge(getJWTData()?.patientssn ?? '') ?? 49) + 1 + (addToYearFloor ? addToYearFloor : 0),
                        addToYearCeiling,
                        dateHook,
                      )}
                      minWidth={0}
                    />
                  </Item>
                  <Item style={{ width: hideDaySelection ? '58%' : '48%', paddingRight: '0.8rem' }}>
                    <Select
                      type="Select"
                      editing={true}
                      name={name + '_month'}
                      value={path([1], values) || ''}
                      placeholder={monthsAsNumbers ? undefined : <FormattedMessage id="general.month" />}
                      onChange={onChangePartialDate}
                      // Use hook if year selected is the same as the year in the hook
                      options={makeMonthsArray(
                        dateHook?.dateHookFloor && dateHook?.dateHookFloor?.[0] === values?.[0],
                        dateHook?.dateHookCeiling && dateHook?.dateHookCeiling?.[0] === values?.[0],
                        dateHook,
                      )}
                      optionFormatter={monthsAsNumbers ? undefined : monthFormatter}
                      minWidth={0}
                      disabled={!path([0], values)}
                    />
                  </Item>
                  {!hideDaySelection && (
                    <Item style={{ width: '20%' }}>
                      <Select
                        type="Select"
                        editing={true}
                        name={name + '_day'}
                        value={path([2], values) || ''}
                        placeholder={monthsAsNumbers ? undefined : <FormattedMessage id="general.day" />}
                        onChange={onChangePartialDate}
                        options={makeDaysArray(
                          dateHook?.dateHookFloor &&
                            dateHook?.dateHookFloor?.[0] === values?.[0] &&
                            dateHook?.dateHookFloor?.[1] === values?.[1],
                          dateHook?.dateHookCeiling &&
                            dateHook?.dateHookCeiling?.[0] === values?.[0] &&
                            dateHook?.dateHookCeiling?.[1] === values?.[1],
                          dateHook,
                          values,
                        )}
                        minWidth={0}
                        disabled={!path([0], values) || !path([1], values)}
                      />
                    </Item>
                  )}
                </Container>
              </div>
            }
            open={warningOpenPartialDate}
          />
        )}
      </Item>
      <Item style={{ marginLeft: '2rem' }}>
        <Container>
          <Item style={disableDateSelection ? { paddingTop: '0.1rem' } : undefined}>
            <ToolTip
              title={<FormattedMessage id="general.warning" />}
              description={warningMessage ? <FormattedMessage id={warningMessage} /> : ''}
              content={<span />}
              open={warningOpenCalendar}
            />
            {!hideDatePicker && (
              <React.Fragment>
                <EventSharpIconButtonStyled onClick={handleOpen} edge="start">
                  <EventSharpIcon />
                </EventSharpIconButtonStyled>
                <div id="datePicker">
                  <Popover
                    id="datepicker"
                    open={calendarOpen}
                    anchorEl={anchorEl}
                    onClose={(): void => {
                      closeCalendarDialog();
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  >
                    <StaticDatePicker<DateTime>
                      displayStaticWrapperAs="desktop"
                      onChange={onChangeCalendarDate}
                      value={
                        dateHook?.dateHookFloor &&
                        values &&
                        values !== null &&
                        new Date(
                          `${dateHook.dateHookFloor[0]}-${dateHook.dateHookFloor[1]}-${dateHook.dateHookFloor[2]}`,
                        ) > new Date(`${values[0]}-${values[1]}-${values[2]}`)
                          ? DateTime.fromObject({
                              year: dateHook.dateHookFloor[0],
                              month: dateHook.dateHookFloor[1] || undefined,
                              day: dateHook.dateHookFloor[2] || undefined,
                            })
                          : values
                            ? DateTime.fromObject({
                                year: values[0],
                                month: values[1] || undefined,
                                day: values[2] || undefined,
                              })
                            : null
                      }
                      shouldDisableDate={disableDate}
                      //id="date-picker-dialog"
                      minDate={
                        addToYearFloor
                          ? DateTime.fromObject({
                              year: new Date().getFullYear() - addToYearFloor - 49,
                              month: 1,
                              day: 1,
                            }) // Set to first of january
                          : dateHook?.dateHookFloor?.[0] && dateHook?.dateHookFloor?.[1] && dateHook?.dateHookFloor?.[2]
                            ? DateTime.fromObject({
                                year: dateHook.dateHookFloor[0],
                                month: dateHook.dateHookFloor[1],
                                day: dateHook.dateHookFloor[2],
                              })
                            : DateTime.fromObject({ year: new Date().getFullYear() - 49, month: 1, day: 1 })
                      }
                      maxDate={
                        dateHook?.dateHookCeiling?.[0] &&
                        dateHook?.dateHookCeiling?.[1] &&
                        dateHook?.dateHookCeiling?.[2]
                          ? DateTime.fromObject({
                              year: dateHook.dateHookCeiling[0],
                              month: dateHook.dateHookCeiling[1],
                              day: dateHook.dateHookCeiling[2],
                            })
                          : DateTime.fromObject({
                              year: new Date().getFullYear() + addToYearCeiling,
                              month: 12,
                              day: 31,
                            })
                      }
                      onAccept={closeCalendarDialog}
                    />
                  </Popover>
                </div>
              </React.Fragment>
            )}
          </Item>
          {!isNotCancellable ? (
            <Item style={{ marginLeft: '-1rem' }}>
              <ToolTip
                title={<FormattedMessage id="general.erase" />}
                description={<FormattedMessage id="general.clearDateMessage" />}
                content={
                  <ClearIconButtonStyled onClick={handleDeleteClick(onChange)}>
                    <ClearIcon />
                  </ClearIconButtonStyled>
                }
                open={clearOpen}
              />
            </Item>
          ) : null}
        </Container>
      </Item>
    </Container>
  );
};

const ConnectedPartialDate = connect(mapStateToProps, null)(PartialDate);

export default ConnectedPartialDate;
