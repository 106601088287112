import { INeuroDocument } from 'neuro-data-structures';
import { append, assocPath, equals, filter, mergeDeepRight, path } from 'ramda';
import { omitControlProps } from '../../utility/documentHandling';
import { getJWTData } from '../../utility/jwtAuthTools';
import { createAction, createReducer } from '@reduxjs/toolkit';
import store from '../index';
import { omit } from 'Utility/ramdaReplacement';

/* Action types */
const SET_EDITING = 'neuro-ui/form/SET_EDITING';
const UNSET_EDITING = 'neuro-ui/form/UNSET_EDITING';
const SET_VIEWING = 'neuro-ui/form/SET_VIEWING';
const UNSET_VIEWING = 'neuro-ui/form/UNSET_VIEWING';
const LOAD_ALL_EDITING = 'neuro-ui/form/LOAD_ALL_EDITING';
const LOAD_FORM = 'neuro-ui/form/LOAD_FORM';
const CLEAR_FORM = 'neuro-ui/form/CLEAR_FORM';
const UPDATE_FORM = 'neuro-ui/form/UPDATE_FORM';
const UPDATE_FORM_BATCH = 'neuro-ui/form/UPDATE_FORM_BATCH';

/*
  Action creators
*/

// Update editing array when editing starts
const setEditing = createAction(SET_EDITING, (name: string, id: string) => {
  return {
    payload: { data: { name, id } },
  };
});

// Remove id from editing array
const unsetEditing = createAction(UNSET_EDITING, (id: string) => {
  return {
    payload: { data: id },
  };
});

// Update viewing array when viewing starts
const setViewing = createAction(SET_VIEWING, (name: string, id: string) => {
  return {
    payload: { data: { name, id } },
  };
});

// Remove id from viewing array
const unsetViewing = createAction(UNSET_VIEWING, (id: string) => {
  return {
    payload: { data: id },
  };
});

// Load all documents currently being edited
const loadEditingDocuments = createAction(LOAD_ALL_EDITING, (documents: INeuroDocument[]) => {
  const useruuid = getJWTData()?.useruuid || null;
  const unFinishedDocs = filter(
    (d) => filter((c) => c.commitDate === null && c.creatorId === useruuid, d.commits).length !== 0,
    documents,
  );
  const editingDocuments = unFinishedDocs.map((d) => ({ name: d.documentType, id: d.documentId }));
  return {
    payload: { data: editingDocuments },
  };
});

// Load forms values that are loaded when form is opened in editing/viewing mode
const loadFormValues = createAction(LOAD_FORM, (data?: { data: TAnyObject }) => {
  if (data) {
    return {
      payload: data,
    };
  } else {
    return { payload: { data: null } };
  }
});
// Perform check for formValues and whether they should be updated, then load
const checkForLoadFormValues =
  (data: TAnyObject & IControlProps) =>
  (dispatch: any): void => {
    const getState = store.getState;

    const formData = path(['form', 'formData'], getState()) || [];
    const formattedData =
      data && data._id
        ? {
            data: {
              // Dont load control props to form data, they will get saved to server as well
              [data._id]: omitControlProps(data),
            },
          }
        : undefined;
    if (!equals(formData, formattedData?.data)) dispatch(loadFormValues(formattedData));
  };

// Clear form data from form state
const clearFormValues = createAction(CLEAR_FORM, (id: string) => {
  return {
    payload: { data: id },
  };
});

// Update form values in state on every onChange input action
const updateFormValues = createAction(UPDATE_FORM, (id: string, values: TOnChangeValues) => {
  const names = Object.keys(values);
  let handledValues = {};
  names.forEach((n) => {
    const value = values[n];
    const path = n.split('.'); // Some forms use dots in the field name to assign the value in lower hierarchy objects
    handledValues = { ...handledValues, ...assocPath(path, value, {}) };
  });

  return {
    payload: { data: { [id]: handledValues } },
  };
});

// Update multiple form values at once
const updateFormValuesBatch = createAction(UPDATE_FORM_BATCH, (values: TAnyObject, id: string) => {
  return {
    payload: { data: { [id]: values } },
  };
});

export const actions = {
  setEditing,
  unsetEditing,
  setViewing,
  unsetViewing,
  loadEditingDocuments,
  checkForLoadFormValues,
  clearFormValues,
  updateFormValues,
  updateFormValuesBatch,
};

const initialState: IFormStore = { editingDocuments: [], viewingDocuments: [], formData: {} };

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEditing, (state, action) => {
      state.editingDocuments = append(action.payload.data, state.editingDocuments ?? []);
    })
    .addCase(unsetEditing, (state, action) => {
      state.editingDocuments = filter((d) => d.id !== action.payload.data, state.editingDocuments ?? []);
    })
    .addCase(setViewing, (state, action) => {
      state.viewingDocuments = append(action.payload.data, state.viewingDocuments ?? []);
    })
    .addCase(unsetViewing, (state, action) => {
      state.viewingDocuments = filter((d) => d.id !== action.payload.data, state.viewingDocuments ?? []);
    })
    .addCase(loadEditingDocuments, (state, action) => {
      state.editingDocuments = action.payload.data;
    })
    .addCase(loadFormValues, (state, action) => {
      if (action.payload.data !== null) {
        state.formData = { ...state.formData, ...action.payload.data };
      }
    })
    .addCase(clearFormValues, (state, action) => {
      state.formData = omit([action.payload.data], state.formData);
    })
    .addCase(updateFormValues, (state, action) => {
      state.formData = mergeDeepRight(state.formData ?? {}, action.payload.data);
    })
    .addCase(updateFormValuesBatch, (state, action) => {
      state.formData = mergeDeepRight(state.formData ?? {}, action.payload.data);
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
