import { DateTime } from 'luxon';
import {
  sortDate,
  partialDateToValue,
  getSSNAgeInWholeMonths,
  calculatePartialDateDifferenceInMonths,
  exists,
  isPartialDate,
} from 'neuro-utils';
import { intersperse, keys, values } from 'ramda';
import { fieldNameToCodeString } from 'Routes/Background/utils';
import { getJWTData } from '../../../utility/jwtAuthTools';
import { a2String } from '../../../utility/string';

/**
 * Format strength into a string by given key
 * @param s
 */
export const formatStrengthStringKeys = (s: TAnyObject): string => a2String(intersperse(' / ', keys(s)));

/**
 * Format strength into a string by given values
 * @param s
 */
export const formatStrengthStringValues = (s: { [key: string]: string }): string =>
  a2String(intersperse(' / ', values(s)));

/**
 * Format medication substances to match other tooltips
 * @param s
 */
export const formatMedicationSubstances = (s: string): string => s.replace(/;/g, ' /');

/**
 * TODO: This exists in neuro-utils, but without 'time'. Should probably be moved/combined?
 */
/**
 * Convert a PartialDate to Date
 * @param {PartialDate} partialDate - the PartialDate to convert
 * @param {Time} time - time to include in the converted date
 * @returns {Date} - PartialDate converted to Date
 */
export const dateFromPartial = (partialDate: PartialDate, time?: Time): Date => {
  const year = partialDate[0];
  const month = partialDate[1] || 1;
  const date = partialDate[2] || 1;
  if (time) return new Date(year, month - 1, date, time?.[0], time?.[1]);
  return new Date(year, month - 1, date);
};

/**
 * TODO: This should probably be moved to neuro-utils
 */
/**
 * Formats a Date to string
 * @param {Date} date - Date to be formatted
 * @returns {string} - date converted to string in yyyy-MM-dd format
 */
export const formatDate = (date: Date) => DateTime.fromJSDate(date).toISODate();

/**
 * TODO: This should probably be moved to neuro-utils
 */
/**
 * Find next Date in a list of Dates
 * @param {PartialDate} currDate - the date to find the next date for
 * @param {Date[]} dates - list of Dates
 * @returns {Date} - the next Date or a new Date if not found
 */
export const findNextDate = (currDate: PartialDate, dates: Date[]): Date => {
  return (
    dates.sort((n1, n2) => sortDate(n1, n2)).filter((d) => d.getTime() > partialDateToValue(currDate))?.[0] ||
    new Date()
  );
};

/**
 * Round and format value to it can be shown on top of care bar next to event icon. For now, max 4-digit (excluding decimal separator) values are permitted.
 * @param {string} value - The (numeric) value to be formatted
 * @returns {string | undefined} - The formatted value or undefined if it doesn't fit the format
 */
export const roundValue = (value: string): string | undefined => {
  if (typeof value === 'string') {
    if (['.', ','].includes(value.charAt(1))) {
      return parseFloat(value).toFixed(2).charAt(3) === '0'
        ? parseFloat(value).toFixed(2).substring(0, 3)
        : parseFloat(value).toFixed(2);
    }
    if (['.', ','].includes(value.charAt(2))) {
      return parseFloat(value).toFixed(1).charAt(3) === '0'
        ? parseFloat(value).toFixed(1).substring(0, 2)
        : parseFloat(value).toFixed(1);
    }
    if (value.length > 0 && value.length < 5) {
      return value;
    }
    return undefined;
  }
  return undefined;
};

/**
 * Function for finding the latest weight value within given (6) months. Is supposed to work at least in epilepsy platform (d.weight vs d.weights)
 * @param {IMeasurement[]} docs - array of documents that include background documents
 * @param {PartialDate | undefined} date - the date of the point in time from where the latest weight value is looked for
 * @param {6 | number} months - number of monthts that defines how recent the weight information has to be
 * @returns {number | undefined} - latest weight as a number if it can be found, undefined if not
 */
export const getLatestWeight = (
  docs: IMeasurement[],
  date: PartialDate | undefined,
  months = 6,
): string | undefined => {
  const weightDocs: IMeasurement[] = [];
  const weightCode = fieldNameToCodeString('weight');
  docs.forEach((d) => {
    if (d._type !== 'measurement') return;
    if (d.code !== weightCode) return;
    isPartialDate(d.date) && exists(d.value) && weightDocs.push(d);
  });

  let latestWeightDoc: IMeasurement | undefined = undefined;
  let minMonthDifference: number;

  weightDocs.forEach((d) => {
    const monthDifference = Math.abs(calculatePartialDateDifferenceInMonths(d.date as PartialDate, date));
    if ((!exists(minMonthDifference) || monthDifference < minMonthDifference) && monthDifference <= months) {
      minMonthDifference = monthDifference;
      latestWeightDoc = d;
    }
  });

  return latestWeightDoc ? (latestWeightDoc as IMeasurement)?.value : undefined;
};

/**
 * Function for checking if we want to render dosage / patient's weight information on the tooltip
 * Requires the patient to be younger than 15 years at the time of the dosage
 * For patients older than 1 year, requires us to have at most 6 months old information about the weight
 * For patients younger than 1 year, requires us to have at most 2 months old information about the weight
 * @param {{ docs: IMeasurement[]; dosageDate: PartialDate | undefined; atc: string }} props - documents including the weight docs, date from which to look into past/future, atc code to see if it is N03*
 * @returns {boolean} - true if value is to be rendered, false if not
 */
export const renderDosagePerWeight = (props: {
  docs: IMeasurement[];
  dosageDate: PartialDate | undefined;
  atc: string;
}): boolean => {
  const age = getSSNAgeInWholeMonths(getJWTData()?.patientssn ?? '', props.dosageDate) ?? 1200;
  const latestWeight = getLatestWeight(props.docs, props.dosageDate, age < 12 ? 2 : 6);
  if (latestWeight && age <= 179 && props.atc.substring(0, 3) === 'N03') return true;
  return false;
};

/**
 * Check if given medication is to be included in graph addons
 * @param {string} medication - ATC code of the medication to be checked
 * @param {string[]} atcCodes - list of ATC codes of disease modifying medications
 * @param {boolean} isClinicalStudy - boolean flag for clinical study medications (medication is included if true)
 * @returns {boolean} - true if given medication has a match in list of given ATC codes or if it is clinical study, false if not
 */
export const includeMedication = (
  metadata: { atc: string | undefined; medicationName: string | undefined },
  atcCodes: string[],
  isClinicalStudy?: boolean,
  platform?: string,
): boolean => {
  if (platform === 'sma' && metadata.medicationName?.toLowerCase().includes('spinraza')) return true;
  if (isClinicalStudy) return true;
  if (!metadata.atc || typeof metadata.atc !== 'string') return false;
  let isInList = false;
  atcCodes.forEach((d) => {
    if (d === (metadata.atc ?? '')?.substring(0, d.length)) {
      isInList = true;
    }
    return;
  });
  return isInList;
};

/**
 * Function for checking other restrictions that might limit list of medications to be shown
 * @param {string} platform - platform
 * @param {IDiagnosis[]} allDocs - list of all documents
 * @returns {boolean} - true if medication passes all checks, false if not
 */
export const shouldMedBeDrawnByOtherRestrictions = (
  platform: string,
  allDocs: IDiagnosis[],
  metadata: { atc: string | undefined; medicationName: string | undefined },
): boolean => {
  const noSleepApnea = !allDocs.find((d) => {
    if (
      d._type === 'diagnosis' &&
      'diagnosis' in d &&
      (d.diagnosis === 'G47.3' || d.diagnosis === 'sleepApneaSuspicion')
    )
      return true;
    return false;
  });

  // If atc is N06BA14, draw the addon only if diagnosis G47.3 or SleepApneaSuspicion can be found (not when diagnosis is J96.1 OR J96.9)
  if (
    platform === 'sleepApnea' &&
    metadata?.atc === 'N06BA14' &&
    // If no sleep apnea (or suspicion) is found and false is returned by the function, which leads to n06ba14 not being drawn
    noSleepApnea
  ) {
    return false;
  }
  return true;
};

export const getNinmtTreatmentPeriodsDateValues = (treatmentPeriodDocs: Array<ININMTTreatmentPeriod>): number[] => {
  return treatmentPeriodDocs
    .map((tp) => partialDateToValue(tp?.date))
    .filter((value) => value > 0)
    .sort((a, b) => a - b);
};
