/**
 * Create an object from given keys array and values array.
 * @param {Array<string>} keys
 * @param {Array<any>} values
 * @returns {object}
 */
export const objectFromArrays = (keys: Array<string>, values: Array<any>): object => {
  let o = {};
  keys.length === values.length && keys.forEach((k: string, i: number) => (o = { ...o, [k]: values[i] }));
  return o;
};

/**
 * Get keys from object while preserving type information.
 * @param obj An object to get the keys from.
 * @returns An array of typed keys.
 */
export const getKeys = <T extends TAnyObject>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as Array<keyof T>;
};
