import { TDescriptionTable } from 'Components/sq-graphics/interfaces';

/**
 * Returns a localized value of something in a given data doc.
 */
export type FDataFieldLocalizer<DocType> = ((dataDoc: DocType) => string) | undefined;

/**
 * Create _n_ descriptions (`TDescriptionTable`) from a given data doc.
 *
 * @param dataDoc Data document to create the descriptions from.
 * @param descriptionSources List of entries in the datadoc that produce descriptions, and the corresponding localizers.
 * @returns A graph "description table".
 */
function createGraphDescriptions<DataDocument>(
  dataDoc: DataDocument,
  descriptionSources: Array<{
    dataKey: keyof DataDocument;
    localizers: {
      /** Localizer for the title of the description. */
      title?: FDataFieldLocalizer<DataDocument>;
      /** Localizer for the "values" of the description. */
      values?: FDataFieldLocalizer<DataDocument>;
    };
  }>,
): TDescriptionTable {
  const descriptionTable: TDescriptionTable = [];

  // create a description from each source
  for (const source of descriptionSources) {
    // don't create descriptions from undefined entries
    if (dataDoc[source.dataKey] === undefined) continue;

    // create the description
    const description: TDescriptionTable[number] = {};
    if (source.localizers.title) description.title = source.localizers.title(dataDoc);
    if (source.localizers.values) {
      description.values = source.localizers.values(dataDoc);
    }
    if (!description.title && !description.values) continue;

    descriptionTable.push(description);
  }

  return descriptionTable;
}

export default createGraphDescriptions;
